// Used by ProjectMainPage.jsx or ProjectMasterReview.jsx. This is the top box of the project master
// page that holds information such as the tagline, team leader, and roles to be filled.

import React from 'react';
import { QueryLink } from 'utils/Utils';
import PropTypes from 'prop-types';

export default class ProjectTopBox extends React.Component {
	static propTypes = {
		videoLink: PropTypes.string
	}

	componentDidMount = this.renderPlayer;
	componentDidUpdate = this.renderPlayer;

	renderPlayer() {
		const { videoLink } = this.props;
		if (!videoLink) {
			return
		}

		let indexString = videoLink.indexOf("embed/");
		let videoId;
		if (indexString >= 0) {
			videoId = videoLink.substr(indexString + 6);
		} else {
			indexString = videoLink.indexOf("youtu.be/");
			videoId = videoLink.substr(indexString + 9);
		}

		let tryLoadPlayer = () => {
			if (!YT || !YT.Player) {
				return setTimeout(tryLoadPlayer, 100);
			}

			new YT.Player('player', {
				height: '100%',
				width: '100%',
				videoId,
				events: {
					onReady: event => {
						this.props.handleDisplayVideoOrLogo();
					},
					// onStateChange: event => {},
				}
			});
		}

		tryLoadPlayer();
	}

	render = () => {
		return (
			<div style={{height: '100%'}}>
				<div id="player" style={{height: '100%'}}></div>
			</div>
		);
	}
};