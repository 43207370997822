import React from 'react';
import NavBarNotifications from '../nav/NavBar_Notifications.jsx';
import Footer from '../footer/Footer.jsx';
import PropTypes from 'prop-types';

export default class ImageSuggestions extends React.Component {
	static contextTypes = {
		router: PropTypes.object
	}

	render = () => {
		return(
			<div className="viewport_width">
				<NavBarNotifications
					showLoginButton={true}
					showSignupButton={true}
				/>
					<div className="signup_hero" style={{textAlign: "center"}}>
						<img src="images/kb_hero.svg" style={{width: "150px", height: "150px", marginTop: "2em"}}/>
					</div>

					<div className="clear"></div>

					<div>
						<div className="signup_container">
							<div className="not_found_container">
								<h4>Don't know what image to use?</h4>
								<p>If you are an artist, coming up with an image or logo that best represents
								your project should be simple. Just draw one. However, for those of us not
								artistically inclined, here are some suggestions on what to do instead.</p>
								<h4>Take a picture</h4>
								<p>The easiest way to come up with a project image is to simply take a picture. It doesn't
								have to be an exact representation of what you want to do. It just has to give people
								an idea of what your project is about. For example, let's say you want to create
								phone cases that will make phones look like animals. You could take a picture of your current
								phone case and post that as your project image. Maybe
								you want to create the next, great tabletop game. Use a picture of your favorite tabletop
								game. What if you want to develop a website that allows parents to donate old baby clothes
								directly to other families with newborns? Take photos of some baby clothes. Whatever image
								you decide on, just make sure you own it or that it is royalty free to avoid copyright
								infringement claims.
								</p>
								<div style={{width: "20em", margin:'0em auto', textAlign: "center"}}>
									<img src="images/image_suggestion_01.png" style={{width:'100%', marginTop: "2em", marginBottom: "2em", border:"1px #D8D8D8 solid"}}/>
								</div> 

								<h4>Draw a simple picture</h4>
								<p>If you cannot think of a picture to use that would represent your project, draw a
								simple illustration. Even a roughly done sketch is better than some stock logo. Most of
								us have the ablility to draw stick figures or basic shapes. Remember, the point of the
								project image is to communicate an idea, so as long as your drawing does that, you're
								good to go. Here is a sample illustration I did in five minutes for a hypothetical
								project named "Drone Crane". Can you roughly tell what this project is about?
								</p>
								<div style={{width: "100%", textAlign: "center"}}>
									<img src="images/image_suggestion_02.png" style={{marginTop: "2em", marginBottom: "2em", border:"1px #D8D8D8 solid"}}/>
								</div>

								<h4>Use an image of text</h4>
								<p>This is more of a last resort. If you cannot think of a picture or a simple drawing you
								could use, just create an image that has a description of your project in text. For instance,
								let's say you want to create a video game called "Rainbow Unicorn Warrior". "Rainbow Unicorn
								Warrior" would be the name of your project. For the project image you could have text that
								says, "2D Sidescroller Video Game". An illustration of a unicorn would probably work much
								better, but in this case, descriptive text would be acceptable.
								</p>
								<div style={{width: "100%", textAlign: "center"}}>
									<img src="images/image_suggestion_03.png" style={{marginTop: "2em", marginBottom: "2em", border:"1px #D8D8D8 solid"}}/>
								</div> 
							</div>
						</div>
					</div>
				<div className="bottom_padding"></div>
				<Footer />
			</div>
		);
	}
};
