// This is the module that is used in the Project Detail Box section of the project page.
// It is used for the Target Market, Competitive Advantage, and Earning Money options that a user
// may or may not fill out.

import React from 'react';
import PropTypes from 'prop-types';

export default class ProjectDetailElement extends React.Component {
	static propTypes = {
		title: PropTypes.string,
		text: PropTypes.string
	}
	render = () => {
		return (
			<div>
				<h4>{this.props.title}</h4>
				<p>{this.props.text}</p>
			</div>
		);
	}
};