// This is the first page a user is directed to when signing up.

import React from 'react';
import HTTP from '../../utils/Http';
import { Link } from 'react-router-dom';
import { QueryLink } from 'utils/Utils';
import NavBarNotifications from '../nav/NavBar_Notifications.jsx';
import Footer from '../footer/Footer.jsx';
import PropTypes from 'prop-types';
import history from 'app/history';

export default class AdminPage extends React.Component {
	state = {
		kreators: [],
		projects: [],
		reports: [],
		elementToPublishId: "",
		elementToPublishType: "",
		elementToPublishName: "",
		publishWarningVisibility: "hidden",
		errPublish: "hidden",
		errPublishText: "",
		elementToRejectId: "",
		elementToRejectType: "",
		elementToRejectName:"",
		rejectText:"",
		rejectWarningVisibility: "hidden",
		errReject: "hidden",
		errRejectText: "",
		opacity: "1",
		pointerEvents: "all",
		kreatorToUnpublishId: "",
		projectToUnpublishId: "",
		elementToUnpublishType: "",
		unpublishText: "",
		unpublishWarningVisibility: "hidden",
		unpublishResponseSuccessVisibility: "hidden",
		elementToArchiveId: "",
		archiveWarningVisibility: "hidden"
	};

	componentDidMount() {
		HTTP.get('/admin/kreators', (error, body) => {
			if (error) return alert(error);

			this.setState({ kreators: body.kreators});
		});

		HTTP.get('/admin/projects', (error, body) => {
			if (error) return alert(error);

			this.setState({ projects: body.projects});
		});

		HTTP.get('/admin/reports?page=1', (error, body) => {
			if (error) return alert(error);

			this.setState({ reports: body.reports});
			console.log(body.reports);
		});
	}

	handleShowPublishWarning(elementId, elementType, elementName) {
		this.setState({
			publishWarningVisibility: "visible_block",
			elementToPublishId: elementId,
			elementToPublishType: elementType,
			elementToPublishName: elementName,
			opacity: "0.5",
			pointerEvents: "none"
		});
	}

	handleCancelPublish = e => {
		this.setState({
			publishWarningVisibility: "hidden",
			elementToPublishId: "",
			elementToPublishType: "",
			elementToPublishName: "",
			opacity: "1",
			pointerEvents: "all"
		});
	}

	handleShowRejectWarning(elementId, elementType, elementName){
		this.setState({
			rejectWarningVisibility: "visible_block",
			elementToRejectId: elementId,
			elementToRejectType: elementType,
			elementToRejectName: elementName,
			opacity: "0.5",
			pointerEvents: "none"
		});
	}

	handleCancelReject = e => {
		this.setState({
			rejectWarningVisibility: "hidden",
			elementToRejectId: "",
			elementToRejectType: "",
			elementToRejectName: "",
			rejectText: "",
			opacity: "1",
			pointerEvents: "all"
		});
	}

	handleShowUnpublishWarning(elementType) {
		this.setState({
			unpublishWarningVisibility: "visible_block",
			elementToUnpublishType: elementType,
			opacity: "0.5",
			pointerEvents: "none"
		});
	}

	handleCancelUnpublishElement = e => {
		this.setState({
			unpublishWarningVisibility: "hidden",
			kreatorToUnpublishId: "",
			projectToUnpublishId: "",
			elementToUnpublishType: "",
			unpublishText: "",
			opacity: "1",
			pointerEvents: "all"
		});
	}

	showUnpublishResponseSuccess = e => {
		this.setState({
			unpublishWarningVisibility: "hidden",
			unpublishResponseSuccessVisibility: "visible",
			kreatorToUnpublishId: "",
			projectToUnpublishId: "",
			elementToUnpublishType: "",
			unpublishText: ""
		});
	}

	hideUnpublishResponseSuccess = e => {
		this.setState({
			unpublishResponseSuccessVisibility: "hidden",
			opacity: "1",
			pointerEvents: "all"
		});
	}

	handleShowArchiveWarning(elementId) {
		this.setState({
			archiveWarningVisibility: "visible_block",
			elementToArchiveId: elementId,
			opacity: "0.5",
			pointerEvents: "none"
		});
	}

	handleCancelArchiveReport = () => {
		this.setState({
			archiveWarningVisibility: "hidden",
			elementToArchiveId: "",
			opacity: "1",
			pointerEvents: "all"
		});
	}

	createProfileList = kreator => {
		const date = new Date(kreator.dateSubmitted);
		return (
			<div key={kreator._id} style={{clear: 'both', borderBottom: 'solid 1px #EDEDED', height: '3.5em'}}>
				<div style={{width: '15em', overflow: 'hidden', float: 'left'}}>
					<div className="admin_link">
						<QueryLink to={{ pathname:"/profilepage", query: { profileId: kreator._id }}}>
						{kreator.firstName + " " + kreator.lastName}
						</QueryLink>
					</div>
				</div>
				<div style={{float: "left", fontSize: "1em", marginTop: "1em"}}>
					{date.toDateString()}
				</div>
				<button className="admin_button" style={{float: 'right', background: 'red'}} onClick={() => this.handleShowRejectWarning(kreator._id, "kreator", kreator.firstName)}> Reject</button>
				<button className="admin_button" style={{float: 'right', marginRight: '1em'}} onClick={() => this.handleShowPublishWarning(kreator._id, "kreator", kreator.firstName)}> Publish Profile </button>
			</div>
		);
	}

	createProjectsList = project => {
		const date = new Date(project.dateSubmitted);
		return (
			<div key={project._id} style={{clear: 'both', borderBottom: 'solid 1px #EDEDED', height: '3.5em'}}>
				<div style={{width: '15em', overflow: 'hidden', float: 'left'}}>
					<div className="admin_link">
						<QueryLink to={{ pathname:"/projectmainpage", query: { projectId: project._id }}}>
						{project.name}
						</QueryLink>
					</div>
				</div>
				<div style={{float: "left", fontSize: "1em", marginTop: "1em"}}>
					{date.toDateString()}
				</div>
				<button className="admin_button" style={{float: 'right', background: 'red'}} onClick={() => this.handleShowRejectWarning(project._id, "project", project.name)}> Reject</button>
				<button className="admin_button" style={{float: 'right', marginRight: '1em'}} onClick={() => this.handleShowPublishWarning(project._id, "project", project.name)}> Publish Project </button>
			</div>
		);
	}

	createReportsList = report => {
		const date = new Date(report.date);
		var path = "";
		var elementId = "";
		var elementName = "";
		var commentText = "";
		var projectLinkVisibility = "";
		var profileLinkVisibility = "";
		var commentTextVisibility = "hidden";
		var offenderTextVisibility = "hidden";
		var projectWithOffendingComment = "";

		if (report.ref === null){
			return;
		}

		if (report.type === "Kreator"){
			path="/profilepage";
			elementId = report.ref._id;
			elementName = report.ref.firstName + " " + report.ref.lastName;
			projectLinkVisibility = "hidden";
			profileLinkVisibility = "visible_block";
		} else if (report.type === "ProjectComment"){
			path = "/projectmainpage";
			elementId = report.ref.project._id;
			elementName = report.ref.kreator.firstName + " " + report.ref.kreator.lastName;;
			commentText = report.ref.text;
			projectLinkVisibility = "visible_block";
			profileLinkVisibility = "hidden";
			commentTextVisibility = "visible";
			offenderTextVisibility = "visible";
			projectWithOffendingComment = report.ref.project.name;

		} else if (report.type === "ProjectUpdate"){
			path = "/projectmainpage";
			elementId = report.ref.project._id;
			elementName = report.ref.project.name;
			projectLinkVisibility = "visible_block";
			profileLinkVisibility = "hidden";
			projectWithOffendingComment = report.ref.project.name;
		} else {
			path = "/projectmainpage";
			elementId = report.ref._id;
			elementName = report.ref.name;
			projectLinkVisibility = "visible_block";
			profileLinkVisibility = "hidden";
			projectWithOffendingComment = report.ref.name;
		}

		return (
			<div key={report._id} style={{clear: 'both', borderBottom: 'solid 1px black', overflow: 'hidden'}}>
				<div style={{width: '20em', overflow: 'hidden', float: 'left', marginBottom: '1em'}}>
					Report Type: {report.type}<br/>
					Reason: {report.reason}<br/>
					<span className={commentTextVisibility}>Comment Text:</span> {commentText}<br/>
					<div className = {offenderTextVisibility}><span>Offender:</span> {elementName}</div><br/>
					<div className={"admin_link " + projectLinkVisibility}>
						<QueryLink to={{ pathname:path, query: {projectId: elementId }}} style={{color: '#00A4A4', fontSize: '1em'}}>
						 {projectWithOffendingComment}
						</QueryLink>
					</div>
					<div className={"admin_link " + profileLinkVisibility}>
						<QueryLink to={{ pathname:path, query: {profileId: elementId }}} style={{color: '#00A4A4', fontSize: '1em'}}>
						 {elementName}
						</QueryLink>
					</div>
				</div>
				<div style={{float: "left", fontSize: "1em", marginLeft: '1em', width: '19em'}}>
					Date Reported:&nbsp;{date.toDateString()}<br/>
					User Who Reported:&nbsp;
					<QueryLink to={{ pathname:"/profilepage", query: { profileId: report.kreator._id}}} style={{color: '#00A4A4', fontSize: '1em'}}>
						{report.kreator.firstName + " " + report.kreator.lastName}
					</QueryLink><br/>
					<button className="admin_button" style={{float: 'right', marginTop: '1em'}} onClick={() => this.handleShowArchiveWarning(report._id)}> Archive Report </button>
				</div>

			</div>
		);
	}

	handleRejectElementTextChange = e => {
		this.setState({rejectText: e.target.value});
	}

	handleUnpublishKreatorIdChange = e => {
		this.setState({kreatorToUnpublishId: e.target.value});
	}

	handleUnpublishProjectIdChange = e => {
		this.setState({projectToUnpublishId: e.target.value});
	}

	handleUnpublishTextChange = e => {
		this.setState({unpublishText: e.target.value});
	}

	handlePublishElement = e => {
		const { elementToPublishType, elementToPublishId } = this.state;

		HTTP.post(`/admin/publish/${elementToPublishType}`, { id: elementToPublishId, shouldPublish: true }, (error, body) => {
			if (error) return alert(error);

			this.setState({
				[`${elementToPublishType}s`]: [...this.state[`${elementToPublishType}s`]].filter(({ _id }) => elementToPublishId !== _id),
				publishWarningVisibility: 'hidden',
				elementToPublishId: '',
				elementToPublishType: '',
				elementToPublishName: '',
				opacity: '1',
				pointerEvents: 'all',
			});
		});
	}

	handleRejectElement = e => {
		const { elementToRejectType, elementToRejectId } = this.state;
		HTTP.post(`/admin/publish/${elementToRejectType}`, { id: elementToRejectId, shouldPublish: false, rejectionComments: this.state.rejectText}, (error, body) => {
			if (error) return alert(error);

			this.setState({
				[`${elementToRejectType}s`]: [...this.state[`${elementToRejectType}s`]].filter(({ _id }) => elementToRejectId !== _id),
				rejectWarningVisibility: 'hidden',
				elementToRejectId: '',
				elementToRejectType: "",
				elementToRejectName:"",
				rejectText:"",
				opacity: '1',
				pointerEvents: 'all',
			});
		});
	}

	handleUnpublishElement = e => {
		const { elementToUnpublishType, kreatorToUnpublishId, projectToUnpublishId, unpublishText } = this.state;
		var elementToUnpublishId;
		if(elementToUnpublishType === "kreator"){
			elementToUnpublishId = kreatorToUnpublishId;
		} else if (elementToUnpublishType === "project") {
			elementToUnpublishId = projectToUnpublishId;
		}
		HTTP.post(`/admin/publish/${elementToUnpublishType}`, { id: elementToUnpublishId, shouldPublish: false, rejectionComments: this.state.unpublishText }, (error, body) => {
			if (error) return alert(body);

			this.showUnpublishResponseSuccess();
		});
	}

	handleArchiveReport = e => {
		HTTP.post('/admin/report/' + this.state.elementToArchiveId + '/archive', { shouldArchive: true }, (error, body) => {
				if (error) {
					return alert(error);
				} else {
					var currentReports = this.state.reports;
					var i;
					for (i = 0; i < currentReports.length; i++) {
					  if(this.state.elementToArchiveId === currentReports[i]._id) {
					  	currentReports.splice(i, 1);
					  }
					}
					this.setState({
						reports: currentReports,
						archiveWarningVisibility: "hidden",
						elementToArchiveId: "",
						opacity: "1",
						pointerEvents: "all"
					});
				}
			});
	}

	render() {

		return(
			<div>
				<NavBarNotifications />

				<div className="clear"></div>

				<div className={"teammate_invitation_warning " + this.state.publishWarningVisibility} style={{width: "35em", minHeight: "8em"}}>
					<p style={{margin: "1em 0 0.5em 5em", float: "left"}}>
						Are you damn sure you want to publish {this.state.elementToPublishName}?
					</p>
					<br/>
					<span className={this.state.errPublish + " error"} style={{float: "left", margin: '0.5em 0em 0.5em 5em'}}>{this.state.errPublishText}</span>
					<div className="clear"></div>
					<div style={{width:"20em", margin: "0.5em auto 1.5em auto"}}>
						<button className="delete_teammate_warning_cancel" onClick={this.handleCancelPublish}>Cancel</button>
						<button className="delete_teammate_warning_cancel" style={{float: "right"}} onClick={this.handlePublishElement}>I am Sure</button>
					</div>
				</div>

				<div className={"teammate_invitation_warning " + this.state.rejectWarningVisibility} style={{width: "35em", minHeight: "8em"}}>
					<p style={{margin: "1em 0 0.5em 5em", float: "left"}}>
						Are you sure you want to reject {this.state.elementToRejectName}?
					</p>
					<div style={{width: "35em", textAlign: "center"}}>
					<textarea rows="4" cols="50" style={{width: "30em", padding: "0.5em", fontFamily:"Avenir, Futura, Helvetica Neue, Helvetica, sans-serif", fontSize: "1em"
}} onChange={this.handleRejectElementTextChange} value = {this.state.rejectText} placeholder="Enter reason for rejection."></textarea>
					</div>
					<br/>
					<span className={this.state.errPublish + " error"} style={{float: "left", margin: '0.5em 0em 0.5em 5em'}}>{this.state.errPublishText}</span>
					<div className="clear"></div>
					<div style={{width:"20em", margin: "0.5em auto 1.5em auto"}}>
						<button className="delete_teammate_warning_cancel" onClick={this.handleCancelReject}>Cancel</button>
						<button className="delete_teammate_warning_cancel" style={{float: "right"}} onClick={this.handleRejectElement}>I am Sure</button>
					</div>
				</div>

				<div className={"teammate_invitation_warning " + this.state.unpublishWarningVisibility} style={{width: "35em", minHeight: "8em"}}>
					<p style={{margin: "1em 0 0.5em 5em", float: "left"}}>
						Are you damn sure you want to unpublish this {this.state.unpublishType}?
					</p>
					<div style={{width: "35em", textAlign: "center"}}>
					<textarea rows="4" cols="50" style={{width: "30em", padding: "0.5em", fontFamily:"Avenir, Futura, Helvetica Neue, Helvetica, sans-serif", fontSize: "1em"
}} onChange={this.handleUnpublishTextChange} value={this.state.unpublishText} placeholder="Enter reason for unpublishing."></textarea>
					</div>
					<br/>
					<span className={this.state.errPublish + " error"} style={{float: "left", margin: '0.5em 0em 0.5em 5em'}}>{this.state.errPublishText}</span>
					<div className="clear"></div>
					<div style={{width:"20em", margin: "0.5em auto 1.5em auto"}}>
						<button className="delete_teammate_warning_cancel" onClick={this.handleCancelUnpublishElement}>Cancel</button>
						<button className="delete_teammate_warning_cancel" style={{float: "right"}} onClick={this.handleUnpublishElement}>I am Sure</button>
					</div>
				</div>

				<div className={"teammate_invitation_warning " + this.state.archiveWarningVisibility} style={{width: "35em", minHeight: "8em"}}>
					<p style={{margin: "1em 0 0.5em 5em", float: "left"}}>
						Are you sure you want to archive this report??
					</p>
					<br/>
					<span className={this.state.errPublish + " error"} style={{float: "left", margin: '0.5em 0em 0.5em 5em'}}>{this.state.errPublishText}</span>
					<div className="clear"></div>
					<div style={{width:"20em", margin: "0.5em auto 1.5em auto"}}>
						<button className="delete_teammate_warning_cancel" onClick={this.handleCancelArchiveReport}>Cancel</button>
						<button className="delete_teammate_warning_cancel" style={{float: "right"}} onClick={this.handleArchiveReport}>I am Sure</button>
					</div>
				</div>

				<div className={"teammate_invitation_warning " + this.state.unpublishResponseSuccessVisibility} style={{textAlign: "center"}}>
					<p>
						Your response was sent successfully!
					</p>
					<div style={{width:"20em", margin: "1em auto"}}>
						<button className="delete_teammate_warning_cancel" onClick={this.hideUnpublishResponseSuccess}>Close</button>
					</div>
				</div>

				<div className="clear"></div>

				<div style={{opacity: this.state.opacity, pointerEvents: this.state.pointerEvents}}>
					<div className="signup_hero" style={{textAlign: "center", fontSize: "1.6em", marginTop: "1em", marginBottom: "2em"}}>
						Administration Page
					</div>
					<div style={{overflow: 'scroll', height: '50em'}}>
						<h1 style={{fontSize: '1.2em', textAlign: 'center'}}>Profiles Under Review</h1>
						<div style={{border: 'solid 1px #EDEDED', width: '40em', margin: '0 auto', padding: '1em'}}>
							<div style={{width: '40em', margin: '0 auto'}}>
								{this.state.kreators.map(this.createProfileList)}
							</div>
						</div>
						<div className="clear"></div>
						<h1 style={{fontSize: '1.2em', textAlign: 'center'}}>Projects Under Review</h1>
						<div style={{border: 'solid 1px #EDEDED', width: '40em', margin: '0 auto', padding: '1em'}}>
							<div style={{width: '40em', margin: '0 auto'}}>
								{this.state.projects.map(this.createProjectsList)}
							</div>
						</div>
						<div className="clear"></div>
						<h1 style={{fontSize: '1.2em', textAlign: 'center'}}>Unpublish Kreator</h1>
						<div style={{border: 'solid 1px #EDEDED', width: '40em', margin: '1em auto', padding: '1em'}}>
							<div style={{width: '40em', margin: '0 auto'}}>
								<input  style={{fontSize: '1.2em', width: '20em'}} placeholder="Type in profile Id" value={this.state.kreatorToUnpublishId} onChange={this.handleUnpublishKreatorIdChange}/>
								<button className="admin_button" style={{float: 'right', marginTop: '-0.2em'}} onClick={() => this.handleShowUnpublishWarning("kreator")}> Unpublish Kreator </button>
							</div>
						</div>
						<div className="clear"></div>
						<h1 style={{fontSize: '1.2em', textAlign: 'center'}}>Unpublish Project</h1>
						<div style={{border: 'solid 1px #EDEDED', width: '40em', margin: '1em auto', padding: '1em'}}>
							<div style={{width: '40em', margin: '0 auto'}}>
								<input  style={{fontSize: '1.2em', width: '20em'}} placeholder="Type in project Id" value={this.state.projectToUnpublishId} onChange={this.handleUnpublishProjectIdChange}/>
								<button className="admin_button" style={{float: 'right', marginTop: '-0.2em'}} onClick={() => this.handleShowUnpublishWarning("project")}> Unpublish Project </button>
							</div>
						</div>
						<div className="clear"></div>
						<h1 style={{fontSize: '1.2em', textAlign: 'center'}}>Reports</h1>
						<div style={{border: 'solid 1px #EDEDED', width: '40em', margin: '0 auto', padding: '1em'}}>
							<div style={{width: '40em', margin: '0 auto'}}>
								{this.state.reports.map(this.createReportsList)}
							</div>
						</div>
					</div>
				</div>
				<div className="bottom_padding"></div>
				<Footer />
			</div>
		);
	}
};
