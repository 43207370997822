// This is the detail page that uses ProfileDetail.jsx module to show a user's detail information.

import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import HTTP from '../../utils/Http';

import { login } from '_redux/actions';
import * as Utils from 'utils/Utils';
import history from 'app/history';

import NavBarNotifications from '../nav/NavBar_Notifications.jsx';
import Footer from '../footer/Footer.jsx';
import ProfileDetail from './ProfileDetail.jsx';
import ReportIssue from '../misc/ReportIssue.jsx';


class ProfilePage extends React.Component {
	state = {
		report_container_visibility: 'hidden',
		radiobox_visibility: 'hidden',
		thankyou_visibility: 'hidden',
		buttonVisibility:'visible_block',
		profileId: '',
		allowUserInput: 'true',
		reportBoxVisibility: 'hidden',
		objectToBeReportedType: '',
		objectToBeReportedId: '',
		objectToBeReportedText: '',
		reportText: '',
		reportTextVisibility: 'visible',
		reportSuccessVisibility: 'hidden'
	};

  	UNSAFE_componentWillMount = () => {
  		this.loadProfile();
  	}

  	UNSAFE_componentWillUpdate = () => {
		if(Utils.getLocationSearchParam('profileId') !== this.state.profileId){
			const newProfileId = Utils.getLocationSearchParam('profileId');
			this.setState({profileId: newProfileId});
		}
	}

	loadProfile = () => {

  		var profileId = Utils.getLocationSearchParam('profileId');
  		if (!profileId || !profileId.match(/^[0-9a-f]{24}$/)) {
  			history.replace('/pagenotfound');
  		}

  		this.setState({profileId: profileId});

  		if(this.props.loggedIn){
  			if (profileId === USER._id){
	  			this.setState({buttonVisibility: "hidden"});
	  		}
  		}
  		if(!USER){
  			this.setState({buttonVisibility: "hidden"});
  		}
	}

  	handleReportTextChange = (e) => {
		this.setState({reportText: e.target.value});
	}

	handleShowReportBox = (objectId, objectType) => {
		console.log(objectType + " " + objectId);
		this.setState({
			objectToBeReportedType: objectType,
			objectToBeReportedId: objectId,
			reportBoxVisibility: "visible",
			allowUserInput: false
		});
	}

	handleHideReportBox = () => {
		this.setState({
			objectToBeReportedType: '',
			objectToBeReportedId: '',
			reportBoxVisibility: "hidden",
			reportTextVisibility: "visible",
			reportSuccessVisibility: "hidden",
			reportText: "",
			allowUserInput: true
		});
	}

	handleSuccessfulReport = () => {
		this.setState({
			reportTextVisibility: 'hidden',
			reportSuccessVisibility: 'visible'
		});
	}

	handleReporting = () => {
		var objectType = this.state.objectToBeReportedType;
		var objectId = this.state.objectToBeReportedId;
		var objectText = this.state.reportText;

		HTTP.post(`/report`, { type: objectType, id: objectId, reason: objectText}, (error, body) => {
			if (error) {
				alert(error);
				return;
			} else {
				console.log("report successful");
				this.handleSuccessfulReport();
			}
		});
	}

	render = () => {
		var userInputState = true;
		if (!this.state.allowUserInput){
			userInputState = "prevent_user_input";
		} else {
			userInputState = "allow_user_input";
		}
		return (
			<div className="viewport_width">
				<NavBarNotifications
					showLoginButton={true}
					showSignupButton={true}
				/>

				<div className={"report_box_container " + this.state.reportBoxVisibility}>
					<div className={this.state.reportTextVisibility}>
						<p>
						If you believe this violates our rules of conduct, please make a report.
						</p>
						<textarea cols="50" rows="5" value={this.state.reportText} onChange={this.handleReportTextChange} placeholder="Let us know what is the problem."></textarea>
						<div className="report_box_button_holder">
							<button className="report_box_button_cancel" onClick={this.handleHideReportBox}>Cancel</button>
							<button className="report_box_button_report" style={{float: "right"}} onClick={this.handleReporting}>Report</button>
						</div>
					</div>
					<div className={this.state.reportSuccessVisibility} style={{textAlign: "center"}}>
						<p>
						Your report was succesfully submitted.
						</p>
						
						<button className="report_box_button_cancel" style={{display: "block", margin: "1.5em auto"}}onClick={this.handleHideReportBox}>Close</button>
						
					</div>
				</div>
				<div className={userInputState}>
				<ProfileDetail
					profileId={this.state.profileId}
					displayReportIssue={this.displayReportIssue}
					buttonVisibility={this.state.buttonVisibility}
					handleShowReportBox={this.handleShowReportBox}
				/>
				</div>
				<div className="clear"></div>
				<div className="bottom_padding"></div>
				<Footer />
			</div>
		);
	}
};

export default connect(({ loggedIn }) => ({ loggedIn }))(ProfilePage);