// Component used by TeamManagement to show a summary of a teammate.

import React from 'react';
import { QueryLink } from 'utils/Utils';
import PropTypes from 'prop-types';
import HTTP from '../../utils/Http';

export default class TeammateSummary extends React.Component {
	static propTypes = {
	  kreator: PropTypes.object,
	  projectId: PropTypes.string,
	  roleSummary: PropTypes.string,
	  handleShowDeleteTeammateWarning: PropTypes.func,
	  handleUpdateTeammates: PropTypes.func
	}

	state = {
		text: "",
		descriptionVisibility: "visible_block",
		editDescriptionVisibility: "hidden"
	}

	UNSAFE_componentWillMount = () => {
		if (this.props.roleSummary !== ""){
			this.setState({text: this.props.roleSummary});
		}
	}

	UNSAFE_componentWillReceiveProps = nextProps => {
		this.setState({
			text: nextProps.roleSummary,
			descriptionVisibility: "visible_block",
			editDescriptionVisibility: "hidden"
		});
	}

	handleTextChange = e => {
		this.setState({ text: e.target.value });
	}

	handleEditTeammateClick = () => {
		this.setState({
			descriptionVisibility: "hidden",
			editDescriptionVisibility: "visible"
		});
	}

	handleCancelEditTeammate = () => {
		this.setState({
			descriptionVisibility: "visible_block",
			editDescriptionVisibility: "hidden",
		});
	}

	handleSaveEditTeammate = (projectId, teammateId, teammateRoleSummary) => {

		HTTP.patch(`/project/${projectId}/members`, {member: teammateId, roleSummary: teammateRoleSummary}, (error, body) => {
			if (error) {
				alert("Could not load team members");
				return false;
			} else {
				var newTeammates = this.props.teammates;

				for(var i = 0; i < newTeammates.length; i++){
					if ( newTeammates[i].member._id === body[i].member){
						newTeammates[i].roleSummary = body[i].roleSummary;
					}
				}
				
				this.props.handleUpdateTeammates(newTeammates);
				this.handleCancelEditTeammate();
				return true;
			}
		});
	}

	render = () => {
		const placeholder = "Kreators should be credited with the work that they do. Write about the role this team member has played on your project.";
		var roleDescription = this.props.roleSummary;
		if(roleDescription === "") {
			roleDescription = placeholder;
		}
		return (
				<div >
					<div className="manage_teambox">
						<div className="manage_team_profile">
							<QueryLink to={{ pathname:"/profilepage", query: { profileId: this.props.kreator.member._id }}}>
								<div className="manage_team_picbox">
									<img src={"/uploads/" + this.props.kreator.member.photo} />
								</div>
							</QueryLink>
							<h4>{this.props.kreator.member.firstName} {this.props.kreator.member.lastName}</h4>
						</div>
						<div className="manage_team_description">
							<div className={this.state.descriptionVisibility} style={{minHeight: "11em"}}>
								<p className="teammate_description">
									{roleDescription}
								</p>
							</div>
							<textarea style={{}}className={this.state.editDescriptionVisibility} style={{fontFamily:"Avenir, Futura, Helvetica Neue, sans-serif"}} rows="10" value={this.state.text} onChange={this.handleTextChange} placeholder={placeholder}/>
							<div className={this.state.descriptionVisibility + " manage_team_buttonbox"}>
								<button className="manage_team_delete_button" onClick={() => this.props.handleShowDeleteTeammateWarning(this.props.kreator.member._id)}>delete</button>
								<button className="manage_team_edit_button" onClick={this.handleEditTeammateClick}>edit</button>
							</div>
							<div className={this.state.editDescriptionVisibility + " manage_team_buttonbox"} style={{marginRight: "-1em"}}>
								<button className="manage_team_edit_button" onClick={()=> this.handleSaveEditTeammate(this.props.projectId, this.props.kreator.member._id, this.state.text)}>save</button>
								<button className="manage_team_edit_button" onClick={this.handleCancelEditTeammate}>cancel</button>
							</div>
						</div>

					</div>
				</div>
		);
	}
};