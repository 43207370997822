// This is the search page to search for projects.

import React from 'react';
import HTTP from '../../utils/Http';

import ProjectSummary from './ProjectSummary.jsx';
import NavBarNotifications from '../nav/NavBar_Notifications.jsx';
import Footer from '../footer/Footer.jsx';

export default class ProjectSearch extends React.Component {
	state = {
		mainSearchBar: '',
		searchResults: [],
		searchOptions: false,
		noMoreResults: false,
		shouldShowResultsBox: true,

		expertises: [],
		categories: [],

		schoolText: '',
		school: '',
		autoCompleteSchools: [],
		displayAutoCompleteSchools: false,
		course: '',

		baseSkill: -1,
		travelRadius: -1,
		whatToGain: -1,
		country: '',

		courseInputPlaceholder: 'Input School First',
		courseInputDisabled: true,

		page: 1,
		scrolling: false,
		noResultsVisibility: 'hidden'
	};

	UNSAFE_componentWillMount() {
		this.setLocalStorage();
		this._schoolInputTimer = -1;
		this.loadSearchParameters();
	}

	componentDidMount() {
		this.onSearch();
		var projectResults = document.getElementById("project_results");
		this.scrollListener = projectResults.addEventListener('scroll', (e) => {
			this.handleScroll(e)
		});
	}

	componentWillUnmount() {
		var projectResults = document.getElementById("project_results");
		projectResults.removeEventListener('scroll', this.handleScroll);
	}

	setLocalStorage = () => {
		if(localStorage.getItem('mainSearchBarProject') === null){
			localStorage.setItem('mainSearchBarProject', this.state.mainSearchBar);
		}
		if(localStorage.getItem('expertisesProject') === null){
			localStorage.setItem('expertisesProject', this.state.expertises);
		}
		if(localStorage.getItem('categoriesProject') === null){
			localStorage.setItem('categoriesProject', this.state.categories);
		}
		if(localStorage.getItem('schoolTextProject') === null){
			localStorage.setItem('schoolTextProject', this.state.schoolText);
		}
		if(localStorage.getItem('schoolProject') === null){
			localStorage.setItem('schoolProject', this.state.school);
		}
		if(localStorage.getItem('courseProject') === null){
			localStorage.setItem('courseProject', this.state.course);
		}
		if(localStorage.getItem('baseSkillProject') === null){
			localStorage.setItem('baseSkillProject', this.state.baseSkill);
		}
		if(localStorage.getItem('travelRadiusProject') === null){
			localStorage.setItem('travelRadiusProject', this.state.travelRadius);
		}
		if(localStorage.getItem('whatToGainProject') === null){
			localStorage.setItem('whatToGainProject', this.state.whatToGain);
		}
		if(localStorage.getItem('countryProject') === null){
			localStorage.setItem('countryProject', this.state.country);
		}
	}

	saveSearchParameters = () => {
		localStorage.setItem('mainSearchBarProject', this.state.mainSearchBar);
		localStorage.setItem('expertisesProject', this.state.expertises);
		localStorage.setItem('categoriesProject', this.state.categories);

		localStorage.setItem('schoolTextProject', this.state.schoolText);
		localStorage.setItem('schoolProject', this.state.school);
		localStorage.setItem('courseProject', this.state.course);

		localStorage.setItem('baseSkillProject', this.state.baseSkill);
		localStorage.setItem('travelRadiusProject', this.state.travelRadius);
		localStorage.setItem('whatToGainProject', this.state.whatToGain);
		localStorage.setItem('countryProject', this.state.country);
	}

	loadSearchParameters = () => {

		if (localStorage.getItem('mainSearchBarProject') !== ''){
			this.setState({mainSearchBar: localStorage.getItem('mainSearchBarProject')});
		}
		if (localStorage.getItem('expertisesProject') !== ''){
			this.setState({
				expertises: [localStorage.getItem('expertisesProject')],
				searchOptions: true
			});
		}
		if (localStorage.getItem('categoriesProject') !== ''){
			const categories = localStorage.getItem('categoriesProject');
			const savedCategoriesArray = categories.split(/[, ]/g).filter(category => category);
			this.setState({
				categories: savedCategoriesArray,
				searchOptions: true
			});
		}
		if (localStorage.getItem('schoolTextProject') !== ''){
			this.setState({
				schoolText: localStorage.getItem('schoolTextProject'),
				searchOptions: true
			});
		}
		if (localStorage.getItem('schoolProject') !== ''){
			this.setState({
				school: localStorage.getItem('schoolProject'),
				courseInputDisabled: false,
				courseInputPlaceholder: "Ex: KB101",
				searchOptions: true
			});
		}
		if (localStorage.getItem('courseProject') !== ''){
			this.setState({
				course: localStorage.getItem('courseProject'),
				searchOptions: true
			});
		}
		if (localStorage.getItem('baseSkillProject') !== '-1'){
			this.setState({
				baseSkill: localStorage.getItem('baseSkillProject'),
				searchOptions: true
			});
		}
		if (localStorage.getItem('travelRadiusProject') !== '-1'){
			this.setState({
				travelRadius: localStorage.getItem('travelRadiusProject'),
				searchOptions: true
			});
		}
		if (localStorage.getItem('whatToGainProject') !== '-1'){
			this.setState({
				teamOrProject: localStorage.getItem('whatToGainProject'),
				searchOptions: true
			});
		}
		if (localStorage.getItem('countryProject') !== ''){
			this.setState({
				country: localStorage.getItem('countryProject'),
				searchOptions: true
			});
		}
	}

	handleScroll = (e) => {
		const { scrolling, page } = this.state;
		if (scrolling) {
			return;
		}

		const projectResults = document.getElementById("project_results");
		const lastComponent = document.querySelector('#project_results > div:last-child');
		var lastComponentOffset = 0;
		//lastComponent.offsetTop is the amount of pixels from the top the component is from the parent top.
		// The parent in this case, is the first parent that does not have "static" as a position.
		//lastComponent.clientHeight is the visible height in pixels of the last component.
		if (lastComponent !== null) {
			lastComponentOffset = lastComponent.offsetTop + lastComponent.clientHeight;
		}

		//projectResults.scrollTop is the amount of pixels an element has been scrolled vertically.
		const pageOffset = projectResults.scrollTop;
		var bottomOffset = 0;

		//Prevents this.state.page from advancing when new search is done and lastComponentOffset initially sets to 0.
		if (lastComponentOffset > 0){
			//Because we want the added search results to load before reaching the last component, we subtract the
			//bottomOffset from the lastComponentOffest.
			bottomOffset = 1000;
		}

		if(this.state.noMoreResults === false){
			if (pageOffset > lastComponentOffset - bottomOffset && pageOffset > 0) this.loadMore();
		}

	}

	loadMore = () => {
		this.setState(prevState => ({
			page: prevState.page + 1,
			scrolling: true,
		}), this.onSearch);
	}

	onSearch = () => {
		HTTP.post('/project/search', { ...this.state, searchResults: null }, (error, body) => {
			if (error) {
				return alert('Could not fetch projects.');
			}

			let noResultsVisibility;
			if(!body.length && !this.state.searchResults.length){
				window.scrollTo(0,0);
				noResultsVisibility = 'visible';
			} else {
				noResultsVisibility = 'hidden';
			}

			this.setState({
				noResultsVisibility,
				searchResults: [...this.state.searchResults, ...body],
				scrolling: false
			});


			if (body.length == 0){
				this.setState({noMoreResults: true});
			}

			this.saveSearchParameters();
		});
	}

	newSearch = (hideMoreSearch)  => {
		const newState = {};
		// check if school input is "dirty"/invalid
		if (!this.state.school && this.state.autoCompleteSchools.length) {
			Object.assign(newState, {
				autoCompleteSchools: [],
				schoolText: '',
				displayAutoCompleteSchools: false
			});
		}

		if(hideMoreSearch){
			this.setState({
				shouldShowResultsBox: true,
				searchOptions:false
			});
		}


		this.setState(prevState => ({
			...newState,
			page: 1,
			searchResults: [],
			noMoreResults: false,
		}), this.onSearch);
	}

	toggleSearchOptions = e => {
		this.setState({ searchOptions: !this.state.searchOptions });
	}

	toggleSearchOptionsMobile = e => {
		this.setState({
			searchOptions: !this.state.searchOptions,
			shouldShowResultsBox: !this.state.shouldShowResultsBox
		});
	}

	onMainSearchBarUpdate = e => {
		this.setState({ mainSearchBar: e.target.value });
	}

	getSchoolAutoCompleteList() {
		if (!this.state.schoolText) {
			return;
		}

		HTTP.get(`/schools?query=${this.state.schoolText}`, (error, object) => {
			if (error) {
				return;
			}

			this.setState({ autoCompleteSchools: object.schools || [] });
		});
	}

	generateAutoCompleteSchools = school => {
		return(
			<div key={school._id}>
				<li className="auto_complete_item" data-id={school._id} data-school={school.names[0]} onClick={this.handleAutoComplete} >{school.names[0]}</li>
			</div>
		);
	}

	handleAutoComplete = e => {
		this.setState({
			school: e.target.dataset.id,
			schoolText: e.target.dataset.school,
			courseInputDisabled:false,
			courseInputPlaceholder: "Ex: KB101",
			displayAutoCompleteSchools: false
		});
	}

	onSchoolUpdate = e => {

		this.setState({
			displayAutoCompleteSchools: !!e.target.value,
			schoolText: e.target.value,
			school: '',
			courseInputDisabled: true,
			courseInputPlaceholder: 'Input School First',
			course: ''
		}, () => {
			if (this.state.schoolText && this._schoolInputTimer === -1) {
				this._schoolInputTimer = setTimeout(function() {
					this.getSchoolAutoCompleteList();

					this._schoolInputTimer = -1;
				}.bind(this), 100);
			}
		});
	}

	// when user blurs school input, check if "dirty"/invalid
	onSchoolBlur = e => {
		const schoolText = this.state.schoolText.trim();
		if(schoolText === ''){
			this.setState({
				autoCompleteSchools: [],
				courseInputDisabled:true,
				courseInputPlaceholder: 'Input School First',
				school:'',
				course:'',
				displayAutoCompleteSchools: false
			});
		}
		if (this.state.autoCompleteSchools.length || this.state.school !== '') {
			return
		}

		this.setState({
			schoolText: '',
			courseInputDisabled: true,
			courseInputPlaceholder: 'Input School First',
			school: '',
			course:'',
			displayAutoCompleteSchools: false
		});
	}

	onResetFilters = e => {
		this.setState ({
			expertises: [],
			categories: [],

			school: '',
			course: '',

			baseSkill: -1,
			travelRadius: -1,
			whatToGain: -1,
			schoolText: '',
			country:''
		});
	}

	onCountryChangeUpdate = e => {
		this.setState({ country: e.target.value });
	}

	onCourseUpdate = e => {
		this.setState({ course: e.target.value });
	}

	onBaseSkillUpdate = e => {
		this.setState({ baseSkill: +e.target.value });
	}

	onExpertisesUpdate = e => {
		this.setState({ expertises: +e.target.value === -1 ? [] : [e.target.value] });
	}

	onSkillsUpdate = e => {
		this.setState({
			skillsText: e.target.value,
			skills: e.target.value.split(/[, ]/g).filter(skill => skill)
		});
	}

	onTravelRadiusUpdate = e => {
		this.setState({ travelRadius: +e.target.value });
	}

	onWhatToGainUpdate = e => {
		this.setState({ whatToGain: +e.target.value });
	}

	onCategoriesUpdate = e => {
		var categories = [...this.state.categories];
		var value = e.target.value;

		var potentialValueIndex = categories.indexOf(value);
		if (potentialValueIndex === -1) {
			categories.push(value);
		} else {
			categories.splice(potentialValueIndex, 1);
		}

		this.setState({ categories }); // +e.target.value === -1 ? [] : [+e.target.value]
	}

	onEnterKeyPressed = (e) => {
		if (e.key === 'Enter') {
			this.onSearch();
		}
	}
	generateBaseSkillOption = baseSkill => {
		return <option key={baseSkill.id} value={baseSkill.id}>{baseSkill.text}</option>;
	}

	generateExpertiseOption = expertise => {
		return <option key={expertise.id} value={expertise.id}>{expertise.id}</option>;
	}

	generateTravelRadiusOption = travelRadius => {
		return <option key={travelRadius.id} value={travelRadius.id}>{travelRadius.text}</option>;
	}

	generateTeammateEquityOption = whatToGain => {
		return <option key={whatToGain.id} value={whatToGain.id}>{whatToGain.text}</option>;
	}

	generateProjectCategoryOption = projectCategory => {
		return <option key={projectCategory.id} value={projectCategory.id}>{projectCategory.text}</option>;
	}

	generateCountryOptions = option => {
		return <option key={option.code} value={option.code}>{option.name}</option>
	}

	generateSummary = project => {
		return <ProjectSummary
			key={project._id}
			project={project}
			mainSearchBar={this.state.mainSearchBar}
			expertises={this.state.expertises}
			skills={this.state.skills}
			categories={this.state.categories}
			school={this.state.school}
			schoolText={this.state.schoolText}
			course={this.state.course}
			searching={true}
		/>;
	}

	render() {

		var filtersVisibilityTitle;

		if (this.state.searchOptions) {
			filtersVisibilityTitle = "hide filters";
		} else {
			filtersVisibilityTitle = "use filters";
		}

		var expertiseValue = "-1";
		if (this.state.expertises.length > 0){
			expertiseValue = this.state.expertises[0];
		}

		return (
			<div class="viewport_width" style={{overflowY:'hidden'}}>

				<NavBarNotifications
					showLoginButton={true}
					showSignupButton={true}
				/>
				<div className="master_search_box">
					<div className="search_barbox">
						<input
							type="text"
							placeholder="Search Projects"
							value={this.state.mainSearchBar}
							onChange={this.onMainSearchBarUpdate}
							onKeyPress={this.onEnterKeyPressed}
						/>
						<div className="show_for_desktop">
							<button onClick={() => this.newSearch(false)}> </button>
						</div>
						<div className="show_for_mobile">
							<button onClick={() => this.newSearch(true)}> </button>
						</div>
					</div>

					<div className="show_for_desktop">
						<button className="more_search_button" onClick={this.toggleSearchOptions}>&nbsp;&nbsp;&nbsp;{filtersVisibilityTitle}</button>
					</div>

					<div className="show_for_mobile">
						<button className="more_search_button" onClick={this.toggleSearchOptionsMobile}>&nbsp;&nbsp;&nbsp;{filtersVisibilityTitle}</button>
					</div>

					<div className="clear"></div>
					<div className={`more_search_options_box ${this.state.searchOptions ? 'visible_block' : 'hidden'}`}>
						<button className="reset_filters_button" onClick={this.onResetFilters}>&nbsp;&nbsp;&nbsp;reset filters</button>
						<div className="main_search_options" style={{height: '4em'}}>
						{/*
						<p>B.A.S.E. Skill:</p>
						<select value={this.state.baseSkill} onChange={this.onBaseSkillUpdate}>
							<option value="-1">B.A.S.E. skill</option>
							{ CONSTANTS.baseSkills.map(this.generateBaseSkillOption) }
						</select>
						*/}
						<p>Expertise:</p>
						<select value={expertiseValue} onChange={this.onExpertisesUpdate}>
							<option value="-1">Expertise</option>
							{ CONSTANTS.expertises.map(this.generateExpertiseOption) }
						</select>
						</div>

						<div className="more_search_options">
							<p>School:</p>
							<input
								type="text"
								placeholder="Ex: Santa Clara University"
								value={this.state.schoolText}
								onChange={this.onSchoolUpdate}
								onBlur={this.onSchoolBlur}
							/>
							<div className="search_schools_dropdown">
								<div className={`search_auto_complete_schools ${this.state.displayAutoCompleteSchools ? 'visible_block' : 'hidden'}`}>
									<ul >
										{ this.state.autoCompleteSchools.map(this.generateAutoCompleteSchools) }
									</ul>
								</div>
							</div>
								<span>
									<p style={{marginTop: '-1.5em'}}>Course #</p>
									<input
										disabled={this.state.courseInputDisabled}
										type="text"
										placeholder={this.state.courseInputPlaceholder}
										className="course_input"
										value={this.state.course}
										onChange={this.onCourseUpdate}
									/>
								</span>
							<p>Within:</p>
								<select value={this.state.travelRadius} onChange={this.onTravelRadiusUpdate}>
									{ CONSTANTS.additionalInfo.travelRadius.map(this.generateTravelRadiusOption) }
								</select>
							{/*<p>Equity in Project</p>
								<select value={this.state.whatToGain} onChange={this.onWhatToGainUpdate}>
									{CONSTANTS.additionalInfo.whatToGain.map(this.generateTeammateEquityOption)}
								</select>*/}
							<p>Categories:</p>
							<select value={this.state.categories} onChange={this.onCategoriesUpdate} multiple={true}>
								<option value="">Select category</option>
								{ CONSTANTS.projectCategories.map(this.generateProjectCategoryOption) }
							</select>
							<p>Location:</p>
							<select className="country_select" value={this.state.country} onChange={this.onCountryChangeUpdate}>
								<option value="">Select Country</option>
								{CONSTANTS.countryData.map(this.generateCountryOptions)}
							</select>
						</div>
						<div className="clear"></div>
						<div className="show_for_desktop">
							<button className="search_button" onClick={() => this.newSearch(false)}>Search</button>
						</div>
						<div className="show_for_mobile">
							<button className="search_button" onClick={() => this.newSearch(true)}>Search</button>
						</div>
						<div className="bottom_padding"></div>
					</div>

				</div>

				<div className={`master_resultsbox ${this.state.shouldShowResultsBox ? 'visible_block' : 'hidden'}`}>
					<div className={"no_results_found " + this.state.noResultsVisibility} >
						Sorry, there were no results that matched your search.
					</div>
					<div id="project_results">
						{ this.state.searchResults.map(this.generateSummary) }
						<div style={{height:'10em', width:'100%', float:'left'}}></div>
					</div>

				</div>


				<div className="clear"></div>
				<div className="bottom_padding"></div>
				<Footer />
			</div>
		);
	}
};
