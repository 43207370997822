import React from 'react';
import { Link } from 'react-router-dom';
import HTTP from '../../utils/Http.js';
import NavBarNotifications from '../nav/NavBar_Notifications.jsx';
import ProgressBar from './ProgressBar.jsx';
import Footer from '../footer/Footer.jsx';
import PropTypes from 'prop-types';
import history from 'app/history';

export default class SignupAchievement extends React.Component {
	state = {
		successSave: 'hidden',
		failedSave: 'hidden',
		achievementBody: USER ? USER.achievement : '',
		profileCompleteness: USER ? USER.profileCompleteness : 0
	};

	componentDidMount = () => {
		// window.scrollTo(0, 0);
	}

	handleAchievementBodyChange = e => {
		this.setState({
			achievementBody: e.target.value,
			successSave: 'hidden'
		});
	}

	handleSubmit = () => {
		this.save(true);
	}

	handleSave = () => {
		this.save();
	}

	save = shouldContinueSignUp => {
		var achievementBody = this.state.achievementBody.trim();

		HTTP.post('/profile/achievement', { achievement: achievementBody }, (error, body) => {
			if (error) {
				this.setState({
					failedSave: 'visible',
					successSave: 'hidden'
				});

				return;
			}

			USER.achievement = achievementBody;
			USER.profileCompleteness = body.profileCompleteness;

			this.setState({
				profileCompleteness: body.profileCompleteness,
				failedSave: 'hidden',
				successSave: 'visible'
			});

			if (shouldContinueSignUp) {
				history.push('signupschools');
			}
		});
	}

	render = () => {
		return (
			<div className="viewport_width">
				<NavBarNotifications
					showLoginButton={false}
					showSignupButton={false}
				 />
				<div id='achievement_top' className="signup_hero">
					<img src="images/signup_achievement.svg" />
				</div>

				<div className="clear"></div>
				
				<div className="show_for_desktop">
					<div className="signup_progressbar" style={{width:'56em'}}>
						<ProgressBar progress={this.state.profileCompleteness} width="56em"/>
					</div>
				</div>

				<div className="show_for_mobile">
					<div className="signup_progressbar" style={{width:'19em'}}>
						<ProgressBar progress={this.state.profileCompleteness} width="18em"/>
					</div>
				</div>

				<div className="signup_container">
					<div id="achievement_container">
						<div id="achievement_header">Tell people about your proudest achievement:</div>
						<span className={this.state.successSave + " signup_success"} style={{"marginTop": "-0.2em"}}>{CONSTANTS.errors.successSave}</span>
						<span className={this.state.failedSave + " signup_error"} style={{"marginTop": "-0.2em"}}>{CONSTANTS.errors.failedSave}</span>
						<textarea id="achievement_textarea" rows="8" cols="84" value={this.state.achievementBody} onChange={this.handleAchievementBodyChange} placeholder="This can be anything from something you did as a child to a recent accomplishment. What’s important is describing how you achieved your goal, and why you are so proud of this achievement." />
						<div className="signup_navigation">
							<input name="submit" className="nextnav" type="submit" value="Next >" onClick={this.handleSubmit}/>
							<Link to="/signupschools" className="skipnav">Skip</Link>
							<input name="submit" className="savenav" type="submit" value="Save" onClick={this.handleSave}/>
							<Link to="/signupbaseskills" className="backnav">&lt; Back</Link>
						</div>
					</div>
				</div>
				<div className="bottom_padding"></div>
				<Footer />
			</div>
		);
	}
};
