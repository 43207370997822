// This is the box that shows the comments of a project. It is used in ProjectMainPage.

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { login } from '_redux/actions';
import { QueryLink } from 'utils/Utils';
import HTTP from '../../utils/Http';

import ProjectComment from './ProjectComment.jsx';

class ProjectCommentsBox extends React.Component {
	static propTypes = {
		projectId: PropTypes.string
	}

	static contextTypes = {
		router: PropTypes.object
	}

	state = {
		skipComments: 0,
		comments: [],
		commentText: '',
		errComment: 'hidden',
		errCommentText: '',
		displayEditCommentTextArea: false
	}

	UNSAFE_componentWillMount = () => {
		this.getComments();
	}

	checkProfileCompleteness = () => {
		if(!this.props.loggedIn){
			return false;
		}
		var profileComplete = true;
		if (USER.baseSkills.length === 0) {
			profileComplete = false;
		} else if (USER.schools.length === 0) {
			profileComplete = false;
		} else if (USER.bio === "") {
			profileComplete = false;
		} else if (USER.interests.length === 0) {
			profileComplete = false;
		} else if (USER.additionalInfo.hoursPerWeek === -1) {
			profileComplete = false;
		} else if (USER.additionalInfo.teamOrProject === -1) {
			profileComplete = false;
		} else if (USER.additionalInfo.travelRadius === -1) {
			profileComplete = false;
		// } else if(USER.additionalInfo.whatToGain === -1) {
		// 	profileComplete = false;
		} else if (USER.photo === "") {
			profileComplete = false;
		}

		return profileComplete;
	}

	getComments = ({ shouldAppend = false } = {}) => {
		var commentsToSkip = 0;

		if(shouldAppend){
			commentsToSkip = this.state.skipComments;
		}

		HTTP.get(`/project/${this.props.projectId}/comments`, { skip: commentsToSkip }, (error, body) => {
			if (error) {
				return;
			}

			if(this.state.skipComments === 0){
				this.setState({
					comments: body
				})
			} else {
				if(shouldAppend){
					this.setState({
						comments: [...this.state.comments, ...body]
					});
				}

				if(!shouldAppend){
					const newComment = [body[0]];
					this.setState({ comments: [...newComment, ...this.state.comments]});
				}

			}

		});
	}

	generateComments = (comment = {}) => {
		return [...this.state.comments]
			.sort((a, b) =>
				+new Date(b.date) - +new Date(a.date)
			)
			.map(comment => {
				const date = new Date(comment.date);
				return (
					<ProjectComment
						key={comment._id}
						kreator={comment.kreator}
						comment={comment}
						commentId={comment._id}
						commentText={comment.text}
						dateString={date.toDateString()}
						timeString={date.toLocaleTimeString()}
						handleEditComment={this.handleEditComment}
						handlePostComment={this.handlePostComment}
						handleDeleteComment={this.handleDeleteComment}
						handleShowReportBox={this.props.handleShowReportBox}
						displayEditCommentTextArea={this.state.displayEditCommentTextArea}
						canDeleteComments = {this.props.canDeleteComments}
					/>
				);
			});
	}

	handleCommentChange = e => {
		this.setState({commentText: e.target.value});
	}

	handlePostComment = (commentText) => {
		if (commentText == ""){
			return;
		}
		HTTP.post(`/project/${this.props.projectId}/comments`, { text: commentText }, (error, body) => {
			if (error) {
				this.setState({errCommentText: error, errComment: "visible_block"});
				return;
			}
			this.setState({
				errCommentText: "",
				errComment: "hidden",
				commentText: ""
			});
			this.getComments();
		});
	}

	handleEditComment = (commentId, commentText) => {
		if (commentText == ""){
			return;
		}

		HTTP.patch(`/project/${this.props.projectId}/comment/${commentId}`, { text: commentText }, (error, body) => {
			if (error) {
				this.setState({errCommentText: error, errComment: "visible_block"});
				return;
			} else {

				//Finds the specific comment out of all the current comments and changes the text.
				const newUpdatedComments = this.state.comments.map(obj =>
    				obj._id === body._id ? { ...obj, text: body.text } : obj
				);
				this.setState({errCommentText: "", errComment: "hidden", comments: newUpdatedComments});
			}
		});
	}

	handleDeleteComment = (commentId) => {
		HTTP.delete(`/project/${this.props.projectId}/comment/${commentId}`, (error, body) => {
			if (error) {
				this.setState({errCommentText: error, errComment: "visible_block"});
				return;
			}

			const newUpdatedComments = this.state.comments.filter(obj => obj._id !== commentId);
			this.setState({
				errCommentText: "",
				errComment: "hidden",
				comments: newUpdatedComments
			});

		});
	}

	nextCommentPage = () => {
		if(this.state.comments.length < 10){
			return;
		}
		this.setState({
			skipComments: this.state.comments.length
		}, () => this.getComments({ shouldAppend: true }));
	}

	render = () => {
		var postCommentVisibility = "inline_block";
		var postCommentWarning = "hidden";
		if (USER){
			if(!USER.published){
				postCommentVisibility = "hidden";
				postCommentWarning = "visible_block";
			}
		} else {
			postCommentVisibility = "hidden";
			postCommentWarning = "visible_block";
		}

		return (
			<div className="project_updatebox">
				<h3>Project Comments</h3>
				<div className={postCommentWarning} style={{textAlign: "center", background: "#FFB0B0", padding: "1em"}}>
					Your profile needs to be approved before posting comments.
				</div>
				<div className={postCommentVisibility}>
					<p className={"update_error " + this.state.errComment}>{this.state.errCommentText}</p>
					<div className="post_commentbox">
							<textarea cols="50" rows="5" value={this.state.commentText} onChange={this.handleCommentChange}></textarea>
					</div>
					<div className="clear"></div>
					<div>
						<button className="post_comment_button" onClick={() => this.handlePostComment(this.state.commentText)}>Post Comment</button>
					</div>
				</div>
				{this.generateComments()}
				<button className="show_more" onClick={this.nextCommentPage} style={{marginTop: '0.5em'}}>+show more</button>
			</div>
		);
	}
};

export default connect(({ loggedIn }) => ({ loggedIn }))(ProjectCommentsBox);