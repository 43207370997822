// This is the first page a user is directed to when signing up.

import React from 'react';
import HTTP from '../../utils/Http';
import { Link } from 'react-router-dom';
import { QueryLink } from 'utils/Utils';
import NavBarNotifications from '../nav/NavBar_Notifications.jsx';
import Footer from '../footer/Footer.jsx';
import PropTypes from 'prop-types';
import history from 'app/history';

export default class PasswordChange extends React.Component {
	state = {
		errPassword: 'hidden',
		errEmptyPassword: 'hidden',
		errPasswordConfirm: 'hidden',
		errSubmitPassword: 'hidden',
		errSubmitPasswordText: '',
		currentPassword: '',
		newPassword: '',
		passwordConfirmation: '',
		passwordChangeBoxVisibility: "visible_block",
		passwordChangeSuccessVisibility: "hidden",
		error: ''
	};

	handleCurrentPasswordChange = e => {
		this.setState({ currentPassword: e.target.value});
	}

	handleNewPasswordChange = e => {
		this.setState({ newPassword: e.target.value });
	}

	handlePasswordConfirmationChange = e => {
		this.setState({ passwordConfirmation: e.target.value });
	}

	onEnterKeyPressed = (e) => {
		if (e.key === 'Enter') {
			this.handleSubmit(e);
		}
	}

	checkPasswordEmpty = e => {
		const currentPassword = this.state.currentPassword.trim();

		if (currentPassword === "") {
			this.setState({errEmptyPassword: "visible_block"});
		} else {
			this.setState({errEmptyPassword: "hidden"});
		}
	}

	checkPassword = e => {
		var reg = /^.*(?=.{8,})(?=.*[a-zA-Z])(?=.*\d).*$/;

		this.setState({ errPassword: reg.test(this.state.newPassword) ? 'hidden' : 'visible_block' });

		return reg.test(this.state.newPassword);
	}

	checkPasswordConfirm = e => {
		this.setState({ errPasswordConfirm: this.state.newPassword === this.state.passwordConfirmation ? 'hidden' : 'visible_block' });
	}

	handleGotoAccountSettings = () => {
		history.push('/accountsettings');
	}

	handleBack = () => {
		history.push('/accountsettings');
	}

	handleSubmit = () => {

		if (!this.checkPassword()){
			this.setState({errPassword: "visible_block"});
			return;
		}
		if (this.state.newPassword !== this.state.passwordConfirmation){
			this.setState({errPasswordConfirm: "visible_block"});
			return;
		}

		var currentPassword = this.state.currentPassword.trim();
		var newPassword = this.state.newPassword.trim();
		var passwordConfirmation = this.state.passwordConfirmation.trim();

		HTTP.post('/profile/password', { oldPassword: currentPassword, newPassword: newPassword, newPasswordConfirm: passwordConfirmation }, (error, body) => {
			if (error) {
				const errorText = body.split(":");
				return this.setState({ errSubmitPassword: 'visible_block', errSubmitPasswordText: errorText[1]  });
			}
			this.setState({
				passwordChangeSuccessVisibility: "visible_block",
				passwordChangeBoxVisibility: "hidden",
				errSubmitPassword: "hidden"
			});
		});
	}

	render = () => {
		return(
			<div className="viewport_width">
				<NavBarNotifications
					showLoginButton={false}
					showSignupButton={false}
				/>

				<div className="clear"></div>

				<div>
					<div className="signup_container">
						<div style={{textAlign: "center"}}>
							<p className={"failed_submit " + this.state.errSubmitPassword} style={{marginTop: "1em", marginBottom: "-1em"}}>{this.state.errSubmitPasswordText}</p>
						</div>
						<div className={"password_reset_signin_box " + this.state.passwordChangeBoxVisibility}>

							<div className="show_for_mobile">
								<div style={{float: "left"}}>Current Password:</div>
							</div>

							<input type="password" placeholder="Your current password" name="password" value={this.state.currentPassword} onChange={this.handleCurrentPasswordChange} onBlur={this.checkPasswordEmpty}/>
							
							<div className="show_for_desktop">
								<div style={{float: "right", marginRight: "1em"}}>Current Password:</div>
							</div>

							<div className="clear"></div>
							<span className={this.state.errEmptyPassword + " error"} style={{margin: '0.1em auto 1em auto', maxWidth: '26em', width: '87%', textAlign: 'center'}}>{CONSTANTS.errors.passwordEmptyError}</span>
							<div className="clear"></div>

							<div className="show_for_desktop">
								<span className="subtitle" style={{float: 'left', width: '21em', marginBottom: '1em', marginLeft: "9.3em"}}>Password must be at least eight characters long and contain one letter and one number.</span>
							</div>

							<div className="show_for_mobile">
								<span className="subtitle" style={{float: 'left', width: '16em', marginBottom: '1em'}}>Password must be at least eight characters long and contain one letter and one number.</span>
							</div>

							<div className="show_for_mobile">
								<div style={{float: "left"}}>New Password:</div>
							</div>

							<input type="password" placeholder="Your new password" name="password" value={this.state.newPassword} onChange={this.handleNewPasswordChange} onBlur={this.checkPassword} />
							
							<div className="show_for_desktop">
								<div style={{float: "right", marginRight: "1em"}}>New Password:</div>
							</div>

							<div className="clear"></div>
							<span className={this.state.errPassword + " error"} style={{margin: '0.1em auto 1em auto', maxWidth: '26em', width: '87%', textAlign: 'center'}}>{CONSTANTS.errors.passwordComplianceError}</span>
							
							<div className="show_for_mobile">
								<div style={{float: "left"}}>Confirm Password:</div>
							</div>

							<input type="password" placeholder="Confirm password" name="confirm" value={this.state.passwordConfirmation} onChange={this.handlePasswordConfirmationChange} onBlur={this.checkPasswordConfirm} onKeyPress={this.onEnterKeyPressed}/>
							
							<div className="show_for_desktop">
								<div style={{float: "right", marginRight: "1em"}}>Confirm Password:</div>
							</div>

							<div className="clear"></div>
							<span className={this.state.errPasswordConfirm + " error"} style={{margin: '0.1em auto 1em auto', maxWidth: '26em', width: '87%', textAlign: 'center'}}>{CONSTANTS.errors.passwordMatchError}</span>

							<div className="show_for_desktop">
								<button className="password_reset_continue_button" onClick={this.handleSubmit}>Submit</button>
							</div>

							<div className="show_for_mobile">
								<button className="password_reset_continue_button" style={{float:'right', marginRight:'0em'}} onClick={this.handleSubmit}>Submit</button>
							</div>

							<button className="password_reset_continue_button" onClick={this.handleBack} style={{float: "left", clear: "none"}}>Back</button>
						</div>
						<div className={"password_reset_signin_box " + this.state.passwordChangeSuccessVisibility} style={{minHeight: "10em", textAlign: "center", fontSize: "1.1em"}}>
							Your password was successfully changed.
							<div className="clear"></div>
							<button className="password_reset_continue_button" onClick={this.handleGotoAccountSettings} style={{margin: "2em auto", float: "none", width: "10em"}}>Account Settings</button>
						</div>
						<br/><br/><br/>
					</div>

					<div className="clear"></div>
				</div>
				<Footer />
			</div>
		);
	}
};
