// This is the module needed for the signup school page in order to add a course.

import React from 'react';
import AddOption from './AddOption.jsx';
import PropTypes from 'prop-types';

export default class AddCourse extends React.Component {
	static propTypes = {
		isAttendingSchools: PropTypes.array,
		course: PropTypes.object,
		deleteCourse: PropTypes.func,
		onCourseSchoolSelect: PropTypes.func
	}
	
	render = () => {
		const courseSchoolList = this.props.isAttendingSchools.map(schoolObject => (
			<AddOption
				key={schoolObject.school._id}
				id={schoolObject.school._id}
				schoolName={schoolObject.school.names[0]}
			/>
		));

		const courseSchool = this.props.course.school_id !== null ? this.props.course.school_id : 'select_school';

		return (
			<div>
				<li>
					<div style={{float: 'left', marginTop: '0.2em'}}>{this.props.course.name}</div>
					<button onClick={this.props.deleteCourse} id={this.props.course.name}>delete</button>
					<select defaultValue={courseSchool} onChange={this.props.onCourseSchoolSelect}>
						<option value="select_school">&nbsp;select attending school</option>
						{courseSchoolList}
					</select>

					<br/>
				</li>
			</div>
		);
	}
};