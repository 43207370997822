import React from 'react';
import { Link } from 'react-router-dom';
import HTTP from '../../utils/Http.js';
import NavBarNotifications from '../nav/NavBar_Notifications.jsx';
import ProgressBar from './ProgressBar.jsx';
import Footer from '../footer/Footer.jsx';
import PropTypes from 'prop-types';
import history from 'app/history';

export default class SignupBio extends React.Component {
	state = {
		bioBody: USER ? USER.bio : '',
		profileCompleteness: USER ? USER.profileCompleteness : 0,
		successSave: 'hidden',
		failedSave: 'hidden',
		emptyBioError: 'hidden'
	};

	handleBioBodyChange = e => {
		this.setState({
			bioBody: e.target.value,
			successSave: 'hidden',
			emptyBioError: 'hidden'
		});
	}

	handleSave = () => {
		this.save();
	}

	handleSubmit = () => {
		this.save(true);
	}

	save = shouldContinueSignUp => {
		const bioBody = this.state.bioBody.trim();

		if (!bioBody) {
			return this.setState({
				emptyBioError: 'visible',
				successSave: 'hidden'
			});
		}

		HTTP.post('/profile/bio', { bio: bioBody }, (error, body) => {
			if (error) {
				return this.setState({
					failedSave: 'visible',
					successSave: 'hidden'
				});
			}

			this.setState({
				profileCompleteness: body.profileCompleteness,
				failedSave: 'hidden',
				successSave: 'visible'
			});

			USER.bio = bioBody;
			USER.profileCompleteness = body.profileCompleteness;

			if (shouldContinueSignUp) {
				history.push('/signupinterests');
			}
		});
	}

	render = () => {
		return (
			<div className="viewport_width">
				<NavBarNotifications
					showLoginButton={false}
					showSignupButton={false}
				/>
				<div id='bio_top' className="signup_hero">
					<img src="images/signup_bio.svg" />
				</div>

				<div className="clear"></div>

				<div>
					<div className="show_for_desktop">
						<div className="signup_progressbar" style={{width:'56em'}}>
							<ProgressBar progress={this.state.profileCompleteness} width="56em"/>
						</div>
					</div>

					<div className="show_for_mobile">
						<div className="signup_progressbar" style={{width:'19em'}}>
							<ProgressBar progress={this.state.profileCompleteness} width="18em"/>
						</div>
					</div>


					<div className="signup_container">
						<div id="bio_container">
							<div id="bio_header">Personal Bio:&nbsp;<span className="subtitle">(required)</span></div>
							<span className={this.state.successSave + " signup_success"} style={{'marginRight': '0.5em'}}>{CONSTANTS.errors.successSave}</span>
							<span className={this.state.emptyBioError + " signup_error"}>{CONSTANTS.errors.bioError}</span>
							<span className={this.state.failedSave + " signup_error"}>{CONSTANTS.errors.failedSave}</span>
							<textarea id="bio_textarea" rows="12" cols="84" value={this.state.bioBody} onChange={this.handleBioBodyChange} placeholder="Here is the perfect place to describe yourself. Don’t know what to write? You can start off by listing details of your skills and experience. But don’t stop there. You might write about where you grew up, hobbies that you have, your life philosophies, what you look forward to the most everyday, anything that will give people a better idea of who you are as a person. The best teams have teammates who like working together, so it’s important to let your personality shine through." />
							<div className="signup_navigation">
								<input name="submit" className="nextnav" type="submit" value="Next >" onClick={this.handleSubmit} />
								<input name="submit" className="savenav" type="button" value="Save" onClick={this.handleSave}/>
								<Link to="/signupschools" className="backnav">&lt; Back</Link>
							</div>
						</div>
					</div>
				</div>
				<div className="bottom_padding"></div>
				<Footer />
			</div>
		);
	}
};
