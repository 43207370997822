import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import HTTP from 'utils/Http';
import { QueryLink } from 'utils/Utils';
import history from 'app/history';
import { logout } from '_redux/actions';

class MyAccount extends React.Component {
	state = {
		notifications: [],
		page: 1
	}

	UNSAFE_componentWillReceiveProps = (nextProps) => {
		this.setState({
			notifications: nextProps.notifications
		});
	}

	render = () => {
		return (
			<div>
				<div className="myAccount_arrowbox" style={{display: this.props.visibility}}>
					<div className="myAccount_box">
						<ul>
							<li onClick={this.props.handleGoToUserAccount}>My Account</li>
							<li><Link to="/editprofilepage">Edit Profile</Link></li>
							<li><Link to="/accountsettings">Settings</Link></li>
							<li onClick={this.props.handleLogout} style={{borderBottom: 'none'}}>Logout</li>
						</ul>
					</div>
				</div>
			</div>
		);
	}
};

export default connect()(MyAccount);
