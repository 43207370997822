// This is the box that shows the teammates of a project. It is used in ProjectMaster.

import React from 'react';
import HTTP from '../../utils/Http';
import SingleTeammateBox from './SingleTeammateBox.jsx';
import PropTypes from 'prop-types';

export default class ProjectTeammatesBox extends React.Component {
	static propTypes = {
		projectId: PropTypes.string
	}

	state = {
		teammates: []
	}

	UNSAFE_componentWillMount = () => {
		HTTP.get(`/project/${this.props.projectId}/members`, (error, body) => {
			if (!error) {
				this.setState({ teammates: body });
			}
		});
	}

	generateProjectTeammates = () => {
		return this.state.teammates.map(kreator => (
			<SingleTeammateBox
				key={kreator.member._id}
				kreator={kreator}
				projectId={this.props.projectId}
			/>
		));
	}

	render = () => {
		return (
			<div style={{paddingBottom: '2em', minHeight:'20em'}}>
				{this.generateProjectTeammates()}

			</div>
		);
	}
};