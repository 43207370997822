import React from 'react';
import PropTypes from 'prop-types';

export default class Slide extends React.Component {
	render = () => {
		var displayClassName = this.props.active ? 'slide_active' : 'slide_inactive';

		if (this.props.id === "slide0") {
			return (
				<div className={displayClassName}>
					<img src="images/desert_road_01.png"/>
					<div className="slide_text" style={{top: "1em", color: "white", fontSize: "1.2em", textShadow: "2px 2px 2px black"}}>
						<div style={{width: "33em", margin: "0 auto"}}>
							<p>The journey of a thousand miles begins with a simple step. &nbsp;&nbsp;&nbsp;- Lao Tzu</p>
							<p style={{marginTop: "1.8em"}}>But it's way easier to use the bus.</p>
						</div>
					</div>
				</div>
			);
		}

		if (this.props.id === "slide1") {
			return (
				<div className={displayClassName}>
					<img src="images/howto_signup.png" alt={this.props.imageAlt} />
					<div className="slide_text" style={{top: "2em", color: "white"}}>
					<div style={{float: "left", width: "33em", margin: "0 auto"}}>
						<p style={{fontSize: "1.5em"}}>Signup or Login</p>
						<p style={{fontSize: "1.2em", lineHeight: "1.5em", width: "25em", marginTop: "1em", textAlign: "left", marginLeft: "3em"}}>Every project leader needs to have a profile, so if you haven’t signed up yet, do that first, otherwise just log in.</p>
					</div>
				</div>
				</div>
			);
		}

		return (
			<div className={displayClassName}>
				<p>Nothing to see here.</p>
				<img src="images/howto_projectdetails.png" alt={this.props.imageAlt} />
			</div>
		);
	}
};

Slide.propTypes = {
	active: PropTypes.bool,
	id: PropTypes.string,
	imageAlt: PropTypes.string
};