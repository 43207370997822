import React from 'react';
import { connect } from 'react-redux';

import { login } from '_redux/actions';
import HTTP from '../../utils/Http';
import history from 'app/history';

import NavBarNotifications from '../nav/NavBar_Notifications.jsx';
import Footer from '../footer/Footer.jsx';
import PropTypes from 'prop-types';

class ContactSettings extends React.Component {
	static contextTypes = {
		router: PropTypes.object
	}

	state = {
		errSave: 'hidden',
		successSave: 'hidden',

		updateWebsite: true,
		updateTeamInvite: true,
	}

	componentDidMount() {
		if (!USER) {
			return
		}

		this.setState({
			updateWebsite: !!USER.contactSettings.updateWebsite,
			updateTeamInvite: !!USER.contactSettings.updateTeamInvite,
		});
	}

	handleUpdateChange = e => {
		this.setState({
			successSave: 'hidden',
			[e.target.value]: !!e.target.checked
		});
	}

	handleSave = e => {
		e.preventDefault();

		const { errSave, successSave, ...settings } = this.state;

		HTTP.post('/profile/contactSettings', settings, (error, body) => {
			if (error) {
				this.setState({
					errSave: 'visible_block',
					successSave: 'hidden'
				});

				return console.error(error);
			}

			this.setState({
				errSave: 'hidden',
				successSave: 'visible_block'
			});

			USER.contactSettings = settings;
		});
	}

	generateEmailContactSettingOption = opt => {
		return <option key={opt.id} value={opt.id}>{opt.text}</option>;
	}

	render() {
		if (!this.props.loggedIn) {
			history.push("/loginpage");
		}

		return (
			<div className="viewport_width">
				<NavBarNotifications
					showLoginButton={false}
					showSignupButton={false}
				/>

				<div>
					<div className="settings_container">
						<h3>User Settings</h3>
						<div className="settings_box">
							<div className="update_settings_box">
								<div className="text_style" style={{ marginLeft: '1em', marginBottom: '1em' }}>Email me:</div>
								
								<div className="settings_option_box">
									<input type="checkbox" value="updateWebsite" checked={this.state.updateWebsite} onChange={this.handleUpdateChange}/>
									<p>important news or updates to the website.</p>
									<input type="checkbox" value="updateTeamInvite" checked={this.state.updateTeamInvite} onChange={this.handleUpdateChange}/>
									<p>when a team leader invites me to a team.</p>
								</div>
							</div>
							<span className={`success ${this.state.successSave}`}>{CONSTANTS.errors.successSaveSettings}</span>
							<span className={`error ${this.state.errSave}`}>{CONSTANTS.errors.failedSaveSettings}</span>
							<input className="settings_save_button" type="submit" value="Save" onClick={this.handleSave}/>
						</div>
					</div>
				</div>
				<Footer />
			</div>
		);
	}
};

export default connect(
	({ loggedIn }) => ({ loggedIn })
)(ContactSettings);
