// This is the page that tells the user to confirm their account through the email that has been sent to them.

import React from 'react';
import NavBar_Notifications from 'nav/NavBar_Notifications.jsx';
import Footer from 'footer/Footer.jsx';
import HTTP from 'utils/Http';

export default class EmailVerify extends React.Component {
	state = {
		errorSendingVerificationEmail: "hidden",
		successSendingVerificationEmail: "hidden"
	};

	sendEmail = e => {
		HTTP.get('/profile/email/verify/resend', (err, res) => {
			if (err) {
				return this.setState({
							errorSendingVerificationEmail: "visible_block",
							successSendingVerificationEmail: "hidden"});
			} 
			this.setState({
				successSendingVerificationEmail: "visible_block",
				errorSendingVerificationEmail: "hidden"
			});
		});
	}

	render() {
		return (
			<div className="viewport_width">
				<NavBar_Notifications
					showLoginButton={true}
					showSignupButton={true}
				/>
				<div className="signup_hero">
					<img src="/images/email_verify.svg" />
				</div>

				<div className="clear"></div>

				<div>
					<div className="signup_container">
						<div id="verify_account">
							<h3>Verify your account!</h3>
							<p>There are plenty of bots roaming around the internet. We just want to make
							sure you’re a real person. An email with a link has been sent to your email
							address. Please click on it to verify your account. Your profile will not show
							up in search results, and you will not be able to create projects until your
							account is verified.
							</p>
							<div className="verify_warning_box">
								<div className={"error " + this.state.errorSendingVerificationEmail}>
									There was a problem sending your verification email.
								</div>
								<div className={"success " + this.state.successSendingVerificationEmail} style={{background: "#B4F4F4"}}>
									A verification email has been sent to you.
								</div>
							</div>
							<div id="send_email_button">
								<button id="send_email" onClick={this.sendEmail}>Send email again</button>
							</div>
						</div>
					</div>
				</div>
				<div className="bottom_padding"></div>
				<Footer />
			</div>
		);
	}
};
