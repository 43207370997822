// This module will allow users to quickly edit their project.

import React from 'react';
import PropTypes from 'prop-types';
import HTTP from '../../utils/Http';
import { getLocationSearchParam } from 'utils/Utils';
import history from 'app/history';

import NavBarNotifications from '../nav/NavBar_Notifications.jsx';
import Footer from '../footer/Footer.jsx';
import ProjectTopBox from '../projects/ProjectTopBox.jsx';
import ProjectDetailBox from '../projects/ProjectDetailBox.jsx';
import ProjectUpdatesBox from '../projects/ProjectUpdatesBox.jsx';
import ProjectCommentsBox from '../projects/ProjectCommentsBox.jsx';
import ProjectTeammatesBox from 'projects/ProjectTeammatesBox.jsx';

export default class EditProjectPage extends React.Component {
	static propTypes = {
		edit: PropTypes.bool
	};

	state = {
		projectKreator: {},
		projectId: '',
		projectName: '',
		tagline: '',
		category: -1,
		expertiseList: [],
		roleList: [],

		fileData: null, // @todo This isn't being used.
		file: '/images/add_logo.svg',

		videoLink: '',
		projectDescription: '',
		projectCourse:'',
		projectSchool:'',
		projectMission: '',
		startDate: '',
		projectStatus: '',
		projectZipcode: '',
		underReview: false,
		published: false,
		unpublishButtonVisibiity: 'hidden',

		profitPlan: '',
		competition: '',
		targetMarket: '',
		teammateEquity: false,
		futureBusiness: false,

		buttonVisible: 'details',
		updateButtonVisibility: 'hidden',
		isProjectReviewPage: true,

		unpublishProjectWarning: 'hidden',
		unpublishResponseSuccess: 'hidden',
		projectAlreadyUnpublishedError: 'hidden',

		allowUserInput: 'true',
		reportBoxVisibility: 'hidden',
		objectToBeReportedType: '',
		objectToBeReportedId: '',
		objectToBeReportedText: '',
		reportText: '',
		reportTextVisibility: 'visible',
		reportSuccessVisibility: 'hidden',

		opacity: "1",
		pointerEvents: "all"
	};

	UNSAFE_componentWillMount = () => {

		const projectId = getLocationSearchParam('projectId');
		const projectPageTarget = getLocationSearchParam('projectPageTarget');

		HTTP.get(`/project/${projectId}`, (error, body) => {
			if (error) {
				return;
			}
			if (USER._id !== body.kreator._id) {
				history.push('/useraccount');
			} else {
				this.setState({
					projectKreator: body.kreator,
					projectId: body._id,
					projectName: body.name,
					tagline: body.tagline,
					category: body.category,
					leaderRoleSummary: body.leaderRoleSummary,
					videoLink: body.video,
					projectDescription: body.description,
					projectCourse: body.course,
					projectSchool:body.school,
					projectMission: body.mission,
					startDateID: body.startDateID,
					startDate: body.startDate,
					projectStatus: body.status,
					projectZipcode: body.zipCode,
					projectLocation: body.location,
					teammateEquity: body.teammateEquity,
					futureBusiness: body.futureBusiness,
					profitPlan: body.profitPlan,
					competition: body.competition,
					targetMarket: body.targetMarket,
					underReview: body.underReview,
					published: body.published,
					file: body.logo ? '/uploads/' + body.logo : '/images/add_logo.svg',
					roleList: body.lookingFor.map(this.generateRoleList),
					...(body.published && { saveButton: 'hidden' })
				});

				if (projectPageTarget === 'projectTeamMembers') {
					this.setState({buttonVisible: 'teammembers'});
				} else if (projectPageTarget === 'projectUpdates'){
					this.setState({buttonVisible: 'updates'});
				} else if (projectPageTarget === 'projectComments'){
					this.setState({buttonVisible: 'comments'});
				}else {
					this.setState({buttonVisible: 'details'});
				}

				if (body.published === true) {
					this.setState({
						updateButtonVisibility: 'visible',
						unpublishButtonVisibiity: 'visible_block'
					});
				}

				this.setState({unpublishAlertText: "Your project was successfully unpublished."});
			}
		});
	}

	// This will turn the roleDescription information saved in the project into a roleList needed
	// to generate the added roles correctly.
	generateRoleList = (role, index) => {
		var baseSkillTitle = (CONSTANTS.baseSkills.find(baseSkill => baseSkill.id === role.baseSkill) || {}).text || '';
		return {
			key: `${role.expertise}${index}`,
			id: `${role.expertise}${index}`,
			image: baseSkillTitle ? `images/lookingfor_${baseSkillTitle.toLowerCase()}.svg` : '',
			baseSkill: baseSkillTitle ? role.baseSkill : '',
			expertise: role.expertise,
			baseSkillTitle,
			text: role.description
		};
	}

	handleClick = e => {
		// This keeps track of the states of the details, updates, and teammebers buttons.
		this.setState({ buttonVisible: e.target.name });
	}

	showUnpublishProjectWarning = () => {
		if (!this.state.published) {
			this.setState({
				projectAlreadyUnpublishedError: "visible",
				opacity: "0.5",
				pointerEvents: "none"
			});
			return;
		}

		this.setState({
			unpublishProjectWarning: "visible",
			unpublishResponseSuccess: "hidden",
			opacity: "0.5",
			pointerEvents: "none"
		});
	}

	showUnpublishResponseSuccess = () => {
		this.setState({
			unpublishProjectWarning: "hidden",
			unpublishResponseSuccess: "visible",
		});
	}

	hideUnpublishProjectWarning = () => {
		this.setState({
			unpublishProjectWarning: "hidden",
			opacity: "1",
			pointerEvents: "all"
		});
	}


	hideProjectAlreadyUnpublishedError = () => {
		this.setState({
			projectAlreadyUnpublishedError: "hidden",
			opacity: "1",
			pointerEvents: "all"
		});
	}

	hideUnpublishResponseSuccess = () => {
		this.setState({
			unpublishResponseSuccess: "hidden",
			opacity: "1",
			pointerEvents: "all"
		});
	}

	handleUnpublishProject = () => {
		HTTP.post(`/project/${this.state.projectId}/published`, { shouldPublish: false }, (error, body) => {
			if (error) return alert(body);

			this.setState({
				underReview: body.underReview,
				published: body.published,
				unpublishButtonVisibiity: 'hidden',
			});

			this.showUnpublishResponseSuccess();
		});
	}

	handleDeleteProject = () => {
		console.log("project deleted");
	}

	handleReportTextChange = (e) => {
		this.setState({reportText: e.target.value});
	}

	handleShowReportBox = (objectId, objectType) => {
		console.log(objectType + " " + objectId);
		this.setState({
			objectToBeReportedType: objectType,
			objectToBeReportedId: objectId,
			reportBoxVisibility: "visible",
			allowUserInput: false
		});
	}

	handleHideReportBox = () => {
		this.setState({
			objectToBeReportedType: '',
			objectToBeReportedId: '',
			reportBoxVisibility: "hidden",
			reportTextVisibility: "visible",
			reportSuccessVisibility: "hidden",
			reportText: "",
			allowUserInput: true
		});
	}

	handleSuccessfulReport = () => {
		this.setState({
			reportTextVisibility: 'hidden',
			reportSuccessVisibility: 'visible'
		});
	}

	handleReporting = () => {
		var objectType = this.state.objectToBeReportedType;
		var objectId = this.state.objectToBeReportedId;
		var objectText = this.state.reportText;

		HTTP.post(`/report`, { type: objectType, id: objectId, reason: objectText}, (error, body) => {
			if (error) {
				alert(error);
				return;
			} else {
				console.log("report successful");
				this.handleSuccessfulReport();
			}
		});
	}

	render = () => {
		var editProjectButtonDisplay = this.props.edit ? true : false;

		//This code changes which button is highlighted between details, updated, and teammates and shows the appropriate module underneath.
		var bottombox_element;

		// @todo (perf) React rebuilds the whole element every time a button is clicked.
		// Instead, maybe set `display: none` property on these to hide them and let them build in the DOM.
		switch (this.state.buttonVisible) {
			case 'details':
				bottombox_element = (
					<ProjectDetailBox
						projectDescription = {this.state.projectDescription}
						projectMission = {this.state.projectMission}
						profitPlan = {this.state.profitPlan}
						competition = {this.state.competition}
						targetMarket = {this.state.targetMarket}
						futureBusiness = {this.state.futureBusiness}
					/>
				);
				break;

			case 'updates':
				bottombox_element = (
					<ProjectUpdatesBox
						projectId = {this.state.projectId}
						published = {this.state.published}
						projectKreator={this.state.projectKreator}
						canBeEditedByUSER={true}
					/>
				);
				break;

			case 'comments':
				bottombox_element = (
					<ProjectCommentsBox
						projectId = {this.state.projectId}
						projectKreator={this.state.projectKreator}
						published = {this.state.published}
						handleShowReportBox={this.handleShowReportBox}
						canDeleteComments = {true}
					/>
				);
				break;

			case 'teammembers':
				bottombox_element = (
					<ProjectTeammatesBox
						projectId={this.state.projectId}
					/>
				);
		}

		var projectStatusText = 'When you finish editing your project, please submit it for review.';
		var backgroundColor = '#FFB0B0'
		if (this.state.underReview === true){
			projectStatusText = 'Your project has been submitted and is currently under review.';
		} else if (this.state.published === true){
			projectStatusText = 'Your project is currently published and searchable on KreatorBus.';
			backgroundColor = '#B6FFC0'
		}

		return (
			<div className="viewport_width">
				<NavBarNotifications
					showLoginButton={false}
					showSignupButton={false}
				/>
				<br/><br/>
				<div className="clear"></div>
				<div className={"report_box_container " + this.state.reportBoxVisibility}>
					<div className={this.state.reportTextVisibility}>
						<p>
						If you believe this violates our rules of conduct, please make a report.
						</p>
						<textarea cols="50" rows="5" value={this.state.reportText} onChange={this.handleReportTextChange} placeholder="Let us know what is the problem."></textarea>
						<div className="report_box_button_holder">
							<button className="report_box_button_cancel" onClick={this.handleHideReportBox}>Cancel</button>
							<button className="report_box_button_report" style={{float: "right"}} onClick={this.handleReporting}>Report</button>
						</div>
					</div>
					<div className={this.state.reportSuccessVisibility} style={{textAlign: "center"}}>
						<p>
						Your report was succesfully submitted.
						</p>
						<button className="report_box_button_cancel" style={{display: "block", margin: "1.5em auto"}}onClick={this.handleHideReportBox}>Close</button>
					</div>
				</div>

				<div className={"teammate_invitation_warning " + this.state.unpublishProjectWarning}>
					<p>
						By unpublishing a project, it will no longer be searchable or viewable by users. It will also
						require you to resubmit your project for review if you decide to publish it later. Are you sure
						you want to unpublish this project?
					</p>
					<div style={{width:"14em", margin: "1em auto"}}>
						<button className="delete_teammate_warning_cancel" onClick={this.hideUnpublishProjectWarning}>Cancel</button>
						<button className="delete_teammate_warning_cancel" style={{float: "right"}} onClick={this.handleUnpublishProject}>I&#39;m Sure</button>
					</div>
				</div>
				<div className={"teammate_invitation_warning " + this.state.unpublishResponseSuccess} style={{textAlign: "center"}}>
					<p>
						Your response was sent successfully!
					</p>
					<div style={{width:"14em", margin: "1em auto"}}>
						<button className="delete_teammate_warning_cancel" onClick={this.hideUnpublishResponseSuccess}>Close</button>
					</div>
				</div>
				<div className={"teammate_invitation_warning " + this.state.projectAlreadyUnpublishedError} style={{textAlign: "center"}}>
					<p>
						This project has not been published yet.
					</p>
					<div style={{width:"14em", margin: "1em auto"}}>
						<button className="delete_teammate_warning_cancel" onClick={this.hideProjectAlreadyUnpublishedError}>Close</button>
					</div>
				</div>
				<div style={{opacity: this.state.opacity, pointerEvents: this.state.pointerEvents}}>
					<div className="project_status_box" style={{background: backgroundColor}}>
						<div style={{textAlign: 'center'}}>Project Status</div>
						<div className="clear"></div>
						<p>
							{projectStatusText}
						</p>
					</div>

					<ProjectTopBox
						key={this.state.projectId}
						projectKreator = {this.state.projectKreator}
						projectId = {this.state.projectId}
						projectName = {this.state.projectName}
						tagline = {this.state.tagline}
						category = {this.state.category}
						leaderRoleSummary = {this.state.leaderRoleSummary}
						roleList = {this.state.roleList}
						logo = {this.state.file}
						videoLink = {this.state.videoLink}
						teammateEquity = {this.state.teammateEquity}
						startDate = {this.state.startDate}
						projectStatus = {this.state.projectStatus}
						handleFollowProject = {this.handleFollowProject}
						projectSchool = {this.state.projectSchool}
						projectCourse = {this.state.projectCourse}
						projectZipcode = {this.state.projectZipcode}
						projectLocation={this.state.projectLocation}
						editProjectButtonDisplay = {editProjectButtonDisplay}
						showUnpublishProjectWarning = {this.showUnpublishProjectWarning}
						handleDeleteProject = {this.handleDeleteProject}
						isProjectReviewPage = {this.state.isProjectReviewPage}
						unpublishButtonVisibiity = {this.state.unpublishButtonVisibiity}
					/>
					<div className="projectnav_buttonbox">
						<button name="details" className={`${this.state.buttonVisible === 'details' ? '' : 'in'}active`} onClick={this.handleClick}>Details</button>
						<div className={this.state.updateButtonVisibility}><button name="updates" className={`${this.state.buttonVisible === 'updates' ? '' : 'in'}active`} onClick={this.handleClick}>Updates</button></div>
						<div className={this.state.updateButtonVisibility}><button name="comments" className={`${this.state.buttonVisible === 'comments' ? '' : 'in'}active`} onClick={this.handleClick}>Comments</button></div>
						<button name="teammembers" className={`${this.state.buttonVisible === 'teammembers' ? '' : 'in'}active`} onClick={this.handleClick}>Team</button>
					</div>
					<div className="project_bottombox">
						{bottombox_element}
					</div>
				</div>
				<div className="bottom_padding"></div>
				<Footer />
			</div>
		);
	}
};