// This is the first page a user needs to fill out in order to create a project.

import React from 'react';
import ReactImageCropper from '../misc/ReactImageCropper.jsx';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

export default class CreateProject01 extends React.Component {
	static contextTypes = {
		router: PropTypes.object
	}

	static propTypes = {
		roleList: PropTypes.array,
		otherBusiness: PropTypes.string,
		otherArtist: PropTypes.string,
		otherScribe: PropTypes.string,
		otherEngineer: PropTypes.string,
		generateExpertiseInput: PropTypes.func,
		handleOtherExpertise: PropTypes.func,
		handleRoleDelete: PropTypes.func,
		handleRoleDescriptionChange: PropTypes.func,
		handleSave: PropTypes.func,
		handleSkip: PropTypes.func,
		handleSubmit: PropTypes.func,
		projectNameError: PropTypes.string,
		saveButton: PropTypes.string,
		failedSave: PropTypes.string,
		successSave: PropTypes.string,
		roleListError: PropTypes.string,
		emptyRoleError: PropTypes.string,
		handleAddRoles: PropTypes.func,
		handleCategoryChange: PropTypes.func,
		category: PropTypes.number,
		leaderRoleSummary: PropTypes.string,
		categoryError: PropTypes.string,
		handleTaglineChange: PropTypes.func,
		taglineError: PropTypes.string,
		leadershipRoleError: PropTypes.string,
		projectNameError: PropTypes.string,
		projectName: PropTypes.string,
		handleProjectNameChange: PropTypes.func,
		displayCrop: PropTypes.bool,
		file: PropTypes.string,
		handlePhotoChange: PropTypes.func,
		displayImageBox: PropTypes.string,
		projectLogoError: PropTypes.string,
		shouldAddFile: PropTypes.func,
		addFile: PropTypes.func
	}

	state = {
		roleList: this.props.roleList
	}

	UNSAFE_componentWillReceiveProps = nextProps => {
		this.setState({roleList: nextProps.roleList});
	}

	// creates the expertise columns.
	generateBaseSkillColumns = baseSkill => {
		var key, inputValue;
		switch (baseSkill.id) {
			case 0:
				key = 0;
				inputValue = this.props.otherBusiness;
				break;

			case 1:
				key = 1;
				inputValue = this.props.otherArtist;
				break;

			case 2:
				key = 2;
				inputValue = this.props.otherScribe;
				break;

			case 3:
				key = 3;
				inputValue = this.props.otherEngineer;
				break;

			default:
				return null;
		}

		return (
			<div key={key} className="base_detailbox">
				<div className="checkbox_container">
					{CONSTANTS.expertises.filter(expertise => expertise.baseSkill === baseSkill.id).map(this.props.generateExpertiseInput)}
				</div>
				<input key={key} value={inputValue} id={"other" + baseSkill.text} type="text" className={"tiny_icon other_" + baseSkill.text.toLowerCase()} onChange={this.props.handleOtherExpertise} placeholder="unlisted expertise" />
			</div>
		);
	}

	generateOptions = option => {
		return <option key={option.id} value={option.id}>{option.text}</option>
	}

	generateRoleList = role => {
		return (
			<div key={role.id} className="added_kreator">
				<div className="addkreator_symbol">
					<img src={role.image} /><br/>
					<p>
						{role.baseSkillTitle}<br/>
						<span>{role.expertise}</span>
					</p>
				</div>
				<textarea id={role.id} style={{fontFamily:"Avenir, Futura, Helvetica Neue, sans-serif"}} className="addkreator_textarea" rows="5" cols="55" value={role.text} onChange={this.handleRoleDescriptionChange.bind(this, role.id)}></textarea>
				<div className="show_for_mobile"><br/></div>
				<div className="roleDeleteButton">
					<img src="/images/delete_button.svg" onClick={this.props.handleRoleDelete.bind(null, role.id)}/>
				</div>
			</div>
		);
	}

	handleRoleDescriptionChange = (roleId, e) => {
		this.props.handleRoleDescriptionChange(e.target.value, roleId);
	}

	render = () => {
		const categoryOptions = CONSTANTS.projectCategories.concat({ id: '-1', text: 'Select category' }).map(this.generateOptions);
		return (
			<div>
				<div>
					<div className="project_container">
						<div className="back_button_warning">
						{CONSTANTS.warnings.backButtonWarning}
						</div>
						<div className="create_project">
							<h2>Starting a Project? Good for you!</h2>
							<p>
							First we need some basic information about your project. If you don’t
							know the answer or don’t have a requested item ready, don’t worry. You can
							always save your progress and provide it later before submitting.
							</p>
						</div>
						<div className="clear"></div>

						<div className="project_box_holder">
							<div id="project_namebox">
								<div className="show_for_mobile">
									<div className="project_titles">Project Name:<span className="project_subtitle">&nbsp;(required)</span></div>
									<div className="clear"></div>
									<div className={this.props.projectNameError + " error text_style"} style={{float: 'left', width: '13em', margin: '0'}}> {CONSTANTS.errors.projectNameError} </div>
								</div>

								<div className="show_for_desktop">
									<div className={this.props.projectNameError + " error text_style"} style={{margin: '0em 0em 0.5em 21.5em', float: 'left'}}> {CONSTANTS.errors.projectNameError} </div>
									<div className="clear"></div>
								</div>
								<input id="project_name" maxLength="25" type="text" value={this.props.projectName} onChange={this.props.handleProjectNameChange}/>
								
								<div className="show_for_desktop">	
									<div className="project_titles">Project Name:<br/><span className="project_subtitle">(required)</span></div>
								</div>
								
							</div>
						</div>
						
						<div className="project_box_holder" style={{padding:'1em 0em'}}>
							<form className="fileUploadForm_project">
								<div className="project_imagebox" className={this.props.displayImageBox}>

									<div className="show_for_desktop">
										<div className={this.props.projectLogoError + " error text_style"} style={{margin: 'auto', width: '18em'}}> {CONSTANTS.errors.projectLogoError} </div>
										<div className="clear"></div>
										<div className={"error text_style " + this.props.fileTooBigError} style={{margin: 'auto', width: '18em'}}>
											{CONSTANTS.errors.fileSizeUploadError}
										</div>
									</div>

									<div className="show_for_mobile">
										<div className={this.props.displayCrop ? "hidden" : "project_titles"}>Project Image:<span className="project_subtitle">&nbsp;(required)</span></div>
										<div className={this.props.projectLogoError + " error text_style"} style={{margin: '0em', float: 'left'}}> {CONSTANTS.errors.projectLogoError} </div>
										<div className="clear"></div>
										<div className={"error text_style " + this.props.fileTooBigError} style={{disply: 'block', margin: '0.5em 0em 0em 0em', float: 'left'}}>
											{CONSTANTS.errors.fileSizeUploadError}
										</div>
									</div>

									<ReactImageCropper
										file={this.props.file}
										loadCroppedFile={this.props.loadCroppedFile}
										showFileTooBigWarning={this.props.showFileTooBigWarning}
										displayCrop={this.props.displayCrop}
										displayCropBox={this.props.displayCropBox}
										hideCropBox={this.props.hideCropBox}
										aspect={473/315}
										width={473}
										minWidth={200}
										isSignupPhotoPage={false}
									/>
									
									<div className="show_for_desktop">
										<div className={this.props.displayCrop ? "hidden" : "project_titles"}>Project Image:<br/><span className="project_subtitle">(required)</span></div>
									</div>
								</div>
							</form>
						</div>

						<div className="project_box_holder">
							<div id="project_taglinebox">

								<div className="show_for_desktop">
									<div className={this.props.taglineError + " error text_style"} style={{marginLeft: '21.5em', float: 'left'}}> {CONSTANTS.errors.projectTaglineError} </div>
									<div className="clear"></div>
								</div>

								<div className="show_for_mobile">
									<div className="project_titles">Project Tagline:<span className="project_subtitle">&nbsp;(required)</span></div>
									<div className={this.props.taglineError + " error text_style"} style={{margin: '0em', float: 'left'}}> {CONSTANTS.errors.projectTaglineError} </div>
									<div className="clear"></div>
								</div>

								<textarea id="tagline_textarea" maxLength="105" rows="3" cols="30" value={this.props.tagline} onChange={this.props.handleTaglineChange} placeholder="Describe your project in one or two short sentences. Nothing too detailed here. If you had to sum up your project in 150 characters, what would you write. "></textarea>
								
								<div className="show_for_desktop">
									<div className="project_titles">Project Tagline:<br/><span className="project_subtitle">(required)</span></div>
								</div>
							</div>
						</div>

						<div className="project_box_holder">
							<div id="project_categorybox">
								<div className="show_for_desktop">
									<div className={this.props.categoryError + " error text_style"} style={{marginLeft: '21.5em', float: 'left'}}> {CONSTANTS.errors.projectCategoryError} </div>
									<div className="clear"></div>
								</div>

								<div className="show_for_mobile">
									<div className="project_titles">Category:<span className="project_subtitle">(required)</span></div>
									<div className="clear"></div>
									<div className={this.props.categoryError + " error text_style"} style={{margin: '0em 0em -0.5em 0em', float: 'left'}}> {CONSTANTS.errors.projectCategoryError} </div>
									<div className="clear"></div>
								</div>
								
								<select id="project_category" value={this.props.category} onChange={this.props.handleCategoryChange}>
									{categoryOptions}
								</select>
								
								<div className="show_for_desktop">
									<div className="project_titles">Category:<br/><span className="project_subtitle">(required)</span></div>
								</div>		
							</div>
						</div>

						<div className="project_box_holder">
							<div id="project_teamleaderbox">

								<div className="show_for_desktop">
									<div className={this.props.leadershipRoleError + " error text_style"} style={{marginLeft: '21.5em', float: 'left'}}> {CONSTANTS.errors.projectLeadershipRoleError} </div>
									<div className="clear"></div>
								</div>

								<div className="show_for_mobile">
									<div className="project_titles">Leadership Role:<span className="project_subtitle">(required)</span></div>
									<div className={this.props.leadershipRoleError + " error text_style"} style={{margin: '0em', float: 'left'}}> {CONSTANTS.errors.projectLeadershipRoleError} </div>
									<div className="clear"></div>
								</div>

								<textarea id="tagline_textarea" maxLength="2000" rows="3" cols="30" value={this.props.leaderRoleSummary} onChange={this.props.handleLeadershipRoleChange} placeholder="As the project leader, let potential teammates know what role you plan to play. For example, if you are building a website, will you be the designer and marketing person."></textarea>
								
								<div className="show_for_desktop">
									<div className="project_titles">Leadership Role:<br/><span className="project_subtitle">(required)</span></div>
								</div>

							</div>
						</div>

						<div className="project_box_holder">
							<div id="project_teamneedbox">

								<div className="show_for_desktop">
									<div className="teamneedbox_intro">
										Select the teammates needed to get your project done.
										You can always add or subtract positions later,
										but start off with what you feel will be the core.
									</div>
									<div className="project_titles">Teammates needed:<br/><span className="project_subtitle">(required)</span></div>
								</div>

								<div className="show_for_mobile">
									<div className="project_titles">Teammates needed:<span className="project_subtitle">(required)</span></div>
									<div className="teamneedbox_intro">
										Select the teammates needed to get your project done.
										You can always add or subtract positions later,
										but start off with what you feel will be the core.
									</div>								
								</div>

								<div className="project_teamneed_image">
									<img src="images/signup_baseskills_select.svg" /><br/>
									<div className="clear"></div>
								</div>

								<div id="project_baseboxes">
									{CONSTANTS.baseSkills.map(this.generateBaseSkillColumns)}
								</div>
								<div className="clear"></div>
								<button onClick={this.props.handleAddRoles}>Add Roles</button>
								<a id="rolesAdded">
									<h2>
									Here’s the place to let kreators know more about the
									<div className="show_for_desktop">
										<br/>
									</div>
									roles you want them to fill and the skills they may need:<br/><br/>
									<div className={this.props.roleListError + " error"} style={{"fontSize": "1em", float: 'left'}}> {CONSTANTS.errors.roleListError} </div>
									<div className={this.props.emptyRoleError + " error"} style={{"fontSize": "1em", float: 'left'}}> {CONSTANTS.errors.emptyRoleError} </div>
									<br/><br/>
									</h2>
								</a>
								<div className="roleList">
									{this.state.roleList.map(this.generateRoleList)}
								</div>
							</div>
						</div>

						<div className="clear"></div>
						<div className="lower_navigation">
							<p className={this.props.successSave + " success"} style={{float: 'right', marginRight: '0.8em'}}>{CONSTANTS.errors.successSave}</p>
							<p className={this.props.failedSave + " error"} style={{float: 'right', marginRight: '0.8em'}}>{CONSTANTS.errors.failedSaveCheckFields}</p>

							<div className="clear"></div>
							<input className="lower_nextnav" name="submit" type="submit" value="Next >" onClick={this.props.handleSubmit.bind(null, 2)}/>
							{/*<input className="skipnav" name="skip" onClick={this.props.handleSkip.bind(null, 2)} value="Skip"/>*/}
							<input className={this.props.saveButton + " savenav"} name="submit" onClick={this.props.handleSave} value="Save"/>
						</div>
					</div>
				</div>
			</div>
		);
	}
};

//import ImageCropper from '../misc/ImageCropper.jsx';
/*<div className="show_for_desktop">
	<ImageCropper
		file={this.props.file}
		handlePhotoChange={this.props.handlePhotoChange}
		displayCrop={this.props.displayCrop}
		cutoutWidth={30}
		windowWidth={390}
		windowHeight={260}
		panelWidth={450}
		panelHeight={320}
	/>
</div>

<div className="show_for_mobile">
	<ImageCropper
		file={this.props.file}
		handlePhotoChange={this.props.handlePhotoChange}
		displayCrop={this.props.displayCrop}
		cutoutWidth={30}
		windowWidth={200}
		windowHeight={133}
		panelWidth={260}
		panelHeight={185}
	/>
</div>*/
