import React from 'react';
import { Link } from 'react-router-dom';
import HTTP from '../../utils/Http';
import PropTypes from 'prop-types';

export default class ImageCropper extends React.Component {
	static contextTypes = {
		router: PropTypes.object
	}

	state = {
		scale: 0.2,
	}

	constructor(props) {
		super(props)
		this.panel = React.createRef();
	}

	componentDidMount() {
		this.ctx = this.panel.current.getContext('2d');
		this.image = new Image();
		this.image.onload = this.onImageLoad;
		this.click = false;
		this.baseX = 0;
		this.baseY = 0;
		this.lastPointX = 0;
		this.lastPointY = 0;
	}

	componentDidUpdate(prevProps) {
		if (prevProps.file !== this.props.file) {
			this.image.src = this.props.file;
			this.baseX = 0;
			this.baseY = 0;
			this.lastPointX = 0;
			this.lastPointY = 0;
			this.setState({ scale: 0.2 })
		}
	}

	/**
	 * Animation on the canvas depends on three events of mouse. down, up and move
	 */
	onImageLoad = e => {
		this.drawimage(0, 0);
		this.ctx.canvas.onmousedown = this.onMouseDown.bind(this);
		this.ctx.canvas.onmousemove = this.onMouseMove.bind(this);
		this.ctx.canvas.onmouseup = this.onMouseUp.bind(this);
	}

	/*
	 * Draw image on canvas, after any changes
	 */
	drawimage(x, y) {
		var w = this.ctx.canvas.width,
			h = this.ctx.canvas.height;
		this.ctx.clearRect(0, 0, w, h);
		this.baseX = this.baseX + (x - this.lastPointX);
		this.baseY = this.baseY + (y - this.lastPointY);
		this.lastPointX = x;
		this.lastPointY = y;
		this.ctx.drawImage(this.image, this.baseX, this.baseY, Math.floor(this.image.width * this.state.scale), Math.floor(this.image.height * this.state.scale));
		this.drawCutout();
	}

	/**
	 * Responsible to draw the cutout over canvas, clockwise rectangle and anticlock wise rectangle, make sure a cutout.
	 * @return {[type]}
	 */
	drawCutout() {
		this.ctx.fillStyle = 'rgba(128, 128, 128, 0.7)';
		this.ctx.beginPath();
		this.ctx.rect(0, 0, this.ctx.canvas.width, this.ctx.canvas.height);
		//Draw anti clockwise rectangle, for cutout.
		this.ctx.moveTo(this.props.cutoutWidth, this.props.cutoutWidth);
		this.ctx.lineTo(this.props.cutoutWidth, this.props.windowHeight + this.props.cutoutWidth);
		this.ctx.lineTo(this.props.cutoutWidth + this.props.windowWidth, this.props.windowHeight + this.props.cutoutWidth);
		this.ctx.lineTo(this.props.cutoutWidth + this.props.windowWidth, this.props.cutoutWidth);
		this.ctx.closePath();
		this.ctx.fill();
	}

	/**
	 * Get call on mouse press, make click variable to true, which will be used in mousemove events
	 * It also set the point were mouse click happened.
	 * @param  {[type]} e
	 * @return {[type]}
	 */
	onMouseDown = e => {
		e.preventDefault();
		var loc = this.windowToCanvas(e.clientX, e.clientY);
		this.click = true;
		this.lastPointX = loc.x;
		this.lastPointY = loc.y;
	}

	/**
	 * Track the mouse movment and draw the image accordingly, but only when clicked happened.
	 * @param  {[type]} e
	 * @return {[type]}
	 */
	onMouseMove = e => {
		e.preventDefault();
		if (this.click) {
			var loc = this.windowToCanvas(e.clientX, e.clientY);
			this.drawimage(loc.x, loc.y);
		}
	}

	/**
	 * make click = false, hence no canvas draw on mousemovment.
	 * @param  {[type]} e
	 * @return {[type]}
	 */
	onMouseUp = e => {
		e.preventDefault();
		this.click = false;
	}

	/**
	 * Translate to HTML coordinates to Canvas coordinates.
	 */
	windowToCanvas(x, y) {
		var canvas = this.ctx.canvas;
		var bbox = canvas.getBoundingClientRect();
		return {
			x: x - bbox.left * (canvas.width / bbox.width),
			y: y - bbox.top * (canvas.height / bbox.height)
		};
	}

	/**
	 * Get the canavs, remove cutout, create image elemnet on UI.
	 * @return {[type]}
	 */
	showCropedImage = e => {
		var temp_ctx, temp_canvas;
		temp_canvas = document.createElement('canvas');
		temp_ctx = temp_canvas.getContext('2d');
		temp_canvas.width = this.props.windowWidth;
		temp_canvas.height = this.props.windowHeight;
		temp_ctx.drawImage(this.ctx.canvas, this.props.cutoutWidth, this.props.cutoutWidth, this.props.windowWidth, this.props.windowHeight, 0, 0, this.props.windowWidth, this.props.windowHeight);
		var vData = temp_canvas.toDataURL();
		this.props.handlePhotoChange(vData);
	}

	handleSliderValueChange = e => {
		this.setState({ scale: e.target.value }, () => {
			this.drawimage(this.lastPointX, this.lastPointY);
		});
	}

	render() {
		return (
			<div className={this.props.displayCrop}>
				<div className="table">
					<div className="tableRow">
						<div className="tableCell box">
							<canvas ref={this.panel} width={this.props.panelWidth} height={this.props.panelHeight}></canvas>
						</div>
					</div>

					<div className="tableRow">
						<div className="tableCell">
							<input type="range" min="0.2" max="3" step="0.001" value={this.state.scale} onChange={this.handleSliderValueChange} />
						</div>
					</div>
					<div className="clear"></div>
					<button id="cropImgButtn" onClick={this.showCropedImage}>Crop</button>
				</div>
			</div>
		);
	}
};
