import React from 'react';
import { Link } from 'react-router-dom';
import HTTP from '../../utils/Http';
import NavBarNotifications from '../nav/NavBar_Notifications.jsx';
import ProgressBar from './ProgressBar.jsx';
import Footer from '../footer/Footer.jsx';
import PropTypes from 'prop-types';
import history from 'app/history';

export default class SignupWeekend extends React.Component {
	state = {
		profileCompleteness: USER ? USER.profileCompleteness : 0,
		weekendBody: USER ? USER.weekend : '',
		successSave: 'hidden',
		failedSave: 'hidden'
	};

	handleWeekendBodyChange = e => {
		this.setState({ weekendBody: e.target.value, successSave: 'hidden' });
	}

	handleBack = () => {
		this.save({ shouldContinueSignUp: true, backToEditProfilePage: true });
	}

	handleSave = () => {
		this.save();
	}

	handleSubmit = () => {
		this.save({ shouldContinueSignUp: true });
	}

	save = ({ shouldContinueSignUp = false, backToEditProfilePage = false } = {}) => {
		const weekendBody = this.state.weekendBody.trim();

		HTTP.post('/profile/weekend', { weekend: weekendBody }, (error, body) => {
			if (error) {
				return this.setState({ failedSave: 'visible', successSave: 'hidden' });
			}

			USER.profileCompleteness = body.profileCompleteness;
			USER.weekend = weekendBody;

			this.setState({
				failedSave: 'hidden',
				successSave: 'visible',
				profileCompleteness: body.profileCompleteness
			});

			if (shouldContinueSignUp) {
				const path = backToEditProfilePage ? '/editprofilepage' : '/signupreview';
				history.push(path);
			}
		});
	}

	render = () => {
		return (
			<div className="viewport_width">
				<NavBarNotifications
					showLoginButton={false}
					showSignupButton={false}
				/>
				<div className="signup_hero">
					<img src="images/signup_weekend.svg" />
				</div>

				<div className="clear"></div>

				<div>
					<div className="show_for_desktop">
						<div className="signup_progressbar" style={{width:'56em'}}>
							<ProgressBar progress={this.state.profileCompleteness} width="56em"/>
						</div>
					</div>

					<div className="show_for_mobile">
						<div className="signup_progressbar" style={{width:'19em'}}>
							<ProgressBar progress={this.state.profileCompleteness} width="18em"/>
						</div>
					</div>

					<div className="signup_container">
						<div className="weekend_container">
							<div className="weekend_header">Describe what you do on a typical weekend:</div>
							<span className={this.state.successSave + " signup_success"}>{CONSTANTS.errors.successSave}</span>
							<span className={this.state.failedSave + " signup_error"}>{CONSTANTS.errors.failedSave}</span>

							<textarea
								className="weekend_textarea"
								rows="8"
								cols="84"
								value={this.state.weekendBody}
								onChange={this.handleWeekendBodyChange}
								placeholder="Let people know how you spend your free time."></textarea>

							<div className="signup_navigation">
								<input name="submit" className="nextnav" type="submit" value="Next >" onClick={this.handleSubmit}/>
								<Link to="/signupreview" className="skipnav">Skip</Link>
								<input name="submit" className="savenav" type="submit" value="Save" onClick={this.handleSave}/>
								<Link to="/signupfictional" className="backnav">&lt; Back</Link>
							</div>
						</div>
					</div>
				</div>
				<div className="bottom_padding"></div>
				<Footer />
			</div>
		);
	}
};
