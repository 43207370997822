// This page allows the users to review their project before submitting.

import React from 'react';
import ProjectMasterReview from './ProjectMasterReview.jsx';
import PropTypes from 'prop-types';

export default class CreateProjectReview extends React.Component {
	static propTypes = {
		projectId: PropTypes.string,
		projectName: PropTypes.string,
		tagline: PropTypes.string,
		leaderRoleSummary: PropTypes.string,
		category: PropTypes.number,
		roleList: PropTypes.array,
		logo: PropTypes.string,
		videoLink: PropTypes.string,
		projectDescription: PropTypes.string,
		projectCourse: PropTypes.string,
		projectMission: PropTypes.string,
		startDate: PropTypes.object,
		projectStatus: PropTypes.string,
		profitPlan: PropTypes.string,
		competition: PropTypes.string,
		targetMarket: PropTypes.string,
		teammateEquity: PropTypes.bool,
		futureBusiness: PropTypes.bool,
		projectSubmitError: PropTypes.string,
		projectCountryCode: PropTypes.string,
		projectZipcode: PropTypes.string,
		editProjectButtonDisplay: PropTypes.bool,
		published:PropTypes.bool
	}

	state = {
		startDateWarning: "hidden"
	}

	UNSAFE_componentWillMount = () => {
		this.setStartDateWarning();
	}

	UNSAFE_componentWillReceiveProps = (nextProps) => {
		this.setStartDateWarning();
	}

	setStartDateWarning = () => {
		var startDateObject = new Date(this.props.startDate);
		var currentDate = new Date();
		var oneDay = 24 * 60 * 60 * 1000; // hours * minutes * seconds * milliseconds

		var daysToStart = Math.round((startDateObject.getTime() - currentDate.getTime()) / oneDay);

		if (daysToStart <= 10) {
			if (!this.props.published && !this.props.underReview){
				this.setState({startDateWarning : "visible"});
			} else {
				this.setState({startDateWarning: "hidden"});
			}
		} else {
			this.setState({startDateWarning: "hidden"});
		}
	}

	render = () => {
		const submitButtonTitle = this.props.published ? 'My Account' : 'Submit';
		var revieweTitleText = '';
		if (!this.props.published && !this.props.underReview) {
			revieweTitleText = 'You’re almost done. This is how your project will appear to other kreators. Please take a moment to review all the information.'
		} else if (this.props.underReview){
			revieweTitleText = 'Your project is currently under review for publishing, but you can still submit changes.'
		} else {
			revieweTitleText = 'This is how your project will appear to other kreators. Please take a moment to review all the information.'
		}
		return (
			<div>
				<div>
					<div className="project_intro">
						<div className="back_button_warning">
							{CONSTANTS.warnings.backButtonWarning}
						</div>
						<div className="create_project">
							<h2>Let's review.</h2>
							<p>
							{revieweTitleText}
							<br/><br/>
							</p>
						</div>
						<div className={this.state.startDateWarning + " startdate_warning"}>
							<p>
							YOUR PROJECT START DATE IS LESS THAN TEN DAYS AWAY!<br/>
							THIS MAY NOT BE ENOUGH TIME FOR YOU TO CONTACT AND FIND TEAMMATES.<br/>
							IF YOU DO NOT HAVE AT LEAST ONE OTHER TEAMMATE ON YOUR PROJECT BY<br/>
							THE START DATE TIME, YOUR PROJECT WILL BE UNPUBLISHED! <br/>
							ARE YOU SURE THIS IS THE START DATE YOU WANT?</p>
						</div>
					</div>
					<div style={{pointerEvents: "none"}}>
						<ProjectMasterReview
							projectKreator={USER}
							projectId={this.props.projectId}
							projectName={this.props.projectName}
							tagline={this.props.tagline}
							leaderRoleSummary={this.props.leaderRoleSummary}
							category={this.props.category}
							roleList={this.props.roleList}
							logo={this.props.logo}
							videoLink={this.props.videoLink}
							projectDescription={this.props.projectDescription}
							projectCourse={this.props.projectCourse}
							projectSchool={this.props.projectSchool}
							projectMission={this.props.projectMission}
							startDate={this.props.startDate}
							projectStatus={this.props.projectStatus}
							profitPlan={this.props.profitPlan}
							competition={this.props.competition}
							targetMarket={this.props.targetMarket}
							teammateEquity={this.props.teammateEquity}
							futureBusiness={this.props.futureBusiness}
							projectSubmitError={this.props.projectSubmitError}
							projectCountryCode={this.props.projectCountryCode}
							projectZipcode={this.props.projectZipcode}
							projectLocation={this.props.projectLocation}
							editProjectButtonDisplay={this.props.editProjectButtonDisplay}
						/>
					</div>
					<div className="lower_navigation">
						<p className={this.props.projectSubmitError + " error"} style={{float: 'right'}}>{CONSTANTS.errors.projectSubmissionError}<br/> {this.props.errorText}</p><br/>
						<div className="clear"></div>
						<div className="show_for_desktop">
							<input className="submit_button" name="submit" type="submit" value={submitButtonTitle} onClick={this.props.handleProjectSubmit}/>
						</div>
						<div className="show_for_mobile">
							<input className="submit_button" style={{marginLeft:'0em'}} name="submit" type="submit" value={submitButtonTitle} onClick={this.props.handleProjectSubmit}/>
						</div>
						<input className="backnav" name="submit" type="submit" value="< Back" onClick={this.props.handleBackButtonClick.bind(null, 2)}/>
					</div>
				</div>
			</div>
		);
	}
};
