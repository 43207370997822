import React from 'react';
import ReactRouter from 'react-router';
import { Link } from 'react-router-dom';
import HTTP from '../utils/Http';
import NavBarNotifications from './nav/NavBar_Notifications.jsx';
import FeaturedProjects from './projects/FeaturedProjects.jsx';
import FeaturedProfiles from './profiles/FeaturedProfiles.jsx';
import Slideshow from './misc/Slideshow.jsx';
import Footer from './footer/Footer.jsx';
import PropTypes from 'prop-types';

export default class HomePage extends React.Component {
	static contextTypes = {
		router: PropTypes.object
	}

	render = () => {
		return (
			<div className="viewport_width">
				<NavBarNotifications
					showLoginButton={true}
					showSignupButton={true}
				/>

				<div className="clear"></div>

				<div className="hero">

					<div className="show_for_desktop">
						<img className="hero_image" src="images/kb_hero.png" />
					</div>
				
					<div className="show_for_mobile">
						<div style={{textAlign: 'center'}}>
							<img className="hero_image" style={{width: '200px', margin: '2em auto'}} src="images/bus_icon_large.png" />
						</div>
					</div>
				
					<div className="hero_text_box">
						<div>
							<p className="hero_image_font">Bringing great ideas and talent together.<br/>
							The journey starts here.
							</p>
						</div>
					</div>
					<div className="signup_button">
						<Link to="/signupbasic">Signup for Free</Link>
					</div>
				</div>

				<div className="clear"></div>

				<div className="show_for_desktop">
					<div className="center_box">
						<img src="images/how_it_works.svg" />
					</div>
				</div>
				<div className="show_for_mobile">
					<div className="center_box" style={{marginTop: '4em'}}>
						<img src="images/how_it_works_mobile.svg" />
					</div>
				</div>
				<div className="clear"></div>

				<div className="show_for_desktop">
					<div className="center_box" style={{margin: "3em auto 3em auto", padding: '4em', height: "30em"}}>
						<div style={{width:'63em', margin:'auto'}}>
							<div style={{fontSize: "2.5em", paddingTop: "1em", float: "left", width: "13em"}}>
								<p>Create a detailed profile and show them what you got.</p>
								<div className="signup_button" style={{fontSize: "0.5em", margin: "5em auto", position: "static"}}>
									<Link to="/signupbasic">Signup Now</Link>
								</div>
							</div>
							<div style={{float:"right"}}><img style={{width: "23em"}} src="images/create_profile.png" /></div>
						</div>
					</div>
				</div>

				<div className="show_for_mobile">
					<div className="center_box" style={{textAlign: 'center', margin: "3em auto 3em auto", height: "40em"}}>
						<div style={{fontSize: "1.5em", padding: "1em", marginBottom: "2em"}}>
							<p>Create a detailed profile and <br/>show them what you got.</p>
							<div><img style={{width: "11em"}} src="images/create_profile.png" /></div>
							<div className="signup_button" style={{margin: "1em auto"}}>
								<Link to="/signupbasic">Signup Now</Link>
							</div>
						</div>
						
					</div>
				</div>

				<div className="clear"></div>

				<div className="show_for_desktop">
					<div style={{margin: "3em auto 3em auto", height: "30em", padding: "4em", borderTop: "1px solid #EDEDED"}}>
						<div style={{width:'63em', margin:'auto'}}>
							<div style={{fontSize: "2.5em", paddingRight: "1em", float: "left", width: "15em"}}>
								<p>Post a project or join a project.</p>
								<p style={{fontSize: "0.6em", marginTop: "2em"}}>At KreatorBus we welcome all types of projects from the next big startup, to passion
								projects, to class projects. As long as it's a great idea, we want to see it made.
								</p>
							</div>
							<div style={{float:"left"}}><img style={{width: "23em"}} src="images/create_project.png" /></div>
						</div>
					</div>
				</div>

				<div className="show_for_mobile">
					<div style={{margin: "2em auto 2em auto", height: "40em", paddingTop: "1em", borderTop: "1px solid #EDEDED"}}>
						<div style={{paddingTop: "1em", width: "19em", margin: 'auto'}}>
							<p style={{fontSize: '1.5em'}}>Post a project or join a project.</p>
							<p style={{fontSize: "1em", marginTop: "2em"}}>At KreatorBus we welcome all types of projects from the next big startup, to passion
							projects, to class projects. As long as it's a great idea, we want to see it made.
							</p>
						</div>
						<div style={{margin: '1em auto', textAlign: 'center'}}><img style={{width: '300px'}}src="images/create_project.png" /></div>
					</div>
				</div>

				<div className="clear"></div>
				
				<div className="show_for_desktop">
					<div style={{margin: "1em auto 3em auto", height: "50em", borderTop: "1px solid #EDEDED"}}>
						<div style={{fontSize: "2.5em", paddingTop: "1em", width: "15em", margin: "0 auto"}}>
							<p>Get recognized for your work.</p>
							<p style={{fontSize: "0.6em", marginTop: "2em"}}>Every project has a team page where teammates
							can be credited for their contributions. Show potential employers, investors, or just family
							and friends what you've accomplished.  
							</p>
						</div>
						<div style={{textAlign: "center", margin: "4em 0 3em 0"}}><img style={{width: "55em", margin: "0 auto"}} src="images/teammate_profile.png" /></div>
					</div>
				</div>

				<div className="show_for_mobile">
					<div style={{margin: "3em auto 3em auto", height: "40em", borderTop: "1px solid #EDEDED"}}>
						<div style={{paddingTop: "3em", width: "19em", margin: "0 auto"}}>
							<p style={{fontSize: '1.5em'}}>Get recognized for your work.</p>
							<p style={{fontSize: "1em", marginTop: "2em"}}>Every project has a team page where teammates
							can be credited for their contributions. Show potential employers, investors, or just family
							and friends what you've accomplished.  
							</p>
						</div>
						<div style={{textAlign: "center", margin: "4em 0 3em 0"}}><img style={{width: '100%', maxWidth: '40em', margin: "0 auto"}} src="images/teammate_profile.png" /></div>
					</div>
				</div>
				<div className="clear"></div>
				<Footer />

			</div>
		);
	}
};
