import React from 'react';
import PropTypes from 'prop-types';

export default class Controls extends React.Component {
	static propTypes = {
		name: PropTypes.string.isRequired,
		togglePrev: PropTypes.func,
  		toggleNext: PropTypes.func
	};
	render = () => {
		return (
			<div className="controls">
				<div className="togglePrev" onClick={this.props.togglePrev}>&lt;</div>
				<div className="toggleNext" onClick={this.props.toggleNext}>&gt;</div>
			</div>
		);
	}
};