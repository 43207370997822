import React from 'react';
import { Link } from 'react-router-dom';
import HTTP from '../../utils/Http';
import NavBarNotifications from '../nav/NavBar_Notifications.jsx';
import ProgressBar from './ProgressBar.jsx';
import Footer from '../footer/Footer.jsx';
import PropTypes from 'prop-types';
import history from 'app/history';

export default class SignupInterests extends React.Component {
	static propTypes = {
		edit: PropTypes.bool
	};

	state = {
		profileCompleteness: USER ? USER.profileCompleteness : 0,
		failedSelection: 'hidden',
		successSave: 'hidden',
		failedSave: 'hidden',
		otherInterest: '',
		interests: USER ? USER.interests : [] // array of IDs
	};

	onInterestClick = e => {
		const editedInterest = +e.target.value;
		var interests = [...this.state.interests];

		if (e.target.checked){
			interests.push(editedInterest);
		} else if (!e.target.checked) {
			const index = interests.indexOf(editedInterest);

			if (index > -1) {
    			interests.splice(index, 1);
			}
		}

		this.setState({
			successSave: 'hidden',
			failedSelection: 'hidden',
			interests
		});
	}

	handleOtherInterest = e => {
		this.setState({ otherInterest: e.target.value });
	}

	generateInterestInput = interest => {
		return (
			<label key={+interest.id}>
				<input
					type="checkbox"
					id={+interest.id}
					defaultChecked={this.state.interests.indexOf(+interest.id) !== -1}
					value={+interest.id}
					onChange={this.onInterestClick}
				/>
				{interest.text}
			</label>
		);
	}

	generateInterestColumn = columnInterests => {
		return (
			<div className="interests_column">
				{columnInterests.map(this.generateInterestInput)}
			</div>
		);
	}

	handleBack = () => {
		this.save({ shouldContinueSignUp: true, backToEditProfilePage: true });
	}

	handleSave = () => {
		this.save();
	}

	handleSubmit = () => {
		this.save({ shouldContinueSignUp: true });
	}

	save = ({ shouldContinueSignUp = false, backToEditProfilePage = false } = {}) => {
		if (!this.state.interests.length) {
			return this.setState({ failedSelection: 'visible', successSave: 'hidden' });
		}

		HTTP.post('/profile/interests', this.state.interests, (error, body) => {
			if (error) {
				return this.setState({ failedSave: 'visible', successSave: 'hidden' });
			}

			USER.interests = this.state.interests;
			USER.profileCompleteness = body.profileCompleteness;

			this.setState({
				profileCompleteness: body.profileCompleteness,
				failedSave: 'hidden',
				successSave: 'visible'
			});

			if (shouldContinueSignUp) {
				const path = backToEditProfilePage ? '/editprofilepage' : '/signupaddinfo';
				history.push(path);
			}
		});
	}

	render = () => {
		const interestsColumnOne = CONSTANTS.projectCategories.slice(0, 5);
		const interestsColumnTwo = CONSTANTS.projectCategories.slice(5, 10);
		const interestsColumnThree = CONSTANTS.projectCategories.slice(10);
		const editNavBarDisplay = this.props.edit ? 'visible_block' : 'hidden';
		const signupNavBarDisplay = this.props.edit ? 'hidden' : 'visible_block';

		return (
			<div className="viewport_width">
				<NavBarNotifications
					showLoginButton={false}
					showSignupButton={false}
				/>

				<div className="signup_hero">
					<img src="images/signup_interests.svg" />
				</div>

				<div className="clear"></div>

				<div>
					<div className="show_for_desktop">
						<div className="signup_progressbar" style={{width:'56em'}}>
							<ProgressBar progress={this.state.profileCompleteness} width="56em"/>
						</div>
					</div>

					<div className="show_for_mobile">
						<div className="signup_progressbar" style={{width:'19em'}}>
							<ProgressBar progress={this.state.profileCompleteness} width="18em"/>
						</div>
					</div>

					<div className="signup_container">
						<div id="interests_container">
							<div className="show_for_desktop"><div id="interests_header">What types of projects interest you?</div>&nbsp;&nbsp;<span className="subtitle" style={{'fontSize': '1.1em'}}>(Select at least one)</span></div>
							<div className="show_for_mobile">
								<div id="interests_header">What types of projects interest you?</div>
								<div className="clear"></div>
								<span className="subtitle" style={{'fontSize': '1.1em', marginLeft: '0.2em'}}>(Select at least one)</span>
							</div>
							<div className="clear"></div><br/>
							<span className={this.state.successSave + " signup_success"} style={{'marginBottom': '0.5em', marginLeft: '0.2em' }}>{CONSTANTS.errors.successSave}</span>
							<span className={this.state.failedSave + " signup_error"} style={{'marginBottom': '0.5em', marginLeft: '0.2em' }}>{CONSTANTS.errors.failedSave}</span>
							<span className={this.state.failedSelection + " signup_error"} style={{'marginBottom': '0.5em', marginLeft: '0.2em' }}>{CONSTANTS.errors.interestsError}</span>
							<div id="interests_box">
								{this.generateInterestColumn(interestsColumnOne)}
								{this.generateInterestColumn(interestsColumnTwo)}
								{this.generateInterestColumn(interestsColumnThree)}
								<br/>
								{/*}
								<span className="suggest_one">Suggest One:</span>
								<br/>
								<input type="text" id="interests_input" value={this.state.otherInterest} onChange={this.handleOtherInterest}/>
								*/}
							</div>
							<div className={"signup_navigation " + signupNavBarDisplay}>
								<input name="submit" className="nextnav" type="submit" value="Next >" onClick={this.handleSubmit}/>
								<input name="submit" className="savenav" type="submit" value="Save" onClick={this.handleSave}/>
								<Link to="/signupbio" className="backnav">&lt; Back</Link>
							</div>
							<div className={"edit_signup_navigationb " + editNavBarDisplay} style={{width:"100%"}}>
								<input className="savenavb" style={{marginRight:"-0.8em"}} type="submit" value="Save" onClick={this.handleSave}/>
								<input className="backnavb" style={{marginLeft:0}} type="submit" value="Back to Profile" onClick={this.handleBack}/>
							</div>
						</div>
					</div>
				</div>
				<div className="bottom_padding"></div>
				<Footer />
			</div>
		);
	}
};