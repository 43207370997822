import React from 'react';
import NavBarNotifications from './NavBar_Notifications.jsx';
import Footer from '../footer/Footer.jsx';
import PropTypes from 'prop-types';

export default class Page404 extends React.Component {
	static contextTypes = {
		router: PropTypes.object
	}

	render = () => {
		return(
			<div className="viewport_width">
				<NavBarNotifications
					showSignupButton={true}
					showLoginButton={true}
				/>
					<div className="signup_hero">
						<img src="../images/404.svg" />
					</div>

					<div className="clear"></div>

					
					<div className="signup_container">
						<div className="not_found_container" style={{padding: '0em 1em'}}>
							<h4>Uh oh! We couldn’t find the page you were looking for.</h4>
							<p>For some reason the page that you were trying to navigate to could not be found. No worries. You can try again or contact us if you think there might be a broken link. We are always making improvements to the site and sometimes problems happen. We will try and fix them as soon as possible.</p>
						</div>
					</div>
					
				<Footer />
			</div>
		);
	}
};
