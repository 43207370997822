import React from 'react';
import NavBarNotifications from '../nav/NavBar_Notifications.jsx';
import Footer from '../footer/Footer.jsx';
import PropTypes from 'prop-types';

export default class About extends React.Component {
	static contextTypes = {
		router: PropTypes.object
	}

	render = () => {
		return(
			<div className="viewport_width">
				<NavBarNotifications
					showLoginButton={true}
					showSignupButton={true}
				/>
					<div className="signup_hero" style={{textAlign:"center"}}>
						<img src="images/kb_hero.svg" style={{width: "150px", height: "150px", marginTop: "2em"}}/>
					</div>

					<div className="clear"></div>

					<div>
						<div className="signup_container">
							<div className="not_found_container" style={{ marginBottom: "2em"}}>
								<h4>Who are we?</h4>
								<p>Kreatorbus was started by two college students sharing a similar problem. One had a project idea
								but could not find the proper teammates to make it happen. One had the skills but was looking
								for something meaningful to work on. Through luck we met and decided to create a website that
								would make the process of pairing talented people with wonderful projects much easier.</p>
								<br/>
								<p>Kreatorbus has one simple goal. We never want to see a good idea die because it could
								not find the right teammates. This idea could be a startup. It could also be a passion project or
								even a class project. As long as it can benefit the world, we want to see it made. Working on this
								website, we understand the difficulties, hard work, and satisfaction that comes with making something
								personally meaningful. The process is like a journey, and we hope you will hop on the bus and find yours.
								</p>
							</div>
						</div>
					</div>
				<div className="bottom_padding"></div>
				<Footer />
			</div>
		);
	}
};
