import React from 'react';
import { Link } from 'react-router-dom';
import { WithContext as ReactTags } from 'react-tag-input';
import HTTP from '../../utils/Http';
import NavBarNotifications from '../nav/NavBar_Notifications.jsx';
import ProgressBar from './ProgressBar.jsx';
import Footer from '../footer/Footer.jsx';
import Sortable from 'react-sortablejs';
import PropTypes from 'prop-types';
import history from 'app/history';

const baseSkillKeys = ['otherBusiness', 'otherArtist', 'otherScribe', 'otherEngineer'];
const placeholder = 'This could be programming languages such as Java, C++, Python. If you are an artist, it could be things like Photoshop, Sketch, or Adobe Illustrator.';

export default class SignupBaseSkills extends React.Component {
	static propTypes = {
		edit: PropTypes.bool
	};

	state = {
		otherBusiness: '',
		otherArtist: '',
		otherScribe: '',
		otherEngineer: '',
		expertises: USER ? USER.expertises : [''], // array of IDs
		baseSkills:[],
		tags: [],
		profileCompleteness: USER ? USER.profileCompleteness : 0,

		failedSave: 'hidden',
		successSave: 'hidden',
		errorRepeatTag: 'hidden',
		errorEmptyExpertise: 'hidden'
	};

	UNSAFE_componentWillMount = () => {
		this.setState({
			tags: USER.skills.map(string => ({ id: string, text: string })),
			...USER.expertisesOther.reduce((state, expertise) => {
				const baseSkillKey = baseSkillKeys[expertise.baseSkill];

				baseSkillKey && (state[baseSkillKey] = expertise.text);

				return state;
			}, {})
		});
	}

	//creates a clickable checkbox with label for the expertise columns.
	generateExpertiseInput = expertise => {
		return (
			<div key={expertise.id}>
				<label><input type="checkbox" id={expertise.id} defaultChecked={this.state.expertises.indexOf(expertise.id) !== -1} value={expertise.id} onChange={this.onExpertiseClick}/> <div className="text_style">{expertise.id}</div></label>
			</div>
		);
	}

	//creates the expertise columns.
	generateBaseSkillColumns = baseSkill => {
		const inputValue = this.state[baseSkillKeys[baseSkill.id]] || '';

		return (
			<div key={baseSkill.id} className="base_detailbox">
				<div className="checkbox_container">
					{CONSTANTS.expertises.filter(expertise => expertise.baseSkill === baseSkill.id).map(this.generateExpertiseInput)}
				</div>
				<input value={inputValue} id={"other" + baseSkill.text} type="text" className={"tiny_icon other_" + baseSkill.text.toLowerCase()} onChange={this.handleOtherExpertise} placeholder="other expertise" />
			</div>
		);
	}

	//handles the delete of tags from react tags.
	handleDelete = e => {
		var i = +e.target.getAttribute('data-index');

		var tags = [...this.state.tags];
		tags.splice(i, 1);

		this.setState({
			tags,
			successSave: 'hidden'
		});
	}

	//handles the addition of tags from react tags.
	handleAddition = tag => {
		const inputTag = tag.text.toLowerCase();
		if (this.state.tags.some(tag => tag.text.toLowerCase() === inputTag)) {
			return this.setState({ errorRepeatTag: 'visible' });
		}

		var tagArray = this.state.tags;
		tagArray.push(tag);

		this.setState({
			tags: tagArray,
			successSave: 'hidden',
			errorRepeatTag: 'hidden'
		});
	}

	//adds expertise to the expertise array based on whether a user clicks a checkbox on and off.
	onExpertiseClick = e => {
		var expertises = null;

		if (e.target.checked) {
			expertises = this.state.expertises.concat(e.target.value);
		} else {
			var array = [...this.state.expertises];
			const index = array.indexOf(e.target.value);
			if (index > -1) {
    			array.splice(index, 1);
			}

			expertises = array;
		}

		this.setState({
			expertises,
			successSave: 'hidden',
			errorEmptyExpertise: 'hidden'
		});
	}

	//sets the state values for otherExpertises when a user types in an other expertise.
	handleOtherExpertise = e => {
    	this.setState({
    		[e.target.id]: e.target.value,
    		successSave: 'hidden'
    	});
	}

	handleBack = () => {
		this.save(true, true);
	}

	handleSave = () => {
		this.save(false, false);
	}

	handleSubmit = () => {
		this.save(true, false);
	}

	handleFilterSuggestions = (textInputValue, possibleSuggestionsArray) => {
		const lowerCaseQuery = textInputValue.toLowerCase();
		return possibleSuggestionsArray.filter(suggestion => (
			suggestion.text.toLowerCase().includes(lowerCaseQuery)
		));
	}

	scrollIntoView = (elementName) => {
		var element = document.getElementById(elementName);
		element.scrollIntoView();
	}

	save = (shouldContinueSignUp, backToEditProfilePage) => {
		//creates an array that holds all of the other expertises a user may input.
		var otherExpertises = [
			{ text: this.state.otherBusiness, baseSkill: 0 },
			{ text: this.state.otherArtist, baseSkill: 1 },
			{ text: this.state.otherScribe, baseSkill: 2 },
			{ text: this.state.otherEngineer, baseSkill: 3 }
		];

		//trims the otherExpertises array to only include objects with values.
		const otherExpertiseResults = otherExpertises.filter(expertise => (
			expertise.text !== ''
		));

		if (this.state.expertises.length <= 0 && otherExpertiseResults.length <= 0){
			this.scrollIntoView('baseskills_select');
			return this.setState({errorEmptyExpertise: 'visible', successSave: 'hidden', failedSave: 'hidden'});
		}


		//turns the tag objects array into an array of strings.
		const skillsResults = this.state.tags.map(tag => tag.text);

		//creates a baseskill array then pushes baseskills to array based on what expertises are checked.
		const baseSkills = this.state.expertises.map(expertise => (CONSTANTS.expertises.find(cExpertise => cExpertise.id === expertise) || {}).baseSkill)
			.concat(otherExpertiseResults.map(expertise => expertise.baseSkill))
			.reduce((acc, baseSkill) => (acc.indexOf(baseSkill) === -1 ? acc.concat([baseSkill]) : acc), []);

		HTTP.post('/profile/baseSkills', baseSkills, (error, body) => {
			if (error) {
				return this.setState({
					failedSave: 'visible',
					successSave: 'hidden'
				});
			}

			USER.baseSkills = baseSkills;
			HTTP.post('/profile/expertises', this.state.expertises, (error, body) => {
				if (error) {
					return this.setState({
						failedSave: 'visible',
						successSave: 'hidden'
					});
				}

				USER.expertises = this.state.expertises;
				HTTP.post('/profile/expertisesOther', otherExpertiseResults, (error, body) => {
					if (error) {
						return this.setState({
							failedSave: 'visible',
							successSave: 'hidden'
						});
					}

					USER.expertisesOther = otherExpertiseResults;
					HTTP.post('/profile/skills', skillsResults, (error, body) => {
						if (error) {
							return this.setState({
								failedSave: 'visible',
								successSave: 'hidden'
							});
						}

						USER.profileCompleteness = body.profileCompleteness;
						USER.skills = skillsResults;
						this.setState({
							failedSave: 'hidden',
							successSave: 'visible',
							profileCompleteness: body.profileCompleteness
						});

						if (shouldContinueSignUp) {
							history.push(backToEditProfilePage ? '/editprofilepage' : '/signupachievement');
						}
					});
				});
			});
		});
	}

	//handles the dragging of tags from react tags.
	onSortableTagChange = (order, sortable, evt) => {
		var tags = [...this.state.tags];

		const tag = tags[evt.oldIndex];

		// mutate array
		tags.splice(evt.oldIndex, 1);
		tags.splice(evt.newIndex, 0, tag);

		// re-render
		this.setState({ tags });
	}

	tagToListElement = (tag, index) => {
		return <span key={tag.id} className="ReactTags__tag" data-id={tag.id}>{tag.text} <a className="ReactTags__remove" onClick={this.handleDelete} data-index={index}>×</a></span>;
	}

	render = () => {
		const editNavBarDisplay = this.props.edit ? 'visible' : 'hidden';
		const signupNavBarDisplay = this.props.edit ? 'hidden' : 'visible';
		return (
			<div className="viewport_width">
				<NavBarNotifications 
					showLoginButton={false}
					showSignupButton={false}
				/>
				<div className="signup_hero">
					<img src="images/signup_baseskills.svg" />
				</div>

				<div className="clear"></div>

				<div className="show_for_desktop">
					<div className="signup_progressbar" style={{width:'56em'}}>
						<ProgressBar progress={this.state.profileCompleteness} width="56em"/>
					</div>
				</div>

				<div className="show_for_mobile">
					<div className="signup_progressbar" style={{width:'19em'}}>
						<ProgressBar progress={this.state.profileCompleteness} width="18em"/>
					</div>
				</div>
				
				<div>

					<div className="signup_container">
						<h4 style={{textAlign: 'center'}}>B.A.S.E. Skills</h4>
						<p className="baseskills_intro_text">
							A good team often starts with a good B.A.S.E. (Business Person, Artist, Scribe,
							Engineer) depending on the project. Every team member needs to bring a skill and
							often times one person will have multiple skills. Please select every category that
							describes you. Don’t worry if you’re not an expert in something. We’re all here to
							learn while building great stuff.
						</p>

						<div id="baseskills_select">
							<div className="baseskills_image_box">
								<img src="images/signup_baseskills_select.svg" />
							</div>
							<br/>
							<div className="clear"></div>
							<div id='emptyExpertiseError' className="baseSkillErrorBox">
								<p className={"error " + this.state.errorEmptyExpertise}>
									{CONSTANTS.errors.emptyExpertiseError}
								</p>
							</div>
							<div className="clear"></div>
							<div className="base_detailboxes">
								{CONSTANTS.baseSkills.map(this.generateBaseSkillColumns)}
							</div>
							<div className="clear"></div>
							<h3 className="specific_skills_header" style={{marginBottom:"0.5em"}}>Please list any specific skills that you have (hit enter after each entry or select suggested tags):</h3>
							<p className={"errorRepeatTag " + "error " + this.state.errorRepeatTag} style={{marginBottom: '-1em'}}>{CONSTANTS.errors.repeatTagError}</p>
							<div className="clear"></div>

							<br />

							<div className="ReactTags__selected">
								<Sortable
									options={{ animation: 100 }}
									onChange={this.onSortableTagChange}
								>
									{this.state.tags.map(this.tagToListElement)}
								</Sortable>
							</div>

							<ReactTags
								suggestions={CONSTANTS.skills}
								handleDelete={()=>{}}
								handleAddition={this.handleAddition}
								placeholder={placeholder}
								delimeters={[13, 188]}
								allowDeleteFromEmptyInput={false}
								handleFilterSuggestions={this.handleFilterSuggestions}
								/>
							<br/><br/><br/><br/><br/><br/><br/><br/>
						</div>
							<div className={"signup_navigationb " + signupNavBarDisplay}>
								<div className={"success " + this.state.successSave} style={{float: 'right', marginRight: '4.5em'}}> {CONSTANTS.errors.successSave}</div>
								<div className={"error " + this.state.failedSave} style={{float: 'right', marginRight: '4.5em'}}>{CONSTANTS.errors.failedSave}</div>
								<div className="clear"></div>
								<input className="nextnavb" type="submit" value="Next >" onClick={this.handleSubmit} style={{marginRight: '3em'}}/>
								<input className="savenavb" type="submit" value="Save" onClick={this.handleSave}/>
							</div>
							<br/>
							<div className={"edit_signup_navigationb " + editNavBarDisplay}>
								<div className="show_for_desktop">
									<div className={"success " + this.state.successSave} style={{float: 'right', marginRight: '4.5em'}}> {CONSTANTS.errors.successSave}</div>
									<div className={"error " + this.state.failedSave} style={{float: 'right', marginRight: '4.5em'}}>{CONSTANTS.errors.failedSave}</div>
								</div>
								<div className="show_for_mobile">
									<div className={"success " + this.state.successSave} style={{float: 'right', marginRight: '2.5em'}}> {CONSTANTS.errors.successSave}</div>
									<div className={"error " + this.state.failedSave} style={{float: 'right', marginRight: '2.5em'}}>{CONSTANTS.errors.failedSave}</div>
								</div>
								<div className="clear"></div>
								<div>
									<input className="savenavb" type="submit" value="Save" onClick={this.handleSave}/>
									<input className="backnavb" type="submit" value="Back to Profile" onClick={this.handleBack}/>
								</div>
							</div>
							<br/><br/><br/>
					</div>
				</div>
				<div className="bottom_padding"></div>
				<Footer />
			</div>
		);
	}
};