/**
 * This is the page that will let the user see their messages and respond to them.
 */

import React from 'react';
import ReactDOM from 'react-dom';
import HTTP from '../../utils/Http';

import store from '_redux/store';
import { refreshUnread } from '_redux/actions';

import NavBarNotifications from '../nav/NavBar_Notifications.jsx';
import Footer from '../footer/Footer.jsx';
import { QueryLink } from 'utils/Utils';

export default class Messages extends React.Component {
	state = {
		error: '',
		currentMessage: '',
		openConversationIndex: 0,
		previousConversationIndex: 0,
		conversations: [],
		currentConversationMessagePage: 1,
		conversationHasBeenClicked: false,
		retrievingMoreResults: false,
		noMoreMessages: false,
		conversationBoxVisibility: "visible",
		sendNewMessageBoxVisibility: "hidden",
		recipientName: "",
		recipientProfileId: "",
		displayAutoCompleteRecipientName: "hidden",
		validRecipientNameError: "hidden",
		suggestedRecipients: [],
		conversationPointerEvents: "all",
		conversationOpacity: "1"
	}

	getAllMessages() {
		HTTP.get('/messages/all', (error, conversations) => {
			if (error) {
				return this.setState({ error });
			}

			this.updateConversations(conversations);
		});
	}

	updateConversations = (conversations) => {
		conversations.forEach(conversation => {
			// Filter ourselves
			conversation.users = conversation.users.filter(user => USER._id !== user._id);

			// Convert date strings to date objects.
			conversation.messages.forEach(message => { message.date = new Date(message.date) });
			conversation.read = new Date(conversation.read);
			conversation.newMessageDot = false;

			conversation.messages.forEach(function(message){
				if(message.date > conversation.read){
					conversation.newMessageDot = true;
				}
			});
		});
		this.setState({ conversations }, () => {
			this.scrollDown();
		});
	}

	UNSAFE_componentWillMount = () => {
		this.getAllMessages();
	}

	componentDidMount = () => {
		var conversationMessages = document.getElementById("conversation_messages");
		this.scrollListener = conversationMessages.addEventListener('scroll', (e) => {
			this.handleScroll(e)
		});
	}

	componentDidUpdate() {
		this._recipientInputTimer = -1;
	}

	sendMessageToStartNewConversation = e => {
		if (!this.state.currentMessage.trim()) {
			return;
		}

		if(!this.state.recipientProfileId.trim()){
			this.setState({validRecipientNameError: 'visible_block'} );
			return;
		}

		HTTP.post('/messages', { message: this.state.currentMessage, to: this.state.recipientProfileId }, (error, body) => {
			if (error) {
				return alert('Could not send message!');
			} else {
				this.getAllMessages();
				this.setState({
					openConversationIndex: 0,
					noMoreMessages: false,
					currentConversationMessagePage: 1,
					currentMessage: ''
				});
				this.showConversationBox();
			}
		});
	}

	generateSuggestedRecipients = suggestedRecipient => {
		if(USER._id === suggestedRecipient._id){
			return;
		}
		return (
			<div key={suggestedRecipient._id}>
				<li className="auto_complete_suggested_recipient_item" data-id={suggestedRecipient._id} data-name={suggestedRecipient.firstName + " " + suggestedRecipient.lastName} onClick={this.handleAutoComplete} ><img src={`/uploads/${suggestedRecipient.photo}`}/> {suggestedRecipient.firstName} {suggestedRecipient.lastName}</li>
			</div>
		);
	}

	handleAutoComplete = e => {
		this.setState({
			recipientProfileId: e.target.dataset.id,
			recipientName: e.target.dataset.name,
			validRecipientNameError: 'hidden',
			displayAutoCompleteRecipientName: 'hidden'
		});
	}

	sendMessage = e => {
		if (!this.state.currentMessage.trim()) {
			return;
		}

		var conversations = [...this.state.conversations];
		var conversation = conversations[this.state.openConversationIndex];
		var conversationMessagesCurrent = conversation.messages;
		var conversationMessagesUpdated;

		HTTP.post('/messages', {
			to: this.state.conversations[this.state.openConversationIndex].users[0]._id,
			message: this.state.currentMessage
		}, (error, message) => {
			if (error) {
				return;
			}
			HTTP.get('/messages/page', {conversation: conversation._id, page: 1}, (error, body) => {
				if (error) {
					return alert('Could not fetch messages.');
				}

				conversationMessagesUpdated = body;
				conversationMessagesUpdated.forEach(message => {
					message.date = new Date (message.date);
				});

				conversation.messages = conversationMessagesUpdated;
				//When sending a message, this code determines if the new message dot should appear and where the
				//NEW MESSAGE text should appear based on whether or not new messages were sent while the USER
				//was on the page without having hit the refresh button yet.
				var newUnreadMessages;
				const messagesCurrentNewestToOldest = [...conversationMessagesCurrent].reverse();
				const messagesUpdatedNewestToOldest = [...conversationMessagesUpdated].reverse();
				const firstMessageNotSentByUserCurrent = messagesCurrentNewestToOldest.find(message => message.from._id !== USER._id);
				const firstMessageNotSentByUserUpdated = messagesUpdatedNewestToOldest.find(message => message.from._id !== USER._id);

				if (typeof firstMessageNotSentByUserCurrent !== 'undefined' && firstMessageNotSentByUserUpdated !== 'undefined'){
					if (firstMessageNotSentByUserCurrent._id !== firstMessageNotSentByUserUpdated._id){
						newUnreadMessages = true;
					} else {
						newUnreadMessages = false;
					}
				} else {
					if(typeof firstMessageNotSentByUserCurrent === 'undefined' && firstMessageNotSentByUserUpdated === 'undefined'){
						newUnreadMessages = false;
					}

					if(typeof firstMessageNotSentByUserCurrent === 'undefined' && firstMessageNotSentByUserUpdated !== 'undefined'){
						newUnreadMessages = false;
					}
				}


				conversation.newMessageDot = false;

				if(newUnreadMessages){
					HTTP.post('/messages/read', { conversation: conversation._id }, (err, date) => {
						if (err) {
							return;
						}
						conversation.oldRead = firstMessageNotSentByUserCurrent.date;
						conversation.read = new Date(date);

						this.setState({
							conversations: conversations,
							currentMessage: '',
							noMoreMessages: false,
							currentConversationMessagePage: 1
							 }, () => {
							this.scrollDown();
						});
					});
				} else {
					HTTP.post('/messages/read', { conversation: conversation._id }, (err, date) => {
						if (err) {
							return;
						}
						conversation.oldRead = new Date(date);
						conversation.read = new Date(date);
						this.setState({
							conversations: conversations,
							currentMessage: '',
							noMoreMessages: false,
							currentConversationMessagePage: 1
							 }, () => {
							this.scrollDown();
						});
					});
				}
				store.dispatch(refreshUnread());
			});
		});
	}

	handleConversationClick = index => {
		this.setState({
			openConversationIndex: index,
			noMoreMessages: false,
			currentConversationMessagePage: 1
		}, this.checkMoreMessagesSent(index));
	}

	checkMoreMessagesSent = index => {
		var conversations = [...this.state.conversations];
		var conversation = conversations[index];
		var conversationMessagesCurrent = conversation.messages;
		var conversationMessagesUpdated;

		HTTP.get('/messages/page', {conversation: conversation._id, page: 1}, (error, body) => {
			if (error) {
				return alert('Could not fetch messages');
			}

			conversationMessagesUpdated = body;

			//Return results are coming as strings so this turns them into dates.
			conversationMessagesUpdated.forEach(message => {
				message.date = new Date (message.date);
			});

			//This block of code will determine whether the new message dot should appear or not by checking to see if
			//the most recent message sent by another kreator is the same before and after clicking on a conversation.
			//If they are the same message, the new message dot will stay hidden. If they are not the same, it means that
			//a message had been sent while the USER was on the page and had not refreshed yet.
			var moreMessagesSent;
			const messagesCurrentNewestToOldest = [...conversationMessagesCurrent].reverse();
			const messagesUpdatedNewestToOldest = [...conversationMessagesUpdated].reverse();
			const firstMessageNotSentByUserCurrent = messagesCurrentNewestToOldest.find(message => message.from._id !== USER._id);
			const firstMessageNotSentByUserUpdated = messagesUpdatedNewestToOldest.find(message => message.from._id !== USER._id);

			if (typeof firstMessageNotSentByUserCurrent !== 'undefined' && firstMessageNotSentByUserUpdated !== 'undefined'){
				if (firstMessageNotSentByUserCurrent._id !== firstMessageNotSentByUserUpdated._id){
					moreMessagesSent = true;
				} else {
					moreMessagesSent = false;
				}
			} else {
				if(typeof firstMessageNotSentByUserCurrent === 'undefined'){
					if(typeof firstMessageNotSentByUserUpdated === 'undefined'){
						moreMessagesSent = false;
					}
				}

				if(typeof firstMessageNotSentByUserCurrent === 'undefined'){
					if (typeof firstMessageNotSentByUserUpdated !== 'undefined'){
						moreMessagesSent = true;
					}
				}
			}

			this.updateMessagesFromConversation(index, conversationMessagesUpdated, moreMessagesSent);
		});
	}

	updateMessagesFromConversation = (index, conversationMessagesUpdated, moreMessagesSent) => {
		var conversations = [...this.state.conversations];
		var conversation = conversations[index];

		if (moreMessagesSent && conversation.newMessageDot === false) {
			conversation.newMessageDot = true;
		} else {
			conversation.newMessageDot = false;
		}

		conversation.messages = conversationMessagesUpdated;

		this.markConversationAsRead(index);
	}

	markConversationAsRead = index => {
		var conversations = [...this.state.conversations];
		var conversation = conversations[index];

		HTTP.post('/messages/read', { conversation: conversation._id }, (err, date) => {
			if (err) {
				return;
			}
			conversation.oldRead = conversation.read;
			conversation.read = new Date(date);
			this.setState({conversations: conversations, conversationHasBeenClicked: true }, () => {
				this.scrollDown();
			});
			store.dispatch(refreshUnread());
		});
	}

	loadMoreMessages = () => {
		var conversations = [...this.state.conversations];
		var conversation = conversations[this.state.openConversationIndex];

		HTTP.get('/messages/page', {conversation: conversation._id, page: this.state.currentConversationMessagePage}, (error, body) => {
			if (error) {
				return alert('Could not fetch messages.');
			}
			body.forEach(message => {
				message.date = new Date (message.date);
			});

			conversation.messages = [...body, ...conversation.messages];

			this.setState({
				conversations: conversations,
				retrievingMoreResults: false
			});

			if(body.length <= 0){
				window.scrollTo(0,0);
			}

			if (body.length == 0){
				this.setState({noMoreMessages: true});
			}
		});
	}

	handleScroll = (e) => {

		const { retrievingMoreResults, page } = this.state;
		if (retrievingMoreResults) {
			return;
		}
		const conversation = this.state.conversations[this.state.openConversationIndex];
		const conversationMessages = document.getElementById("conversation_messages");
		const firstComponent = conversationMessages.firstChild;
		var firstComponentOffset = 0;
		//firstComponent.offsetTop is the amount of pixels from the top the component is from the parent top.
		// The parent in this case, is the first parent that does not have "static" as a position.
		//firstComponent.clientHeight is the visible height in pixels of the last component.
		if (firstComponent !== null) {
			firstComponentOffset = firstComponent.offsetTop + firstComponent.clientHeight;
		}

		//projectResults.scrollTop is the amount of pixels an element has been scrolled vertically.
		const pageOffset = conversationMessages.scrollTop;
		var bottomOffset = 0;

		//Prevents this.state.page from advancing when new search is done and firstComponentOffset initially sets to 0.
		if (firstComponentOffset > 0){
			//Because we want the added messages to load before reaching the first component, we add the
			//bottomOffset to the firstComponentOffest.
			bottomOffset = 50;
		}

		if(this.state.noMoreMessages == false){
			if (pageOffset < firstComponentOffset + bottomOffset && conversation.messages.length >= 25) {
				this.setState({
					currentConversationMessagePage: this.state.currentConversationMessagePage + 1,
					retrievingMoreResults: true,
				}, this.loadMoreMessages);
			}
		}

	}
	getListOfSuggestedRecipients = () => {
		if (!this.state.recipientName.trim()) {
			return;
		}

		HTTP.get(`/kreator/searchByName?query=${this.state.recipientName}`, (error, object) => {
			if (error) {
				// this.setState({ error })
				return;
			}
			this.setState({ suggestedRecipients: object || [] });
		});
	}

	handleRecipientChange = e => {
		if (this._recipientInputTimer === -1) {
			this._recipientInputTimer = setTimeout(() => {
				this.getListOfSuggestedRecipients();
				this._recipientInputTimer = -1;
			}, 100);
		}

		this.setState({
			displayAutoCompleteRecipientName: e.target.value ? 'visible_block' : 'hidden',
			recipientName: e.target.value,
			recipientProfileId: ''
		});
	}

	onCurrentMessageChange = e => {
		this.setState({ currentMessage: e.target.value });
	}

	scrollDown = () => {
		setTimeout(() => {
			this._conversationDiv.scrollTop = this._conversationDiv.scrollHeight;
		}, 1);
	}

	hideConversationBox = e => {
		this.setState({
			conversationBoxVisibility: 'hidden',
			sendNewMessageBoxVisibility: 'visible',
			recipientProfileId: '',
			recipientName: '',
			currentMessage: '',
			validRecipientNameError: 'hidden',
			displayAutoCompleteRecipientName: 'hidden',
			conversationPointerEvents: 'none',
			conversationOpacity: '0.5'
		});
	}

	showConversationBox = e => {
		this.setState({
			conversationBoxVisibility: 'visible',
			sendNewMessageBoxVisibility: 'hidden',
			recipientProfileId: '',
			recipientName: '',
			currentMessage: '',
			validRecipientNameError: 'hidden',
			displayAutoCompleteRecipientName: 'hidden',
			conversationPointerEvents: 'all',
			conversationOpacity: '1'
		});
	}

	renderMessage(message, index, messages) {
		const previousMessage = messages[index - 1];
		const shouldBlockOffDate = !previousMessage || previousMessage.date.getDate() - message.date.getDate() !== 0;

		return (
			<div key={message._id} className="message">
				{
					shouldBlockOffDate ? (
						<div className="message_date">
							<p>{`${message.date.toLocaleString(undefined, { month: 'short' })} ${message.date.getDate()}, ${message.date.getFullYear()}`}</p>
						</div>
					) : null
				}
				<img src={message.from.photo ? `/uploads/${message.from.photo}` : '/images/add_photo_account.svg'} />
				<p>
					<QueryLink to={{ pathname:"/profilepage", query: { profileId: message.from._id} }}>
						<span>{`${message.from.firstName} ${message.from.lastName}`}</span>
					</QueryLink>
					<span className="text_style" style={{float: 'left'}}>{`${(message.date.getHours() % 12) || 12}:${message.date.getMinutes().toString().padStart(2, '0')} ${message.date.getHours() < 12 ? 'am' : 'pm'}`}</span><br/>
					{message.message}
				</p>
			</div>
		);
	}

	renderConversation() {
		const openConversation = this.state.conversations[this.state.openConversationIndex];

		if (!openConversation) {
			return 'Please open something.';
		}

		var read = openConversation.read;

		if (typeof openConversation.oldRead !== 'undefined'){
			read = openConversation.oldRead;
		}

		const firstMessageNewerThanReadDate = openConversation.messages.find(message => +message.date > +read && message.from._id !== USER._id);
		var messageEndIndex;
		if (read){
			if(typeof firstMessageNewerThanReadDate !== 'undefined'){
				messageEndIndex = openConversation.messages.indexOf(firstMessageNewerThanReadDate);
			} else {
				messageEndIndex = openConversation.messages.length;
			}
		} else {
			messageEndIndex = 0;
		}
		
		return [
			...openConversation.messages.slice(0, messageEndIndex).map(this.renderMessage),
			messageEndIndex >= openConversation.messages.length ? undefined : <p key="new_messages">--- NEW MESSAGES ---</p>,
			...openConversation.messages.slice(messageEndIndex).map(this.renderMessage)
		];

	}

	render() {
		var publishWarningVisbility = "hidden";
		var messageVisibility = "visible_block";
		if(!USER.published){
			publishWarningVisbility = "visible_block";
			messageVisibility = "hidden";
		}
		return (
			<div className="viewport_width" style={{overflowY:'hidden'}}>
				<NavBarNotifications
					showLoginButton={false}
					showSignupButton={false}
				/>

				<div style={{height: "100%"}}>
					<h1 className={publishWarningVisbility} style={{color: "#00A4A4", textAlign: "center", margin: "auto", width: "19em", paddingTop: "3em"}}> Your profile must be approved before you can send messages.</h1>
					<div className={messageVisibility} className="messages_container">

						<div className="show_for_desktop">
							<div className="message_buttons_box">
								<button style={{float: 'left', marginRight: '2em'}}><span>Inbox</span></button>
								<button className={this.state.conversationBoxVisibility} style={{float: 'left', marginLeft: '13.5em'}} onClick={this.hideConversationBox}><img src="/images/add_message.svg"/></button>
								<button className={this.state.sendNewMessageBoxVisibility} style={{float: 'right'}} onClick={this.showConversationBox}><img src="/images/delete_message.svg" /></button>
							</div>
						</div>

						<div className="clear"></div>
						<div className="sender_box" style={{pointerEvents: this.state.conversationPointerEvents, opacity: this.state.conversationOpacity}}>
							<div className="show_for_mobile">
								<button className={this.state.conversationBoxVisibility} style={{float: 'left', background: 'transparent'}} onClick={this.hideConversationBox}><img src="/images/add_message.svg"/></button>
							</div>
							{
								this.state.conversations.map((conversation, index) => {
									var summaryOfExpertiseStrings = conversation.users[0].expertises.join(', ');
									if (summaryOfExpertiseStrings.length > 35) {
										var abridgedString01 = summaryOfExpertiseStrings.substr(0, 35);
										var lastComma = abridgedString01.lastIndexOf(",");
										var abridgedString02 = abridgedString01.substr(0, lastComma) + "...";
										summaryOfExpertiseStrings = abridgedString02;
									} else {
										summaryOfExpertiseStrings = conversation.users[0].expertises.join(', ');
									}
									//var unreadCount = conversation.messages.filter(message => +message.date > +conversation.read).length;
									var newMessageDot = conversation.newMessageDot;

									return (
										<div key={conversation._id} className={`sender ${index === this.state.openConversationIndex ? 'background_B4F4F4' : ''}`} style={{cursor: "pointer"}} onClick={() => this.handleConversationClick(index)}>
											<div className="sender_pic_box">
												<img src={conversation.users[0].photo ? `/uploads/${conversation.users[0].photo}` : '/images/add_photo_account.svg'} /><br />
												<div className="clear"></div>
												{
													newMessageDot ? (
														<div className="message_count">
															<div className="text_style" style={{width: "10px", height: "10px", borderRadius: "10px", background: "#00DEDE", margin: "0 auto"}}>{/*unreadCount*/}</div>
														</div>
													) : null
												}
											</div>
											<div className="show_for_desktop">
												<div className="info_box">
													<div className="name">
													{`${conversation.users[0].firstName} ${conversation.users[0].lastName}`}
													</div>
													{/*<button>
														<img src="/images/delete_message.svg" />
													</button>*/}
													<div className="clear"></div>
													<span>{summaryOfExpertiseStrings}</span>
												</div>
											</div>
										</div>
									)
								})
							}
						</div>

						<div className="show_for_mobile_block" style={{width:'1px', height:'100%', float:'left', background:'#EDEDED'}}>
							
						</div>
						<div className="right_message_box">
							<div className={this.state.conversationBoxVisibility}>
								<div className="message_box">
									<div id="conversation_messages" className="conversation" ref={div => { this._conversationDiv = div; } }>
										{this.renderConversation()}
									</div>
								</div>
								<div className="send_message_box">
									<textarea rows="3" cols="40" placeholder="Send a message" value={this.state.currentMessage} onChange={this.onCurrentMessageChange}></textarea>
									
									<button onClick={this.sendMessage}>Send</button>
								</div>
							</div>
							<div className={this.state.sendNewMessageBoxVisibility}>
								<div className="send_message_box" style={{height: '17em'}}>
									<div style={{borderBottom: '1px #EDEDED solid', borderTop: '1px #EDEDED solid' }}>
										<div className={this.state.validRecipientNameError} style={{background: '#FFB0B0', paddingLeft: '1em'}}> Please select a kreator to message.</div>
										<div style={{marginLeft: '1em', marginRight: '1em', float: 'left', marginTop: '0.39em'}}>To:</div> <input style={{border: 'none', height: '2em', fontSize: '1em'}} type="text" name="recipient" value={this.state.recipientName} onChange={this.handleRecipientChange} />
										
										<div className="show_for_mobile">
											<button className={this.state.sendNewMessageBoxVisibility} style={{float: 'right', background: 'transparent', padding:'0em', margin:'0.3em'}} onClick={this.showConversationBox}><img src="/images/delete_message.svg" /></button>
										</div>
									</div>
									<div className="suggested_recipients_dropdown">
										<div className={"auto_complete_suggested_recipients " + this.state.displayAutoCompleteRecipientName}>
											<ul className="auto_complete_suggested_recipient_list">
												{this.state.suggestedRecipients.map(this.generateSuggestedRecipients)}
											</ul>
										</div>
									</div>
									<div className="clear"></div>
									<textarea rows="3" cols="40" style={{height: '70%'}} placeholder="Send a message" value={this.state.currentMessage} onChange={this.onCurrentMessageChange}></textarea>
									<div className="clear"></div>
									<div className="show_for_desktop">
										<button style={{margin:'1em 1em 0.5em 0em'}}onClick={this.sendMessageToStartNewConversation}>Send</button>
									</div>
									<div className="show_for_mobile">
										<button style={{margin:'1em auto 0.5em auto'}}onClick={this.sendMessageToStartNewConversation}>Send</button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="bottom_padding" style={{paddingBottom:'3em'}}></div>
				<Footer/>
			</div>
		);
	}
};
