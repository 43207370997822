// This is the nav bar with notifications incorporated into it.

import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import _ from 'lodash';

import { login, refreshedUnread } from '_redux/actions';
import HTTP from '../../utils/Http';
import history from 'app/history';

import Notifications from './Notifications.jsx';
import MyAccount from './MyAccount.jsx';
import { logout } from '_redux/actions';

class NavBarNotifications extends React.Component {
	state = {
		signUp: 'visible',
		notifications: [],
		invitesReceived: [],
		hasUnread: false,
		page: 1,
		scrolling: false,
		startProjectVisibility: 'hidden',
		notificationMessageMyAccountVisibility: 'hidden',
		signUpLoginVisibility: 'visible',
		notificationVisibility: 'none',
		myAccountVisibility: 'none',
		invitesPendingCircle: false,
		newMessagesCircle: false,
		showMobileMenuLinks: false,
		showMobileNotificationCircle: false,
		photo: '',
	};

	constructor(props) {
		super(props);

		
		this.getHasUnreadMessage = _.throttle(this._getHasUnreadMessage, 100)
		
		
	}

	componentDidUpdate(prevProps) {
		if (!prevProps.shouldRefreshUnread && this.props.shouldRefreshUnread) {
			this.getHasUnreadMessage();
			this.props.dispatch(refreshedUnread());
		}
	}

	componentDidMount () {
		this.getNotifications();
		this.getInvites();
		this.getHasUnreadMessage();

		document.addEventListener('mousedown', this.handleClickOutside);

		if (this.props.loggedIn) {
			this.setState({
				startProjectVisibility: this.props.isProjectStartPage ? 'hidden' : 'visible',
				notificationMessageMyAccountVisibility: 'visible',
				signUpLoginVisibility: 'hidden',
				photo: USER.photo ? `/uploads/${USER.photo}` : '/images/add_photo_account.svg'
			});
		}
	}

	componentWillUnmount() {
		document.removeEventListener('mousedown', this.handleClickOutside);
	}

	setNotificationRef = node => {
		this.notificationRef = node;
	}

	setMyAccountRef = node => {
		this.myAccountRef = node;
	}

	handleClickOutside = event => {
		if (this.notificationRef && !this.notificationRef.contains(event.target)) {
			this.hideShowNotifications(true);
		}

		if(this.myAccountRef && !this.myAccountRef.contains(event.target)){
			this.hideShowMyAccount(true);
		}
	}

	handleGoToUserAccount = () => {
		history.push(this.props.loggedIn ? '/useraccount' : '/loginpage');
	}

	getNotifications = () => {
		if(this.props.loggedIn) {
			const { page, notifications } = this.state;
			HTTP.get('/notifications', {page}, (error, body) => {
				if (error) {
					console.log ("error fetching notifications.");
				} else {
					const allNotifications = [...body];
					this.setState({
						hasUnread: allNotifications.some(notification => new Date(notification.date) >= new Date(USER.lastReadNotification)),
						notifications: allNotifications,
						scrolling: false,
					}, () => {
						this.handleShowMobileMenuCircle();
					});
				}
			});
		}
	}

	getInvites = () => {
		if(this.props.loggedIn){
			HTTP.get(`/invites`, (error, body) => {
				if (error){
					return alert('Could not retrieve invites.');
				}
				this.checkPendingInvites(body.received);
			});
		}
	}

	_getHasUnreadMessage = () => {
		if(USER){
			HTTP.get('/messages/hasUnread', (error, body) => {
				if (error) return console.error('Could not get /messages/hasUnread:', error);
				this.setState({
					newMessagesCircle: body === true ? true : false
				}, () => {
					this.handleShowMobileMenuCircle();
				});
			});
		}
	}

	checkPendingInvites = (invitesReceived) => {
		const invitesPendingCircle = this.state.invitesPendingCircle;
		invitesReceived.forEach(invite => {
			if (invite.pending){
				if(this.state.invitesPendingCircle === false){
					this.setState({invitesPendingCircle: true});
				}
			}
		});
		this.handleShowMobileMenuCircle();
	}

	hideShowNotifications = (override) => {
		if (override === true) {
			this.setState({notificationVisibility: 'none'});
		} else if (this.state.notificationVisibility === 'none') {
			// read all notifications when user clicks on
			this.getNotifications();
			HTTP.post('/profile/readNotifications', {}, error => {
				if (error) return console.error('Could not post /readNotifications:', error);
				USER.lastReadNotification = new Date().toString();
				this.setState({ hasUnread: false }, () => {this.handleShowMobileMenuCircle();});
			});
			this.setState({notificationVisibility: 'initial'});
		} else {
			this.setState({notificationVisibility: 'none'});
		}
	}

	hideShowMyAccount = (override) => {
		if (override === true) {
			this.setState({myAccountVisibility: 'none'});
		} else if (this.state.myAccountVisibility === 'none') {
			this.setState({myAccountVisibility: 'initial'});
		} else {
			this.setState({myAccountVisibility: 'none'});
		}
	}

	handleLogIn = () => {
		history.push('/loginpage');
	}

	handleSignup = () => {
		history.push('/signupbasic');
	}

	handleLogout = () => {
		HTTP.get('/profile/logout', (error, body) => {
			if (error) {
				return alert('Problem logging out.');
			}

			USER = null;
			this.setState({notifications: []});
			this.props.dispatch(logout());
			history.push('/logoutpage');
		});
	}

	handleOpenCloseMenu = () => {
		if(this.state.showMobileMenuLinks){
			this.setState({showMobileMenuLinks: false});
		} else {
			this.setState({showMobileMenuLinks: true});
		}
		
	}

	handleShowMobileMenuCircle = () => {
		if(this.state.hasUnread || this.state.invitesPendingCircle || this.state.newMessagesCircle){
			this.setState({showMobileNotificationCircle: true});
		}

		if(!this.state.hasUnread && !this.state.invitesPendingCircle && !this.state.newMessagesCircle){
			this.setState({showMobileNotificationCircle: false});
		}
	}

	updateUSERLastReadDate = () => {
		USER.lastReadNotification = new Date().toString();
	}

	render() {
	
		return (
			<div>
				<div className="navigation_bar">
					<Link to="/"> <img src="/images/kb_nav.svg" /> </Link>
					<div className="show_for_desktop">
						<p><Link to="/kreator/search">Kreators</Link></p>
						<p><Link to="/project/search">Projects</Link></p>
						<p className={this.state.startProjectVisibility}><Link to="/createprojectstart">Start Project</Link></p>
						<div className={this.state.notificationMessageMyAccountVisibility}>
							<div className = "myAccount_container">
								<div className="myAccount_icon">
									<img src={this.state.photo} onClick={this.hideShowMyAccount}/>
								</div>
								<div ref={this.setMyAccountRef}>
									<MyAccount
										visibility={this.state.myAccountVisibility}
										handleGoToUserAccount={this.handleGoToUserAccount}
										handleLogout={this.handleLogout}
									/>
								</div>
							</div>



							<div className="notification_bar">
								<div className="notification_group" ref={this.setNotificationRef}>
									{ this.state.hasUnread ? <div className="notification_circle"></div> : null }
									<span onClick={this.hideShowNotifications}>notifications</span>
									<Notifications
										notifications={this.state.notifications}
										visibility={this.state.notificationVisibility}
									/>
								</div>
								{ this.state.invitesPendingCircle ? <div className="notification_circle"></div> : null }
								<span><Link to="/teammanageinvites">invites</Link></span>
								{ this.state.newMessagesCircle ? <div className="notification_circle"></div> : null }
								<span><Link to="/messages">messages</Link></span>
							</div>
						</div>
						<div className={this.state.signUpLoginVisibility}>
							<button style={{ cursor: 'pointer' }} className={this.props.showLoginButton ? "visible" : "hidden"} onClick={this.handleLogIn}>Login</button>
							<button style={{ cursor: 'pointer', marginRight: '1em' }} className={this.props.showSignupButton ? "visible" : "hidden"} onClick={this.handleSignup}>Signup</button>
						</div>
					</div>

					<div className="show_for_mobile">
						<div style={{float: 'right'}}>
						<div onClick={this.handleOpenCloseMenu}>{this.state.showMobileNotificationCircle ? <div className="notification_circle_red" style={{position: 'relative', left: '2.3em'}}></div> : null}<img src="/images/hamburger_menu.png" /></div>
							
						</div>
						<div className={this.state.showMobileMenuLinks ? 'visible' : 'hidden'}>
							<div className="mobile_menu">
								<Link to="/kreator/search">Kreators</Link><br/>
								<Link to="/project/search">Projects</Link><br/>
								<div className={this.state.signUpLoginVisibility}><Link className={this.props.showLoginButton ? "visible" : "hidden"} to="/loginpage" >Login</Link></div>
								<div className={this.state.signUpLoginVisibility}><Link className={this.props.showSignupButton ? "visible" : "hidden"} to="/signupbasic">Signup</Link></div>
								<div className={this.state.startProjectVisibility}><Link to="/createprojectstart">Start Project</Link></div>
								<Link onClick={this.updateUSERLastReadDate} className={this.state.notificationMessageMyAccountVisibility} to="/notificationspage">Notifications{ this.state.hasUnread ? <div className="notification_circle_red"></div> : null }</Link>
								<Link className={this.state.notificationMessageMyAccountVisibility} to="/teammanageinvites">Invites{ this.state.invitesPendingCircle ? <div className="notification_circle_red"></div> : null }</Link>
								<Link className={this.state.notificationMessageMyAccountVisibility} to="/messages">Messages{ this.state.newMessagesCircle ? <div className="notification_circle_red"></div> : null }</Link>
								<Link className={this.state.notificationMessageMyAccountVisibility} to="/useraccount">My Account</Link>
								<Link className={this.state.notificationMessageMyAccountVisibility} style={{borderBottom: 'none'}} onClick={this.handleLogout} >Logout</Link>
							</div>
						</div>
						
						{/*<div className={this.state.notificationMessageMyAccountVisibility}>
							<div className = "myAccount_container">
								<div className="myAccount_icon">
									<img src={this.state.photo} onClick={this.hideShowMyAccount}/>
								</div>
								<div ref={this.setMyAccountRef}>
									<MyAccount
										visibility={this.state.myAccountVisibility}
										handleGoToUserAccount={this.handleGoToUserAccount}
									/>
								</div>
							</div>



							<div className="notification_bar">
								<div className="notification_group" ref={this.setNotificationRef}>
									{ this.state.hasUnread ? <div className="notification_circle"></div> : null }
									<span onClick={this.hideShowNotifications}>notifications</span>
									<Notifications
										notifications={this.state.notifications}
										visibility={this.state.notificationVisibility}
									/>
								</div>
								<div className={"notification_circle " + this.state.invitesPendingCircle}></div>
								<span><Link to="/teammanageinvites">invites</Link></span>
								<div className={"notification_circle " + this.state.newMessagesCircle}></div>
								<span><Link to="/messages">messages</Link></span>
							</div>
						</div>*/}
						
					</div>
				</div>
			</div>
		);
	}
};

export default connect(({ loggedIn, shouldRefreshUnread }) => ({ loggedIn, shouldRefreshUnread }))(NavBarNotifications);