// A profile element is the answers to the signup questions the users gave.
// This is used by the ProfileDetail module.

import React from 'react';
import PropTypes from 'prop-types';

export default class ProfileElement extends React.Component {
	static propTypes = {
		icon: PropTypes.string,
		title: PropTypes.string,
		text: PropTypes.string
	}
	render = () => {
		return (
			<div className="user_profile_element">
				<img src={this.props.icon} />
				<h3 style={{float: 'left'}}>{this.props.title}</h3>
				<div className="textareabox">
					<p>{this.props.text}</p>
				</div>
			</div>
		);
	}
};
