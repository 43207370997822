import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import HTTP from 'app/utils/Http';
import { QueryLink, getLocationSearchParam } from 'utils/Utils';
import { logout } from '_redux/actions';

import NavBarNotifications from '../nav/NavBar_Notifications.jsx';
import Footer from '../footer/Footer.jsx';

class EmailChangeConfirmPage extends React.Component {
	static contextTypes = {
		router: PropTypes.object
	}

	state = {
		isLoading: false,
	}

	componentDidMount() {
		HTTP.post('/profile/email/verify', { confId: getLocationSearchParam('confId'), userId: getLocationSearchParam('userId') }, (err, res) => {
			if (err) return alert('Error confirming your email.');
			this.setState({ isLoading: true });

			USER = null;
			this.props.dispatch(logout());
		});
	}

	renderBody() {
		if (!this.state.isLoading) {
			return (
				<p>Verifying...</p>
			);
		}

		var loginButtonVisibility;
		var userAccountButtonVisibility;

		if (this.props.loggedIn) {
			loginButtonVisibility = 'hidden';
			userAccountButtonVisibility = 'visible_block';
		} else {
			loginButtonVisibility = 'visible_block';
			userAccountButtonVisibility = 'hidden';
		}

		return (
			<div>
				<h3>Your new email address has been verified! Use it to sign into your account.</h3>
				<div id="navbox" style={{ textAlign: 'center' }}>
					<QueryLink to={{ pathname: '/useraccount' }} className={userAccountButtonVisibility}>My Account</QueryLink>
					<QueryLink to={{ pathname: '/loginpage' }} className={loginButtonVisibility}>Login</QueryLink>
				</div>
			</div>
		);
	}

	render() {
		return (
			<div className="viewport_width">
				<NavBarNotifications
					showLoginButton={true}
					showSignupButton={true}
				/>
				<div className="signup_hero">
					<img src="images/account_confirm.svg" />
				</div>

				<div className="clear"></div>

				<div>
					<div className="signup_container">
						<div id="account_confirm">
							{this.renderBody()}
						</div>
					</div>
				</div>
				<Footer />
			</div>
		);
	}
};

export default connect(({ loggedIn }) => ({ loggedIn }))(EmailChangeConfirmPage);
