import React from 'react';

export default class BasePage extends React.Component {
	render = () => {
		return (
			<div>
				<div>
					{this.props.children}
				</div>
			</div>
		);
	}
};
