import React from 'react';
import { Link } from 'react-router-dom';
import HTTP from '../../utils/Http.js';
import NavBarNotifications from '../nav/NavBar_Notifications.jsx';
import ProgressBar from './ProgressBar.jsx';
import Footer from '../footer/Footer.jsx';
import AddCourse from './AddCourse.jsx';
import DraggableSchools from './DraggableSchools.jsx';
import PropTypes from 'prop-types';
import history from 'app/history';

export default class SignupSchools extends React.Component {
	static propTypes = {
		edit: PropTypes.bool
	};

	state = {
		successSave: 'hidden',
		failedSave: 'hidden',
		duplicateSchoolError: 'hidden',
		noAddedSchoolsError: 'hidden',
		validSchoolNameError: 'hidden',
		chooseCourseSchoolError: 'hidden',
		profileCompleteness: USER.profileCompleteness,
		schoolList: USER.schools,
		courseList: [],
		schoolId: '',
		schoolText: '',
		courseText: '',
		autoCompleteSchools: [],
		isAttendingSchools: [],
		displayAutoCompleteSchools: 'hidden',
		saveNoCheckWindow: 'hidden',
		submitNoCheckWindow: 'hidden',
		signupNavigation: 'visible',
		editSignupNavigation: 'visible',
		pointerEvents: 'all',
		opacity: 1,
		isEditing: false
	};

	UNSAFE_componentWillMount = () => {
		this.setState({
			isAttendingSchools: this.state.schoolList.filter(school => school.attending),
			courseList: USER.courses.map(course => ({
				course_id: course._id,
				name: course.name,
				school_id: course.school._id,
				schoolName: course.school.names[0]
			}))
		});

		if (this.props.edit){
			this.setState({isEditing: true});
		}
	}

	componentDidMount = () => {
		this._collegeInputTimer = -1;
	}

	getCollegeAutocompleteList = () => {
		if (!this.state.schoolText) {
			return;
		}

		HTTP.get(`/schools?query=${this.state.schoolText}`, (error, object) => {
			if (error) {
				return;
			}
			this.setState({ autoCompleteSchools: object.schools || [] });
		});
	}

	generateAutoCompleteSchools = school => {
		return (
			<div key={school._id}>
				<li className="auto_complete_item" data-id={school._id} data-school={school.names[0]} onClick={this.handleAutoComplete} >{school.names[0]}</li>
			</div>
		);
	}

	handleAutoComplete = e => {
		this.setState({
			schoolId: e.target.dataset.id,
			schoolText: e.target.dataset.school,
			displayAutoCompleteSchools: 'hidden'
		});
	}

	checkIfAttendingChangeAffectsProjects = () => {
		var projectSchools = [];
		var doesAffectProjects = false;

		USER.projectsCreated.forEach(project => {
			if(project.school !== null){
				projectSchools.push(project.school);
			}
		});

		projectSchools.forEach(projectSchoolId => {
			this.state.schoolList.forEach(schoolObject => {
				if(projectSchoolId === schoolObject.school._id && schoolObject.attending === false){
					doesAffectProjects = true;
				}
			});
		});

		return doesAffectProjects;
	}

	checkIfDeletionAffectsProjects = () => {
		var projectSchools = [];
		var doesAffectProjects = false;

		
		USER.projectsCreated.forEach(project => {
			if(project.school !== null){
				projectSchools.push(project.school);
			}
		});

		var schoolList = [];
		this.state.schoolList.forEach(schoolObject => {
			schoolList.push(schoolObject.school._id);
		});

		projectSchools.forEach(projectSchoolId => {
			if(schoolList.includes(projectSchoolId) === false){
				doesAffectProjects = true;
			}
		});

		return doesAffectProjects;
	}

	handleAttendingChange = (schoolId, shouldCheck, e) => {

		var schoolList = [...this.state.schoolList];
		var courseList = [...this.state.courseList];

		schoolList.forEach(object => {
			if (object.school._id === schoolId) {
				object.attending = !!e.target.checked;
			}
		});

		var attendingSchoolList = schoolList.filter(school => school.attending);

		courseList.forEach(courseObject => {
			var match = false;

			attendingSchoolList.forEach(schoolObject => {
				if (schoolObject.school._id === courseObject.school_id){
					match = true;
				}
			});

			if (!match) {
				courseObject.school_id = null;
				courseObject.schoolName = null;
			}
		});

		this.setState({
			schoolList,
			courseList,
			isAttendingSchools: attendingSchoolList,
			successSave: 'hidden'
		});
	}

	onSchoolClick = e => {
		e.preventDefault();

		var schoolText = this.state.schoolText;
		var schoolId = this.state.schoolId;
		var schoolList = this.state.schoolList;

		// Searches the autoCompleteSchools to see if the school text input by the user matches any
		// of the school names in the array of names of an auto complete school. If there is a match,
		// then the school text is automatically set to the first name in the array of the auto complete school.
		this.state.autoCompleteSchools.forEach(function(autoCompleteSchoolObject){
			const autoCompleteSchoolNames = autoCompleteSchoolObject.names;
			autoCompleteSchoolNames.forEach(function (schoolName){
				if (schoolName.toLowerCase() === schoolText.toLowerCase().trim()){
					schoolText = autoCompleteSchoolNames[0];
					schoolId = autoCompleteSchoolObject._id;
				}
			});
		});

		var duplicateSchool = false;

		// Checks to see if the school text is a duplicate school in the schoolList array.
		schoolList.forEach(function(schoolObject) {
			var trimmedSchoolText = schoolText.trim();
			if (schoolObject.school.names[0] === trimmedSchoolText) {
				duplicateSchool = true;
			}
		});

		if (duplicateSchool) {
			return this.setState({ duplicateSchoolError: 'visible' });
		}

		if (schoolText.trim() === '') {
			return this.setState({ validSchoolNameError: 'visible' });
		}

		if (schoolId === '') {
			// @todo Why??
			schoolId = '' + Math.floor(Math.random() * 100000);
		}

		var school = {
			_id: schoolId,
			attending: false,
			school: { names: [schoolText], _id: schoolId }
		};

		this.setState({
			schoolList: schoolList.concat(school),
			successSave: 'hidden',
			duplicateSchoolError: 'hidden',
			noAddedSchoolsError: 'hidden',
			schoolText: '',
			schoolId: ''
		});
	}

	onSchoolDrag = schoolArray => {
		this.setState({schoolList: schoolArray});
	}

	onSchoolChange = e => {
		if (this._collegeInputTimer === -1) {
			this._collegeInputTimer = setTimeout(function() {
				this.getCollegeAutocompleteList();

				this._collegeInputTimer = -1;
			}.bind(this), 100);
		}

		this.setState({
			displayAutoCompleteSchools: e.target.value ? 'visible_block' : 'hidden',
			validSchoolNameError: 'hidden',
			schoolText: e.target.value,
			schoolId: ''
		});
	}

	deleteSchool = (schoolId, shouldCheck, e) => {
		e.preventDefault();

		var updatedSchoolList = [...this.state.schoolList].filter(el => schoolId !== el.school._id);
		var attendingSchoolList = updatedSchoolList.filter(school => school.attending);
		var courseList = [...this.state.courseList];

		courseList.forEach(courseObject => {
			var match = false;

			attendingSchoolList.forEach(schoolObject => {
				if (schoolObject.school._id === courseObject.school_id) {
					match = true;
				}
			});

			if (!match) {
				courseObject.school_id = null;
				courseObject.schoolName = null;
			}
		});

		this.setState({
			schoolList: updatedSchoolList,
			isAttendingSchools: attendingSchoolList,
			courseList: courseList,
			successSave: 'hidden'
		});
	}

	onCourseClick = e => {
		e.preventDefault();

		const courseText = this.state.courseText;

		if (courseText && courseText.trim()) {
			var course = {
				// @todo Why??
				course_id: '' + Math.floor(Math.random() * 10000),
				name: courseText,
				schoolName: null,
				school_id: null
			};

			this.setState({
				courseList: this.state.courseList.concat(course),
				successSave: 'hidden',
				courseText: ''
			});
		}
	}

	onCourseChange = e => {
		this.setState({courseText: e.target.value});
	}

	onCourseSchoolSelect = (course, e) => {
		const selectedSchool = this.state.isAttendingSchools.find(schoolObject => schoolObject.school._id === e.target.value);
		var courseList = [...this.state.courseList];

		courseList.forEach(function(courseObject){
			if (courseObject.course_id === course.course_id) {
				courseObject.schoolName = selectedSchool ? selectedSchool.school.names[0] : null;
				courseObject.school_id = selectedSchool ? selectedSchool.school._id : null;
			}
		});

		this.setState({
			courseList: courseList,
			successSave: 'hidden',
			chooseCourseSchoolError: 'hidden'
		});
	}

	deleteCourse = (course, e) => {
		e.preventDefault();

		this.setState({
			courseList: this.state.courseList.filter(courseObject => course.course_id !== courseObject.course_id),
			successSave: 'hidden'
		});
	}

	handleGoToAchievements = () => {
		this.save({shouldContinueSignUp: true, shouldGoToAchievements: true});
	}

	handleBack = () => {
		this.save({ shouldContinueSignUp: true, backToEditProfilePage: true });
	}

	handleSave = () => {
		this.save();
	}

	handleSubmit = () => {
		this.save({ shouldContinueSignUp: true });
	}

	handleSaveNoCheck = () => {
		this.save({shouldCheck: false});
	}

	handleSubmitNoCheck = () => {
		this.save({shouldContinueSignUp: true, shouldCheck: false});
	}

	handleSubmitNoCheckBackToEditProfilePage = () => {
		this.save({shouldContinueSignUp: true, backToEditProfilePage: true, shouldCheck: false});
	}

	hideSaveNoCheckWindow = () => {
		if (this.state.isEditing){
			this.setState({
				saveNoCheckWindow: 'hidden',
				editSignupNavigation: 'visible',
				opacity: 1,
				pointerEvents: "all"
			});
		} else {
			this.setState({
				saveNoCheckWindow: 'hidden',
				signupNavigation: 'visible',
				opacity: 1,
				pointerEvents: "all"
			});
		}

	}

	hideSubmitNoCheckWindow = () => {
		if (this.state.isEditing){
			this.setState({
				submitNoCheckWindow: 'hidden',
				editSignupNavigation: 'visible',
				opacity: 1,
				pointerEvents: "all"
			});
		} else {
			this.setState({
				submitNoCheckWindow: 'hidden',
				signupNavigation: 'visible',
				opacity: 1,
				pointerEvents: "all"
			});
		}
	}

	showSaveNoCheckWindow = () => {
		this.setState({
			saveNoCheckWindow: 'visible',
			submitNoCheckWindow: 'hidden',
			editSignupNavigation: 'hidden',
			signupNavigation: 'hidden',
			successSave: 'hidden',
			opacity: 0.5,
			pointerEvents: "none"
		});
	}

	showSubmitNoCheckWindow = () => {
		this.setState({
			submitNoCheckWindow: 'visible',
			saveNoCheckWindow: 'hidden',
			editSignupNavigation: 'hidden',
			signupNavigation: 'hidden',
			successSave: 'hidden',
			opacity: 0.5,
			pointerEvents: "none"
		});
	}

	updateUserProjectsCreated = (schoolList) => {
		var projectsCreated = USER.projectsCreated;

		var i;
		for (i = 0; i < projectsCreated.length; i++) {
			var t;
			for (t = 0; t < schoolList.length; t++){
				if (projectsCreated[i].school === schoolList[t].school._id && schoolList[t].attending === false){
					projectsCreated[i].school = null;
				}
			}
			
		}

		for (i = 0; i < projectsCreated.length; i++) {
			var projectSchoolExists = false;
			var t;
			for (t = 0; t < schoolList.length; t++){				
				if (projectsCreated[i].school === schoolList[t].school._id){
					projectSchoolExists = true;
				}
			}
			if (!projectSchoolExists){
				projectsCreated[i].school = null;
			}
			
		}
		USER.projectsCreated = projectsCreated;
	}

	save = ({ shouldContinueSignUp = false, backToEditProfilePage = false, shouldCheck = true, shouldGoToAchievements = false } = {}) => {

		var schoolList = this.state.schoolList;

		var revisedSchoolList = schoolList.map(schoolObject => {
			var schoolId = schoolObject.school._id;
			// @todo Why?? (10)
			if (schoolId.length < 10) {
				schoolId = schoolObject.school.names[0];
			}

			return {
				school: schoolId,
				attending: schoolObject.attending
			};
		});

		var validEntries = true;

		if (!schoolList.length) {
			var element = document.getElementById('noSchoolError');
			element.scrollIntoView();
			this.setState({noAddedSchoolsError: 'visible'});
			validEntries = false;
		}

		this.state.courseList.forEach(courseObject => {
			if (!courseObject.school_id) {
				this.setState({ chooseCourseSchoolError: 'visible' });
				validEntries = false;
			}
		});

		if(validEntries === false) {
			return;
		}

		if (shouldCheck){
			if(this.checkIfAttendingChangeAffectsProjects() === true) {
				if (shouldContinueSignUp){
					this.showSubmitNoCheckWindow();
				} else {
					this.showSaveNoCheckWindow();
				}

				return;
			}

			if(this.checkIfDeletionAffectsProjects() === true){
				if (shouldContinueSignUp){
					this.showSubmitNoCheckWindow();
				} else {
					this.showSaveNoCheckWindow();
				}

				return;
			}
		} else {
			this.hideSaveNoCheckWindow();
		}
		
		if (validEntries) {
			HTTP.post('/profile/schools', revisedSchoolList, (error, body) => {
				if (error) {
					return this.setState({
						failedSave: 'visible',
						successSave: 'hidden'
					});
				}
				USER.schools = body.schools;
				schoolList = body.schools;
				USER.profileCompleteness = body.profileCompleteness;
				this.updateUserProjectsCreated(schoolList);
				this.setState({
					failedSave: 'hidden',
					schoolList: schoolList,
					profileCompleteness: body.profileCompleteness
				});

				const revisedCourseList = this.state.courseList.map(courseObject => {
					var courseSchoolId = courseObject.school_id;
					if (courseSchoolId) {
						// @todo Why?? (10)
						if (courseSchoolId.length < 10) {
							schoolList.forEach(schoolObject => {
								if (courseObject.schoolName === schoolObject.school.names[0]) {
									courseSchoolId = schoolObject.school._id;
								}
							});
						}
					}

					return {
						name: courseObject.name,
						school: courseSchoolId
					};
				});

				HTTP.post('/profile/courses', revisedCourseList, (error, body) => {
					if (error) {
						this.setState({
							failedSave: 'visible',
							successSave: 'hidden'
						});
						return;
					} else {
						this.setState({
							failedSave: 'hidden',
							successSave: 'visible'
						});

						USER.courses = body.courses;
						if (shouldContinueSignUp) {
							if (shouldGoToAchievements){
								history.push('/signupachievement');
							} else {
								const path = backToEditProfilePage ? '/editprofilepage' : '/signupbio';
								history.push(path);
							}
						}
					}
				});
			});
		}
	}

	renderCourse = course => {
		// @todo Why?? (key=)
		return (
			<AddCourse
				key={course._id || ('' + Math.floor(Math.random() * 10000))}
				course={course}
				deleteCourse={this.deleteCourse.bind(null, course)}
				onCourseSchoolSelect={this.onCourseSchoolSelect.bind(null, course)}
				isAttendingSchools={this.state.isAttendingSchools}
			/>
		);
	}

	render = () => {
		const editNavBarDisplay = this.props.edit ? 'visible' : 'hidden';
		const signupNavBarDisplay = this.props.edit ? 'hidden' : 'visible';

		if (this.state.displayAutoCompleteSchools === 'visible_block') {
			var that = this;
			window.onclick = function(event) {
				if (!event.target.matches('.auto_complete_item') && that.state.displayAutoCompleteSchools === 'visible_block') {
					that.setState({ displayAutoCompleteSchools: 'hidden' });
				}
			};
		}

		return (
			<div className="viewport_width">
				<NavBarNotifications
					showLoginButton={false}
					showSignupButton={false}
				/>

				<div id='schools_top' className="signup_hero">
					<img src="images/signup_schools.svg" />
				</div>

				<div className="clear"></div>

				<div className="show_for_desktop">
					<div className="signup_progressbar" style={{width:'56em'}}>
						<ProgressBar progress={this.state.profileCompleteness} width="56em"/>
					</div>
				</div>

				<div className="show_for_mobile">
					<div className="signup_progressbar" style={{width:'19em'}}>
						<ProgressBar progress={this.state.profileCompleteness} width="18em"/>
					</div>
				</div>
				<div>
					<div className="signup_container">
						<div id="schools_container">
							<div id="schoolbox" style={{opacity: this.state.opacity, pointerEvents: this.state.pointerEvents}}>
								<h4>Here is where you put the schools you've gone to.</h4><br/>
								<span>(Start with the school you most recently attended.)</span>

								<br/><br/><br/><br/>
								<div id="input_schoolbox">
									<div className="text_style">School:</div>

									<div className="show_for_mobile">
										<div className="clear"></div>
									</div>

									<input
										id="input_college"
										type="text"
										value={this.state.schoolText}
										onChange={this.onSchoolChange}
									/>

									<div className="show_for_mobile">
										<div className="clear"></div>
									</div>

									<button onClick={this.onSchoolClick}>add</button>
								</div>
								<div className="schools_dropdown">
									<div className={"auto_complete_schools " + this.state.displayAutoCompleteSchools}>
										<ul className="auto_complete_item">
											{this.state.autoCompleteSchools.map(this.generateAutoCompleteSchools)}
										</ul>
									</div>
								</div>
								<a id="noSchoolError"></a>
								<div className="show_for_desktop">
									<div style={{marginLeft: "11.8em"}} className={this.state.validSchoolNameError + " signup_error"}>{CONSTANTS.errors.validSchoolNameError}</div>
									<div style={{marginLeft: "11.8em"}} className={this.state.noAddedSchoolsError + " signup_error"}>{CONSTANTS.errors.noAddedSchoolsError}</div>
									<div style={{marginLeft: "11.8em"}} className={this.state.duplicateSchoolError + " signup_error"}>{CONSTANTS.errors.duplicateSchoolError}</div>
								</div>

								<div className="show_for_mobile">
									<div style={{marginLeft: "0em"}} className={this.state.validSchoolNameError + " signup_error"}>{CONSTANTS.errors.validSchoolNameError}</div>
									<div style={{marginLeft: "0em"}} className={this.state.noAddedSchoolsError + " signup_error"}>{CONSTANTS.errors.noAddedSchoolsError}</div>
									<div style={{marginLeft: "0em"}} className={this.state.duplicateSchoolError + " signup_error"}>{CONSTANTS.errors.duplicateSchoolError}</div>
								</div>
								<div className="clear"></div>

								<div id="added_schools">
									<p>
									School List
									</p>
									<br/><br/><br/>
									<span>Place a check next to the schools your are currently attending.</span>
										<div id="school_list">
											<DraggableSchools
											schoolList={this.state.schoolList}
											deleteSchool={this.deleteSchool}
											handleAttendingChange={this.handleAttendingChange}
											onSchoolDrag={this.onSchoolDrag}
											/>
										</div>
								</div>

								<div id="input_coursebox">
									<h4>Want other kreators to be able to search for you by course number?</h4>
									<span>(If not, leave blank.)</span>
									<div className="clear"></div>
										<div id="enter_course">
											<div className='text_style'>Enter Course Number:</div>
											<input
												type="text"
												value={this.state.courseText}
												onChange={this.onCourseChange}
											/>
											<button onClick={this.onCourseClick}>add</button>
										</div>
									<br/><br/><br/>
								</div>

								<div id="added_courses">
									<p>
									Course List
									</p>
									<div style={{clear: "both", marginTop: "0.5em"}} className={this.state.chooseCourseSchoolError + " signup_error"}>{CONSTANTS.errors.chooseCourseSchoolError}</div>
									<br/>
										<ul id="course_list">
											{this.state.courseList.map(this.renderCourse)}
										</ul>
								</div>
							</div>
							<span style={{float: "right", marginRight: '0.8em'}} className={this.state.successSave + " signup_success"}>{CONSTANTS.errors.successSave}</span>
							<span style={{float: "right", marginRight: '0.8em'}} className={this.state.failedSave + " signup_error"}>{CONSTANTS.errors.failedSave}</span>
							<div className="clear"></div>
							<div className={this.state.saveNoCheckWindow} id="save_noCheck">
								<h1>Save Warning</h1>
								<p> You have deleted or unchecked an attending school associated with a project you created.<br/>
								Clicking &quot;Continue&quot; will remove the school from the project or click &quot;Cancel&quot; to cancel this action.
								</p>
								<div className="save_noCheck_buttons">
									<button style={{marginRight: "6em"}}onClick={this.hideSaveNoCheckWindow}>Cancel</button>
									<button onClick={this.handleSaveNoCheck}>Continue</button>
								</div>
							</div>
							<div className={this.state.submitNoCheckWindow} id="save_noCheck">
								<h1>Submit Warning</h1>
								<p> You have deleted or unchecked an attending school associated with a project you created.<br/>
								Clicking &quot;Continue&quot; will remove the school from the project or click &quot;Cancel&quot; to cancel this action.
								</p>
								<div className="save_noCheck_buttons">
									<button style={{marginRight: "6em"}}onClick={this.hideSubmitNoCheckWindow}>Cancel</button>
									<button onClick={this.handleSubmitNoCheckBackToEditProfilePage}>Submit</button>
								</div>
							</div>
							<br/>
							<div className={this.state.signupNavigation}>
								<div className={"signup_navigation " + signupNavBarDisplay}>
									<input name="submit" className="nextnav" type="submit" value="Next >" onClick={this.handleSubmit}/>
									<input name="submit" className="savenav" type="submit" value="Save" onClick={this.handleSave}/>
									<input name="submit" className="backnav" style={{width:'4em'}} type="submit" value="< Back" onClick={this.handleGoToAchievements}/>
									{/*<Link to="/signupachievement" className="backnav">&lt; Back</Link>*/}
								</div>
							</div>
							<div className={this.state.editSignupNavigation}>
								<div className={"edit_signup_navigationb " + editNavBarDisplay}>
									<div className="clear"></div>
									<input className="savenavb" type="submit" value="Save" onClick={this.handleSave}/>
									<input className="backnavb" type="submit" value="Back to Profile" onClick={this.handleBack}/>
								</div>
							</div>
							<br/><br/><br/>
						</div>
					</div>
				</div>
				<div className="bottom_padding"></div>
				<Footer />
			</div>
		);
	}
};