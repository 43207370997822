import React from 'react';
import NavBarNotifications from './nav/NavBar_Notifications.jsx';
import Footer from './footer/Footer.jsx';
import PropTypes from 'prop-types';

export default class TermsOfService extends React.Component {
	static contextTypes = {
		router: PropTypes.object
	}

	render = () => {
		return(
			<div className="viewport_width">
				<NavBarNotifications
					showLoginButton={true}
					showSignupButton={true}
				/>
					<div className="clear"></div>

					<div>
						<div className="signup_container" style={{margin: '0 auto', maxWidth: '50em', padding: '0em 1em', fontSize: '1.1em'}}>
							<h4 style={{textAlign: 'center', color: '#00A4A4', marginTop: '1.5em', marginBottom: '1em', fontSize: '2em'}}>Terms of Service</h4>
							These Terms of Use (hereinafter referred to as "Agreement", “Terms of Use” or “Terms”) are a legally binding agreement between You either an individual, group or entity (the “User”, “You”, or “Your”) and KreatorBus (“KreatorBus”, “Website”, “Site”, or “Platform”)
							operating in accordance with the Laws of the State of California, USA (“Company”, “We”, “Us”, or “Our”).<br/><br/>
							These Terms of Use govern your use of the Platform and constitutes a legally binding agreement between you and the Company. By using the Platform, you accept and agree to all the provisions of this Agreement, the Privacy Policy, and any future amendments and additions to this Agreement (as defined below) as we may publish from time to time.
							<br/><br/>

							<b className="termsofservice_title">Definitions</b><br/>
							“Users” - refers to all registered Users of the Platform and includes any reference to  Teammates, Project leaders and/or Students.<br/>
							“Project Leader” - A User who creates and owns the Project on the Platform.<br/>
							“Projects” - refers to any projects created by Users on the Platform whether intended to be for hobby, educational, not for profit or for profit purposes.<br/>
							“Platform Services” - includes but are not limited to any search functionality that enables users to: search for teammates or projects using selected search criteria; share project progress; communicate with other Users; showcase their portfolios, profiles; and any other features introduced by the Platform from time to time.<br/>
							“Project Participation Agreement” - An agreement between the teammates and project leader outlining all the terms of engagement and participation in a project as well as the interests and relationship of parties to the agreement.<br/>
							<br/><br/>

							<b className="termsofservice_title">Eligibility</b><br/>
							The Platform is not designed for Users under the age of thirteen (13) years.<br/><br/>
							IF YOU ARE UNDER THE AGE OF THIRTEEN (13) YEARS, YOU ARE NOT ALLOWED TO USE OUR WEBSITE UNDER ANY CIRCUMSTANCES.<br/><br/>
							By signing up and creating a profile on KreatorBus, you represent and warrant to the Company that you will use the Platform in a manner consistent with any and all applicable laws and regulations. You are required to agree to and accept these Terms of Use and the Privacy Policy in order to access and use the Platform.<br/>
							<br/><br/>

							<b className="termsofservice_title">How it works?</b><br/>
							KreatorBus is a unique online platform that helps high school / college students (“Users”) to search and find teammates for their project/s or project/s that are looking for new teammates to join. The Platform enables Users to create a detailed profile that allows other Users who are looking for a teammate to search and find them by using any of the following search criteria: the User’s name, school, course number from the school they are attending, valid zip code, skills, interests, amount of time available per week, what they are looking to gain from the project (please note that the Platform does not allow users to recruit candidates for remuneration) and other search filtering criteria. Users can also showcase their portfolio on the Platform by uploading web links to their past or current work. Users who are looking to join a project can easily search for suitable projects by: project name, category, type of teammate a project is looking for, course number (if provided), whether project has a potential to become a business as well and more.
							<br/><br/>
							When a User finds a teammate whose profile suggests a potential match to their project needs or a project that they would like to join, such User can communicate with the other potential teammate through the online messaging functionality to further explore their suitability. If the Users are happy to proceed they can join/accept the project and start making their contribution to the Project. Once a project has started, the project leader can post project progress updates, add or drop teammates if needed and in accordance with their agreement, if any. The Platform also enables Users the ability to comment on each other’s project and write about their teammates’ performance on a project. A project can be deleted or unpublished at any time, if the Project leader decides that they no longer wish to continue with the project.
							<br/><br/>
							PLEASE NOTE THAT - ANY DECISION TO ACCEPT A NEW TEAMMATE, DROP AN EXISTING TEAMMATE OR TO DELETE OR UNPUBLISH ANY PROJECT ON THE PLATFORM IS THE PREROGATIVE OF THE TEAM LEADER. THE COMPANY DOES NOT ACT AS AN ARBITRATOR FOR ANY PROJECT MANAGEMENT ISSUES OR CONFLICT BETWEEN TEAMMATES. IF ANY TEAMMATE HAS ANY GRIEVANCE AND/OR REASON TO BELIEVE THAT THE PROJECT LEADER OR ANOTHER TEAMMATE ACTED UNREASONABLY, THEIR SOLE REMEDY ON THE PLATFORM IS TO ATTEMPT TO AMICABLY RESOLVE SUCH ISSUES WITH THE RELEVANT PARTY. THE COMPANY  RESERVES THE RIGHT TO BUT IS NOT OBLIGATED TO OVERRIDE ANY PROJECT DECISIONS OF THE PROJECT LEADER INCLUDING BUT NOT LIMITED TO REINSTATING A TEAMMEMBER, PUBLISHING OR UNPUBLISHING A PROJECT ON THE PLATFORM.
							<br/><br/>
							Although Users can create an account without a profile picture, Users must  upload a profile picture for their profile to appear in search results. Also, the projects created by Users will not appear in the search results unless they have been published by the Project leader which requires the Project leader to first submit the project for approval. The Company reserves the right to refuse approval without providing any reasons to a User.
							<br/><br/>
							Please note that we cannot guarantee the quality of any service rendered by any teammate on any project and in no event shall we be liable for any damages whatsoever, arising out of or relating to your conduct or anyone else in connection with the Platform.
							<br/><br/>
							ANY / ALL AGREEMENTS ENTERED INTO BY USERS WORKING ON A SPECIFIC PROJECT ARE BETWEEN SUCH USERS ONLY. THE COMPANY IS NOT PARTY TO ANY AGREEMENT BETWEEN THE USERS (FOR EXAMPLE - AGREED EQUITY DISTRIBUTION AMONG TEAMMATES) AND AT NO TIME WILL THE COMPANY BE HELD RESPONSIBLE FOR THE FAILURE OF ANY USER TO MEET ITS OBLIGATIONS UNDER ANY AGREEMENT OR FOR ANY DAMAGE / LOSS SUFFERED AS A RESULT OF ANY ACTION OR OMISSION OF ANY USER IN ANY WAY ASSOCIATED WITH OR ARISING FROM THE USE OF THE PLATFORM.
							<br/><br/>
							All Users are strongly advised not to share their personal financial information (e.g., credit card or bank account numbers) with other Users through the Platform.
							<br/><br/>
							Please note that the Company only enables a connection between people who are either  looking for teammates to join their projects or looking to join projects available on the Platform. The Company does not have any control over the quality, legality, delivery, timing, failure to provide or any other aspect whatsoever of any projects, users or content on the Platform.
							<br/><br/>
							Unless explicitly specified otherwise, Our responsibility is limited to facilitating the availability of the Platform.
							<br/><br/>
							WE DO NOT SUPERVISE, DIRECT OR CONTROL ANY WORK / CONTRIBUTION MADE  BY ANY USER ON THE PLATFORM, OR VERIFY / TEST ANY SKILLS THAT A USER MAY CLAIM TO POSSESS. WE DO NOT ACT AS AN AGENT ON BEHALF OF ANY USER AND THE COMPANY IS NOT RESPONSIBLE FOR AND DISCLAIMS ANY AND ALL LIABILITY ARISING FROM YOUR USE OF OUR PLATFORM. ACCORDINGLY, ANY DECISION TO ACCEPT A TEAMMATE OR TO JOIN A PROJECT ON THE PLATFORM WILL BE MADE BY USERS AT THEIR OWN RISK.
							<br/><br/>
							PLEASE NOTE - WE CANNOT GUARANTEE ANY SPECIFIC RESULTS FROM THE USE OF OUR PLATFORM. WE ASSUME NO RESPONSIBILITY FOR ANY CONSEQUENCE RELATING WHETHER DIRECTLY OR INDIRECTLY FOR ANY ACTION OR OMISSION ON YOUR BEHALF, BASED ON THE INFORMATION OR OTHER MATERIAL MADE AVAILABLE THROUGH OUR PLATFORM.
							<br/><br/>
							THE COMPANY IS NOT RESPONSIBLE FOR AND DISCLAIMS ANY AND ALL LIABILITY RELATED TO ANY CONTENT SHARED BY ANY USER, INCLUDING BUT NOT LIMITED TO ANY COMMENTS MADE BY A USER ABOUT ANOTHER PROJECT OR A TEAMMATE  THROUGH THE PLATFORM. ACCORDINGLY, ANY DECISION YOU TAKE BASED ON ANY INFORMATION / CONTENT  YOU RECEIVE THROUGH THE PLATFORM IS AT YOUR OWN RISK.
							<br/><br/>
							<b className="termsofservice_title">Account Registration and Access</b><br/>
							In order to make use of the unique functionality offered by KreatorBus, Users are required to sign-up at www.Kreatorbus.com. You agree to provide us with complete and accurate information when creating your profile on the platform as well as when submitting any projects for approval. Any failure on Your part to provide Us with the aforementioned information shall constitute a breach of these Terms of Use. In the event of such breach, We reserve the right to immediately terminate Your account.
							<br/><br/>
							You are strongly advised to keep your Username and Password strictly confidential and that you refrain from disclosing your sign in credentials to any third party including your teammates and project leader. You acknowledge that any activities under your Account, whether or not you have authorized such activities are solely your responsibility. You agree to immediately notify us of any unauthorized use of your account at www.KreatorBus.com. We disclaim any and all responsibility for any unauthorized access to your account. Please note that we reserve the right to refuse registration of a User account without providing any reasons for our decision.
							<br/><br/>
							Account registration on the Platform is currently free but the Company reserve the right to introduce any paid features and functionalities without giving any notice to you. You accept and acknowledge that any paid functionality will in addition to this Agreement, be governed by the Company’s Payment, Cancellation and Refund Policy which is incorporated herein by reference.
							<br/><br/>
							The Company may access your account and any information that you have provided us, for any support, maintenance or for any security or business reasons that we in our sole discretion deem fit.
							<br/><br/>
							We may suspend or terminate your Account and your access to the Platform if we find that any information provided by you during the Sign-up process or thereafter is inaccurate, fraudulent, not current or incomplete.
							<br/><br/>

							<b className="termsofservice_title">Account termination </b><br/>
							You can terminate your KreatorBus account by contacting us at contact@kreatorbus.com with the words “Account termination”.
							Please take note that the Company has the right but not an obligation to keep your account data after you cancel your account. It is solely your responsibility to ensure that you backup any and all data that you wish to access at a later stage. The Company will not assume any responsibility for any loss or damage incurred as a result of loss of data upon account termination.
							<br/><br/>
							We reserves the right to terminate or suspend Your access to the Platform if we find that:
							<br/><br/>
							You have violated any provisions of this Agreement;
							Your conduct is harmful to the Company or any of its Users; or
							We cease Our business operations for any reason.
							<br/><br/>

							<b className="termsofservice_title">User Verification</b><br/>
							The Company does not verify its Users and is unable to confirm that each User is who they claim to be and therefore, Company cannot and does not assume any responsibility for the accuracy or reliability of identity or any information provided by the User through the Platform.
							<br/><br/>
							THE COMPANY, ITS DIRECTORS, OFFICERS, SHAREHOLDERS, EMPLOYEES, AFFILIATES OR LICENSORS DO NOT ASSUME ANY RESPONSIBILITY FOR THE CONDUCT, WHETHER ONLINE OR OFFLINE, OF ANY USER AND YOU HEREBY RELEASE THE COMPANY AND ITS DIRECTORS, OFFICERS, SHAREHOLDERS, EMPLOYEES, AFFILIATES OR LICENSORS FROM ANY LIABILITY RELATED THERETO. THE COMPANY AND THE PARTIES MENTIONED ABOVE WILL NOT BE LIABLE FOR ANY CLAIM, INJURY OR DAMAGE ARISING IN CONNECTION WITH YOUR USE OF THE PLATFORM OR SERVICES OFFERED BY THE PLATFORM.
							<br/><br/>

							<b className="termsofservice_title">Public Areas</b><br/>
							The Platform contains publicly visible user profiles, messaging systems, chat areas, published projects and/or other message or communication facilities that may be visible to all Users of the Platform  (“Public Areas”). You may only use such public areas to send and receive messages and material that are relevant and proper to the applicable forum.
							<br/><br/>
							You understand that any content posted to Public Areas is visible to all Users of the Platform and that You will be publicly identified by Your username when communicating in Public Areas. You accept that the Company will not be responsible for the action of any Users with respect to any content or materials posted in such Public Areas. Any comments or feedback posted by teammates about each other’s performance on any project is the opinion of the user who posted it and not the opinion of the Company. The Company will not be liable for the opinions, comments or any copyright violations by Users. If you believe that your copyright has been violated by another User, please view our Copyright dispute policy to take the right steps.
							<br/><br/>

							<b className="termsofservice_title">Platform Content”</b><br/>
							The Platform hosts “User Generated Content” which includes but is not limited to your User publicly visible user profile, portfolio, published projects, comments posted in any public areas or which is publicly visible on the Platform. Please take note that the Company does not verify or approve such User Generated Content and each User should exercise their best judgment before relying upon any User Generated Content.
							<br/><br/>
							The Platform cannot guarantee the authenticity, accuracy or legality of any such User Generated Content. The User accepts and agrees that the User will not hold the Company liable for any loss, injury or damages incurred (whether personal or monetary in nature) as a result of or arising out of/related to any User Generated Content or use of the Platform.
							<br/><br/>
							You hereby accept that You use the Platform and access of any User Generated Content provided therein is at Your own risk and You will be solely responsible and liable for any loss that may arise as a result there-from.
							<br/><br/>
							You understand that all information transmitted by a User is the sole responsibility of the User from whom such Content originated; that the Platform will not be liable for any errors or omissions in any User Generated Content; and that the Platform cannot guarantee the identity of any other Users with whom You may interact in the course of using the Platform whether online or offline.
							<br/><br/>
							You hereby grant the Company a non-exclusive, worldwide, perpetual, irrevocable, royalty-free, sublicensable right to exercise all copyright, publicity rights, and any other rights You have in such User Generated Content, in order to perform and improve upon the Platform and for any marketing purposes.
							<br/><br/>

							<b className="termsofservice_title">User Obligations</b><br/>
							You agree that at all times:<br/>
							<ul>
								<li>You will comply with this Agreement and all applicable laws and regulations;</li>
								<li>You will post only accurate information on the Platform;</li>
								<li>You will promptly and efficiently perform all Your obligations to other Users under a Project Participation Agreement and towards the Company under this Agreement;</li>
								<li>all content (whether provided by the Company, a User or a third party) on the Platform may not be used on third party sites or for other business purposes without Company’s prior permission; and</li>
								<li>When You enter into a Project Participation Agreement, unless such agreement is prohibited by law or by these Terms of Use, You create a legally binding contract with the other Platform User. If You do not comply with Your obligations to another User, you may become legally liable to compensate the other User in accordance with the Project Participation Agreement, if any.</li>
								<li>If the Platform determines at its sole discretion that You have breached any obligation under this clause, it reserves the rights to remove any content contributed by you on the Platform or cancel or suspend Your account.</li>
							</ul>
							You must not:<br/>
							<ul>
								<li>Post any content that may be potentially or actually harmful to the Company or any User;</li>
								<li>Post any content or information that is false, inaccurate, misleading or deceptive in nature;</li>
								<li>In any way, infringe any User or third party’s copyright, patent, trademark, trade secret or other proprietary rights or intellectual property rights including but not limited to rights of publicity, confidentiality or privacy;</li>
								<li>Act in any manner that may lead to violation of any applicable laws or regulation, including but not limited to those governing consumer protection, unfair competition, antidiscrimination and trade practices / fair trading laws;</li>
								<li>Post any content that may be deemed defamatory, libellous, threatening or harassing;</li>
								<li>Post any content that is obscene or contain any material, that we may in our sole discretion deem inappropriate or unlawful;</li>
								<li>Intentionally or unintentionally cause harm or subvert the function of the Platform by introducing any viruses, trojan horses, time bombs, cancelbots or other computer programming routines that may damage, modify, delete or interfere with any system, data or personal information stored on the Platform;</li>
							</ul>

							<b className="termsofservice_title">Remedies</b><br/>
							We reserve the right to investigate and prosecute violations of any prohibited behaviour to the fullest extent of the law. We reserve the right to take actions to prevent and/or control such violation including but not limited to, removing any inappropriate communication or content from the Platform and/or terminating the account of such violators and/or blocking their use of the Platform.
							<br/><br/>

							<b className="termsofservice_title">Intellectual Property</b><br/>
							Except for the User Generated Content, all content and services made available by the Platform including without limitation, the text, graphics, audio, video, interactive features, code, marks, software and the like (“Company Content”) are owned by and/or licensed to the Company and subject to trademark, copyright and other intellectual property rights under the Federal laws, foreign laws and international conventions. You agree not to copy, sell, transfer, assign, reproduce, republish, disassemble, decompile, reverse engineer or distribute any Company Content in any way except as intended under the Agreement. You also agree not to adapt, alter or create a derivative work from any Company Content without the prior written permission of the Company. You agree that you will not remove any copyright, trademarks and any proprietary notices from any Company Content or any other User Generated Content (“Third-Party Intellectual Property”) that you do not have rights and licences to use. You understand that any use of Company Content or any Third-Party Intellectual Property on any website, system or application that is not owned and operated by us is strictly prohibited.
							<br/><br/>
							We hereby grant you a limited non-exclusive, non-transferable license to use our services for your personal and non-commercial use only.
							<br/><br/>
							We reserve the right to prosecute any violations of this provision to the fullest extent permitted by law. You acknowledge that breach of this provision can incur criminal as well as civil liability.
							<br/><br/>

							<b className="termsofservice_title">Infringement of Copyright</b><br/>
							We respect the Intellectual Property Rights of others and have zero tolerance policy towards  violations of copyright laws. If we find that you have violated a copyright of another person or entity, we reserve the right to immediately terminate your access to our services and take other remedial actions that we in our sole discretion deem appropriate. You are under obligation not to upload, download, post, transmit, reproduce, republish, distribute or in any way access or make available any content that is protected by copyright laws without the the express permission to use such content from the authorized person. You may not use any copyrighted material for any purpose other than for which you have received authorization.
							<br/><br/>
							If you believe that your copyrighted material or content is posted, uploaded or made accessible on our Platform, please inform us at the email address listed below of such material and provide us with the following information:
								<ul>
									<li>Identification of the material or content that is claimed to be infringing your copyrighted works;</li>
									<li>Your contact information including your name, phone number and email address;</li>
									<li>A statement by you setting out that you are either the owner of the copyrighted work or have been authorized by the owner to communicate with us with regard to the copyrighted work.</li>
								</ul>
							<br/><br/>
							We will respond to your email within fourteen (14) days from the date of receipt and investigate your claim provided you have given us all requested information.
							<br/><br/>
							Contact us: contact@kreatorbus.com
							<br/><br/>

							<b className="termsofservice_title">Feedback</b><br/>
							We welcome Your feedback and suggestions about how to improve our Platform. Please feel free to submit feedback to contact@kreatorbus.com. By submitting feedback, You agree to grant Us the right, at Our discretion, to use, disclose and otherwise exploit the feedback, in whole or part, without any restriction or compensation to You.
							<br/><br/>

							<b className="termsofservice_title">Modification</b><br/>
							Any provisions of this Agreement and any features and functionality of the Platform can be modified by the Company at its sole discretion, without prior notice to you. In the event we make any modification to any features and functionality, such modifications will become effective from the date of implementation of such feature and functionality on the Platform. Where we make amendments to these terms, we will notify you of such change by  changing the last updated date on top of these terms of use. You are advised to review these Terms of Use periodically to familiarize yourself of any such modifications. The Company expressly disclaims any liability for any loss suffered by You as a result of your failure to review such modified terms.
							<br/><br/>

							<b className="termsofservice_title">No Liability for User Conduct</b><br/>
							If You participate in any projects published on the Platform or engage in any transaction, interaction or agreement with any other User, whether online or offline, such activity would be solely between You and such other User. The Company will not be liable or have any responsibility for any such activity.
							<br/><br/>

							<b className="termsofservice_title">Disclaimer</b><br/>
							You accept that we have no duty to take any action regarding: the accuracy or correctness of any content you receive through the Platform; the effects of any decision by project leader including but not limited to drop a specific teammate or to unpublish/delete any project on the platform; how you may interpret any content or information you send or receive through the Platform; or any actions that you may take after receiving or sending such information/content.
							<br/><br/>
							We make no representations concerning any User Generated Content contained in or accessed through the Platform, and the Company will not be responsible or liable for the accuracy, copyright compliance, availability, legality or decency of material contained in or accessed through the Platform.
							<br/><br/>
							You understand that we do not, in any way, screen Users. In no event shall we be liable for any damages whatsoever, arising out of or relating to your conduct or anyone else in connection with the Platform.
							<br/><br/>
							The Platform service, content and add-to link are provided to You on "AS IS" and "AS AVAILABLE" basis and are provided without any representations or warranties of any kind, express or implied, including, but not limited to, the implied warranties of title, non-infringement, merchantability and fitness for a particular purpose, and any warranties implied by any course of performance, all of which are expressly disclaimed, save to the extent required by law.
							<br/><br/>
							The Company, and its directors, employees, agents, representatives, suppliers and partners do not warrant that:
							<br/><br/>
							(a) the Platform will be secure or available at any particular time or location;<br/>
							(b) any defects or errors will be corrected;<br/>
							(c) any content or software available at or through the Platform is free of viruses or other harmful components; or<br/>
							(d) the results of using the Platform will meet your requirements.<br/>
							<br/><br/>

							<b className="termsofservice_title">Indemnification</b><br/>
							To the fullest extent permitted by law, you hereby agree to indemnify, defend, and hold harmless the Company, its directors, officers, employees, agents, licensors, attorneys, and affiliates from and against any and all claim, loss, expense or demand of liability, including attorneys' fees and costs incurred, in connection with (i) Your use or inability to use the Platform, or (ii) any content submitted by you or using your account to the Platform, (iii) Any dispute or Conflict between you and another User.
							<br/><br/>

							<b className="termsofservice_title">LIMITATION OF LIABILITY</b><br/>
							THE COMPANY IS ONLY WILLING TO OFFER THE SERVICES UNDER THIS AGREEMENT IF YOU ACCEPT CERTAIN LIMITATIONS OF OUR LIABILITY TO YOU AND THIRD PARTIES.
							<br/><br/>
							THE COMPANY, ITS AFFILIATES, ITS LICENSORS, OR ANY OF SUCH PARTIES’ AGENTS, EMPLOYEES, OFFICERS, DIRECTORS, CORPORATE PARTNERS, OR PARTICIPANTS’ LIABILITY IS LIMITED TO THE MAXIMUM EXTENT PERMITTED BY LAW FOR ANY DIRECT, INDIRECT, INCIDENTAL, CONSEQUENTIAL, SPECIAL OR EXEMPLARY DAMAGES ARISING IN CONNECTION WITH YOUR USE OF OR INABILITY TO USE THE PLATFORM. SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR LIMITATION OF INCIDENTAL OR CONSEQUENTIAL DAMAGES, SO THE ABOVE LIMITATIONS MAY NOT APPLY TO YOU, IN SUCH EVENT, OUR LIABILITY SHALL BE LIMITED TO THE MAXIMUM EXTENT PERMITTED BY LAW.
							<br/><br/>
							IF, NOTWITHSTANDING THE FOREGOING EXCLUSIONS, IT IS DETERMINED THAT THE COMPANY OR ITS EMPLOYEES, OFFICERS, DIRECTORS, PARTNERS, AFFILIATES, SHAREHOLDERS OR ANY OF SUCH PARTIES’ AGENTS, EMPLOYEES, OFFICERS, DIRECTORS, CORPORATE PARTNERS, OR PARTICIPANTS IS LIABLE FOR DAMAGES, IN NO EVENT WILL THE AGGREGATE LIABILITY, WHETHER ARISING IN CONTRACT, TORT, STRICT LIABILITY OR OTHERWISE, EXCEED THE FEES, IF ANY, PAID BY SUCH USER TO THE COMPANY DURING THE ONE (1) MONTH PRIOR TO THE TIME SUCH CLAIM AROSE.
							<br/><br/>

							<b className="termsofservice_title">Governing Law</b><br/>
							This Agreement shall be governed by and construed in accordance with the Laws of the State of California, USA without regard to choice of law principles. Any actions arising out of this Agreement or associated with this Agreement must be brought in the Courts of Law located in the State of California.
							<br/><br/>

							<b className="termsofservice_title">General Provisions</b><br/>
							Any failure on the part of the Company to enforce any provision(s) of this Agreement will not constitute a waiver of any provision or right under this Agreement. This Agreement and the privacy policy which is included herein by reference constitutes the entire understanding between you and the Company. If for any reason, any provision of this Agreement is found to be void or unenforceable; that provision should be limited to the minimum extent permitted by law and the remaining provisions shall be fully applicable. This Agreement may not be assigned or transferred by you without prior written consent of the Company but the Company may assign or transfer any rights or obligations under this Agreement without your consent. Any assignment in violation of this provision of the Agreement shall be null and void.
							<div className="clear" style={{marginBottom: '3em'}}></div>
						</div>
					</div>
				<div className="bottom_padding"></div>
				<Footer />
			</div>
		);
	}
};
