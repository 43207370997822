import React from 'react';
import { Link } from 'react-router-dom';
import HTTP from '../../utils/Http.js';
import NavBarNotifications from '../nav/NavBar_Notifications.jsx';
import ProgressBar from './ProgressBar.jsx';
import Footer from '../footer/Footer.jsx';
import PropTypes from 'prop-types';
import history from 'app/history';

export default class SignupAddInfo extends React.Component {
	static propTypes = {
		edit: PropTypes.bool
	};

	state = {
		profileCompleteness: USER ? USER.profileCompleteness : 0,
		addInfo: USER ? USER.additionalInfo : 0,
		hoursPerWeek: USER ? USER.additionalInfo.hoursPerWeek : 0,
		whatToGain: USER ? USER.additionalInfo.whatToGain : 0,
		travelRadius: USER ? USER.additionalInfo.travelRadius : 0,
		teamOrProject: USER ? USER.additionalInfo.teamOrProject : 0,

		successSave: 'hidden',
		failedSave: 'hidden',
		addInfoError: 'hidden'
	};

	generateOptions = option => {
		return <option key={option.id} value={option.id}>{option.text}</option>
	}

	handleHoursPerWeek = e => {
		this.setState({
			hoursPerWeek: +e.target.value,
			successSave: 'hidden'
		});
	}

	handleWhatToGain = e => {
		this.setState({
			whatToGain: +e.target.value,
			successSave: 'hidden'
		});
	}

	handleTravelRadius = e => {
		this.setState({
			travelRadius: +e.target.value,
			successSave: 'hidden'
		});
	}

	handleTeamOrProject = e => {
		this.setState({
			teamOrProject: +e.target.value,
			successSave: 'hidden'
		});
	}

	handleBack = () => {
		this.save(true, true);
	}

	handleSave = () => {
		this.save(false, false);
	}

	handleSubmit = () => {
		this.save(true, false);
	}

	save = (shouldContinueSignUp, backToEditProfilePage) => {
		const path = backToEditProfilePage ? '/editprofilepage' : '/signupfuture';

		if (this.state.hoursPerWeek === -1) {
			this.setState({ addInfoError: 'visible', successSave: 'hidden' });
		// } else if (this.state.whatToGain === -1) {
		// 	this.setState({ addInfoError: 'visible', successSave: 'hidden' });
		} else if (this.state.travelRadius === -1) {
			this.setState({ addInfoError: 'visible', successSave: 'hidden' });
		} else if (this.state.teamOrProject === -1) {
			this.setState({ addInfoError: 'visible', successSave: 'hidden' });
		} else {
			HTTP.post('/profile/additionalInfo', { hoursPerWeek: this.state.hoursPerWeek, whatToGain: 0 /*this.state.whatToGain*/, travelRadius: this.state.travelRadius, teamOrProject: this.state.teamOrProject }, (error, body) => {
				if (error) {
					return this.setState({ failedSave: 'visible', successSave: 'hidden' });
				}

				USER.additionalInfo.hoursPerWeek = this.state.hoursPerWeek;
				USER.additionalInfo.whatToGain = this.state.whatToGain;
				USER.additionalInfo.travelRadius = this.state.travelRadius;
				USER.additionalInfo.teamOrProject = this.state.teamOrProject;
				USER.profileCompleteness = body.profileCompleteness;

				this.setState({
					profileCompleteness: body.profileCompleteness,
					failedSave: 'hidden',
					successSave: 'visible',
					addInfoError: 'hidden'
				});

				if (shouldContinueSignUp) {
					history.push(path);
				}
			});
		}
	}

	render = () => {
		var profileCompleteness = this.state.profileCompleteness;
		var editNavBarDisplay = this.props.edit ? 'visible_block' : 'hidden';
		var signupNavBarDisplay = this.props.edit ? 'hidden' : 'visible_block';

		return (
			<div className="viewport_width">
				<NavBarNotifications
					showLoginButton={false}
					showSignupButton={false}
				/>
				<div className="signup_hero">
					<img src="images/signup_additional.svg" />
				</div>

				<div className="clear"></div>

				<div>
					<div className="show_for_desktop">
						<div className="signup_progressbar" style={{width:'56em'}}>
							<ProgressBar progress={this.state.profileCompleteness} width="56em"/>
						</div>
					</div>

					<div className="show_for_mobile">
						<div className="signup_progressbar" style={{width:'19em'}}>
							<ProgressBar progress={this.state.profileCompleteness} width="18em"/>
						</div>
					</div>

					<div className="signup_container">
						<div id="additional_container">
							<div className="subtitle" style={{float: 'left'}}>(All fields required)</div>
							<span className={this.state.successSave + " signup_success"} style={{"margin": "0 0 0 0.5em"}}>{CONSTANTS.errors.successSave}</span>
							<span className={this.state.failedSave + " signup_error"} style={{"margin": "0 0 0 0.5em"}}>{CONSTANTS.errors.failedSave}</span>
							<span className={this.state.addInfoError + " signup_error"} style={{"margin": "0 0 0 0.5em"}}>{CONSTANTS.errors.addInfoError}</span>
							<div className="clear"></div>
							<div id="additional_box">
								<p>How many hours a week can you devote to a project</p>
								<select defaultValue={this.state.hoursPerWeek} onChange={this.handleHoursPerWeek}>
									{CONSTANTS.additionalInfo.hoursPerWeek.map(this.generateOptions)}
								</select>
								{/*<p>What are you looking to gain from working on a project?</p>
								<select defaultValue={this.state.whatToGain} onChange={this.handleWhatToGain}>
									{CONSTANTS.additionalInfo.whatToGain.map(this.generateOptions)}
								</select>*/}
								<p>How far are you willing to travel to meet a team?</p>
								<select defaultValue={this.state.travelRadius} onChange={this.handleTravelRadius}>
									{CONSTANTS.additionalInfo.travelRadius.map(this.generateOptions)}
								</select>
								<p>I want to...</p>
								<select defaultValue={this.state.teamOrProject} onChange={this.handleTeamOrProject}>
									{CONSTANTS.additionalInfo.teamOrProject.map(this.generateOptions)}
								</select>
							</div>
							<div className={"signup_navigation " + signupNavBarDisplay}>
								<input name="submit" className="nextnav" type="submit" value="Next >" onClick={this.handleSubmit}/>
								<input name="submit" className="savenav" type="submit" value="Save" onClick={this.handleSave}/>
								<Link to="/signupinterests" className="backnav">&lt; Back</Link>
							</div>
							<div className={"edit_signup_navigationb " + editNavBarDisplay} style={{width:"100%"}}>
								<input className="savenavb" style={{marginRight:"-0.6em"}} type="submit" value="Save" onClick={this.handleSave}/>
								<input className="backnavb" style={{marginLeft:"0"}} type="submit" value="Back to Profile" onClick={this.handleBack}/>
							</div>
						</div>
					</div>
				</div>
				<div className="bottom_padding"></div>
				<Footer />
			</div>
		);
	}
};