import React from 'react';
import PropTypes from 'prop-types';

export default class Slide extends React.Component {
	render = () => {
		var displayClassName = this.props.active ? 'slide_active' : 'slide_inactive';

		if (this.props.id === "slide0") {
			return (
				<div className={displayClassName}>
					<img src="images/kb_hero.png" />
					
					<div className="slide_text" style={{top: "1em", color: "white", fontSize: "1.2em", textShadow: "2px 2px 2px black"}}>
						<div style={{width: "33em", margin: "0 auto"}}>
							<p style={{marginTop: "0.4em", fontSize: "1.2em"}}>Bringing great ideas and talent together.</p>
							<p style={{marginTop: "8.5em", fontSize: "1.2em"}}>The journey starts here.</p>
						</div>
					</div>
				</div>
			);
		}

		if (this.props.id === "slide1") {
			return (
				<div className={displayClassName} style={{background: "black"}}>
					<img src="images/kb_animation.gif" style={{opacity: "0.9"}}/>
					<div className="slide_text" style={{top: "2em", color: "white"}}>
					<div style={{width: "33em", margin: "0 auto", color:"#4A4A4A", fontSize: "2em", textAlign: "left"}}>
							<p style={{marginLeft: "5em"}}>Every great project needs a great team.</p>
							<p style={{marginLeft: "5em", marginTop: "0.3em"}}>Get on the bus!</p>
					</div>
				</div>
				</div>
			);
		}

		return (
			<div className={displayClassName}>
				<img src="images/desert_road_01.png"/>
				<div className="slide_text" style={{top: "1em", color: "white", fontSize: "1.2em", textShadow: "2px 2px 2px black"}}>
					<div style={{width: "33em", margin: "0 auto"}}>
						<p>The journey of a thousand miles begins with a simple step. &nbsp;&nbsp;&nbsp;- Lao Tzu</p>
						<p style={{marginTop: "1.8em"}}>But it's way easier to use the bus.</p>
					</div>
				</div>
			</div>
		);
	}
};

Slide.propTypes = {
	active: PropTypes.bool,
	id: PropTypes.string,
	imageAlt: PropTypes.string
};