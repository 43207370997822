// This is the page for contacting other members on the website.

import React from 'react';
import PropTypes from 'prop-types';
import HTTP from '../../utils/Http';
import { getLocationSearchParam } from 'utils/Utils';
import NavBarNotifications from '../nav/NavBar_Notifications.jsx';
import Footer from '../footer/Footer.jsx';
import history from 'app/history';

export default class ContactKreator extends React.Component {
	state = {
		firstName: '',
		lastName: '',
		photo: '',
		projectsCreatedCanInvite: [],
		projectsCreatedCannotInvite: [],
		selectedProjectId: '',
		selectedProjectName: '',
		displayProjectLinks: 'hidden',
		message: '',
		messageVisibility: 'visible_block',
		inviteConfirmation: 'hidden',
		sourePage: '',
		buttonType: '',
		inviteButtonVisibility: 'hidden',
		messageButtonVisibility: 'visible_block',
		messageWarningVisibility: 'hidden'
	};

	UNSAFE_componentWillMount = () => {
		const kreatorId = getLocationSearchParam('profileId');
		const sourcePage = getLocationSearchParam('sourcePage');
		const buttonType = getLocationSearchParam('buttonType');

		this.setState({sourcePage: sourcePage, buttonType: buttonType});

		if (buttonType === 'invite'){
			this.setState({
				inviteButtonVisibility: 'visible_block',
				messageButtonVisibility: 'hidden'
			});
		} else {
			this.setState({
				inviteButtonVisibility: 'hidden',
				messageButtonVisibility: 'visible_block'
			});
		}

		if (!kreatorId) {
			return history.push('/notfoundpage');
		}

		HTTP.get(`/kreator/${kreatorId}/summary`, (error, body) => {
			if (error) {
				return;
			}

			this.setState({
				firstName: body.firstName,
				lastName: body.lastName,
				photo: 'uploads/' + body.photo
			});
		});

		HTTP.get(`/kreator/${kreatorId}/inviteProjects`, (error, body) => {
			if (error) {
				return;
			}
			this.setState({
				projectsCreatedCanInvite: body.goodProjects,
				projectsCreatedCannotInvite: body.badProjects
			});
		});
	}

	generateProjectsCreatedCanInvite = project => {
			return(
			<option className="link_item" key={project._id} value={project._id}>{project.name}</option>
		);
	}

	generateProjectsCreatedCannotInvite = project => {
			return(
			<option className="link_item" key={project._id} value={project._id} disabled >{project.name}</option>
		);
	}

	handleSelectProject = (e) => {

		var projectId = '';
		var projectName = '';

		this.state.projectsCreatedCanInvite.forEach(function(project){
			if(e.target.value === project._id){
				projectId = project._id;
				projectName = project.name;
			}
		});

		this.setState({
			selectedProjectId: projectId,
			selectedProjectName: projectName
		});
	}

	handleCancelButton = () => {
		if(this.state.sourcePage === 'projectMainPage'){
			const projectId = getLocationSearchParam('projectId');
			history.push(`/projectmainpage?projectId=${projectId}`);
		} else {
			const profileId = getLocationSearchParam('profileId');
			history.push(`/profilepage?profileId=${profileId}`);
		}
	}

	handleDisplayProjectLink = () => {
		this.setState({ displayProjectLinks: 'visible_block' });
	}

	handleMessageUpdate = e => {
		this.setState({ message: e.target.value });
		this.hideMessageWarning();
	}

	handleSendMessage = () => {

		if (this.state.message.trim() === ''){
			this.showMessageWarning();
			return;
		}

		const profileId = getLocationSearchParam('profileId');

		HTTP.post('/messages', { message: this.state.message, to: profileId }, (error, body) => {
			if (error) {
				return alert('Could not send message!');
			} else {
				history.push('/messages');
			}
		});
	}

	handleSendInvite = () => {
		const profileId = getLocationSearchParam('profileId');
		const projectId = this.state.selectedProjectId;

		HTTP.post('/kreator/' + profileId + '/invite', { project: projectId }, (error, body) => {
			if (error) {
				console.log("there was a problem");
				return alert(body);
			} else {
				this.handleSendMessage();
			}
		});
	}

	handleShowInviteConfirmation = () => {
		if (this.state.message.trim() === ''){
			this.showMessageWarning();
			return;
		}

		this.setState({
			inviteConfirmation: 'visible_block',
			messageVisibility: 'hidden',
			inviteButtonVisibility: 'hidden'
		});
	}

	handleCancelInvite = () => {
		this.setState({
			inviteConfirmation: 'hidden',
			messageVisibility: 'visible_block',
			inviteButtonVisibility: 'visible_block'
		});
	}

	showMessageWarning = () => {
		this.setState({messageWarningVisibility: 'visible_block'});
	}

	hideMessageWarning = () => {
		this.setState({messageWarningVisibility: 'hidden'});
	}

	render = () => {
		if (this.state.displayProjectLinks === 'visible_block') {
			window.onclick = event => {
				if (!event.target.matches('.link_item') && this.state.displayProjectLinks === 'visible_block') {
					this.setState({displayProjectLinks: 'hidden'});
				}
			}
		}

		var textBoxTitle;
		var placeholderMessage;
		if (this.state.buttonType === 'invite'){
			textBoxTitle = "Invite";
			var placeholderMessage = "Write a message inviting this kreator to be a part of your team!";
		} else {
			textBoxTitle = "Message";
			var placeholderMessage = "Send this kreator a wonderful message!";
		}

		var inviteButtonClickable;
		var inviteButtonBackgroundColor;
		if (this.state.selectedProjectId !== ''){
			inviteButtonClickable = "invite_button_enabled";
		} else {
			inviteButtonClickable = "invite_button_disabled";
			inviteButtonBackgroundColor = '#C8C8C8';
		}

		var projectsPublished = [];
		var projectSelectionTitle = "Select Project";

		var publishWarningVisbility = "hidden";
		var messageVisibility = "visible_block";

		if (USER !== null){
			if(!USER.published){
				publishWarningVisbility = "visible_block";
				messageVisibility = "hidden";
			}
		}
		
		return (
			<div className="viewport_width">
				<NavBarNotifications
					showLoginButton={false}
					showSignupButton={false}
				/>
				<h1 className={publishWarningVisbility} style={{color: '#00A4A4', textAlign: 'center', paddingTop: '4em', width: '90%', margin: 'auto', fontSize: '1.2em'}}> Your profile must be approved before you can send messages or invites.</h1>
				<div className={messageVisibility}>
					<div className="contact_user_topbox">
						<div>
							<div className="contact_user_picbox">
								<img src={this.state.photo} className="profile_pic" /><br/>
								<p>{this.state.firstName} {this.state.lastName}</p>
							</div>
						</div>
					</div>

					<div className="clear"></div>
					<div className="project_box_holder">
						<div className="contact_user_bottombox">
							<div className={this.state.inviteConfirmation + ' invite_confirmation_box'}>
								<p style={{ marginBottom: '2em' }}>
									Are you sure you want to invite this kreator to <span style={{color: 'red'}}>{this.state.selectedProjectName}</span>?
									If the kreator accepts the invitation, he or she will be added to your project as a team member.
								</p>
								<div className="invite_confirmation_button_box">
									<div className="show_for_desktop">
										<div className="invite_confirmation_button" style={{ float: 'left', marginLeft: '3em' }}>
											<p>Nope, Go back.</p>
											<button onClick={this.handleCancelInvite}>Cancel</button>
										</div>
										<div className="invite_confirmation_button" style={{ float: 'right', marginRight: '3em' }}>
											<p>Yes, Definitely!</p>
											<button onClick={this.handleSendInvite}>Send Invite</button>
										</div>
									</div>
									<div className="show_for_mobile">
										<div className="invite_confirmation_button" style={{ float: 'left', marginLeft: '1em' }}>
											<p>Nope, Go back.</p>
											<button onClick={this.handleCancelInvite}>Cancel</button>
										</div>
										<div className="invite_confirmation_button" style={{ float: 'right', marginRight: '1em' }}>
											<p>Yes, Definitely!</p>
											<button onClick={this.handleSendInvite}>Send Invite</button>
										</div>
									</div>
								</div>
							</div>
							<div className="clear"></div>
							<div className={this.state.messageVisibility + ' textarea_container'}>
								<div className="show_for_desktop">
									<h3 style={{textAlign: "left", marginLeft: "2.3em", fontWeight: "500"}}>{textBoxTitle}:</h3>
								</div>
								<div className="show_for_mobile">
									<h3 style={{textAlign: "left", marginLeft: "0em", fontWeight: "500"}}>{textBoxTitle}:</h3>
								</div>
								<p className={this.state.messageWarningVisibility}style={{background: "#FFB0B0", margin: "0 auto 0.5em auto", width: "100%"}}>Please enter a message.</p>
								<textarea rows="10" cols="65" style={{fontFamily:"Avenir, Futura, Helvetica Neue, sans-serif"}} placeholder={placeholderMessage} value={this.state.message} onChange={this.handleMessageUpdate}></textarea>
								<div className={"contact_user_buttonbox " + this.state.messageButtonVisibility}>
									<button onClick={this.handleSendMessage}>Message</button>

									<div className="show_for_desktop">
										<button onClick={this.handleCancelButton}>Cancel</button>
									</div>

									<div className="show_for_mobile">
										<button onClick={this.handleCancelButton} style={{marginLeft:'0em', float:'left'}}>Cancel</button>
									</div> 
								</div>
								<div className={"contact_user_buttonbox " + this.state.inviteButtonVisibility}>			
									<div className="project_links">
										<select className="link_item" style={{zIndex: "10"}} value={this.state.selectedProjectId}onChange={this.handleSelectProject}>
											<option className="link_item" key='no project selected' value=''>{projectSelectionTitle}</option>
											{this.state.projectsCreatedCanInvite.map(this.generateProjectsCreatedCanInvite)}
											{this.state.projectsCreatedCannotInvite.map(this.generateProjectsCreatedCannotInvite)}
										</select>
									</div>
										<button onClick={this.handleShowInviteConfirmation} className={inviteButtonClickable} style={{background: inviteButtonBackgroundColor}}>Invite</button>
										<button onClick={this.handleCancelButton}>Cancel</button>
									{/*<button className="link_item" style={{marginLeft:"0"}} onClick={this.handleDisplayProjectLink}>Project</button>*/}
								</div>
							</div>
						</div>
					</div>
					<div className="clear"></div>
				</div>
				<Footer />
			</div>
		);
	}
};
