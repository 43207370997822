// This is the first page a user is directed to when signing up.

import React from 'react';
import HTTP from '../../utils/Http';
import { Link } from 'react-router-dom';
import { QueryLink, getLocationSearchParam } from 'utils/Utils';
import NavBarNotifications from '../nav/NavBar_Notifications.jsx';
import Footer from '../footer/Footer.jsx';
import PropTypes from 'prop-types';
import history from 'app/history';

export default class PasswordResetSignin extends React.Component {
	state = {
		errPassword: 'hidden',
		errPasswordConfirm: 'hidden',
		errSubmitPassword: 'hidden',
		newPassword: '',
		passwordConfirmation: '',
		passwordChangeBoxVisibility: "visible_block",
		passwordChangeSuccessVisibility: "hidden",
		userId: "",
		resetId: "",
		error: ''
	};

	UNSAFE_componentWillMount = () => {

		const userId = getLocationSearchParam('userId');
		const resetId = getLocationSearchParam('resetId');

		this.setState({userId: userId, resetId: resetId});
	}

	handlePasswordChange = e => {
		this.setState({ newPassword: e.target.value });
	}

	handlePasswordConfirmationChange = e => {
		this.setState({ passwordConfirmation: e.target.value });
	}

	onEnterKeyPressed = (e) => {
		if (e.key === 'Enter') {
			this.handleSubmit(e);
		}
	}

	checkPassword = e => {
		var reg = /^.*(?=.{8,})(?=.*[a-zA-Z])(?=.*\d).*$/;

		this.setState({ errPassword: reg.test(this.state.newPassword) ? 'hidden' : 'visible_block' });

		return reg.test(this.state.newPassword);
	}

	checkPasswordConfirm = e => {
		this.setState({ errPasswordConfirm: this.state.newPassword === this.state.passwordConfirmation ? 'hidden' : 'visible_block' });
	}

	handleGotoLoginPage = () => {
		history.push('/loginpage');
	}

	handleSubmit = () => {

		if (!this.checkPassword()){
			this.setState({errPassword: "visible_block"});
			return;
		}
		if (this.state.newPassword !== this.state.passwordConfirmation){
			this.setState({errPasswordConfirm: "visible_block"});
			return;
		}

		const newPassword = this.state.newPassword.trim();
		const newPasswordConfirm = this.state.passwordConfirmation.trim();

		HTTP.post('/profile/password/reset', { userId: this.state.userId, resetId: this.state.resetId, newPassword: newPassword, newPasswordConfirm: newPasswordConfirm }, (error, body) => {
			if (error) {
				return this.setState({ errSubmitPassword: 'visible_block', errSubmitPasswordText: body  });
			}
			this.setState({
				passwordChangeSuccessVisibility: "visible_block",
				passwordChangeBoxVisibility: "hidden",
				errSubmitPassword: "hidden"
			});
		});
	}

	render = () => {
		return(
			<div className="viewport_width">
				<NavBarNotifications
					showLoginButton={true}
					showSignupButton={true}
				 />

				<div className="clear"></div>

				<div >
					<div className="signup_container">
						<p className={"failed_submit " + this.state.errSubmitPassword} style={{marginTop: "1em", marginBottom: "-1em"}}>{this.state.errSubmitPasswordText}</p>
						<div className={"password_reset_signin_box " + this.state.passwordChangeBoxVisibility}>
							<div>

								<div className="show_for_mobile">
									<div>Password:</div>
								</div>

								<input type="password" placeholder="Create a password" name="password" value={this.state.password} onChange={this.handlePasswordChange} onBlur={this.checkPassword} />
								
								<div className="show_for_desktop">
									<div style={{float: "right", marginRight: "1em"}}>Password:</div>
								</div>

								<div className="clear"></div>
								<span className={this.state.errPassword + " error"} style={{margin: "0.1em auto 1em auto", width: "90%", textAlign: "center"}}>{CONSTANTS.errors.passwordComplianceError}</span>
								<span className="subtitle password_reset_text">Password must be at least eight characters long and contain one letter and one number.</span>
							</div>

							<div>

								<div className="show_for_mobile">
									<div>Confirm Password:</div>
								</div>
								<input type="password" placeholder="Confirm password" name="confirm" value={this.state.passwordConfirmation} onChange={this.handlePasswordConfirmationChange} onBlur={this.checkPasswordConfirm} onKeyPress={this.onEnterKeyPressed}/>
								
								<div className="show_for_desktop">
									<div style={{float: "right", marginRight: "1em"}}>Confirm Password:</div>
								</div>

								<div className="clear"></div>
								<span className={this.state.errPasswordConfirm + " error"} style={{margin: "0.1em auto 1em auto", width: "90%", textAlign: "center"}}>{CONSTANTS.errors.passwordMatchError}</span>
							</div>
							<button className="password_reset_continue_button" onClick={this.handleSubmit}>Submit</button>
						</div>
						<div className={"password_reset_signin_box " + this.state.passwordChangeSuccessVisibility} style={{minHeight: "10em", textAlign: "center"}}>
							Your password was successfully changed.
							<div className="clear"></div>
							<button className="password_reset_continue_button" onClick={this.handleGotoLoginPage} style={{margin: "2em auto", float: "none"}}>Login</button>
						</div>
						<br/><br/><br/>
					</div>

					<div className="clear"></div>
				</div>
				<div className="bottom_padding"></div>
				<Footer />
			</div>
		);
	}
};
