import React from 'react';
import Pager from './Pager.jsx';
import PropTypes from 'prop-types';

export default class Pagination extends React.Component {
	render = () => {
		var toggleSlide = this.props.toggleSlide;

		var paginationNodes = this.props.data.map((node, index) => (
			<Pager
				isActive={this.props.currentSlide === index}
				id={node.id}
				key={node.id}
				title={node.title}
				toggleSlide={toggleSlide}
			/>
		));

		return (
			<div className="paginationbox">
				<div className="pagination">
					{paginationNodes}
				</div>
			</div>
		);
	}
};

Pagination.propTypes = {
	toggleSlide: PropTypes.func,
	data: PropTypes.array,
	currentSlide: PropTypes.number
};