import React from 'react';
import { Link } from 'react-router-dom';
import HTTP from '../utils/Http';
import { QueryLink } from 'utils/Utils';
import NavBarNotifications from './nav/NavBar_Notifications.jsx';
import ProgressBar from './signup/ProgressBar.jsx';
import FeaturedProfiles from './profiles/FeaturedProfiles.jsx';
import ProfileSummary from './profiles/ProfileSummary.jsx';
import FeaturedProjects from './projects/FeaturedProjects.jsx';
import ProjectSummary from './projects/ProjectSummary.jsx';
import ProjectUpdate from './projects/ProjectUpdate.jsx';
import Footer from './footer/Footer.jsx';

export default class HomePage extends React.Component {

	state = {
		feedResults: [],
		feedPage: 1,
		totalPages: null,
		scrolling: false
	}

	UNSAFE_componentWillMount = () => {
		this.getUserFeed();
	}

	componentDidMount = () => {
		var userFeedBox = document.getElementById("user_feedbox");
		this.scrollListener = userFeedBox.addEventListener('scroll', (e) => {
			this.handleScroll(e)
		});
	}

	getUserFeed = () => {
		const {feedPage, feedResults } = this.state;
		HTTP.post('/feed', {'feedPage': feedPage}, (error, body) => {
			if (error) {
				console.error('error fetching feed.');
			} else {
				this.setState({
					feedResults: [...feedResults, ...body ],
					scrolling: false,
				});
			}
		});
	}

	loadMore = () => {
		this.setState(prevState => ({
			feedPage: prevState.feedPage + 1,
			scrolling: true,
		}), this.getUserFeed);
	}

	handleScroll = (e) => {
		const { scrolling, totalPages, feedPage } = this.state
		if (scrolling) return

		const userFeedBox = document.getElementById("user_feedbox");
		const lastComponent = document.querySelector('#user_feedbox > div:last-child');
		const lastComponentOffset = lastComponent.offsetTop + lastComponent.clientHeight;

		const pageOffset = userFeedBox.scrollTop;
		var bottomOffset = 1000;

		if (pageOffset > lastComponentOffset - bottomOffset) this.loadMore();
	}

	stripHTMLFromString = (htmlString) => {
		var stripedHtml = htmlString.replace(/<[^>]+>/g, '');
		return stripedHtml;
	}

	createFeedComponent = (feedObject) => {
		if (feedObject.type === "Project") {
			return (
				<div key={feedObject._id} className="feed_component">
					<div className="feed_project">
						<div className="feed_title" style={{marginBottom: "0.5em"}}>New Project Posted</div>
						<ProjectSummary
							project={feedObject}
						/>
						<div className="clear"></div>
					</div>
				</div>
			);

		}

		if (feedObject.type === "ProjectUpdate") {
			var text = feedObject.text;
			var projectName = feedObject.project.name;
			
			return (
				<div key={feedObject._id} className="feed_component">

					<div className="feed_project_update">
						<div className="feed_title" style={{marginBottom: '0.5em'}}>{projectName} Update</div>
						<div className="feed_project_update_box">
							<QueryLink to={{ pathname:"/projectmainpage", query: { projectId: feedObject.project._id, projectPageTarget: "projectUpdates" }}}>
							<div style={{paddingBottom: "1em"}}>
								<div dangerouslySetInnerHTML={{ __html: feedObject.text }} />
							</div>
							</QueryLink>
						</div>
						<div className="clear"></div>
					</div>
				</div>
			);

		}

		if (feedObject.type === "User") {
			return (
				<div key={feedObject._id} className="feed_component">
					<div className="feed_kreator">
						<div className="feed_title">Just Joined</div>
						<ProfileSummary
							key={feedObject._id}
							kreator={feedObject}
							mainSearchBar=""
							expertises={feedObject.expertises}
							skills={feedObject.skills}
							interests={feedObject.interests}
							school={feedObject.school}
							schoolText={feedObject.schoolText}
							course={feedObject.course}
							searching={false}
						/>
						<div className="clear"></div>
					</div>
				</div>
			);
		}
	}

	render = () => {
		const photo = USER.photo ? `/uploads/${USER.photo}` : '/images/add_photo_account.svg';

		return (
			<div className="viewport_width">
				<div style={{background: "#DEFFFF", overflow:'hidden', height:'100%'}}>
					<NavBarNotifications
						showLoginButton={false}
						showSignupButton={false}
					/>
					<div className="clear"></div>

					<div className="show_for_desktop">
						<div className="user_homepage_profilebox">
							<div className="user_homepage_picbox">
								<img src={photo} />
							</div>
							<div className="user_homepage_namebox">
								<p>{USER.firstName} {USER.lastName}</p>
								<div className="clear"></div>
								<Link to="/editprofilepage">Edit Profile</Link>
							</div>
							<div style={{margin: "0.8em 0.5em 0.5em 1em", float: "left"}}>
								<ProgressBar progress={USER.profileCompleteness} width="35em"/>
							</div>
						</div>
					</div>
					<div className="clear"></div>
					<div className="user_homepage_lowerbox">
						<div id="user_feedbox">
							{this.state.feedResults.map(this.createFeedComponent)}
						</div>
						{/*<div className="featured_projectbox">
							<FeaturedProjects />
						</div>*/}
					</div>
					{/*<FeaturedProfiles backgroundColor="white"/>*/}
					<div className="clear"></div>

					<Footer />
				</div>
			</div>
		);
	}
};
