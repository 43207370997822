import React from 'react';
import { Link } from 'react-router-dom';
import HTTP from '../../utils/Http';
import NavBarNotifications from '../nav/NavBar_Notifications.jsx';
import ProgressBar from './ProgressBar.jsx';
import Footer from '../footer/Footer.jsx';
import PropTypes from 'prop-types';
import history from 'app/history';

export default class SignupFictional extends React.Component {
	state = {
		profileCompleteness: USER ? USER.profileCompleteness : 0,
		successSave: 'hidden',
		failedSave: 'hidden',
		fictionalBody: USER ? USER.fictional : ''
	};

	handleFictionalBodyChange = e => {
		this.setState({
			fictionalBody: e.target.value,
			successSave: 'hidden'
		});
	}

	handleBack = () => {
		this.save({
			shouldContinueSignUp: true,
			backToEditProfilePage: true
		});
	}

	handleSave = () => {
		this.save();
	}

	handleSubmit = () => {
		this.save({ shouldContinueSignUp: true });
	}

	save = ({ shouldContinueSignUp = false, backToEditProfilePage = false } = {}) => {
		const fictionalBody = this.state.fictionalBody.trim();

		HTTP.post('/profile/fictional', { fictional: fictionalBody }, (error, body) => {
			if (error) {
				return this.setState({ failedSave: 'visible', successSave: 'hidden' });
			}

			USER.profileCompleteness = body.profileCompleteness;
			USER.fictional = fictionalBody;

			this.setState({
				failedSave: 'hidden',
				successSave: 'visible',
				profileCompleteness: body.profileCompleteness
			});

			if (shouldContinueSignUp) {
				const path = backToEditProfilePage ? '/editprofilepage' : '/signupweekend';
				history.push(path);
			}
		});
	}

	render = () => {
		return (
			<div className="viewport_width">
				<NavBarNotifications
					showLoginButton={false}
					showSignupButton={false}
				/>

				<div className="signup_hero">
					<img src="images/signup_fictional.svg" />
				</div>

				<div className="clear"></div>

				<div>
					<div className="show_for_desktop">
						<div className="signup_progressbar" style={{width:'56em'}}>
							<ProgressBar progress={this.state.profileCompleteness} width="56em"/>
						</div>
					</div>

					<div className="show_for_mobile">
						<div className="signup_progressbar" style={{width:'19em'}}>
							<ProgressBar progress={this.state.profileCompleteness} width="18em"/>
						</div>
					</div>

					<div className="signup_container">
						<div className="fictional_container">
							<div className="fictional_header" style={{lineHeight: "1.5em"}}>If you had to describe yourself as a fictional
								<div className="show_for_desktop">
									<br/>
								</div>
							character, who would it be and why?</div>
							<div className="clear"></div>
							<span className={this.state.successSave + " signup_success"} style={{"marginBottom": "0.5em"}}>{CONSTANTS.errors.successSave}</span>
							<span className={this.state.failedSave + " signup_error"} style={{"marginBottom": "0.5em"}}>{CONSTANTS.errors.failedSave}</span>
							<textarea className="fictional_textarea" rows="10" cols="84" value={this.state.fictionalBody} onChange={this.handleFictionalBodyChange} placeholder="Here’s your chance to be creative. Have some fun with this one and let people know why your chosen fictional character would best represent you. This could be a character from books, movies, television, etc." />
							<div className="signup_navigation">
								<input name="submit" className="nextnav" type="submit" value="Next >" onClick={this.handleSubmit}/>
								<Link to="/signupweekend" className="skipnav">Skip</Link>
								<input name="submit" className="savenav" type="submit" value="Save" onClick={this.handleSave}/>
								<Link to="/signupportfolio" className="backnav">&lt; Back</Link>
							</div>
						</div>
					</div>
				</div>
				<div className="bottom_padding"></div>
				<Footer />
			</div>
		);
	}
};
