// This is the page that will let the user post an update to a project.

import React from 'react';
import PropTypes from 'prop-types';
import { QueryLink, getLocationSearchParam, getLocationSearchParams } from 'utils/Utils';
import HTTP from '../../utils/Http.js';
import history from 'app/history';

import NavBarNotifications from '../nav/NavBar_Notifications.jsx';
import Footer from '../footer/Footer.jsx';
import { Editor as TinyMCE } from '@tinymce/tinymce-react';

export default class PostUpdate extends React.Component {
	state = {
		updateText: '',
		projectId: getLocationSearchParam('projectId'),
		updateDescription: '',
		updateId: '',
		deleteButton: 'hidden',
		failedSave: 'hidden',
		errorFileTooBig: 'hidden',
		editUpdate: false
	}

	UNSAFE_componentWillMount = () => {
		const { updateId, projectId, editUpdate } = getLocationSearchParams();

		if (editUpdate === "true") {
			this.setState({editUpdate: true});
		}

		if (updateId) {
			this.setState({
				updateId,
				deleteButton: 'visible'
			});
		}

		if(!USER){
			history.push('/loginpage');
			return;
		}
		HTTP.get(`/project/${projectId}`, (error, body) => {
			if (error) {
				return;
			}
			
			if (USER._id === body.kreator._id) {
				HTTP.get(`/project/${projectId}/update/${updateId}`, (error, body) => {
					if (error) {
						console.log("there was an error");
						return;
					}
					
					this.setState({updateDescription: body.text});
					
				});
			} else {
				history.push('/useraccount');
			}
		});
	}

	removeWhiteSpaces = content => {
		return content.replace(/\s+/g,'');
	}

	handleFileTooBig = () => {
		this.setState({ errorFileTooBig: 'visible_block'});
		alert("File size too large.");
	}

	handleEditorChange = (e) => {
		var updateDescription = e.target.getContent();
		this.setState({
			updateDescription: updateDescription,
			successSave: 'hidden',
			errorFileTooBig: 'hidden'
		});
	}
	filePickerCallback = (cb, value, meta) => {
		const that = this;

		var input = document.createElement('input');
		input.setAttribute('type', 'file');
		input.setAttribute('accept', 'image/*');
		// Note: In modern browsers input[type="file"] is functional without
		// even adding it to the DOM, but that might not be the case in some older
		// or quirky browsers like IE, so you might want to add it to the DOM
		// just in case, and visually hide it. And do not forget do remove it
		// once you do not need it anymore.

		input.onchange = function() {
			var file = this.files[0];

			if (file.size > CONSTANTS.maxFileSizeUpload) {
				that.handleFileTooBig();
				return false;
			}
			var reader = new FileReader();
			reader.readAsDataURL(file);
			reader.onload = function () {
				// Note: Now we need to register the blob in TinyMCEs image blob
				// registry. In the next release this part hopefully won't be
				// necessary, as we are looking to handle it internally.
				var id = 'blobid' + (new Date()).getTime();
				var blobCache =  tinymce.activeEditor.editorUpload.blobCache;
				var base64 = reader.result.split(',')[1];
				var blobInfo = blobCache.create(id, file, base64);
				blobCache.add(blobInfo);
				// call the callback and populate the Title field with the file name
				cb(blobInfo.blobUri(), { title: file.name });
			};
		};
		input.click();
	}
	handleDeleteUpdate = () => {
		const { projectId, updateId } = this.state;

		HTTP.delete(`/project/${projectId}/update/${updateId}`, (error, body) => {
			if (error) {
				return this.setState({ failedSave: 'visible_block' });
			}

			this.setState({ failedSave: 'hidden' });
			history.push(`/editprojectpage?projectId=${projectId}&projectPageTarget=projectUpdates`);
		});
	}

	handlePostUpdate = () => {
		var updateDescription = this.state.updateDescription;
		const { projectId, updateId } = this.state;
		var error = false;
		const path = `/editprojectpage?projectId=${projectId}&projectPageTarget=projectUpdates`;

		error = this.removeWhiteSpaces(tinyMCE.activeEditor.getContent({ format: 'text' })).length <= 1;
		this.setState({ failedSave: error ? 'visible_block' : 'hidden' });

		if (!error) {
			if (!this.state.updateId) {
				HTTP.post(`/project/${projectId}/updates`, { text: updateDescription }, (error, body) => {
					if (error) {
						return this.setState({failedSave: 'visible_block'});
					}

					this.setState({failedSave: 'hidden'});
					history.push(path);
				});

				return;
			}

			HTTP.patch(`/project/${projectId}/update/${updateId}`, { text: updateDescription }, (error,body) => {
				if (error) {
					return this.setState({ failedSave: 'visible_block' });
				}
				this.setState({ failedSave: 'hidden' });
				history.push(path);
			});
		}
	}

	render = () => {
		const buttonTitle = this.state.updateId ? 'Save Update' : 'Post Update';
		const projectId = getLocationSearchParam('projectId');
		var postOrEditString = "Post";
		if (this.state.editUpdate === true) {
			postOrEditString = "Edit";
		}
		var shouldRenderTinyMCE;
		
		if (this.state.updateDescription === '' && this.state.updateId !== '') {
			shouldRenderTinyMCE = false;
		} else {
			shouldRenderTinyMCE = true;
		}
		
		return (
			<div className="viewport_width">
				<NavBarNotifications
					showLoginButton={false}
					showSignupButton={false}
				 />
				<div>
					<div className="post_updatebox">
						<div className="post_update_title_box">
							<p className="project_titles">{postOrEditString} an update: ({CONSTANTS.errors.fileSizeUploadError})</p>
							<div className="clear"></div>
							<div className={this.state.failedSave + " error"} style={{textAlign:'center'}}>There was a problem with your submission.</div>
							<div className={this.state.errorFileTooBig + " error"} style={{textAlign:'center'}}>{CONSTANTS.errors.fileSizeUploadError}</div>
						</div>
						<div className="ckeditor_box">
							<div className="show_for_desktop">
							{ shouldRenderTinyMCE ?
								<TinyMCE
									initialValue = {this.state.updateDescription}
	    							init={{
								        plugins: 'autolink link image lists print preview',
								        toolbar: 'undo redo | bold italic | alignleft aligncenter alignright | link image',
								        menubar: false,
								        automatic_uploads: true,
								        images_upload_url: '/project/upload',
								        images_upload_base_path: '/uploads',
								        elementpath: false,
								        browser_spellcheck: true,
							    		width: 800,
							    		height: 600,
							    		max_width: 800,
							    		max_height: 1000,
							    		branding: false,
										content_css: "../../../css/KB_styles.css",
										file_browser_callback_types: 'image',
										file_picker_types: 'image',
										file_picker_callback: this.filePickerCallback,
							        }}
	    							onChange={this.handleEditorChange}
	    						/> : <p>Loading...</p>
	    					}
	    					</div>
	    					<div className="show_for_mobile">
							{ shouldRenderTinyMCE ?
								<TinyMCE
									initialValue = {this.state.updateDescription}
	    							init={{
								        plugins: 'autolink link image lists print preview',
								        toolbar: 'undo redo | bold italic | alignleft aligncenter alignright | link image',
								        menubar: false,
								        automatic_uploads: true,
								        images_upload_url: '/project/upload',
								        images_upload_base_path: '/uploads',
								        elementpath: false,
								        browser_spellcheck: true,
							    		width: 285,
							    		height: 600,
							    		max_width: 285,
							    		max_height: 600,
							    		branding: false,
										content_css: "../../../css/KB_styles.css",
										file_browser_callback_types: 'image',
										file_picker_types: 'image',
										file_picker_callback: this.filePickerCallback,
							        }}
	    							onChange={this.handleEditorChange}
	    						/> : <p>Loading...</p>
	    					}
	    					</div>
					    </div>
					    <div className="post_update_buttonbox">
					    	<button className="post_update_button02" onClick={this.handlePostUpdate}>{buttonTitle}</button>
							<QueryLink className="post_update_cancel_button" to={{ pathname:"/editprojectpage", query: { projectId: projectId, projectPageTarget: "projectUpdates" } }}>Cancel</QueryLink>
							<div className={this.state.deleteButton}>
								<button className="delete_update_button" onClick={this.handleDeleteUpdate}>Delete Update</button>
							</div>
						</div>
					</div>
				</div>
				<div className="bottom_padding" style={{paddingBottom: '4.5em'}}></div>
				<Footer />
			</div>
		);
	}
};
