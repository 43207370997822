import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
//import DocumentTitle from 'react-document-title';

import history from 'app/history';
import HTTP from '../../utils/Http';
import { getLocationSearchParam } from 'utils/Utils';
import { login } from '_redux/actions';

import NavBarNotifications from '../nav/NavBar_Notifications.jsx';
import Footer from '../footer/Footer.jsx';

class LogInPage extends React.Component {
	state = {
		email: '',
		password: '',
		stayLoggedIn: false,
		loginErrorText: '',
		loginError: 'hidden',
		comingFromPage: ''
	};

	UNSAFE_componentWillMount() {
		if (this.props.loggedIn) {
			history.push('/');
		}

		const comingFromPage = getLocationSearchParam('comingFromPage');
		this.setState({comingFromPage: comingFromPage});
	}

	handleEmailChange = e => {
		this.setState({ email: e.target.value });
	}

	handlePasswordChange = e => {
		this.setState({ password: e.target.value });
	}

	OnStayLoggedInChange = e => {
		this.setState({ stayLoggedIn: !this.state.stayLoggedIn });
	}

	handleForgotPassword = () => {
		history.push('/passwordresetsend');
	}

	onEnterKeyPressed = (e) => {
		if (e.key === 'Enter') {
			this.handleLogIn();
		}
	}

	handleLogIn = () => {
		HTTP.post('/profile/login', { email: this.state.email, password: this.state.password, stayLoggedIn: this.state.stayLoggedIn }, (error, body) => {
			if (error) {
				const loginErrorText = body.split(":");
				return this.setState({ loginErrorText: loginErrorText[1], loginError: 'visible' });
			}

			this.props.dispatch(login());
			USER = body;
			// setTimeout(() => {
				if(this.state.comingFromPage === "signupconfirm"){
					history.push('/signupbaseskills');
				} else if (this.state.comingFromPage === "teammanageinvites"){
					history.push('/teammanageinvites');
				} else {
					history.push('/useraccount');
				}
			// }, 1000);
		});
	}

	render = () => {
		return (
			
				<div className="viewport_width">
					<NavBarNotifications
						showLoginButton={false}
						showSignupButton={true}
					/>
					<div className="clear"></div>
						
					<div id="login_box">

						<div className="show_for_mobile">
							<div className="text_style" style={{float:'left', marginLeft:'0.5em'}}>Email:</div>
						</div>
						<div className="clear"></div>
						<input className="login_email" type="email" value={this.state.email} onChange={this.handleEmailChange}/>
						
						<div className="show_for_desktop">
							<div className="text_style">Email:</div>
						</div>

						<br/><br/><br/>
						<div className="show_for_mobile">
							<div className="text_style" style={{float:'left', marginLeft:'0.5em'}}>Password:</div>
						</div>
						<div className="clear"></div>
						<input className="login_password" type="password" value={this.state.password} onChange={this.handlePasswordChange} onKeyPress={this.onEnterKeyPressed}/>
						
						<div className="show_for_desktop">
							<div className="text_style">Password:</div>
						</div>
						
						<br/>
					</div>
					<div className="staylogin">
						<p className="forgot_password_text"onClick={this.handleForgotPassword}><u>Forgot your password?</u></p><br/>
						<input type="checkbox" value="staylogin" onChange={this.OnStayLoggedInChange} defaultChecked={this.state.stayLoggedIn}/>
						<p>Stay signed in</p>
						<div className="clear"></div>
						<div className={"error " + this.state.loginError} style={{float: 'right', marginTop: "1em", marginRight: "1.6em"}}>{this.state.loginErrorText}</div>
						<div className="clear"></div>
						<button className="login_button" onClick={this.handleLogIn}>Login</button>
					</div>
						
					<div className="clear"></div>
					<div className="bottom_padding"></div>
					<Footer />
				</div>
			
		);
	}
};

export default connect(({ loggedIn }) => ({ loggedIn }))(LogInPage);
