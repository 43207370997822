import React from 'react';
import NavBarNotifications from '../nav/NavBar_Notifications.jsx';
import Footer from '../footer/Footer.jsx';
import PropTypes from 'prop-types';

export default class Contact extends React.Component {
	static contextTypes = {
		router: PropTypes.object
	}

	render = () => {
		return(
			<div className="viewport_width">
				<NavBarNotifications
					showLoginButton={true}
					showSignupButton={true}
				/>
					<div className="signup_hero" style={{textAlign: "center"}}>
						<img src="images/kb_hero.svg" style={{width: "150px", height: "150px", marginTop: "2em"}}/>
					</div>

					<div className="clear"></div>

					<div>
						<div className="signup_container">
							<div className="not_found_container">
								<h4>Have a problem, question, or suggestion?</h4>
								<p>Our users are important to us, and we want to hear from you. Send
								 an email to contact@kreatorbus.com, and we'll get back to you as
								 soon as we can.</p>
							</div>
						</div>
					</div>
				<div className="bottom_padding"></div>
				<Footer />
			</div>
		);
	}
};
