import React, {Component} from 'react';
import {SortableContainer, SortableElement, arrayMove} from 'react-sortable-hoc';
import PropTypes from 'prop-types';

const SortableItem = SortableElement(({value}) => <li>{value}</li>);

const SortableList = SortableContainer(({items}) => {
	return (
		<ul>
			{items.map((value, index) =>
                <SortableItem key={`item-${index}`} index={index} value={value} />
            )}
		</ul>
	);
});

class SortableSchoolList extends Component {
	static propTypes = {
        schoolList: PropTypes.array,
        onSchoolDrag: PropTypes.func,
        handleAttendingChange: PropTypes.func,
        deleteSchool: PropTypes.func,
    }

	state = {
        items: [],
        schoolList: this.props.schoolList
   	}


    onSortEnd = ({oldIndex, newIndex}) => {
        this.setState({
            schoolList: arrayMove(this.state.schoolList, oldIndex, newIndex)
        });
        this.props.onSchoolDrag(this.state.schoolList);

    }

    UNSAFE_componentWillReceiveProps(nextProps) {
    	this.setState({schoolList: nextProps.schoolList});
    }

    render() {
   		var attendingFunction = this.props.handleAttendingChange;
   		var deleteSchoolFunction = this.props.deleteSchool;
		var generatedSchoolList = this.state.schoolList.map(function(schoolObject) {
			var randomNumber = Math.floor(Math.random()*10000);
			var schoolName = schoolObject.school.names[0];
			return (
    			<div className="school_list_item" key={randomNumber}>
					<div className='school_input_box'>
						<input type="checkbox" defaultChecked={schoolObject.attending} id={schoolObject.school._id} onChange={(event) => attendingFunction(schoolObject.school._id, true, event)}/>
					</div>
					<div style={{float: "left", marginLeft: "-1em"}}>{schoolName}</div><button onClick={(event) => deleteSchoolFunction(schoolObject.school._id, true, event) } id={schoolName}>delete</button><br/>
				</div>
    		);
		});
        return (
            <SortableList
            	helperClass='school_list_draggable'
            	transitionDuration={500}
            	items={generatedSchoolList}
            	onSortEnd={this.onSortEnd}
            	lockAxis="y"
            	lockOffset="20px"
            />
        )
    }
};

export default SortableSchoolList;
