// A profile element is the answer to the signup questions the users gave such as bio, future, and fictional.
// This is used by the EditProfileDetail module to create the elements with an edit button on the side of it.

import React from 'react';
import PropTypes from 'prop-types';

const placeholderContent = [
	'This can be anything from something you did as a child to a recent accomplishment. What’s important is describing how you achieved your goal, and why you are so proud of this achievement.',
	'Here is the perfect place to describe yourself. Don’t know what to write? You can start off by listing details of your skills and experience. But don’t stop there. You might write about where you grew up, hobbies that you have, your life philosophies, what you look forward to the most everyday, anything that will give people a better idea of who you are as a person. The best teams have teammates who like working together, so it’s important to let your personality shine through.',
	'What do you hope to accomplish in the future?  This could be the next few months or the next few years.',
	'Here’s your chance to be creative. Have some fun with this one and let people know why your chosen fictional character would best represent you. This could be a character from books, movies, television, etc.',
	'Let people know how you spend your free time.'
];

export default class EditProfileElement extends React.Component {
	static propTypes = {
			text: PropTypes.string,
		id: PropTypes.number,
		errorBioEmpty: PropTypes.string,
		handleSaveElement: PropTypes.func,
		icon: PropTypes.string,
		title: PropTypes.string,
		saveError: PropTypes.string
	}

	state = {
		textAreaVisibility: 'hidden',
		textInputVisibility: 'visible',
		text: this.props.text,
		placeholder: '',
		errorBioEmpty: 'hidden'
	}

	UNSAFE_componentWillMount = () => {
		this.setState({
			placeholder: placeholderContent[this.props.id] || '',
			...(this.props.errorBioEmpty === 'visible' && this.props.id === 1 && { errorBioEmpty: 'visible' })
		});
	}

	handleTextChange = e => {
		this.setState({ text: e.target.value });
	}

	handleSaveElement = () => {
		const textToBeSaved = this.state.text.trim();

		this.props.handleSaveElement(this.props.id, textToBeSaved);
		if(this.props.id === 1 && textToBeSaved === ''){
			this.setState({text: USER.bio});
		}
	}

	handleCancelSave = () => {
		this.setState({
			textAreaVisibility: 'hidden',
			textInputVisibility: 'visible',
			text: this.props.text
		});
	}

	UNSAFE_componentWillReceiveProps = nextProps => {
		this.setState({
			textAreaVisibility: nextProps.error,
			textInputVisibility: nextProps.error === 'visible' ? 'hidden' : 'visible',
			...(this.props.id === 1 && { errorBioEmpty: nextProps.errorBioEmpty })
		});
	}

	handleEditElement = () => {
		this.setState({
			textAreaVisibility: 'visible',
			textInputVisibility: 'hidden'
		});
	}

	render = () => {
		return (
			<div className="user_profile_element">
				<div className="clear"></div>
				<img src={this.props.icon} />
				<div className="show_for_mobile_block" style={{clear:'both', marginBottom:'0.5em'}}></div>
				<h3 style={{float: 'left'}}>{this.props.title}</h3>
				<img className="edit_button" src="/images/edit_icon.svg" onClick={this.handleEditElement} style={{ cursor: 'pointer', marginTop: '0.2em' }}/>
				<div className={"error " + this.state.errorBioEmpty} style={{float: 'left'}}>{CONSTANTS.errors.editBioError}</div>
				<div className="clear"></div>
				<div className="user_profile_button_box">
					<button className={this.state.textAreaVisibility} onClick={this.handleSaveElement} style={{ cursor: 'pointer', paddingLeft:'0em' }}>Save</button>
					<button className={this.state.textAreaVisibility} onClick={this.handleCancelSave} style={{ cursor: 'pointer' }}>Cancel</button>
				</div>
				<div className="clear"></div>
				<div className="textareabox">
					<div className={"error " + this.props.saveError}>There was a problem saving your content. </div>
					<div className='clear'></div>
					<p className={this.state.textInputVisibility} >{this.state.text}</p>
					<textarea className={this.state.textAreaVisibility} rows="10" value={this.state.text} onChange={this.handleTextChange} placeholder={this.state.placeholder}/>
				</div>
			</div>
		);
	}
};