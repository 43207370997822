// This is the project page that appears when the user is about to submit a project but needs to review it first.

import React from 'react';
import ProjectTopBox from './ProjectTopBox.jsx';
import ProjectDetailBox from './ProjectDetailBox.jsx';
import PropTypes from 'prop-types';

export default class ProjectMasterReview extends React.Component {
	static propTypes = {
		projectKreator: PropTypes.object,
		projectId: PropTypes.string,
		projectName: PropTypes.string,
		tagline: PropTypes.string,
		tagline: PropTypes.string,
		category: PropTypes.number,
		leaderRoleSummary: PropTypes.string,
		roleList: PropTypes.array,
		logo: PropTypes.string,
		videoLink: PropTypes.string,
		teammateEquity: PropTypes.bool,
		startDate: PropTypes.object,
		projectStatus: PropTypes.string,
		projectCourse: PropTypes.string,
		projectZipcode: PropTypes.string,
		editProjectButtonDisplay: PropTypes.bool,
		projectDescription: PropTypes.string,
		projectMission: PropTypes.string,
		profitPlan: PropTypes.string,
		competition: PropTypes.string,
		targetMarket: PropTypes.string,
		futureBusiness: PropTypes.bool
	}
	
	render = () => {
		return (
			<div>
				<ProjectTopBox
					key={this.props.projectId}
					projectKreator = {this.props.projectKreator}
					projectId = {this.props.projectId}
					projectName = {this.props.projectName}
					tagline = {this.props.tagline}
					leaderRoleSummary = {this.props.leaderRoleSummary}
					category = {this.props.category}
					roleList = {this.props.roleList}
					logo = {this.props.logo}
					videoLink = {this.props.videoLink}
					teammateEquity = {this.props.teammateEquity}
					startDate = {this.props.startDate}
					projectStatus = {this.props.projectStatus}
					projectCourse = {this.props.projectCourse}
					projectSchool = {this.props.projectSchool}
					projectCountryCode = {this.props.projectCountryCode}
					projectZipcode = {this.props.projectZipcode}
					projectLocation = {this.props.projectLocation}
					editProjectButtonDisplay = {this.props.editProjectButtonDisplay}
					isProjectReviewPage = {true}
				/>
				<div className="projectnav_buttonbox">
					<button name="details">Details</button>
					{/*<button name="updates">Updates</button>*/}
					{/*<button name="teammembers">Team Members</button>*/}
				</div>
				<div className="project_bottombox">
					<ProjectDetailBox
						projectDescription = {this.props.projectDescription}
						projectMission = {this.props.projectMission}
						profitPlan = {this.props.profitPlan}
						competition = {this.props.competition}
						targetMarket = {this.props.targetMarket}
						futureBusiness = {this.props.futureBusiness}
					/>
				</div>
			</div>
		);
	}
};