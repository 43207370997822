//CURRENTLY NOT BEING USED BUT COULD BE USEFUL IN FUTURE.
import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { login } from '_redux/actions';
import history from 'app/history';
import HTTP from '../../utils/Http';

import NavBarNotifications from '../nav/NavBar_Notifications.jsx';
import ProgressBar from './ProgressBar.jsx';
import Footer from '../footer/Footer.jsx';
import EditProfileDetail from '../useraccount/EditProfileDetail.jsx';

class SignupReview extends React.Component {
	state = {
		profileCompleteness: USER ? USER.profileCompleteness : 0,
		errorHoursPerWeek: 'hidden',
		errorTeamOrProject: 'hidden',
		errorTravelRadius: 'hidden',
		errorWhatToGain: 'hidden',
		errorInterests: 'hidden',
		errorExpertises: 'hidden',
		errorBioEmpty: 'hidden',
		errorPhoto: 'hidden',
		errorSchools: 'hidden',
		failedSubmit: 'hidden',
		failedSubmitErrorText: "",
		showSubmitButton: true,
		showMyAccountButton:false
	};

	componentDidMount = () => {
		if (this.props.loggedIn) {
			if(USER.photo === '') {
			this.setState({errorPhoto: 'visible'});
			}
			if(USER.additionalInfo.hoursPerWeek === -1) {
				this.setState({errorHoursPerWeek: 'visible'});
			}
			if(USER.additionalInfo.teamOrProject === -1) {
				this.setState({errorTeamOrProject: 'visible'});
			}
			if(USER.additionalInfo.travelRadius === -1) {
				this.setState({errorTravelRadius: 'visible'});
			}
			// if(USER.additionalInfo.whatToGain === -1) {
			// 	this.setState({errorWhatToGain: 'visible'});
			// }
			if(USER.interests.length === 0) {
				this.setState({errorInterests: 'visible'});
			}
			if(USER.baseSkills.length === 0) {
				this.setState({errorExpertises: 'visible'});
			}
			if(USER.bio === '') {
				this.setState({errorBioEmpty: 'visible'});
			}
			if(USER.schools.length === 0) {
				this.setState({errorSchool: 'visible'});
			}
		}

		if(USER.underReview || USER.published){
			this.setState({
				showSubmitButton:false,
				showMyAccountButton:true
			});
		}

		this.setState({failedSubmitErrorText: "There was a problem with your submission. Please check all fields and try submitting again."});
	}

	handleEditProfileDetailCreation = () => {
		const { profileCompleteness, ...state } = this.state

		return USER ? <EditProfileDetail {...state} /> : null;
	}

	handleGoBackToMyAccount = () => {
		history.push('/useraccount');
	}

	handleSubmit = e => {
		e.preventDefault();
		var error = false;

		if (USER.photo === '') {
			this.setState({ failedSubmit: 'visible' });
			error = true;
		}
		if (USER.additionalInfo.hoursPerWeek === -1) {
			this.setState({ failedSubmit: 'visible' });
			error = true;
		}
		if (USER.additionalInfo.teamOrProject === -1) {
			this.setState({ failedSubmit: 'visible' });
			error = true;
		}
		if (USER.additionalInfo.travelRadius === -1) {
			this.setState({ failedSubmit: 'visible' });
			error = true;
		}
		// if (USER.additionalInfo.whatToGain === -1) {
		// 	error = true;
		// 	this.setState({ failedSubmit: 'visible' });
		// }
		if (USER.interests.length == 0) {
			error = true;
			this.setState({ failedSubmit: 'visible' });
		}
		if (USER.baseSkills.length === 0) {
			error = true;
			this.setState({ failedSubmit: 'visible' });
		}
		if (USER.bio === '') {
			error = true;
			this.setState({ failedSubmit: 'visible', errorBioEmpty: 'visible' });
		}

		if (!error) {
			this.submitProfile();
		}
	}

	submitProfile = () => {
		HTTP.post('/profile/published', { shouldPublish: true }, (error, body) => {
			if (error) {
				return this.setState({ failedSubmit: 'visible', failedSubmitErrorText: body});
			}

			this.setState({
				failedSubmit: 'hidden'
			});

			USER.underReview = true;
			history.push('/signupfinish');
		});
	}

	render = () => {
		return (
			<div className="viewport_width">
				<NavBarNotifications
					showLoginButton={false}
					showSignupButton={false}
				/>
				<div className="clear" style={{marginTop: '1em'}}></div>

				<div className="show_for_desktop">
					<div className="signup_progressbar" style={{width:'56em'}}>
						<ProgressBar progress={this.state.profileCompleteness} width="56em"/>
					</div>
				</div>

				<div className="show_for_mobile">
					<div className="signup_progressbar" style={{width:'19em'}}>
						<ProgressBar progress={this.state.profileCompleteness} width="18em"/>
					</div>
				</div>

				<div className="clear"></div>

				<div className="reviewbox">
					<h4>Almost there!</h4>
					<p>
						Here is what your profile will look like to other kreators. Make sure it gives
						people a sense of who you are as a person. At KreatorBus, we feel this is extremely
						important in the team building process. You don't have to answer every question,
						but we may ask you for more information before publishing your profile if we feel it
						does not have sufficient details (e.g. answering questions with only one word or
						sentence). You can come back any time to update it or fill out a section you may have
						skipped. Remember, having a more complete profile will better your chances of finding
						the right teammates.
					</p>
				</div>
				<div style={{pointerEvents: "none"}}>
					{this.handleEditProfileDetailCreation()}
				</div>
				<div className="clear"></div>

				<div className="reviewnav">
					<p className={"error fontsize_onePointOne " + this.state.failedSubmit} style={{float: 'right'}}> {this.state.failedSubmitErrorText}</p>
					<form>
						<div className="signup_navigation">
							<div className="show_for_desktop">
								<div className={this.state.showSubmitButton ? 'visible' : 'hidden'}><input className="submit_button" name="submit" type="submit" onClick={this.handleSubmit} value="Submit For Review"/></div>
							</div>
							<div className="show_for_mobile">
								<div className={this.state.showSubmitButton ? 'visible' : 'hidden'}><input className="submit_button" name="submit" type="submit" onClick={this.handleSubmit} value="Submit"/></div>
							</div>
							<div className={this.state.showMyAccountButton ? 'visible' : 'hidden'}><input className="submit_button" name="submit" type="submit" onClick={this.handleGoBackToMyAccount} value="My Account"/></div>
							<Link to="/signupweekend"><input className="backnav" style={{width: '4em'}} name="submit" type="submit" value="< Back"/></Link>
						</div>
					</form>
				</div>
				<div className="bottom_padding"></div>
				<Footer />
			</div>
		);
	}
};

export default connect(({ loggedIn }) => ({ loggedIn }))(SignupReview);