// This is the editable main profile module for users that holds all their information.

import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { QueryLink } from 'utils/Utils';
import HTTP from '../../utils/Http';
import history from 'app/history';

import ProfileLink from '../profiles/ProfileLink.jsx';
// import ProfilePortfolioImage from '../profiles/ProfilePortfolioImage.jsx';
import EditProfileElement from './EditProfileElement.jsx';

const baseSkillImageNames = ['business', 'artist', 'scribe', 'engineer'];
const profileElementNames = ['achievement', 'bio', 'futureGoals', 'fictional', 'weekend'];

export default class EditProfileDetail extends React.Component {
	static propTypes = {
		errorHoursPerWeek: PropTypes.string,
		errorTeamOrProject: PropTypes.string,
		errorTravelRadius: PropTypes.string,
		errorWhatToGain: PropTypes.string,
		errorInterests: PropTypes.string,
		errorExpertises: PropTypes.string,
		errorPhoto: PropTypes.string,
		errorSchools: PropTypes.string,
		handleProfileCompletenessChange: PropTypes.func
	};

	state = {
		firstName: USER.firstName,
		lastName: USER.lastName,
		email: USER.email,
		zipCode: USER.zipCode,
		location: USER.location,
		baseSkills: USER.baseSkills,
		expertises: USER.expertises,
		expertisesOther: USER.expertisesOther,
		skills: USER.skills,
		achievement: USER.achievement,
		schools: USER.schools,
		courses: USER.courses,
		bio: USER.bio,
		interests: USER.interests,
		photo: USER.photo ? `/uploads/${USER.photo}` : '/images/add_photo_edit.svg',
		additionalInfo: USER.additionalInfo,
		futureGoals: USER.futureGoals,
		portfolio: USER.portfolio,
		fictional: USER.fictional,
		weekend: USER.weekend,
		projectsCreated: USER.projectsCreated,
		errorBioEmpty: 'hidden',
		errorbio: 'hidden',
		errorachievement: 'hidden',
		errorfutureGoals: 'hidden',
		errorfictional: 'hidden',
		errorweekend: 'hidden',
		errorHoursPerWeek: this.props.errorHoursPerWeek,
		errorTeamOrProject: this.props.errorTeamOrProject,
		errorTravelRadius: this.props.errorTravelRadius,
		errorWhatToGain: this.props.errorWhatToGain,
		errorInterests: this.props.errorInterests,
		errorExpertises: this.props.errorExpertises,
		errorPhoto: this.props.errorPhoto,
		errorProfilePublish: 'hidden',
		errorSchools: this.props.errorSchools,
		displayCourses: USER.courses.length > 0 ? "visible" : "hidden"
	};

	UNSAFE_componentWillMount = () => {

		if (USER.bio === '') {
			this.setState({
				errorBioEmpty: 'visible',
				errorProfilePublish: 'visible'
			});
		}

		const errorProfilePublish =
			this.props.errorHoursPerWeek === 'visible' ||
			this.props.errorTeamOrProject === 'visible' ||
			this.props.errorTravelRadius === 'visible' ||
			this.props.errorWhatToGain === 'visible' ||
			this.props.errorInterests === 'visible' ||
			this.props.errorExpertises === 'visible' ||
			this.props.errorPhoto === 'visible' ||
			this.state.errorBioEmpty === 'visible' || // @todo Why??
			this.props.errorSchools === 'visible';

		this.setState({
			errorProfilePublish: errorProfilePublish ? 'visible' : 'hidden'
		});
	}

	UNSAFE_componentWillReceiveProps = nextProps => {
		const errorProfilePublish =
			nextProps.errorHoursPerWeek === 'visible' ||
			nextProps.errorTeamOrProject === 'visible' ||
			nextProps.errorTravelRadius === 'visible' ||
			nextProps.errorWhatToGain === 'visible' ||
			nextProps.errorInterests === 'visible' ||
			nextProps.errorExpertises === 'visible' ||
			nextProps.errorPhoto === 'visible' ||
			this.state.errorBioEmpty === 'visible' || // @todo Why??
			nextProps.errorSchools === 'visible';

		this.setState({
			errorHoursPerWeek: nextProps.errorHoursPerWeek,
			errorTeamOrProject: nextProps.errorTeamOrProject,
			errorTravelRadius: nextProps.errorTravelRadius,
			errorWhatToGain: nextProps.errorWhatToGain,
			errorInterests: nextProps.errorInterests,
			errorExpertises: nextProps.errorExpertises,
			errorPhoto: nextProps.errorPhoto,
			errorSchools: nextProps.errorSchools,
			errorProfilePublish: errorProfilePublish ? 'visible' : 'hidden'
		});
	}

	generateSchools = () => {
		var schools = this.state.schools;
		var i
		for(i = 0; i < schools.length; i++){
			if(schools[i].attending == true){
				var schoolObject = schools[i];
				schools.splice(i, 1);
				schools.splice(0, 0, schoolObject);
			}
		}

		return schools.map(schoolObject => (
			<div key={schoolObject.school._id}>
				<div className={`middle_dot ${schoolObject.attending ? "visible_block" : "hidden"}`}>&middot;</div>{schoolObject.school.names[0]}
			</div>
		));
	}

	generateCourses = () => {
		var courses = this.state.courses;
		var coursesNameArray = [];

		courses.forEach(function(course){
			coursesNameArray.push(course.name);
		});
		var listOfCourses = coursesNameArray.join(', ');
		return listOfCourses;
	}

	generateBaseSkill = baseSkill => {
		return <img key={baseSkill} src={`/images/${baseSkillImageNames[baseSkill] || 'unknown'}.svg`} />
	}

	generateExpertises = () => {
		var listOfExpertiseStrings = USER.expertises
			.concat(USER.expertisesOther.map(expertise => expertise.text))
			.join(', ');

		if (listOfExpertiseStrings.length > 55) {
			var expListSubstr = listOfExpertiseStrings.substr(0, 55);
			return expListSubstr.substr(0, expListSubstr.lastIndexOf(',')) + '...';
		}

		return listOfExpertiseStrings;
	}

	generateSkills = () => {
		return USER.skills.join(', ');
	}

	generateInterests = () => {
		return USER.interests
			.map(interest => (CONSTANTS.projectCategories[interest] || {}).text || '')
			.join(', ');
	}

	generateProfileLinkList = portfoliolink => {
		return (
			<ProfileLink key={portfoliolink} link={portfoliolink} />
		);
	}

	generateProjectList = () => {
		return this.state.projectsCreated.map(project => (
			<div key={project._id}>
				<li><QueryLink to={{ pathname:'/projectmainpage', query: { projectId: project._id } }}>{project.name}</QueryLink></li>
			</div>
		));
	}

	isValidProfileElementId = id => {
		return id > -1 && id < profileElementNames.length;
	}

	generateElementList = () => {
		return [
			{ key: 0, icon: 'images/achievement_icon.svg', title: 'Proudest Achievement', text: this.state.achievement },
			{ key: 1, icon: 'images/bio_icon.svg', title: 'Personal Bio', text: this.state.bio },
			{ key: 2, icon: 'images/future_icon.svg', title: 'Future Goals', text: this.state.futureGoals },
			{ key: 3, icon: 'images/fictional_icon.svg', title: 'Fictional Character', text: this.state.fictional },
			{ key: 4, icon: 'images/weekend_icon.svg', title: 'Typical Weekend', text: this.state.weekend }
		].map(profileElement => {
			const id = profileElement.key;
			const error = this.isValidProfileElementId(id) ? 'hidden' : this.state[`error${profileElementNames[id]}`];

			// Used to set save error states for each of the elements.
			var saveError = "";
			switch (profileElement.key) {
				case 0:
					saveError = this.state.errorachievement;
					break;
				case 1:
					saveError = this.state.errorbio;
					break;
				case 2:
					saveError = this.state.errorfutureGoals;
					break;
				case 3:
					saveError = this.state.errorfictional;
					break;
				case 4:
					saveError = this.state.errorweekend;
					break;
				default:
					break;
			}
			return (
				<EditProfileElement
					id={profileElement.key}
					{...profileElement}
					error={error}
					saveError={saveError}
					handleSaveElement={this.handleSaveElement}
					errorBioEmpty={this.state.errorBioEmpty}
				/>
			);
		});
	}

	handleSaveElement = (id, text) => {
		if (id === 1 && text === ''){
			return
		}

		const postToServer = url => {
			HTTP.post('/profile/' + url, { [url]: text }, (error, body) => {
				if (error) {
					return this.setState({ ['error' + url]: 'visible' });
				}

				this.setState({
					['error' + url]: 'hidden',
					...(id === 1 && { errorBioEmpty: text === '' ? 'visible' : 'hidden' })
				});

				this.props.handleProfileCompletenessChange(body.profileCompleteness);
			});
		}

		if (this.isValidProfileElementId(id)) {
			const profileElementName = profileElementNames[id];

			this.setState({ [profileElementName]: text });
			postToServer(profileElementName);
			USER[profileElementName] = text;
		}
	}

	editSignup = e => {
		history.push(`/editsignup${e.target.attributes['data-key'].value}`);
	}

	editAccountSettings = () => {
		history.push('/accountsettings');
	}

	render = () => {
		return (
			<div>
				<div>
					<div className={this.state.errorProfilePublish}>
						<div className="error missing_field_error">
							<p>
							Please fill out any field with a red error sign next to it. Your profile&nbsp;
							<span className="show_for_desktop"> <br/> </span>
							cannot be submitted until these are filled out. Thanks!
							</p>
						</div>
					</div>
					<div className="user_profile_topbox">
						<div className="edit_profile_picbox">
							<div className="user_photo">
								<img src={this.state.photo} /><br/><br/>
							</div>
							<div className="clear"></div>
							<Link to="/editsignupphoto">Change Photo</Link>
							<div className="clear"></div>
							<div className={this.state.errorPhoto}>
								<p className="error" style={{marginTop: '0.5em'}}>{CONSTANTS.errors.editEmptyPhotoError}</p>
							</div>
							<div className="clear"></div>
							<div className="edit_user_p">
								<div className="text_style" style={{float: 'left'}}>{this.state.firstName} {this.state.lastName}</div>
								<img className="edit_button_02" src="/images/edit_icon.svg" onClick={this.editAccountSettings} style={{ cursor: 'pointer' }} data-key="basic" />
							</div>
						</div>
						<div className="user_profile_info">
							<span className="blacktext">Schools:&nbsp;&nbsp;&nbsp;</span>
							<div className="user_profile_edit_button">
								<img className="edit_button" src="/images/edit_icon.svg" onClick={this.editSignup} style={{ cursor: 'pointer' }} data-key="schools" />
							</div>
							<div className="clear"></div>
							<p className={"error " + this.state.errorSchools} style={{fontSize: "1em"}}>{CONSTANTS.errors.editEmptySchoolError}</p>
							{this.generateSchools()}
							<div className="clear" style={{marginBottom: "1em"}}></div>
							<span className={"blacktext " + this.state.displayCourses}>Courses:&nbsp;</span>
							{this.generateCourses()}
							<div className="clear" style={{marginBottom : "1em"}}></div>
							<span className="blacktext">Location:&nbsp;</span>
							{this.state.location}
							<div className="clear" style={{marginBottom : "1em"}}></div>
							<div className="clear"></div>
							<span className="blacktext">B.A.S.E. Skills:</span>
							<div className="user_profile_skills">
								{this.state.baseSkills.map(this.generateBaseSkill)}
							</div>
							<div className="clear"></div>
							<span className="blacktext">Expertises:&nbsp;&nbsp;&nbsp;</span>
								<div className="user_profile_edit_button">
									<img className="edit_button" src="/images/edit_icon.svg" onClick={this.editSignup} style={{ cursor: 'pointer' }} data-key="baseskills" />
								</div>
								<div className="clear"></div>
								<p className={"error " + this.state.errorExpertises} style={{fontSize: "1em"}}>{CONSTANTS.errors.editEmptyExpertiseError}</p>
							{this.generateExpertises()}
							<div className="clear" style={{marginBottom: "1em"}}></div>
							<span className="blacktext">Interests:&nbsp;&nbsp;&nbsp;</span>
								<div className="user_profile_edit_button">
									<img className="edit_button" src="/images/edit_icon.svg" onClick={this.editSignup} style={{ cursor: 'pointer' }} data-key="interests" />
								</div>
								<div className="clear"></div>
								<p className={"error " + this.state.errorInterests} style={{fontSize: "1em"}}>{CONSTANTS.errors.editInterestsError}</p>
							{this.generateInterests()}
							<div className="clear"></div>
						</div>
						<div className="user_profile_detailbox">
							{/*<div className="text_style">
								<span className="blacktext">Last Active:&nbsp;</span> days ago
							</div>
							<div className="clear"></div>*/}
							<div className="text_style">
								<span className="blacktext">Hours can work per week:&nbsp;</span>
								{CONSTANTS.additionalInfo.hoursPerWeek[this.state.additionalInfo.hoursPerWeek + 1].text}
							</div>
							<img className="edit_button" src="/images/edit_icon.svg" onClick={this.editSignup} style={{ cursor: 'pointer' }} data-key="addinfo" />
							<div className="clear"></div>
							<p className={"error " + this.state.errorHoursPerWeek} style={{float: 'left', marginTop: '-1em'}}>{CONSTANTS.errors.editHoursPerWeekError}</p>
							{/*<div className="clear"></div>
							<div className="text_style">
								<span className="blacktext">Looking for:&nbsp;</span>
								{CONSTANTS.additionalInfo.whatToGain[this.state.additionalInfo.whatToGain + 1].text}
							</div>
							<img className="edit_button" src="/images/edit_icon.svg" onClick={this.editSignup} style={{ cursor: 'pointer' }} data-key="addinfo" />*/}
							<div className="clear"></div>
							<p className={"error " + this.state.errorWhatToGain} style={{float: 'left', marginTop: '-1em'}}>{CONSTANTS.errors.editWhatToGainError}</p>
							<div className="clear"></div>
							<div className="text_style">
								<span className="blacktext">Willing to travel:&nbsp;</span>
								{CONSTANTS.additionalInfo.travelRadius[this.state.additionalInfo.travelRadius + 1].text}
							</div>
							<img className="edit_button" src="/images/edit_icon.svg" onClick={this.editSignup} style={{ cursor: 'pointer' }} data-key="addinfo" />
							<div className="clear"></div>
							<p className={"error " + this.state.errorTravelRadius} style={{float: 'left', marginTop: '-1em'}}>{CONSTANTS.errors.editTravelRadiusError}</p>
							<div className="clear"></div>
							<div className="text_style">
								<span className="blacktext">Wants to:&nbsp;</span>
								{CONSTANTS.additionalInfo.teamOrProject[this.state.additionalInfo.teamOrProject + 1].text}
							</div>
							<img className="edit_button" src="/images/edit_icon.svg" onClick={this.editSignup} style={{ cursor: 'pointer' }} data-key="addinfo" />
							<div className="clear"></div>
							<p className={"error " + this.state.errorTeamOrProject} style={{float: 'left', marginTop: '-1em'}}>{CONSTANTS.errors.editTeamOrProjectError}</p>
							<div className="text_style">
								<span className="blacktext" style={{float: 'left'}}>Skills:</span>
								<img className="edit_button" src="/images/edit_icon.svg" onClick={this.editSignup} style={{ cursor: 'pointer' }} data-key="baseskills" /><br/>
								{this.generateSkills()}
							</div>
						</div>
					</div>
				</div>

				<div className="clear"></div>

				<div className="user_profile_bottombox_holder">
					<div className="user_profile_bottombox">
						<br/><br/>
						<div className="left_bottombox">
							<img src="images/portfolio.svg" className="clear" style={{float: 'left'}} />
							<div className="text_style"> Portfolio:</div>
							<img className="edit_button" src="/images/edit_icon.svg" onClick={this.editSignup} style={{ cursor: 'pointer' }} data-key="portfolio" />
							<div className="clear"></div>
							<ul>
							{USER.portfolio.links.map(this.generateProfileLinkList)}
							</ul>
							<div className="clear"></div>
							<br/>
							<img src="/images/bus_icon_small.svg" className="clear" style={{float: 'left'}} />
							<div className="text_style">KreatorBus Projects:</div><br/>
							{/*Created:*/}
							<div className="clear"></div>
							<ul>
							{this.generateProjectList()}
							</ul>
							<div className="clear"></div>
							{/*Joined:*/}
						</div>
						<div className="right_bottombox">
							{this.generateElementList()}
						</div>
					</div>
				</div>
			</div>
		);
	}
};