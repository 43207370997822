import React from 'react';
import { Router, Route, Switch, Redirect } from 'react-router';
import { Provider } from 'react-redux'

import history from './history';
import store from '_redux/store';

import GoogleAnalytics from './GoogleAnalytics';

import BasePage from './components/BasePage.jsx';
import HomePage from './components/HomePage.jsx';
import HomePageUser from './components/HomePageUser.jsx';

import ScrollToTop from './components/ScrollToTop.jsx';

// Signup Components
import SignupBasic from './components/signup/SignupBasic.jsx';
import EmailVerify from './components/signup/EmailVerify.jsx';
import SignupConfirm from './components/signup/SignupConfirm.jsx';
import SignupBaseSkills from './components/signup/SignupBaseSkills.jsx';
import SignupAchievement from './components/signup/SignupAchievement.jsx';
import SignupSchools from './components/signup/SignupSchools.jsx';
import SignupBio from './components/signup/SignupBio.jsx';
import SignupInterests from './components/signup/SignupInterests.jsx';
import SignupAddInfo from './components/signup/SignupAddInfo.jsx';
import SignupFuture from './components/signup/SignupFuture.jsx';
import SignupPhoto from './components/signup/SignupPhoto.jsx';
import SignupPortfolio from './components/signup/SignupPortfolio.jsx';
import SignupFictional from './components/signup/SignupFictional.jsx';
import SignupWeekend from './components/signup/SignupWeekend.jsx';
import SignupReview from './components/signup/SignupReview.jsx';
import SignupFinish from './components/signup/SignupFinish.jsx';

// Create Project Components
import CreateProjectStart from './components/projects/CreateProjectStart.jsx';
import CreateProjectController from './components/projects/CreateProjectController.jsx';
import ProjectFinish from './components/projects/ProjectFinish.jsx';

// User Account Components
import ContactSettings from './components/useraccount/ContactSettings.jsx';
import UserAccount from './components/useraccount/UserAccount.jsx';
import EditProfilePage from './components/useraccount/EditProfilePage.jsx';
import EditProjectPage from './components/useraccount/EditProjectPage.jsx';
import PostUpdate from './components/useraccount/PostUpdate.jsx';
import Messages from './components/useraccount/Messages.jsx';
import TeamManagement from './components/useraccount/TeamManagement.jsx';
import TeamManageInvites	from './components/useraccount/TeamManageInvites.jsx';
import AccountSettings from './components/useraccount/AccountSettings.jsx';
import EmailChangeConfirmPage from './components/useraccount/EmailChangeConfirmPage.jsx';

// Project Page Components
import ProjectMainPage from './components/projects/ProjectMainPage.jsx';

// Profile Page Components
import ProfilePage from './components/profiles/ProfilePage.jsx';

// Admin Page Components
import AdminPage from './components/admin/AdminPage.jsx';

// MISC Components
import ProfileSearch from './components/profiles/ProfileSearch.jsx';
import ContactKreator from './components/profiles/ContactKreator.jsx';
import ProjectSearch from './components/projects/ProjectSearch.jsx';
import LogInPage from './components/useraccount/LogInPage.jsx';
import LogOutPage from './components/useraccount/LogOutPage.jsx';
import PasswordResetSend from './components/useraccount/PasswordResetSend.jsx';
import PasswordResetSignin from './components/useraccount/PasswordResetSignin.jsx';
import PasswordChange from './components/useraccount/PasswordChange.jsx';
import ImageCropper from './components/misc/ImageCropper.jsx';
import Page404 from './components/nav/404.jsx';
import TermsOfService from './components/TermsOfService.jsx';
import PrivacyPolicy from './components/PrivacyPolicy.jsx';
import Contact from './components/misc/Contact.jsx';
import About from './components/misc/About.jsx';
import ImageSuggestions from './components/misc/ImageSuggestions.jsx';
import NotificationsPageForMobile from './components/nav/NotificationsPageForMobile.jsx';

window.store = store;
function requireAuth(Component, props) {
	return function() {
		return store.getState().loggedIn ? <Component {...props} /> : <Redirect to='/loginpage' />;
	};
}

function requireAdmin(Component, props) {
	return function() {
		return store.getState().loggedIn && USER.admin ? <Component {...props} /> : <Page404 />;
	};
}

history.listen((location) => {
	if(window.ga){
		window.ga('set', 'page', location.pathname + location.search);
    	window.ga('send', 'pageview');
	}
    
});

export default (
	<Provider store={store}>
		<Router history={history}>
			<ScrollToTop>
				<div style={{height:'100%'}}>
					<Switch>
						<Route exact path="/" render={() => store.getState().loggedIn ? <HomePageUser /> : <HomePage />} />

						<Route path="/loginpage" component={LogInPage} />
						<Route path="/logoutpage" component={LogOutPage} />

						<Route path="/signupbasic" component={SignupBasic} />
						<Route path="/signupconfirm" component={SignupConfirm} />
						<Route path="/signupbaseskills" render={requireAuth(SignupBaseSkills)} />
						<Route path="/signupachievement" render={requireAuth(SignupAchievement)} />
						<Route path="/signupschools" render={requireAuth(SignupSchools)} />
						<Route path="/signupbio" render={requireAuth(SignupBio)} />
						<Route path="/signupinterests" render={requireAuth(SignupInterests)} />
						<Route path="/signupaddinfo" render={requireAuth(SignupAddInfo)} />
						<Route path="/signupfuture" render={requireAuth(SignupFuture)} />
						<Route path="/signupphoto" render={requireAuth(SignupPhoto)} />
						<Route path="/signupportfolio" render={requireAuth(SignupPortfolio)} />
						<Route path="/signupfictional" render={requireAuth(SignupFictional)} />
						<Route path="/signupweekend" render={requireAuth(SignupWeekend)} />
						<Route path="/signupreview" render={requireAuth(SignupReview)}/>
						<Route path="/signupfinish" render={requireAuth(SignupFinish)} />

						<Route path="/createprojectstart" component={CreateProjectStart} />
						<Route path="/createprojectcontroller" render={requireAuth(CreateProjectController)} />
						<Route path="/projectfinish" render={requireAuth(ProjectFinish)} />
						<Route path="/editprojectpage" render={requireAuth(EditProjectPage, { edit: true })} />
						<Route path="/teammatemanagement" render={requireAuth(TeamManagement)} />
						<Route path="/postupdate" render={requireAuth(PostUpdate)} />
						<Route path="/project/search" component={ProjectSearch}/>
						<Route path="/projectmainpage" component={ProjectMainPage} />
						
						<Route path="/contactsettings" render={requireAuth(ContactSettings)} />
						<Route path="/contactkreator" render={requireAuth(ContactKreator)} />
						<Route path="/useraccount" render={requireAuth(UserAccount)} />
						<Route path="/kreator/search" component={ProfileSearch}/>
						<Route path="/profilepage" component={ProfilePage} />
						<Route path="/notificationspage" render={requireAuth(NotificationsPageForMobile)}/>

						<Route path="/editprofilepage" render={requireAuth(EditProfilePage)} />
						<Route path="/editsignupbaseskills" render={requireAuth(SignupBaseSkills, { edit: true })} />
						<Route path="/editsignupschools" render={requireAuth(SignupSchools, { edit: true })} />
						<Route path="/editsignupinterests" render={requireAuth(SignupInterests, { edit: true })} />
						<Route path="/editsignupaddinfo" render={requireAuth(SignupAddInfo, { edit: true })} />
						<Route path="/editsignupportfolio" render={requireAuth(SignupPortfolio, { edit: true })} />
						<Route path="/editsignupphoto" render={requireAuth(SignupPhoto, { edit: true })} />
						<Route path="/accountsettings" component={requireAuth(AccountSettings)} />
						<Route path="/messages" render={requireAuth(Messages)} />
						<Route path="/teammanageinvites" render={requireAuth(TeamManageInvites)} />

						<Route path="/emailverify" render={requireAuth(EmailVerify)} />
						<Route path="/emailchangeconfirmpage" component={EmailChangeConfirmPage} />

						<Route path="/passwordresetsend" component={PasswordResetSend} />
						<Route path="/passwordresetsignin" component={PasswordResetSignin} />
						<Route path="/passwordchange" component={requireAuth(PasswordChange)} />

						<Route path="/termsofservice" component={TermsOfService} />
						<Route path="/privacypolicy" component={PrivacyPolicy} />

						<Route path="/admin" render={requireAdmin(AdminPage)} />

						<Route path="/pagenotfound" component={Page404} />
						<Route path="/contact" component={Contact} />
						<Route path="/about" component={About} />
						<Route path="/imagesuggestions" component={ImageSuggestions} />
						<Route component={Page404} />
					</Switch>
				</div>
			</ScrollToTop>

			<GoogleAnalytics />
		</Router>
	</Provider>
);
