// This is the first page a user is directed to when signing up.

import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { login } from '_redux/actions';
import history from 'app/history';
import HTTP from '../../utils/Http';
import { QueryLink } from 'utils/Utils';

import NavBarNotifications from '../nav/NavBar_Notifications.jsx';
import Footer from '../footer/Footer.jsx';


class SignupBasic extends React.Component {
	state = {
		errFirstName: 'hidden',
		errLastName: 'hidden',
		errEmail: 'hidden',
		errPassword: 'hidden',
		errPasswordConfirm: 'hidden',
		errZipCode: 'hidden',
		errSubmit: 'hidden',
		errCountryCode: 'hidden',
		validZip: false,
		firstName: '',
		lastName: '',
		email: '',
		countryCode:'',
		zipCode: '',
		password: '',
		passwordConfirmation: '',
		errorText: '',
		shouldDisplayZipcode: false
	};

	UNSAFE_componentWillMount = () => {
		if(this.props.loggedIn){
			history.push('/useraccount');
		}
	}

	generateCountryOptions = option => {
		return <option key={option.code} value={option.code}>{option.name}</option>
	}

	handleFirstNameChange = e => {
		this.setState({ firstName: e.target.value });
	}

	handleLastNameChange = e => {
		this.setState({ lastName: e.target.value });
	}

	handleZipCodeChange = e => {
		this.setState({ zipCode: e.target.value });
	}

	handleEmailChange = e => {
		this.setState({ email: e.target.value });
	}

	handlePasswordChange = e => {
		this.setState({ password: e.target.value });
	}

	handlePasswordConfirmationChange = e => {
		this.setState({ passwordConfirmation: e.target.value });
	}

	handleCountryChange = e => {

		this.setState({ countryCode: e.target.value, errCountryCode: 'hidden' });
		if(e.target.value === "US"){
			this.setState({shouldDisplayZipcode: true});
		} else {
			this.setState({shouldDisplayZipcode: false, zipCode: '', errZipCode: 'hidden'});
		}
	}

	onEnterKeyPressed = (e) => {
		if (e.key === 'Enter') {
			this.handleSubmit(e);
		}
	}

	checkFirstName = e => {
		var reg = /([A-Za-z0-9]){1,}/;
		const text = e.target.value;

		if (!reg.test(text)) {
			this.setState({ errFirstName: 'visible'});
		} else if (text) {
			this.setState({ errFirstName: 'hidden'});
		}
	}

	checkLastName = e => {
		var reg = /([A-Za-z0-9]){1,}/;
		const text = e.target.value;

		if (!reg.test(text)) {
			this.setState({ errLastName: "visible"});
		} else if (text) {
			this.setState({ errLastName: "hidden"});
		}
	}

	checkEmail = e => {
		var reg = /^[A-Za-z0-9_\-\.]{1,}\@[A-Za-z0-9_\-\.]{1,}\.[A-Za-z]{2,}$/;
		/*/.+\@.+\..+/.test(emailString);*/

		this.setState({ errEmail: reg.test(this.state.email) ? 'hidden' : 'visible' });
	}

	checkPassword = e => {
		var reg = /^.*(?=.{8,})(?=.*[a-zA-Z])(?=.*\d).*$/;

		this.setState({ errPassword: reg.test(this.state.password) ? 'hidden' : 'visible' });
	}

	checkPasswordConfirm = e => {
		this.setState({ errPasswordConfirm: this.state.password === this.state.passwordConfirmation ? 'hidden' : 'visible' });
	}

	checkCountryCode = e => {
		if (this.state.countryCode === ""){
			this.setState({errCountryCode: 'visible'});
		} else {
			this.setState({errCountryCode: 'hidden'});
		}
		
	}

	checkZipCode = e => {
		var reg = /^([0-9]){5,}$/;
		var zipCode = this.state.zipCode;

		if (reg.test(zipCode) == false) {
			return this.setState({ errZipCode: 'visible' });
		}

		this.setState({ errZipCode: 'hidden' });
	}

	handleSubmit = e => {
		e.preventDefault();

		var validSubmission = true;

		const firstName = this.state.firstName.trim();
		const lastName = this.state.lastName.trim();
		const email = this.state.email.trim();
		const countryCode = this.state.countryCode;
		const zipCode = this.state.zipCode.trim();
		const password = this.state.password.trim();
		const passwordConfirmation = this.state.passwordConfirmation.trim();

		const validFirstName = /([A-Za-z0-9]){1,}/.test(firstName);
		const validLastName = /([A-Za-z0-9]){1,}/.test(lastName);
		const validEmail = /^[A-Za-z0-9_\-\.]{1,}\@[A-Za-z0-9_\-\.]{1,}\.[A-Za-z]{2,}$/.test(email);
		const validPassword = /^.*(?=.{8,})(?=.*[a-zA-Z])(?=.*\d).*$/.test(password);
		const validZip = /^([0-9]){5,}$/.test(zipCode);

		var validCountryCode = true;

		if(countryCode === "" ){
			validCountryCode = false;
		}

		if (!validFirstName) {
			validSubmission = false;
			this.setState({ errSubmit: 'visible_block', errFirstName: 'visible_block'});
		}
		if (!validLastName){
			validSubmission = false;
			this.setState({ errSubmit: 'visible_block', errLastName: 'visible_block'});
		}
		if (!validEmail){
			validSubmission = false;
			this.setState({ errSubmit: 'visible_block', errEmail: 'visible_block'});
		}
		if (!validPassword){
			validSubmission = false;
			this.setState({ errSubmit: 'visible_block', errPassword: 'visible_block'});
		}
		if (password !== passwordConfirmation){
			validSubmission = false;
			this.setState({ errSubmit: 'visible_block', errPasswordConfirm: 'visible_block'});
		}

		if (!validCountryCode){
			validSubmission = false;
			this.setState({errSubmit: 'visible_block', errCountryCode: 'visible_block'});
		}

		if (!validZip) {
			if (countryCode === "US"){
				validSubmission = false;
				this.setState({ errSubmit: 'visible_block', errZipCode: 'visible_block'});
			}	
		}

		if(!validSubmission){
			return;
		}

		HTTP.post('/profile', { firstName: firstName, lastName: lastName, email: email, password: password, passwordConfirmation: passwordConfirmation, country: countryCode, zipCode: zipCode }, (error, body) => {
			if (error) {
				const errorText = body.split(":");
				return this.setState({
					errSubmit: 'visible_block',
					errorText: errorText[1]
				});
			} else {
				this.props.dispatch(login());
				USER = body;
				history.push('/emailverify');
			}

		});
	}

	render = () => {
		return(
			<div className="viewport_width">
				<NavBarNotifications
					showLoginButton={true}
					showSignupButton={false}
				/>

				<div className="signup_hero">
					<img src="images/signup_basic.png"/>
				</div>

				<div className="clear"></div>

				<div>
					<div className="signup_container">
						<p className={"failed_submit " + this.state.errSubmit}>{this.state.errorText} {CONSTANTS.errors.failedSaveLong}</p>
						<div className="basic_infobox">

							<div className="signup_inputbox">
								<div className="show_for_mobile">
									<div className="text_style" style={{float:'left'}}>First Name:</div>
									<span className={this.state.errFirstName + " error"} style={{margin: '0em 0em 0.5em 0em'}}>{CONSTANTS.errors.firstNameError}</span>
								</div>

								<input className="signup_input" type="text" placeholder="First Name" name="firstname" value={this.state.firstName} onChange={this.handleFirstNameChange} onBlur={this.checkFirstName}/>
								
								<div className="show_for_desktop">
									<div className="text_style">First Name:</div>
									<span className={this.state.errFirstName + " error"} style={{marginBottom: '1em'}}>{CONSTANTS.errors.firstNameError}</span>
								</div>
							</div>

							<div className="signup_inputbox">
								<div className="show_for_mobile">
									<div className="text_style" style={{float:'left'}}>Last Name:</div>
									<span className={this.state.errLastName + " error"} style={{margin: '0em 0em 0.5em 0em'}}>{CONSTANTS.errors.lastNameError}</span>
								</div>

								<input className="signup_input" type="text" placeholder="Last Name" name="lastname" value={this.state.lastName} onChange={this.handleLastNameChange} onBlur={this.checkLastName}/>
								
								<div className="show_for_desktop">
									<div className="text_style">Last Name:</div>
									<span className={this.state.errLastName + " error"} style={{marginBottom: '1em'}}>{CONSTANTS.errors.lastNameError}</span>
								</div>
							</div>

							<div className="signup_inputbox">
								<div className="show_for_mobile">
									<div className="text_style" style={{float:'left'}}>Email Address:</div>
									<span className={this.state.errEmail + " error"} style={{margin: '0em 0em 0.5em 0em'}}>{CONSTANTS.errors.emailError}</span>
								</div>
								
								<input className="signup_input" type="email" placeholder="Type in email" name="email" value={this.state.email} onChange={this.handleEmailChange} onBlur={this.checkEmail} />
								
								<div className="show_for_desktop">
									<div className="text_style">Email Address:</div>
									<span className={this.state.errEmail + " error"} style={{marginBottom: '1em'}}>{CONSTANTS.errors.emailError}</span>
								</div>
							</div>

							<div className="signup_inputbox">

								<div className="show_for_mobile">
									<div className="text_style" style={{float:'left'}}>Password:</div>
									<span className={this.state.errPassword + " error"} style={{margin: '0em 0em 0.5em 0em'}}>{CONSTANTS.errors.passwordComplianceError}</span>
								</div>

								<input className="signup_input" type="password" placeholder="Create a password" name="password" value={this.state.password} onChange={this.handlePasswordChange} onBlur={this.checkPassword} />
								
								<div className="show_for_desktop">
									<div className="text_style">Password:</div>
									<span className={this.state.errPassword + " error"} style={{marginBottom: '1em', marginTop: '-0.8em'}}>{CONSTANTS.errors.passwordComplianceError}</span>
								</div>
								<span className="subtitle" style={{float: 'left', marginBottom: '1em'}}>Password must be at least eight characters long and contain one letter and one number.</span>
							</div>

							<div className="signup_inputbox" style={{marginBottom: '0'}}>

								<div className="show_for_mobile">
									<div className="text_style" style={{float:'left'}}>Confirm Password:</div>
									<div className="clear"></div>
									<span className={this.state.errPasswordConfirm + " error"} style={{margin: '0em 0em 0.5em 0em'}}>{CONSTANTS.errors.passwordMatchError}</span>
								</div>

								<input className="signup_input" type="password" placeholder="Confirm password" name="confirm" value={this.state.passwordConfirmation} onChange={this.handlePasswordConfirmationChange} onBlur={this.checkPasswordConfirm}/>
								
								<div className="show_for_desktop">
									<div className="text_style">Confirm Password:</div>
									<div className="clear"></div>
									<span className={this.state.errPasswordConfirm + " error"} style={{marginBottom: '1em'}}>{CONSTANTS.errors.passwordMatchError}</span>
								</div>

							</div>

							<div className="signup_inputbox">

								<div className="show_for_mobile">
									<div className="text_style" style={{float:'left'}}>Country:</div>
									<span className={this.state.errCountryCode + " error"} style={{marginTop: '0em', marginBottom: '1em'}}>{CONSTANTS.errors.countryCodeError}</span>
								</div>

								<select value={this.countryCode} onChange={this.handleCountryChange} onBlur={this.checkCountryCode}>
									<option value="">Select Country</option>
									{CONSTANTS.countryData.map(this.generateCountryOptions)}
								</select>
							
								<div className="show_for_desktop">
									<div className="text_style">Country:</div>
									<span className={this.state.errCountryCode + " error"} style={{marginBottom: '1em'}}>{CONSTANTS.errors.countryCodeError}</span>
								</div>

							</div>
							
							<div className={`signup_inputbox${this.state.shouldDisplayZipcode ? ' visible' : ' hidden'}`}>

								<div className="show_for_mobile">
									<div className="text_style" style={{float:'left'}}>Zipcode:</div>
									<span className={this.state.errZipCode + " error"} style={{margin: '0em 0em 0.5em 0em'}}>{CONSTANTS.errors.zipCodeError}</span>
									<div className="clear"></div>
								</div>

								<input className="signup_zipcode" type="text" placeholder="5-digits" name="zipcode" maxLength="5" value={this.state.zipCode} onChange={this.handleZipCodeChange} onBlur={this.checkZipCode} onKeyPress={this.onEnterKeyPressed} />
								
								<div className="show_for_desktop">
									<div className="text_style">Zipcode:</div>
									<span className={this.state.errZipCode + " error"} style={{marginTop: '0em', marginBottom: '1em'}}>{CONSTANTS.errors.zipCodeError}</span>
								</div>
							</div>
							<div className="signup_termsbox">
								By signing up you agree to Kreatorbus &#8217; &nbsp;<br/>
								<QueryLink to={{ pathname: '/termsofservice' }}>Terms of Service</QueryLink>
								&nbsp;and&nbsp;
								<QueryLink to={{ pathname: '/privacypolicy' }}>Privacy Policy.</QueryLink>
							</div>
							<input className="submit" type="submit" value="Submit" onClick={this.handleSubmit}/>
						</div>
						<br/><br/><br/>
					</div>

					<div className="clear"></div>
					<div className="bottom_padding"></div>
				</div>
				<Footer />
			</div>
		);
	}
};

export default connect(({ loggedIn }) => ({ loggedIn }))(SignupBasic);

/*<div className="show_for_desktop">
	<span className="subtitle" style={{float: 'left', margin: '0em 0em 1em 10.4em'}}>Currently, only accepting residents living in the United States.</span>
</div>
<div className="show_for_mobile">
	<span className="subtitle" style={{float: 'left', margin: '0em 0em 1em 0em'}}>Currently, only accepting residents living in the United States.</span>
</div>*/