// A component used by the TeamManageInvites to show invitations received by the USER.
import React from 'react';
import { QueryLink } from 'utils/Utils';
import PropTypes from 'prop-types';

export default class TeamReceivedInvitation extends React.Component {
	static propTypes = {
		projectName: PropTypes.string,
		projectLeaderId: PropTypes.string,
		projectLeaderPhoto: PropTypes.string,
		projectLeaderFirstName: PropTypes.string,
		projectLeaderLastName: PropTypes.string,
		handleShowAcceptAlert: PropTypes.func,
		handleShowDeclineAlert: PropTypes.func
	}

	state = {
		descriptionVisibility: "visible",
		editDescriptionVisibility: "hidden"
	}

	render = () => {

		return (
				<div>
					<div className="invitation_box_holder">
						<div className="invitationbox">
							<QueryLink to={{ pathname:"/profilepage", query: { profileId: this.props.projectLeaderId }}}>
								<div className="invitation_picbox">
									<img src={"/uploads/" + this.props.projectLeaderPhoto} />
								</div>
							</QueryLink>
							<h4>{this.props.projectLeaderFirstName} {this.props.projectLeaderLastName}</h4>
							<p>asked you to join</p>
							<QueryLink to={{ pathname: '/projectmainpage', query: { projectId: this.props.projectId }}}>
								<h4>{this.props.projectName}</h4>
							</QueryLink>
							<div style={{marginTop: "1em"}}>
								<button style={{marginRight:'1em'}} onClick={ () => this.props.handleShowAcceptAlert(this.props.inviteId, this.props.projectName)} className="invitation_accept_button">accept</button>
								<button className="invitation_decline_button" onClick={ () =>this.props.handleShowDeclineAlert(this.props.inviteId, this.props.projectName)}>decline</button>
							</div>
						</div>
					</div>
				</div>
		);
	}
};