import React from 'react';
import PropTypes from 'prop-types';
import HTTP from '../../utils/Http';
import history from 'app/history';

import NavBarNotifications from '../nav/NavBar_Notifications.jsx';
import Footer from '../footer/Footer.jsx';

export default class PasswordResetSend extends React.Component {
	state = {
		email: '',
		sendPasswordEmailErrorText: '',
		sendPasswordEmailError: 'hidden',
		passwordSent: 'hidden',
		sendPassword: 'visible_block',
		errEmail: 'hidden'
	};

	handleEmailChange = e => {
		this.setState({
			email: e.target.value,
			errEmail: "hidden"
		});
	}

	checkEmail = e => {
		var reg = /^[A-Za-z0-9_\-\.]{1,}\@[A-Za-z0-9_\-\.]{1,}\.[A-Za-z]{2,}$/;
		/*/.+\@.+\..+/.test(emailString);*/

		var emailValid = reg.test(this.state.email);
		this.setState({ errEmail: reg.test(this.state.email) ? 'hidden' : 'visible' });

		return emailValid;
	}

	handleSendResetPasswordEmail = () => {

		const emailValid = this.checkEmail(this.state.email);
		if (!emailValid) {
			this.setState({ errEmail: 'visible_block' });
			return
		}

		HTTP.post('/profile/password/reset/send', { email: this.state.email}, (error, body) => {
			if (error) {
				return this.setState({ sendPasswordEmailError: 'visible', sendPasswordEmailErrorText: body  });
			}
			this.setState({
				sendPassword: "hidden",
				passwordSent: "visible_block"
			});
		});
	}

	render = () => {
		return(
			<div className="viewport_width">
				<NavBarNotifications 
					showSignupButton={true}
					showLoginButton={true}
				/>
				<div style={{float:'left', width: '100%'}}>
					<div className={"password_reset_box " + this.state.sendPassword}>
						<div>
							<p>Enter your email address and click on the button to have an email sent that will allow you to reset your password.</p>
							<div className="clear"></div>

							<div className="show_for_desktop">
								<div className={this.state.errEmail} style={{background: "#FFB0B0", width: "15.6em", float: "left", marginLeft: "4.2em", marginBottom: "0.5em", padding: "0.2em 0.5em"}}> Please enter a valid email address </div>
							</div>

							<div className="show_for_mobile">
								<div className={this.state.errEmail} style={{background: "#FFB0B0", width: "15.1em", float: "left", marginLeft: "0em", marginBottom: "0.5em", padding: "0.2em 0.5em"}}> Please enter a valid email address </div>
							</div>

							<div className="clear"></div>
							<div className="text_style" style={{float: 'left'}}>Email:</div>
							<input className="password_reset_email" style={{float: 'left'}}type="text" value={this.state.email} onChange={this.handleEmailChange} onBlur={this.checkEmail} placeholder="email address"/>
							<button className="send_password" onClick={this.handleSendResetPasswordEmail}>Reset Password</button>
							<div className={"error text_style " + this.state.sendPasswordEmailError} style={{float: 'right', marginTop: "1em", marginRight: "1.6em"}}>{this.state.sendPasswordEmailErrorText}</div>
							
						</div>
						<div className="clear"></div>
					</div>
					<div className={"password_reset_box " + this.state.passwordSent} style={{textAlign: "center"}}>
						<div className="text_style" style={{marginTop: '1em'}}>
							We just sent a password reset link to:<span style={{color: "#00A4A4"}}> {this.state.email}</span><br/><br/>
							
							<div className="show_for_desktop">If you did not receive an email, please check your spam folder.</div>
							<div className="show_for_mobile">If you did not receive an email,<br/> please check your spam folder.</div>
						</div>
					</div>
				</div>
				<div className="clear"></div>
				<div className="bottom_padding" style={{paddingBottom: '4.5em'}}></div>
				<Footer />
			</div>
		);
	}
};
