import React from 'react';
import ReactRouter from 'react-router';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { logout } from '_redux/actions';

import NavBarNotifications from '../nav/NavBar_Notifications.jsx';
import Footer from '../footer/Footer.jsx';
import HTTP from 'app/utils/Http';
import history from 'app/history';
import * as Utils from 'utils/Utils';

class SignupConfirm extends React.Component {
	static contextTypes = {
		router: PropTypes.object
	}

	state = {
		verified: false,
		loginButton: 'hidden',
		createProfileButton: 'visible_block'
	}

	componentDidMount() {
		HTTP.post('/profile/email/verify', { confId: Utils.getLocationSearchParam('confId'), userId: Utils.getLocationSearchParam('userId') }, (err, res) => {
			if (err) return alert('Error confirming your email.');
			this.setState({ verified: true });
			USER.emailVerified = true;
		});

		if (!USER){
			this.setState({
				loginButton: 'visible_block',
				createProfileButton: 'hidden'
			});
		}
	}

	renderBody() {
		if (!this.state.verified) {
			return (
				<p>Loading...</p>
			);
		}
		var createButtonVisibility;
		var loginButtonVisibility;

		if (this.props.loggedIn){
			createButtonVisibility = "visible_block";
			loginButtonVisibility = "hidden";
		} else {
			createButtonVisibility = "hidden";
			loginButtonVisibility = "visible_block";
		}
		return [
			<div key="1">
				<h3>Your account has been verified!</h3>
				<p>
					You’ve started your journey towards finding like-minded kreators looking to work on
					amazing projects. However, your profile will not be searchable until you finish the
					profile creation process.<br/><br/>

					The profile creation process will let you provide important details on who you are and
					what you are looking for. Some information is required. Other questions can be skipped
					and filled out later, but remember, the more details you give the more likely the right
					people will contact you. So a little time invested now can pay big dividends down the road.
				</p>
				<div id="navbox" style={{textAlign: "center"}}>
					<Utils.QueryLink  className={createButtonVisibility} to={{ pathname: '/signupbaseskills', query: { comingFromPage: "signupconfirm" } }}>Create profile</Utils.QueryLink>
					<Utils.QueryLink className={loginButtonVisibility} to={{ pathname: '/loginpage', query: { comingFromPage: "signupconfirm" } }}>Login</Utils.QueryLink>
				</div>
			</div>
		];
	}

	render = () => {
		return (
			<div className="viewport_width">
				<NavBarNotifications
					showLoginButton={true}
					showSignupButton={false}
				/>

				<div className="signup_hero">
					<img src="images/account_confirm.svg" />
				</div>

				<div className="clear"></div>
				<div className="signup_container">
					<div id="account_confirm">
						{this.renderBody()}
					</div>
				</div>
				<div className="bottom_padding"></div>
				<Footer />
			</div>
		);
	}
};

export default connect(({ loggedIn }) => ({ loggedIn }))(SignupConfirm);
