// This is the users main account page.

import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { QueryLink } from 'utils/Utils';
import HTTP from '../../utils/Http';
import history from 'app/history';

import NavBarNotifications from '../nav/NavBar_Notifications.jsx';
import Footer from '../footer/Footer.jsx';
import BankProfileSummary from './BankProfileSummary.jsx';

const basePicNames = ['business', 'artist', 'scribe', 'engineer'];

export default class UserAccountPage extends React.Component {
	state = {
		firstName: USER.firstName,
		lastName: USER.lastName,
		email: USER.email,
		emailVerified: USER.emailVerified,
		zipCode: USER.zipCode,
		location: USER.location,
		baseSkills: USER.baseSkills,
		expertises: USER.expertises,
		expertisesOther: USER.expertisesOther,
		skills: USER.skills,
		achievement: USER.achievement,
		schools: USER.schools,
		courses: USER.courses,
		bio: USER.bio,
		interests: USER.interests,
		photo: USER.photo ? `/uploads/${USER.photo}` : '/images/add_photo_account.svg',
		additionalInfo: USER.additionalInfo,
		futureGoals: USER.futureGoals,
		portfolio: USER.portfolio,
		fictional: USER.fictional,
		weekend: USER.weekend,
		projectsCreated: USER.projectsCreated,
		projectsJoined: USER.projectsJoined,
		projectsFollowing: USER.projectsFollowing,
		kreatorBank: USER.kreatorBank,
		underReview: USER.underReview,
		published: USER.published,

		deleteButton: "hidden",
		kreatorBankSuccess: "hidden",
		kreatorBankError: "hidden",
		verificationNeeded: "visible_block",
		emailSent: "hidden",
		profileIncomplete: "hidden",
		profileNotSubmitted: "hidden",
		profileNotPublished: "hidden",
		newInvites: "visible",
		displayCourses: USER.courses.length > 0 ? "visible" : "hidden",
	};

	constructor(props){
		super(props);

		this.myRef = React.createRef();
	}

	UNSAFE_componentWillMount = () => {
		//Check used to see if all required fields of a user profile are filled out. If not, a warning is shown.
		var profileComplete = this.checkProfileCompleteness();
		var underReview = USER.underReview;
		var published = USER.published;

		if (!profileComplete) {
			this.setState({
				profileIncomplete: 'visible_block',
				profileNotSubmitted: 'hidden',
				profileNotPublished: 'hidden'
			});
		} else if(!underReview && !published){
			this.setState({
				profileIncomplete: 'hidden',
				profileNotSubmitted: 'visible_block',
				profileNotPublished: 'hidden'
			});
		} else if(!published){
			this.setState({
				profileIncomplete: 'hidden',
				profileNotSubmitted: 'hidden',
				profileNotPublished: 'visible_block'
			});
		}

		if (this.state.emailVerified === true) {
			this.setState({verificationNeeded: "hidden"});
		}

	}

	checkProfileCompleteness = () => {
		var profileComplete = true;
		if (USER.baseSkills.length === 0) {
			profileComplete = false;
		} else if (USER.schools.length === 0) {
			profileComplete = false;
		} else if (USER.bio === "") {
			profileComplete = false;
		} else if (USER.interests.length === 0) {
			profileComplete = false;
		} else if (USER.additionalInfo.hoursPerWeek === -1) {
			profileComplete = false;
		} else if (USER.additionalInfo.teamOrProject === -1) {
			profileComplete = false;
		} else if (USER.additionalInfo.travelRadius === -1) {
			profileComplete = false;
		// } else if(USER.additionalInfo.whatToGain === -1) {
		// 	profileComplete = false;
		} else if (USER.photo === "") {
			profileComplete = false;
		}

		return profileComplete;
	}

	generateSchools = () => {
		var schools = this.state.schools;
		var i
		for(i = 0; i < schools.length; i++){
			if(schools[i].attending == true){
				var schoolObject = schools[i];
				schools.splice(i, 1);
				schools.splice(0, 0, schoolObject);
			}
		}
		return schools.map(schoolObject => (
			<div key={schoolObject.school._id}>
				<div className={`middle_dot ${schoolObject.attending ? "visible_block" : "hidden"}`}>&middot;</div>{schoolObject.school.names[0]}
			</div>
		));
	}

	generateCourses = () => {
		var courses = this.state.courses;
		var coursesNameArray = [];

		courses.forEach(function(course){
			coursesNameArray.push(course.name);
		});
		var listOfCourses = coursesNameArray.join(', ');
		return listOfCourses;
	}
	generateBaseSkills = baseSkill => {
		const basePic = basePicNames[baseSkill] || 'unknownBasePic';

		return <img key={baseSkill} src={`images/${basePic}.svg`} />;
	}

	generateExpertises = () => {
		return this.state.expertises
			.concat(this.state.expertisesOther.map(expertise => expertise.text))
			.join(', ');
	}

	generateSkills = () => {
		return this.state.skills.join(', ');
	}

	generateInterests = () => {
		return this.state.interests
			.map(interestNumber => (CONSTANTS.projectCategories[interestNumber] || {}).text)
			.join(', ');
	}

	generateProjectsCreated = project => {
		const projectId = project._id;
		return(
			<div key={projectId}>
				<li><QueryLink to={{ pathname: '/editprojectpage', query: { projectId: projectId } }}><span>{project.name}</span></QueryLink></li>
			</div>
		);
	}

	generateProjectsJoined = project => {
		const projectId = project._id;
		return(
			<div key={projectId}>
				<li><QueryLink to={{ pathname: '/projectmainpage', query: { projectId: projectId } }}><span>{project.name}</span></QueryLink></li>
			</div>
		);
	}

	generateProjectsFollowing = project => {
		const projectId = project._id;
		return(
			<div key={projectId}>
				<li><QueryLink to={{ pathname: '/projectmainpage', query: { projectId: projectId } }}><span>{project.name}</span></QueryLink></li>
			</div>
		);
	}

	generateProfileSummary = () => {
		return this.state.kreatorBank.map(kreator => (
				<BankProfileSummary
					key={kreator._id}
					kreator={kreator}
					deleteButton={this.state.deleteButton}
					handleDeleteKreatorBank={this.handleDeleteKreatorBank}
				/>
			));
	}

	handleAccountSettings = () => {
		history.push('/accountsettings');
	}

	handleContactSettings = () => {
		history.push('/contactsettings');
	}

	handleGoToInvites = () => {
		history.push('/teammanageinvites');
	}

	handleDeleteButton = () => {
		this.setState({ deleteButton: 'visible_block' });
	}

	handleCancelDelete = () => {
		this.setState({ deleteButton: 'hidden' });
	}

	handleDeleteKreatorBank = kreatorId => {
		var kreatorArray = [];
		kreatorArray.push(kreatorId);
		HTTP.delete('/profile/kreatorBank', kreatorArray, (error, body) => {
			if (error) {
				this.setState({
					kreatorBankError: 'visible',
			 		kreatorBankSuccess: 'hidden'
			 	});
			} else {
				this.setState({
					kreatorBankError: 'hidden',
					kreatorBankSuccess: 'visible',
					kreatorBank: body.kreatorBank
				});
				USER.kreatorBank = body.kreatorBank;
			}
		});
	}

	handleResendEmail = () => {
		HTTP.get('/profile/email/verify/resend', (err, res) => {
			if (err) return alert('Could not resend verification email.');
			this.setState({ emailSent: 'visible_block'});
		});
	}

	render = () => {
		return (
			<div className="viewport_width">
				<div className="container_100_height">
					<div style={{paddingBottom:'53px'}}>
						<NavBarNotifications
							showLoginButton={false}
							showSignupButton={false}
						/>

						<div className="clear"></div>
						<div className={this.state.verificationNeeded + " verification_bar"}>
							<p>
							Please verify your account using the verification email that was sent to you.<br/>
							Your profile won't be visible to other kreators until it is. Thanks! &nbsp;<span onClick={this.handleResendEmail} style={{cursor:'pointer', textDecoration:'underline'}}>Resend Email</span>
							</p>
						</div>
						<div className={this.state.emailSent + " verification_bar"} style={{color:'black', background: '#B4F4F4'}}>
							<p>
							A new verification email has been sent.
							</p>
						</div>
						<div className={this.state.profileIncomplete + " incomplete_profile_bar"}>
							<p>
							Please fill out all required fields in your profile. Your profile cannot be submitted until they are properly filled out.
							</p>
						</div>
						<div className={this.state.profileNotSubmitted + " incomplete_profile_bar"}>
							<p>
							Please submit your profile for review. Your profile won't be visible to other<br/>
							kreators until it is reviewed then published. Thanks! &nbsp; &nbsp; <Link className="warning_link" to="/signupreview">Go to Review Page</Link>
							</p>
						</div>
						<div className={this.state.profileNotPublished + " incomplete_profile_bar"}>
							<p>
							Your profile has been submitted and is currently under review.
							</p>
						</div>


						<div>
							<div className="user_profile_topbox">
								<div className="user_profile_picbox">
									<img src={this.state.photo} /><br/>
									<p>{this.state.firstName} {this.state.lastName}</p><br/>
									<div className="clear"></div>
									<Link to="/editprofilepage">Edit Profile</Link>
									<div className="clear"></div>
									<div className="text_style" onClick={this.handleAccountSettings} style={{cursor: 'pointer', marginTop: '2em'}}>Account Settings</div>
									<div className="clear"></div>
									<div className="text_style" onClick={this.handleContactSettings} style={{cursor: 'pointer', marginTop: '0.5em'}}>Contact Settings</div>
								</div>
								<div className="user_profile_info">
									<span className="blacktext">Schools:</span>
									<div className="clear"></div>
									{this.generateSchools()}
									<div className="clear"></div>
									<div style={{marginTop: '1em'}}>
										<span className={"blacktext " + this.state.displayCourses}>Courses:&nbsp;</span>
										<div style={{marginBottom: "1em"}}>{this.generateCourses()}</div>
									</div>
									<div className="clear" style={{marginBottom : "1em"}}></div>
									<span className="blacktext">Location:&nbsp;</span>
									{this.state.location}
									<div className="clear" style={{marginBottom : "1em"}}></div>
									<span className="blacktext">B.A.S.E. Skills:</span>
									<div className="user_profile_skills">
										{this.state.baseSkills.map(this.generateBaseSkills)}
									</div>
									<div className="clear"></div>
									<span className="blacktext">Expertises:&nbsp;</span>
									{this.generateExpertises()}
									<div className="clear" style={{marginBottom : "1em"}}></div>
									<span className="blacktext">Interests:&nbsp;</span>
									{this.generateInterests()}
									<div className="clear"></div>
								</div>
								<div className="user_profile_detailbox">
									{/*<div className="text_style">
										<span className="blacktext">Last Active:&nbsp;</span> days ago
									</div>*/}
									<div className="text_style">
										<span className="blacktext">Hours can work per week:&nbsp;</span>
										{CONSTANTS.additionalInfo.hoursPerWeek[this.state.additionalInfo.hoursPerWeek + 1].text}
									</div>
									{/*<div className="text_style">
										<span className="blacktext">Looking for:&nbsp;</span>
										{CONSTANTS.additionalInfo.whatToGain[this.state.additionalInfo.whatToGain + 1].text}
									</div>*/}
									<div className="text_style">
										<span className="blacktext">Willing to travel:&nbsp;</span>
										{CONSTANTS.additionalInfo.travelRadius[this.state.additionalInfo.travelRadius + 1].text}
									</div>
									<div className="text_style">
										<span className="blacktext">Wants to:&nbsp;</span>
										{CONSTANTS.additionalInfo.teamOrProject[this.state.additionalInfo.teamOrProject + 1].text}
									</div>
									<div className="text_style">
										<span className="blacktext" style={{float: 'left'}}>Skills:&nbsp;</span>
										{this.generateSkills()}
									</div>
								</div>
							</div>
						</div>

						<div className="clear"></div>

						<div className="project_box_holder" style={{marginBottom: '0em'}}>
							<div className="user_account_bottombox">
								<div className="user_account_bottombox_title">Projects List</div>
								<div id="user_account_projectbox">
									<div className="user_account_project_listbox">
										<h4>Created</h4>
										<ul>
											{this.state.projectsCreated.map(this.generateProjectsCreated)}
										</ul>
									</div>
									<div className="user_account_project_listbox">
										<h4>Joined</h4>
										<ul>
											{this.state.projectsJoined.map(this.generateProjectsJoined)}
										</ul>
									</div>
									<div className="user_account_project_listbox">
										<h4>Following</h4>
										<ul>
											{this.state.projectsFollowing.map(this.generateProjectsFollowing)}
										</ul>
									</div>
								</div>
								<div>
									<div className="user_account_bottombox_title">Kreator Bank</div>
									<button className="user_account_bottombox_button" onClick={this.handleDeleteButton}><img src="/images/edit_icon.svg" /></button><button className={"user_account_bottombox_button " + this.state.deleteButton} onClick={this.handleCancelDelete}>done</button>
									<div id="user_account_bankbox">
										{this.generateProfileSummary()}
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="clear"></div>
				</div>
				<Footer />
			</div>
		);
	}
};
