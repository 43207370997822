import React from 'react';
import { Link } from 'react-router-dom';
import HTTP from 'utils/Http';
import { QueryLink } from 'utils/Utils';

export default class Notifications extends React.Component {

	state = {
		notifications: [],
		page: 1
	}

	UNSAFE_componentWillReceiveProps = (nextProps) => {
		this.setState({
			notifications: nextProps.notifications
		});
	}

	createNotificationsComponent = (notification) => {
		if(notification !== null){
			if (notification.type === "ProjectUpdate") {
				const projectLogo = "/uploads/" + notification.ref0.project.logo;
				return (
					<div key={notification._id} className="notification_component">
						<div>
							<img src={projectLogo}/>
							<div className="notification_textbox">
								<QueryLink to={{ pathname:"/projectmainpage", query: { projectId: notification.ref0.project._id, projectPageTarget: "projectUpdates" }}}>
								<span>{notification.ref0.project.name}</span>
								</QueryLink>
								<div className="clear"></div>
								<span style={{color: '#303030', cursor: 'default'}}>Just posted a new update.</span>
							</div>
						</div>
					</div>
				);
			}

			if (notification.type === "ProjectComment") {
				const kreatorPhoto = "/uploads/" + notification.ref0.kreator.photo;
				return (
					<div key={notification._id} className="notification_component">
						<div>
							<img src={kreatorPhoto} style={{width: '35px', height: '35px', borderRadius: '35px', padding: '0', marginTop: '.35em', marginRight: '.97em', marginLeft: '.8em'}}/>
							<div className="notification_textbox">
								<QueryLink to={{ pathname:"/profilepage", query: { profileId: notification.ref0.kreator._id}}}>
								<span>{notification.ref0.kreator.firstName} {notification.ref0.kreator.lastName}</span>
								</QueryLink>
								<div style={{float:'left', marginTop: '0.1em'}}>commented on</div>
								<div className="clear"></div>
								<QueryLink to={{ pathname:"/editprojectpage", query: { projectId: notification.ref0.project._id, projectPageTarget: "projectComments" }}}>
									<span style={{marginTop: '0', marginLeft: '0.2em'}}>{notification.ref0.project.name}</span>
								</QueryLink>
							</div>
						</div>
					</div>
				);
			}

			if (notification.type === "ProjectInvite") {
				const kreatorPhoto = "/uploads/" + notification.ref0.kreator.photo;
				return (
					<div key={notification._id} className="notification_component">
						<div>
							<img src={kreatorPhoto} style={{width: '35px', height: '35px', borderRadius: '35px', padding: '0', marginTop: '.35em', marginRight: '.97em', marginLeft: '.8em'}}/>
							<div className="notification_textbox">
								<QueryLink to={{ pathname:"/profilepage", query: { profileId: notification.ref0.kreator._id}}}>
								<span>{notification.ref0.kreator.firstName} {notification.ref0.kreator.lastName}</span>
								</QueryLink>
								<div className="clear"></div>
								<div style={{float:'left'}}>invited you to </div>
								<QueryLink to={{ pathname:"/projectmainpage", query: { projectId: notification.ref0._id, projectPageTarget: "projectDetails" }}}>
									<span style={{marginTop: '0', marginLeft: '0.2em'}}>{notification.ref0.name}</span>
								</QueryLink>
							</div>
						</div>
					</div>
				);
			}

			if (notification.type === "TeammateJoin") {
				const kreatorPhoto = "/uploads/" + notification.ref0.photo;
				return (
					<div key={notification._id} className="notification_component">
						<div>
							<img src={kreatorPhoto} style={{width: '35px', height: '35px', borderRadius: '35px', padding: '0', marginTop: '.35em', marginRight: '.97em', marginLeft: '.8em'}}/>
							<div className="notification_textbox">
								<QueryLink to={{ pathname:"/profilepage", query: { profileId: notification.ref0._id}}}>
								<span>{notification.ref0.firstName} {notification.ref0.lastName}</span>
								</QueryLink>
								<div className="clear"></div>
								<div style={{float:'left'}}>just joined</div>
								<QueryLink to={{ pathname:"/editprojectpage", query: { projectId: notification.ref1._id, projectPageTarget: "projectTeamMembers" }}}>
									<span style={{marginTop: '0', marginLeft: '0.2em'}}>{notification.ref1.name}</span>
								</QueryLink>
							</div>
						</div>
					</div>
				);
			}

			if (notification.type === "ProjectPublished") {
				const projectLogo = "/uploads/" + notification.ref0.logo;
				return (
					<div key={notification._id} className="notification_component">
						<div>
							<img src={projectLogo}/>
							<div className="notification_textbox">
								<QueryLink to={{ pathname:"/projectmainpage", query: { projectId: notification.ref0._id}}}>
								<span>{notification.ref0.name}</span>
								</QueryLink>
								<div className="clear"></div>
								<span style={{color: '#303030', cursor: 'default'}}>Has just been published!</span>
							</div>
						</div>
					</div>
				);
			}

			if (notification.type === "ProfilePublished") {
				return (
					<div key={notification._id} className="notification_component">
						<div>
							<img src={`/uploads/${USER.photo}`} style={{width: '35px', height: '35px', borderRadius: '35px', padding: '0', marginTop: '.35em', marginRight: '.97em', marginLeft: '.8em'}}/>
							<span style={{color: '#303030', cursor: 'default'}}> Congratulations {USER.firstName}</span>
							<div className="notification_textbox">
								<span style={{color: '#303030', cursor: 'default'}}>Your profile has been published!</span>
							</div>
						</div>
					</div>
				);
			}
		}
	}

	render = () => {
		return (
			<div>
				<div className="notification_arrowbox" style={{display: this.props.visibility}}>
					<div className="notification_box">
						{ this.state.notifications.map(this.createNotificationsComponent) }
					</div>
				</div>
			</div>
		);
	}
};
