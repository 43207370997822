// This is a module for displaying a single teammate for the ProjectTeammatesBox.jsx.

import React from 'react';
import { QueryLink } from 'utils/Utils';
import PropTypes from 'prop-types';

export default class SingleTeammateBox extends React.Component {
	static propTypes = {
		kreator: PropTypes.object,
		projectId: PropTypes.string
	}

	generateBaseSkills = (baseSkill, index) => {
		return <img key={index} src={`/images/${(CONSTANTS.baseSkills.find(bs => bs.id === baseSkill) || {}).text.toLowerCase()}.svg`} />;
	}

	generateExpertises = () => {
		var listOfExpertiseStrings = this.props.kreator.member.expertises
			.concat(this.props.kreator.member.expertisesOther.map(expertise => expertise.text))
			.join(', ');

		if (listOfExpertiseStrings.length > 55) {
			var expListSubstr = listOfExpertiseStrings.substr(0, 55);
			return expListSubstr.substr(0, expListSubstr.lastIndexOf(',')) + '...';
		}

		return listOfExpertiseStrings;
	}

	generateSkills = () => {
		var listOfSkills = this.props.kreator.member.skills.join(', ');

		if (listOfSkills.length > 55) {
			var skillListSubstr = listOfSkills.substr(0, 55);
			return skillListSubstr.substr(0, skillListSubstr.lastIndexOf(',')) + '...';
		}

		return listOfSkills;
	}

	generateInterests = () => {
		var listOfInterestStrings = this.props.kreator.member.interests
			.map(interestNumber => (CONSTANTS.projectCategories[interestNumber] || {}).text)
			.join(', ');

		if (listOfInterestStrings.length > 55) {
			var interestListSubstr = listOfInterestStrings.substr(0, 55);
			return interestListSubstr.substr(0, interestListSubstr.lastIndexOf(',')) + '...';
		}

		return listOfInterestStrings;
	}

	render = () => {
		const school = this.props.kreator.member.schools[0].school.names[0];
		var projectId = this.props.projectId;
		return(
			<div className="project_teammatebox">
				<div className="teammate_profile">
					<QueryLink to={{ pathname: '/profilepage', query: { profileId: this.props.kreator.member._id } }}>
						<div className="teammate_profile_picbox">
							<img src={'/uploads/' + this.props.kreator.member.photo} />
						</div>
						<h4>{this.props.kreator.member.firstName} {this.props.kreator.member.lastName}</h4>

						<div style={{width: "17em", margin: "0em auto"}}>
							<span>{school}</span><br/>
							<span>{this.props.kreator.member.location}</span><br/>
							<span style={{ marginTop: '0.5em' }}>Skills:</span>
							<div className="teammate_profile_skill">
								{this.props.kreator.member.baseSkills.map(this.generateBaseSkills)}
							</div><br/>
							<div className="clear"></div>
							<div style={{ height: '4em', overflow: 'hidden' }}>
								<span>{this.generateExpertises()}</span>
							</div>
							<div className="clear"></div>
							<div style={{ height: '4em', overflow: 'hidden' }}>
								<span>{this.generateInterests()}</span>
							</div>
						</div>
					</QueryLink>
				</div>
				<div className="show_for_mobile">
					<div className="clear"></div>
				</div>
				<div className="teammate_accomplishments">
					<p>{this.props.kreator.roleSummary}</p>
				</div>
			</div>
		);
	}
};