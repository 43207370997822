import { createStore } from 'redux';
import { LOGIN, LOGOUT, SHOULD_REFRESH_UNREAD, REFRESH_UNREAD_DONE } from './actionTypes';

const initialState = {
	loggedIn: Boolean(USER),
	shouldRefreshUnread: false,
};

const rootReducer = (state = initialState, action = {}) => {
	switch (action.type) {
		case LOGIN:
			return {
				...state,
				loggedIn: true,
			};

		case LOGOUT:
			return {
				...state,
				loggedIn: false,
			};

		case SHOULD_REFRESH_UNREAD:
			return {
				...state,
				shouldRefreshUnread: true,
			};

		case REFRESH_UNREAD_DONE:
			return {
				...state,
				shouldRefreshUnread: false,
			};

		default:
			return state;
	}
};

const store = createStore(
	rootReducer,
	window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);

export default store;
