// This is the box that shows the detailed description of a project. It is used in ProjectMaster.

import React from 'react';
import ProjectDetailElement from './ProjectDetailElement.jsx';
import PropTypes from 'prop-types';

export default class ProjectDetailBox extends React.Component {
	static propTypes = {
		futureBusiness: PropTypes.bool,
		targetMarket: PropTypes.string,
		competition: PropTypes.string,
		profitPlan: PropTypes.string,
		projectDescription: PropTypes.string,
		projectMission: PropTypes.string
	}

	render = () => {
		var elements = [];
		if (this.props.futureBusiness) {
			if (this.props.targetMarket !== "") {
				elements.push({ id: 0, title: "Target Market", text: this.props.targetMarket});
			}
			if (this.props.competition !== "") {
				elements.push({ id: 1, title: "Competition", text: this.props.competition});
			}
			if (this.props.profitPlan !== "") {
				elements.push({ id: 2, title: "Making Money", text: this.props.profitPlan});
			}
		}

		const elementList = elements.map(element => (
			<ProjectDetailElement
				key={element.id}
				title={element.title}
				text={element.text}
			/>
		));

		return (
			<div>
				<div className="project_detailsbox">
					<h3>The Details</h3>
					<div className="project_details" dangerouslySetInnerHTML={{__html: this.props.projectDescription}} />
					<div className="project_detailsElementsBox">
						<h4>Mission:</h4>
						<p>{this.props.projectMission}</p>
						{elementList}
					</div>
				</div>
			</div>
		);
	}
};
