// This is the second page a user needs to fill out in order to start a project.

import React from 'react';
import Footer from '../footer/Footer.jsx';
import AddOption from '../signup/AddOption.jsx';
import DayPicker from 'react-day-picker';
import PropTypes from 'prop-types';
import { Editor as TinyMCE } from '@tinymce/tinymce-react';

export default class CreateProject02 extends React.Component {
	static propTypes = {
		projectCourseSchool: PropTypes.string,
		videoLink: PropTypes.string,
		handleVideoChange: PropTypes.func,
		projectDescriptionError: PropTypes.string,
		projectCourseSchoolError: PropTypes.string,
		projectCourse: PropTypes.string,
		handleProjectCourseInput: PropTypes.func,
		handleCourseSchoolSelect: PropTypes.func,
		projectMissionError: PropTypes.string,
		projectMission: PropTypes.string,
		handleMissionChange: PropTypes.func,
		startDateVisibility: PropTypes.string,
		projectStatus: PropTypes.string,
		handleProjectStatusChange: PropTypes.func,
		projectZipcodeError: PropTypes.string,
		projectZipcode: PropTypes.string,
		handleZipCodeChange: PropTypes.func,
		checkZipCode: PropTypes.func,
		projectNameError: PropTypes.string,
		handleSubmit: PropTypes.func,
		handleSkip: PropTypes.func,
		handleSave: PropTypes.func,
		saveButton: PropTypes.string,
		handleBackButtonClick: PropTypes.func
	}

	state = {
		projectDescription: this.props.projectDescription,
		projectCountryCode: this.props.projectCountryCode,
		successSave: 'hidden',
		failedSave: 'hidden',
		errorFileTooBig: 'hidden'
	}

	UNSAFE_componentWillReceiveProps = nextProps => {
		this.setState({
			successSave: nextProps.successSave,
			failedSave: nextProps.failedSave,
			projectDescription: nextProps.projectDescription
		});
	}

	generateCountryOptions = option => {
		return <option key={option.code} value={option.code}>{option.name}</option>
	}

	handleDayClick = (day, { disabled, selected }) => {
		if (disabled) {
		  return;
		}
		this.props.handleDateChange(day);
	}

	componentWillUnmount = () => {
		//tinymce.EditorManager.editors = [];
	}

	handleEditorChange = (e) => {
		var projectDescription = e.target.getContent();
		this.props.handleTinyMCE(projectDescription);
		this.setState({ successSave: 'hidden', errorFileTooBig: 'hidden' });
	}

	handleFileTooBig = () => {
		this.setState({ errorFileTooBig: 'visible'});
		alert("File size too large.");
	}

	filePickerCallback = (cb, value, meta) => {
		const that = this;

		var input = document.createElement('input');
		input.setAttribute('type', 'file');
		input.setAttribute('accept', 'image/*');
		// Note: In modern browsers input[type="file"] is functional without
		// even adding it to the DOM, but that might not be the case in some older
		// or quirky browsers like IE, so you might want to add it to the DOM
		// just in case, and visually hide it. And do not forget do remove it
		// once you do not need it anymore.

		input.onchange = function() {
			var file = this.files[0];
			if (file.size > CONSTANTS.maxFileSizeUpload) {
				that.handleFileTooBig();
				return false;
			}
			var reader = new FileReader();
			reader.readAsDataURL(file);
			reader.onload = function () {
				// Note: Now we need to register the blob in TinyMCEs image blob
				// registry. In the next release this part hopefully won't be
				// necessary, as we are looking to handle it internally.
				var id = 'blobid' + (new Date()).getTime();
				var blobCache =  tinymce.activeEditor.editorUpload.blobCache;
				var base64 = reader.result.split(',')[1];
				var blobInfo = blobCache.create(id, file, base64);
				blobCache.add(blobInfo);
				// call the callback and populate the Title field with the file name
				cb(blobInfo.blobUri(), { title: file.name });
			};
		};
		input.click();
	}

	render = () => {
		// Sets variables for date picker to work.
		var selectedDay = this.props.startDate;
		var earliestPossibleStartDate = new Date();
		earliestPossibleStartDate.setDate(earliestPossibleStartDate.getDate() + 1);

		if (selectedDay < earliestPossibleStartDate){
			selectedDay = earliestPossibleStartDate;
		}

		var monthOffset = 3; //or whatever offset
		var endDate = new Date();
		endDate.setMonth(endDate.getMonth() + monthOffset);

		var attendingSchoolList = [];

		if (USER != null){
			attendingSchoolList = USER.schools.filter(school => school.attending);
		}

		var courseSchool = this.props.projectCourseSchool;
		if (courseSchool == null) {
			courseSchool = 'select_school';
		}
		var courseSchoolList = attendingSchoolList.map(schoolObject => (
			<AddOption
				key={schoolObject.school._id}
				id={schoolObject.school._id}
				schoolName={schoolObject.school.names[0]}
			/>
		));

		return (
			<div>
				<div>
					<div className="project_container">
						<div className="back_button_warning">
						{CONSTANTS.warnings.backButtonWarning}
						</div>
						<div className="create_project">
							<h2>It's time to tell a story...</h2>
							<p>
							To get people excited about working on your project, you need to tell a
							compelling story. Don’t worry. It’s not as hard as it sounds. Just fill
							out the fields below, be sincere, and let your personality shine.
							</p>
						</div>
						<div className="clear"></div>

						<div className="project_box_holder">
							<div id="project_videobox">

								<div className="show_for_mobile">
									<p className="project_titles">Video Link:</p>
									<div className="clear"></div>
								</div>

								<div id="videobox_textbox">
									<div className="text_style">The best way to show people your personality and tell your story is
									through video. It doesn’t have to be fancy. You can simply introduce
									yourself and your project, then let people know why it is so important
									to you. Copy and paste the link to your youtube video below.</div>
									<div className="clear"></div>
									<br/>
									{/*<div className="text_style">For a sample video</div> <a href="">click here.</a>*/}
									<input id="project_video" type="text" value={this.props.videoLink} onChange={this.props.handleVideoChange} placeholder="Put Youtube link here."/>
								</div>

								<div className="show_for_desktop">
									<p className="project_titles">Video Link:</p>
									<div className="clear"></div>
								</div>
							</div>
						</div>

						<div className="project_box_holder">
							<div id="project_descriptionbox">

								<div className="show_for_mobile">
									<div className="project_titles" style={{marginLeft: '0.9em'}}>
										Description:<span className="project_subtitle">&nbsp;(required)</span>
									</div>
								</div>

								<div className="show_for_desktop">
									<div className={this.props.projectDescriptionError + " error"} style={{marginLeft: '17em', float: 'left'}}>{CONSTANTS.errors.projectDescriptionError}</div>
								</div>

								<div className="show_for_mobile">
									<div className={this.props.projectDescriptionError + " error"} style={{float: 'left'}}>{CONSTANTS.errors.projectDescriptionError}</div>
								</div>

								<div className="clear"></div>

								<div className="project_description_intro">
									<p>Now is your opportunity to let people know how wonderful your project is.
									Try to be as clear and as thorough as possible. We live in a visual world,
									so adding images will go a long way in describing your project. Even sketches
									of an idea are better than nothing.
									</p>
									<div className="clear"></div>
									<br/>
									<p>
									Remember, you are asking people to give up their precious time and effort
									to work on your project. Prospective members want to see team leaders who
									are dedicated, motivated, and have a vision of what they want to accomplish.
									</p><br/><br/>
									<b>Show them what you got!</b>
									<div className="clear"></div>
									{/*<div id="project_examplelink">For an example</div>
									<a href="">click here.</a>*/}
								</div>


								<div className="show_for_mobile">
									<div className={this.state.errorFileTooBig + " error"} style={{float: 'left'}}>{CONSTANTS.errors.fileSizeUploadError}</div>
								</div>
								<div className="show_for_desktop">
									<div className="project_titles">
										Description:<br/>
										<span className="project_subtitle">(required)</span>
									</div>
									<div className="clear"></div>
									<div className="show_for_desktop">
										<div className={this.state.errorFileTooBig + " error"} style={{marginLeft: "17em", float: 'left'}}>{CONSTANTS.errors.fileSizeUploadError}</div>
									</div>
								</div>

								<div className="clear"></div>
								<div className="ckeditor_box">
									<div className="show_for_desktop">
										<TinyMCE
											initialValue={this.state.projectDescription}
											init={{
												automatic_uploads: true,
												height: 600,
												width: 800,
												max_width: 800,
												max_height: 1000,
												branding: false,
												menubar: false,
												browser_spellcheck: true,
												elementpath: false,
												content_css: '../../../css/KB_styles.css',
												file_browser_callback_types: 'image',
												file_picker_types: 'image',
												file_picker_callback: this.filePickerCallback,
												images_upload_url: '/project/upload',
												images_upload_base_path: '/uploads',
												plugins: 'autolink link image imagetools lists print preview',
												toolbar: 'undo redo | bold italic | alignleft aligncenter alignright | link image'
											}}
											onChange={this.handleEditorChange}
										/>
									</div>
									<div className="show_for_mobile">
										<TinyMCE
											initialValue={this.state.projectDescription}
											init={{
												automatic_uploads: true,
												height: 600,
												width: 285,
												max_width: 285,
												max_height: 600,
												branding: false,
												menubar: false,
												browser_spellcheck: true,
												elementpath: false,
												content_css: '../../../css/KB_styles.css',
												file_browser_callback_types: 'image',
												file_picker_types: 'image',
												file_picker_callback: this.filePickerCallback,
												images_upload_url: '/project/upload',
												images_upload_base_path: '/uploads',
												plugins: 'autolink link image imagetools lists print preview',
												toolbar: 'undo redo | bold italic | alignleft aligncenter alignright | link image'
											}}
											onChange={this.handleEditorChange}
										/>
									</div>
								</div>
								<br/>
							</div>
						</div>

						<div className="project_box_holder">
							<div id="project_coursebox">

								<div className="show_for_mobile">
									<p className="project_titles">Course #:</p>
								</div>

								<div id="project_courseintro">
									<div className={this.props.projectCourseError + " error"} style={{float: 'left'}}>{CONSTANTS.errors.projectCourseError}</div>
									<div className={this.props.projectSchoolError + " error"} style={{float: 'left'}}>{CONSTANTS.errors.projectSchoolError}</div>
									<span>Is this project for a particular class you are taking? If so, put down the course
									number of the class so other classmates can find you.
									</span>
									<input id="project_course" value={this.props.projectCourse} type="text" placeholder="Put course number here." onChange={this.props.handleProjectCourseInput}/>
									<div className="clear"></div>
									<select value={courseSchool} onChange={this.props.handleCourseSchoolSelect} style={{marginTop: "0.5em"}}>
										<option value="select_school">&nbsp;select attending school</option>
										{courseSchoolList}
									</select>
								</div>

								<div className="show_for_desktop">
									<p className="project_titles">Course #:</p>
								</div>

							</div>
						</div>

						<div className="project_box_holder">
							<div id="project_missionbox">

								<div className="show_for_mobile">
									<div className="project_titles">
										Mission: <span className="project_subtitle">&nbsp;(required)</span>
									</div>
								</div>

								<div className="show_for_desktop">
									<div className={this.props.projectMissionError + " error text_style"} style={{float: 'left', marginLeft: '17em'}}>{CONSTANTS.errors.projectMissionError}</div>
								</div>

								<div className="show_for_mobile">
									<div className={this.props.projectMissionError + " error text_style"} style={{float: 'left', margin:'0em'}}>{CONSTANTS.errors.projectMissionError}</div>
								</div>

								<div className="clear"></div>
								<div id="project_missionintro">
									<span>
									Let potential kreators know why this project is so important to you.
									It doesn’t have to be earth shattering, but there should be a reason
									you chose to pursue this particular idea.
									</span>
								<textarea id="mission_textarea" maxLength="5000" rows="10" cols="40" value={this.props.projectMission} onChange={this.props.handleMissionChange}></textarea>
								</div>

								<div className="show_for_desktop">
									<div className="project_titles">
										Mission:<br/>
										<span className="project_subtitle">(required)</span>
									</div>
								</div>

							</div>
						</div>

						<div className="project_box_holder">
							<div className={this.props.startDateVisibility + " project_startbox visible_block"}>

								<div className="show_for_mobile">
									<p className="project_titles">Start Date:</p>
									<div className="clear"></div>
								</div>

								<div id="project_startintro">
									Let kreators know when you plan to have this project begin. Choose an
									adequate time frame you feel will allow you to find, meet, and create
									your team.<br/><br/>
									IF YOU DO NOT HAVE AT LEAST ONE OTHER TEAMMATE ON YOUR PROJECT BY THE
									START DATE TIME, YOUR PROJECT WILL BE UNPUBLISHED!
									<div className="clear"></div><br/>
									<div className="project_will_start_on_box">
										Project will start on: <b>{selectedDay ? selectedDay.toLocaleDateString() : 'Please select a day.'}</b>
										<br/>
									</div>
									<div className="daypicker_box">
										<DayPicker
											selectedDays={selectedDay}
											fromMonth={earliestPossibleStartDate}
											toMonth ={endDate}
											disabledDays={[
												{
												  before: earliestPossibleStartDate,
												  after: endDate,
												},
											  ]}
											onDayClick={this.handleDayClick}
											enableOutsideDays
										/>
									</div>
								</div>

								<div className="show_for_desktop">
									<p className="project_titles">Start Date:</p>
								</div>

							</div>
						</div>

						<div className="project_box_holder">
							<div id="project_statusbox">
								<div className="show_for_mobile">
									<p className="project_titles">Status:</p>
									<div className="clear"></div>
								</div>
								<div id="project_statusintro">
									<span>Write a short blurb on what stage your project is in.</span>
									<textarea id="status_textarea" maxLength="75" rows="2" cols="40" value={this.props.projectStatus} onChange={this.props.handleProjectStatusChange} placeholder="(optional) For example, you might write that the web page designs are done but a database needs to be programmed for your website."></textarea>
								</div>

								<div className="show_for_desktop">
									<p className="project_titles">Status:</p>
								</div>

							</div>
						</div>

						<div className="project_box_holder">
							<div id="project_locationbox">

								<div className="show_for_mobile">
									<p className="project_titles">Location:</p>
								</div>

								<div className="show_for_desktop">
									<div className={this.props.projectCountryCodeError + " error"} style={{marginLeft: '17em', float: 'left'}}>{CONSTANTS.errors.projectCountryCodeError}</div>
									<div className={this.props.projectZipcodeError + " error text_style"} style={{marginLeft: '17em', float: 'left'}} >{CONSTANTS.errors.zipCodeError}</div>
								</div>

								<div className="show_for_mobile">
									<div style={{float: 'left', margin:'0em'}} className={this.props.projectCountryCodeError + " error text_style"}>{CONSTANTS.errors.projectCountryCodeError}</div>
								</div>

								<div className="clear"></div>


								<div id="project_location_intro">
									<span> If your project location is different from your profile location, enter the location where your project will take place.</span>
									<select className="country_select" style={{marginTop:'1em', marginBottom:'0em'}} value={this.props.projectCountryCode} onChange={this.props.handleCountryChange} onBlur={this.props.checkCountryCode}>
										<option value="">Select Country</option>
										{CONSTANTS.countryData.map(this.generateCountryOptions)}
									</select>
									<div className="clear"></div>

									<div className={this.props.zipCodeInput}>
										<div className="show_for_mobile_block" style={{marginTop: '1em'}}>
											<div style={{float: 'left', margin:'0em'}} className={this.props.projectZipcodeError + " error text_style"}>{CONSTANTS.errors.zipCodeError}</div>
											<p className="project_titles">Zip Code:</p>
											<div className="clear"></div>
										</div>
									<input type="text" placeholder="5-digits" name="zipcode" maxLength="5" value={this.props.projectZipcode} onChange={this.props.handleZipCodeChange} onBlur={this.props.checkZipCode}/>
									</div>

								</div>

								<div className="show_for_desktop">
									<div style={{float:'right', width:'7em'}}>
									<p className="project_titles">Location:</p>
									<p className={"project_titles " + this.props.zipCodeTitle} style={{marginTop:'3.9em'}}>Zip Code:</p>
									</div>
								</div>

							</div>
						</div>

						<div className="clear"></div>
						<div className="lower_navigation">
							<p className={this.state.successSave + " success"} style={{float: 'right', marginRight: '0.8em'}}>{CONSTANTS.errors.successSave}</p>
							<div className="clear"></div>
							<p className={this.props.projectNameError + " error"} style={{float: 'right'}}>{CONSTANTS.errors.projectNameError}</p>
							<div className="clear"></div>
							<p className={this.state.failedSave + " error"} style={{float: 'right'}}>{CONSTANTS.errors.failedSaveCheckFields}</p>

							<div className="clear"></div>
							<input className="lower_nextnav" name="submit" type="submit" value="Review >" onClick={this.props.handleSubmit.bind(null, 4)}/>
							{/*<input className="skipnav" name="skip" onClick={this.props.handleSkip.bind(null, 3)} value="Skip"/>*/}
							<input className={this.props.saveButton + " savenav"} name="submit" type="submit" value="Save" onClick={this.props.handleSave}/>
							<input className="backnav" name="submit" type="submit" value="< Back" onClick={this.props.handleBackButtonClick.bind(null, 1)}/>
						</div>
					</div>
				</div>
			</div>
		);
	}
};
