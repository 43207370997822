// This module will let kreators manage their invites.

import React from 'react';
import PropTypes from 'prop-types';
import HTTP from '../../utils/Http';
import { getLocationSearchParam } from 'utils/Utils';
import history from 'app/history';
import NavBarNotifications from '../nav/NavBar_Notifications.jsx';
import Footer from '../footer/Footer.jsx';
import TeamReceivedInvitation from  './TeamReceivedInvitation.jsx';
import TeamSentInvitation from './TeamSentInvitation.jsx';

export default class TeamManageInvites extends React.Component {

	state = {
		invitesReceived: [],
		invitesSent: [],
		selectedProjectName:'',
		selectedInvitationId: '',
		pointerEvents: 'all',
		inviteOpacity: '1',
		deleteTeammateWarning: 'hidden',
		deleteSentInvitationButtonsVisibility: 'hidden',
		acceptAlertVisibility: 'hidden',
		declineAlertVisibility: 'hidden',
		deleteInviteAlertVisibility: 'hidden',
		invitationResponseSuccessVisibility: 'hidden'
	}

	UNSAFE_componentWillMount = () => {
		const projectId = getLocationSearchParam('projectId');
		if (!USER){
			history.push({
				pathname: '/loginpage',
  				search: '?comingFromPage=teammanageinvites'
  			});
			return;
		}
		HTTP.get(`/invites`, (error, body) => {
			if (error){
				return alert('Could not retrieve invites.');
			}
			this.setState({
				invitesReceived: body.received,
				invitesSent: body.sent
			});
		});
	}

	handleTogglePointerEvents = (active) => {
		if (active) {
			this.setState({pointerEvents: 'all'});
		} else {
			this.setState({pointerEvents: 'none'});
		}
	}

	handleShowAcceptAlert = (inviteId, projectName) => {
		this.setState({
			selectedInvitationId: inviteId,
			selectedProjectName: projectName,
			pointerEvents: 'none',
			inviteOpacity: '0.5',
			acceptAlertVisibility: "visible",
			declineAlertVisibility: "hidden",
			deleteInviteAlertVisibility: "hidden"
		});
	}

	handleHideAcceptAlert = () => {
		this.setState({
			acceptAlertVisibility: "hidden",
			selectedInvitationId: '',
			selectedProjectName: '',
			pointerEvents: 'all',
			inviteOpacity: '1'
		});
	}

	handleAcceptInvitation = () => {
		HTTP.post('/invites/' + this.state.selectedInvitationId + '/respond', {accepted: true}, (err, body) => {
			if (err) {
				return alert('Could not respond to invitation.');
			} else {
				USER.projectsJoined = body.projectsJoined;
				const updatedInviteList = this.createUpdatedInviteList(body._id, true);
				this.setState({
					acceptAlertVisibility: "hidden",
					invitationResponseSuccessVisibility: 'visible_block',
					invitesReceived: updatedInviteList
				});
			}
		});
	}

	handleShowDeclineAlert = (inviteId, projectName) => {
		this.setState({
			selectedInvitationId: inviteId,
			selectedProjectName: projectName,
			pointerEvents: 'none',
			inviteOpacity: '0.5',
			declineAlertVisibility: "visible",
			acceptAlertVisibility: "hidden",
			deleteInviteAlertVisibility: "hidden"
		});
	}

	handleHideDeclineAlert = () => {
		this.setState({
			declineAlertVisibility: "hidden",
			selectedInvitationId:'',
			selectedProjectName:'',
			pointerEvents: 'all',
			inviteOpacity: '1'
		});
	}

	handleDeclineInvitation = () => {
		HTTP.post('/invites/' + this.state.selectedInvitationId + '/respond', {accepted: false}, (err, body) => {
			if (err) {
				return alert('Could not respond to invitation.');
			} else {
				const updatedInviteList = this.createUpdatedInviteList(body._id, true);
				this.setState({
					declineAlertVisibility: "hidden",
					invitationResponseSuccessVisibility: 'visible_block',
					invitesReceived: updatedInviteList
				});

			}
		});
	}

	handleHideInvitationResponseSuccess = () => {
		this.setState({
			invitationResponseSuccessVisibility: 'hidden',
			pointerEvents: 'all',
			inviteOpacity: '1'
		});
	}

	handleShowSentInvitationsDeleteButtons = () => {
		this.setState({deleteSentInvitationButtonsVisibility: 'visible_block'});
	}

	handleHideSentInvitationsDeleteButtons = () => {
		this.setState({deleteSentInvitationButtonsVisibility: 'hidden'});
	}

	handleShowDeleteInviteAlert = (inviteId, isPending, projectName) => {
		if (isPending){
			this.setState({
				selectedInvitationId: inviteId,
				selectedProjectName: projectName,
				pointerEvents: 'none',
				inviteOpacity: '0.5',
				declineAlertVisibility: "hidden",
				acceptAlertVisibility: "hidden",
				deleteInviteAlertVisibility: "visible"
			});
		} else {
			this.setState({
				selectedInvitationId: inviteId,
			});
			this.handleDeleteSentInvite(inviteId);
		}

	}

	handleHideDeleteInviteAlert = () => {
		this.setState({
			deleteInviteAlertVisibility: "hidden",
			pointerEvents: 'all',
			inviteOpacity: '1'
		});
	}

	handleDeleteSentInvite = (inviteId) => {
		HTTP.get('/invites/' + inviteId + '/hide', (error, body) => {
			if (error){
				return alert('Could not delete invite.');
			}
			const updatedInviteList = this.createUpdatedInviteList(inviteId, false);
			this.setState({invitesSent: updatedInviteList});
		});

		if (this.state.deleteInviteAlertVisibility === "visible") {
			this.handleHideDeleteInviteAlert();
		}
	}

	stripHTMLFromString = (htmlString) => {
		var stripedHtml = htmlString.replace(/<[^>]+>/g, '');
		return stripedHtml;
	}

	createUpdatedInviteList = (InviteId, isUpdatingReceivedInvites) => {
		var invitesToUpdate = [];

		if (isUpdatingReceivedInvites){
			invitesToUpdate = this.state.invitesReceived;
		} else {
			invitesToUpdate = this.state.invitesSent;
		}

		var invitesToUpdateFiltered = invitesToUpdate.filter(function(invite, index, arr){
			return invite._id !== InviteId;
		});

		return invitesToUpdateFiltered;
	}

	generateReceivedInvites = () => {

			return this.state.invitesReceived.map(invite => (
				<TeamReceivedInvitation
					key = {invite._id}
					inviteId = {invite._id}
					projectId = {invite.project._id}
					projectName = {invite.project.name}
					projectLeaderId = {invite.project.kreator._id}
					projectLeaderPhoto = {invite.project.kreator.photo}
					projectLeaderFirstName = {invite.project.kreator.firstName}
					projectLeaderLastName = {invite.project.kreator.lastName}
					handleShowAcceptAlert={this.handleShowAcceptAlert}
					handleShowDeclineAlert={this.handleShowDeclineAlert}
					deleteButtonsVisibility={this.state.deleteReceivedInvitationButtonsVisibility}
				/>
			));
	}

	generateSentInvites = () => {
			return this.state.invitesSent.map(invite => (
				<TeamSentInvitation
					key = {invite._id}
					inviteId = {invite._id}
					inviteAccepted = {invite.accepted}
					invitePending = {invite.pending}
					projectId = {invite.project._id}
					projectName = {invite.project.name}
					inviteeId = {invite.invitee._id}
					inviteePhoto = {invite.invitee.photo}
					inviteeFirstName = {invite.invitee.firstName}
					inviteeLastName = {invite.invitee.lastName}
					handleShowDeleteInviteAlert={this.handleShowDeleteInviteAlert}
					deleteButtonsVisibility={this.state.deleteSentInvitationButtonsVisibility}
				/>
			));
	}

	render = () => {
		if(!USER){
			return null;
		}
		const photo = USER.photo ? `/uploads/${USER.photo}` : '/images/add_photo_account.svg';

		return (
			<div className="viewport_width">
				<div style={{background: "white", marginBottom: '-1.5em'}}>
					<NavBarNotifications
						showLoginButton={false}
						showSignupButton={false}
					/>

					<div className="clear"></div>
					<div className={"teammate_invitation_warning " + this.state.acceptAlertVisibility}>
						<p>
							By accepting this invite, you agree to become a member of {this.state.selectedProjectName}. Would you like to proceed?
						</p>
						<div style={{width:"14em", margin: "1em auto"}}>
							<button className="delete_teammate_warning_cancel" onClick={this.handleHideAcceptAlert}>Cancel</button>
							<button className="delete_teammate_warning_cancel" style={{float: "right"}} onClick={this.handleAcceptInvitation}>I&#39;m Sure</button>
						</div>
					</div>
					<div className={"teammate_invitation_warning " + this.state.declineAlertVisibility}>
						<p>
							Are you sure you want to decline this invitation for {this.state.selectedProjectName}?
							This action cannot be reversed and would require a new invite if you change your mind.
						</p>
						<div style={{width:"14em", margin: "1em auto"}}>
							<button className="delete_teammate_warning_cancel" onClick={this.handleHideDeclineAlert}>Cancel</button>
							<button className="delete_teammate_warning_continue" onClick={this.handleDeclineInvitation} style={{float: "right"}}>I&#39;m Sure</button>
						</div>
					</div>
					<div className={"teammate_invitation_warning " + this.state.deleteInviteAlertVisibility}>
						<p>
							Are you sure you want to delete this invitation for {this.state.selectedProjectName}? This invitation has not been accepted yet.
							By deleting it, this kreator will no longer be able to join your team.
						</p>
						<div style={{width:"14em", margin: "1em auto"}}>
							<button className="delete_teammate_warning_cancel" onClick={this.handleHideDeleteInviteAlert}>Cancel</button>
							<button className="delete_teammate_warning_continue" style={{float: "right"}} onClick={() => this.handleDeleteSentInvite(this.state.selectedInvitationId)}>I&#39;m Sure</button>
						</div>
					</div>
					<div className={"teammate_invitation_warning " + this.state.invitationResponseSuccessVisibility} style={{textAlign: "center"}}>
						<p>
							Your response was sent successfully!
						</p>
						<div style={{width:"14em", margin: "1em auto"}}>
							<button className="delete_teammate_warning_cancel" onClick={this.handleHideInvitationResponseSuccess}>Close</button>
						</div>
					</div>
					<div style={{overflowY: 'hidden', marginBottom: '4em'}}>
						<h1 style={{fontSize: '1.5em', textAlign: 'center'}}>Invitations</h1>
						<div className="clear"></div>
						<div style={{pointerEvents: this.state.pointerEvents, opacity: this.state.inviteOpacity}}>
							<div className="team_invite_box">
								<div className="team_invite_box_title_box">
									<span style={{fontSize: '1.5em', float: 'left', color: '#00A4A4'}}>Received</span>
								</div>
								<div className="clear"></div>
								
									{this.generateReceivedInvites()}
								
							</div>
							<div className="clear"></div>
							<div className="team_invite_box">
								<div div className="team_invite_box_title_box">
								<span style={{fontSize: '1.5em', float: 'left', color: '#00A4A4'}}>Sent</span>
								<button style={{cursor: 'pointer', float: 'left', background: 'transparent', marginTop: '0.1em', marginLeft: '1em', textAlign: 'center'}}onClick={this.handleShowSentInvitationsDeleteButtons}>
									<img style={{marginLeft: '-0.45em'}}src="/images/edit_icon.svg" />
								</button>
								<button className={this.state.deleteSentInvitationButtonsVisibility + " invitation_done_button"} onClick={this.handleHideSentInvitationsDeleteButtons}>done</button>
								</div>
								<div className="clear"></div>
								
									{this.generateSentInvites()}
								
							</div>
						</div>
					</div>
					<div className="bottom_padding" ></div>
					<Footer />

				</div>
			</div>
		);
	}
};
