// This is the page that contains the EditProfileDetail module for users to make changes to their profile.

import React from 'react';
import PropTypes from 'prop-types';
import history from 'app/history';
import { Link } from 'react-router-dom';

import NavBarNotifications from '../nav/NavBar_Notifications.jsx';
import ProgressBar from '../signup/ProgressBar.jsx';
import Footer from '../footer/Footer.jsx';
import EditProfileDetail from '../useraccount/EditProfileDetail.jsx';

export default class EditProfilePage extends React.Component {
	state = {
		profileCompleteness: USER.profileCompleteness,
		errorHoursPerWeek: USER.additionalInfo.hoursPerWeek === -1 ? 'visible' : 'hidden',
		errorTeamOrProject: USER.additionalInfo.teamOrProject === -1 ? 'visible' : 'hidden',
		errorTravelRadius: USER.additionalInfo.travelRadius === -1 ? 'visible' : 'hidden',
		errorWhatToGain: USER.additionalInfo.whatToGain === -1 ? 'visible' : 'hidden',
		errorInterests: !USER.interests.length ? 'visible' : 'hidden',
		errorExpertises: !USER.baseSkills.length ? 'visible' : 'hidden',
		errorSchools: !USER.schools.length ? 'visible' : 'hidden',
		errorPhoto: !USER.photo ? 'visible' : 'hidden',
		emailVerified: USER.emailVerified,
		verificationNeeded: 'visible_block',
		showGoToReviewButton: 'visible_block',
		emailSent: 'hidden',
		profileIncomplete: 'hidden',
		profileNotSubmitted: 'hidden',
		profileNotPublished: 'hidden',
		showContinueSignupButton: 'visible_block'
	};

	componentDidMount = () => {

		this.handleShowProperProfileError();
		this.handleShowContinueSignupButton();
		if (this.state.emailVerified === true) {
			this.setState({verificationNeeded: "hidden"});
		}
	}

	handleShowProperProfileError = () => {
		var profileComplete = this.checkProfileCompleteness();
		var underReview = USER.underReview;
		var published = USER.published;

		if (!profileComplete) {
			this.setState({
				profileIncomplete: 'visible_block',
				profileNotSubmitted: 'hidden',
				profileNotPublished: 'hidden'
			});
		} else if(!underReview && !published){
			this.setState({
				profileIncomplete: 'hidden',
				profileNotSubmitted: 'visible_block',
				profileNotPublished: 'hidden'
			});
		} else if(!published){
			this.setState({
				profileIncomplete: 'hidden',
				profileNotSubmitted: 'hidden',
				profileNotPublished: 'visible_block'
			});
		}
	}

	checkProfileCompleteness = () => {
		var profileComplete = true;
		if (USER.baseSkills.length === 0) {
			profileComplete = false;
		} else if (USER.schools.length === 0) {
			profileComplete = false;
		} else if (USER.bio === "") {
			profileComplete = false;
		} else if (USER.interests.length === 0) {
			profileComplete = false;
		} else if (USER.additionalInfo.hoursPerWeek === -1) {
			profileComplete = false;
		} else if (USER.additionalInfo.teamOrProject === -1) {
			profileComplete = false;
		} else if (USER.additionalInfo.travelRadius === -1) {
			profileComplete = false;
		// } else if(USER.additionalInfo.whatToGain === -1) {
		// 	profileComplete = false;
		} else if (USER.photo === "") {
			profileComplete = false;
		}

		return profileComplete;
	}

	handleResendEmail = () => {
		HTTP.get('/profile/email/verify/resend', (err, res) => {
			if (err) return alert('Could not resend verification email.');
			this.setState({ emailSent: 'visible_block'});
		});
	}

	handleProfileCompletenessChange = profileCompleteness => {
		this.setState({ profileCompleteness });
		this.handleShowProperProfileError();
	}

	handleShowContinueSignupButton = () => {
		if(USER.underReview || USER.published){
			this.setState({showContinueSignupButton: 'hidden'});
		}
	}

	handleContinueSignupProcess = () => {
		var path;
		if (USER.baseSkills.length === 0) {
			path = '/signupbaseskills';
		} else if (USER.achievement === '') {
			path = '/signupachievement';
		} else if (USER.schools.length === 0) {
			path = '/signupschools';
		} else if (USER.bio === '') {
			path = '/signupbio';
		} else if (USER.interests.length === 0) {
			path = '/signupinterests';
		} else if (USER.additionalInfo.hoursPerWeek === -1) {
			path = '/signupaddinfo';
		} else if (USER.additionalInfo.teamOrProject === -1) {
			path = '/signupaddinfo';
		} else if (USER.additionalInfo.travelRadius === -1) {
			path = '/signupaddinfo';
		// } else if(USER.additionalInfo.whatToGain === -1) {
		// 	path = '/signupaddinfo';
		} else if(USER.futureGoals === '') {
			path = '/signupfuture';
		} else if (USER.photo === '') {
			path = '/signupphoto';
		} else if (USER.portfolio.links.length === 0) {
			path = '/signupportfolio';
		} else if (USER.fictional === '') {
			path = '/signupfictional';
		} else if (USER.weekend === '') {
			path = '/signupweekend';
		} else {
			path = '/signupreview';
		}

		history.push(path);
	}

	handleGoToReviewPage = () => {
		history.push('/signupreview');
	}

	render = () => {
		const { verificationNeeded, profileCompleteness, ...errors } = this.state

		return (
			<div className="viewport_width">
				<NavBarNotifications
					showLoginButton={false}
					showSignupButton={false}
				/>

				<div className="clear"></div>
				<div className={this.state.verificationNeeded + " verification_bar"}>
					<p>
					Please verify your account using the verification email that was sent to you.<br/>
					Your profile won't be visible to other kreators until it is. Thanks! &nbsp;<span onClick={this.handleResendEmail} style={{cursor:'pointer', textDecoration:'underline'}}>Resend Email</span>
					</p>
				</div>
				<div className={this.state.emailSent + " verification_bar"} style={{color:'black', background: '#B4F4F4'}}>
					<p>
					A new verification email has been sent.
					</p>
				</div>
				<div className={this.state.profileIncomplete + " incomplete_profile_bar"}>
					<p>
					Please fill out all required fields in your profile. Your profile cannot be submitted until they are properly filled out.
					</p>
				</div>
				<div className={this.state.profileNotSubmitted + " incomplete_profile_bar"}>
					<p>
					Please submit your profile for review. Your profile won't be visible to other<br/>
					kreators until it is reviewed then published. Thanks! &nbsp; &nbsp; <Link className="warning_link" to="/signupreview">Go to Review Page</Link>
					</p>
				</div>
				<div className={this.state.profileNotPublished + " incomplete_profile_bar"}>
					<p>
					Your profile has been submitted and is currently under review.
					</p>
				</div>

				<div className="show_for_desktop">
					<div className="signup_progressbar" style={{width:'56em'}}>
						<ProgressBar progress={USER.profileCompleteness} width="56em"/>
					</div>
				</div>

				<div className="show_for_mobile">
					<div className="signup_progressbar" style={{width:'19em'}}>
						<ProgressBar progress={USER.profileCompleteness} width="18em"/>
					</div>
				</div>

				<div className="clear"></div>
				<div className={this.state.showContinueSignupButton + ' continue_signup_box'}>
					<p> You can edit specific information about yourself on this page or
					continue where you left off in the signup process by clicking on the
					button below.
					</p>
					<button className="continue_signup_button" style={{marginTop: "1em"}} onClick={this.handleContinueSignupProcess}>Continue Signing Up</button>
				</div>
				<div className="clear"></div>
				<EditProfileDetail
					handleProfileCompletenessChange={this.handleProfileCompletenessChange}
					{...errors}
				/>

				<div className="clear"></div>
				<div className="bottom_padding"></div>
				<Footer />
			</div>
		);
	}
};