// This is the page that will let the user see an update to a project. It is used by ProjectUpdatesBox.

import React from 'react';
import PropTypes from 'prop-types';

export default class ProjectUpdate extends React.Component {
	static contextTypes = {
		router: PropTypes.object
	}

	static propTypes = {
		updateId: PropTypes.string,
		text: PropTypes.string,
		dateString: PropTypes.string,
		timeString: PropTypes.string,
		handleEditUpdate: PropTypes.func,
		canBeEditedByUSER: PropTypes.bool
	}

	render = () => {
		var handleEditUpdate = this.props.handleEditUpdate;
		const updateId = this.props.updateId;
		var editButtonVisibility = "hidden";
		if (this.props.canBeEditedByUSER){
			editButtonVisibility = "visible_block";
		}
		var reportButtonVisibility = "visible_block";
		if (!USER){
			reportButtonVisibility = "hidden";
		}
		
		return (
			<div className="project_update">
				<div className="project_imageupdatebox">
					<div dangerouslySetInnerHTML={{ __html: this.props.text }} />
					<span>{this.props.dateString} {this.props.timeString}</span>
					<button className={"edit_update_button " + editButtonVisibility} onClick={function() { handleEditUpdate(updateId); }}>edit update</button>
					<div className="clear"></div>
					<button className={"delete_comment_button " + reportButtonVisibility} onClick={() => this.props.handleShowReportBox(this.props.updateId, "ProjectUpdate")}>report</button>
				</div>
			</div>
		);
	}
};