import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import HTTP from '../../utils/Http';

export default class Footer extends React.Component {
	static contextTypes = {
		router: PropTypes.object
	}

	render = () => {
		const logoutButtonClass = USER ? 'visible' : 'hidden';

		return (
			<div className="footer">
				<p><Link to="/">KreatorBus</Link></p>
				<p><Link to="/contact">Contact</Link></p>
				<p><Link to="/about">About</Link></p>
			</div>
		);
	}
};
