// This module is needed for the Signup School page for adding a course.
// This let's the user select which school a course is associated with.

import React from 'react';
import PropTypes from 'prop-types';

export default class AddOption extends React.Component {
	static propTypes = {
		id: PropTypes.string,
		schoolName: PropTypes.string
	}
	render = () => {
		return (
			<option value={this.props.id}>&nbsp;{this.props.schoolName}</option>
		);
	}
};