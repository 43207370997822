// This is the module needed for the Signup Portfolio page in order to add a link.

import React from 'react';
import PropTypes from 'prop-types';

export default class AddLink extends React.Component {
	static propTypes = {
		name: PropTypes.string,
		deletefunc: PropTypes.func
	}
	render = () => {
		return (
			<div>
				<li>
					<span>{this.props.name}</span>
					<button className="input_dellink" onClick={this.props.deletefunc}>delete</button>
					<br />
				</li>
			</div>
		);
	}
};