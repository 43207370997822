import React from 'react';
import Dropzone from 'react-dropzone';
import ReactCrop from 'react-image-crop';

import {base64StringtoFile, downloadBase64File, extractImageFileExtensionFromBase64, image64toCanvasRef} from './ResuableUtils';
import { Link } from 'react-router-dom';
import HTTP from '../../utils/Http';
import PropTypes from 'prop-types';

const imageMaxSize = CONSTANTS.maxFileSizeUpload;
const acceptedFileTypes = 'image/png, image/jpg, image/jpeg'
class ReactImageCropper extends React.Component {

	constructor(props){
		super(props)
		this.imagePreviewCanvasRef = React.createRef();
		this.imageLoadedRef = React.createRef();
		this.state={
			imgSrc: null,
			imgSrcExt: null,
			crop: {aspect: this.props.aspect, width: this.props.width, minWidth: this.props.minWidth}
		}
	}

	handleOnDrop = (files, rejectedFiles) => {
		if(rejectedFiles && rejectedFiles.length > 0){

			const currentRejectedFile = rejectedFiles[0];
			const currentRejectedFileType = currentRejectedFile.file.type;
			const currentRejectedFileSize = currentRejectedFile.file.size;
			if(currentRejectedFileSize > imageMaxSize){
				alert("This file is too big");
				this.props.showFileTooBigWarning();
			}
		}

		if(files && files.length > 0){
			const currentFile = files[0];
			
			const reader = new FileReader();
			reader.addEventListener('load', ()=>{
				const readerResult = reader.result;

				this.setState({
					imgSrc: readerResult,
					imgSrcExt: extractImageFileExtensionFromBase64(readerResult) //sets extension of file
				})
			}, false);

			reader.readAsDataURL(currentFile);
		}
		
	}

	handleImageLoaded = (image) =>{
		const imageRef = this.imageLoadedRef.current;
		imageRef.src = image.src;
		if(this.props.displayCropBox !== null){
			this.props.displayCropBox();
		}
		
		
	}

	handleOnCropChange = (crop) => {
		this.setState({crop: crop});
	}

	handleOnCropComplete = (crop, pixelCrop)=>{
		const canvasRef = this.imagePreviewCanvasRef.current;
		const {imgSrc} = this.state;
		const imageRef = this.imageLoadedRef.current;
		const imageCurrent = document.getElementsByClassName("ReactCrop__image")[0];

		image64toCanvasRef(canvasRef, imgSrc, crop, imageRef, imageCurrent);
	}

	handleDownloadClick = (event)=> {
		event.preventDefault();
		const {imgSrc} = this.state;
		if(imgSrc){
			const canvasRef = this.imagePreviewCanvasRef.current;
			const {imgSrcExt} = this.state;
			const imageData64 = canvasRef.toDataURL('image/' + imgSrcExt);
			
			
			const fileName = "previewFile." + imgSrcExt;

			//file to be uploaded
			//creates a file from the base64string
			const newCroppedFile = base64StringtoFile(imageData64, fileName);
			this.props.loadCroppedFile(newCroppedFile, imageData64);
			if(this.props.hideCropBox !== null){
				this.props.hideCropBox();
			}
			
			this.handleClearToDefault();
		}
		
	}

	handleClearToDefault = (event) => {
		if (event) event.preventDefault();
		const canvas = this.imagePreviewCanvasRef.current;
		const ctx = canvas.getContext('2d');
		ctx.clearRect(0, 0, canvas.width, canvas.height);

		this.setState({
			imgSrc: null,
			imgSrcExt: null,
			crop:{aspect: this.props.aspect, width: this.props.width, minWidth: this.props.minWidth}
		});
	}

	render() {
		const {imgSrc} = this.state;
		
		return (
			<div>
				{imgSrc !== null ?
					<div className={this.props.isSignupPhotoPage ? "react_cropper_box_signup_photo" : "react_cropper_box_project"}>
						<ReactCrop
							src={imgSrc}
							crop={this.state.crop}
							onImageLoaded={this.handleImageLoaded}
							onComplete={this.handleOnCropComplete}
							onChange={this.handleOnCropChange}
							/>
						<br/>
						<canvas style={{display:'none'}}ref={this.imagePreviewCanvasRef}></canvas>

						<img style={{display:'none'}}ref={this.imageLoadedRef}/>
						
						<button onClick={this.handleDownloadClick}>Crop</button>
					</div>	

					:

					<Dropzone
						onDrop={this.handleOnDrop}
						maxSize={imageMaxSize}
						multiple={false}
						accept={acceptedFileTypes}
					>
					  {({getRootProps, getInputProps}) => (
				  			<section className={this.props.isSignupPhotoPage ? "react_cropper_section" : "react_cropper_section_project"}>
				  				<div className={this.props.isSignupPhotoPage ? "" : "project_placeholder"}>
				  				<div className={this.props.isSignupPhotoPage ? "hidden" : "visible_block"}>
					  				Choose an image to represent your project.<br/>
									<span>This can be a logo, a photo, a piece<br/>
									of artwork you drew etc.
									</span><br/>
								</div>
						      	<div {...getRootProps()} style={this.props.isSignupPhotoPage ? {} : {width:'7em', margin:'auto'}}>
							      	
							      		<div className={this.props.isSignupPhotoPage ? "visible_block" : "hidden"}>
									      	<img height="172" width="172" src={this.props.file} style={{ cursor: 'pointer' }} />
									      	<div className="photo_box_button">choose photo</div>
											<div className="clear"></div>
										</div>
										<div className={this.props.isSignupPhotoPage ? "hidden" : "visible_block"}>
											<img className="project_imagebox_image" src={this.props.file} style={{ cursor: 'pointer' }} /><br/>
											<div className="project_imagebox_button" style={{cursor: 'pointer'}}>+add image</div>
								  		</div>
								  	<input {...getInputProps()}/>
						      	</div>
						      	<br/>
						      	<div className={this.props.isSignupPhotoPage ? "hidden" : "visible_block"}>
								<span>Don't know what image to use?
									<div className="show_for_mobile"><br/></div>
									<Link to="/imagesuggestions" target="_blank">click here</Link>
								</span>
								</div>
								</div>
					    	</section>
					  )}
					</Dropzone>
				}
				
			</div>
		);
	}
};

export default ReactImageCropper