// This is a summary of a project used in search pages and the home page.

import React from 'react';
import PropTypes from 'prop-types';
import { QueryLink } from 'utils/Utils';

export default class ProjectSummary extends React.Component {
	static propTypes = {
		project: PropTypes.object
	}

	state = {
		baseSkills: [],
		timeTitle: '',
		numberOfDays: ''
	}

	UNSAFE_componentWillMount = () => {
		this.generateStartDate(this.props.project.startDate);
	}

	generateBaseSkillImages = baseSkill => {
		if (baseSkill == 0) {
   			return <img key="0" src="/images/business.svg" />
    		}
   		if (baseSkill == 1) {
   			return <img key="1" src="/images/artist.svg" />
   		}
   		if (baseSkill == 2) {
   			return <img key="2" src="/images/scribe.svg" />
    		}
  		if (baseSkill == 3) {
   			return <img key="3" src="/images/engineer.svg" />
 		}
	}

	generateCategory = (categoryId) => {

		var categoryText = "";
		CONSTANTS.interests.forEach( function(interest) {
			if (categoryId === interest.id) {
				categoryText = interest.text;
			}
		});

		return categoryText;
	}

	createBaseSkillsArray(roleList) {
		return roleList
			.map(role => role.baseSkill)
			.filter((item, index, array) => array.indexOf(item) === index)
			.sort();
	}

	generateStartDate = startDate => {
		var timeTitle;
		if (!startDate) {
			this.setState({numberOfDays: 0});
		} else {
			var startDateObject = new Date(this.props.project.startDate);
			var currentDate = new Date();
			var oneDay = 24 * 60 * 60 * 1000; // hours * minutes * seconds * milliseconds

			var daysToStart = Math.round((startDateObject.getTime() - currentDate.getTime()) / oneDay);

			if (daysToStart >= 0) {
				timeTitle = "Starts in: ";
			} else if (daysToStart < 0) {
				timeTitle = "Going for: "
			}

			this.setState({ numberOfDays: Math.abs(daysToStart) });
		}

		this.setState({ timeTitle: timeTitle });
	}

	render() {
		var startDate = "0";
		var roleList = this.props.project.lookingFor;

		var baseSkillsArray = this.createBaseSkillsArray(roleList);
		var categoryId = this.props.project.category;
		var category = this.generateCategory(categoryId);
		return (
			<div className="project_summary" onClick={this.handleProjectClick}>
				<QueryLink to={{ pathname:"/projectmainpage", query: { projectId: this.props.project._id }}}>
					<img className="main_image" src={"/uploads/" + this.props.project.logo} />
					<div className="clear"></div>
					<span className="category">{category}</span>
					<span className="start">{this.state.timeTitle} {this.state.numberOfDays} days</span>
					<div className="clear"></div>
					<span className="project_h3">{this.props.project.name}</span>
					<span className="project_course_h3" style={{float: 'right'}}>{this.props.project.course}</span>
					<span className="project_description">{this.props.project.tagline}</span>
					<span className="project_status">{this.props.project.status}</span>
					<span className="project_h3" style={{padding: "0em 0em 0em 1em"}}>Looking for:</span>
					<div className="skills">
						{baseSkillsArray.map(this.generateBaseSkillImages)}
					</div>
				</QueryLink>
			</div>
		);
	}
};