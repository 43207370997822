import SuperAgent from 'superagent';

const logError = (error, url, verb) =>
	console.error(`Error fetching '${verb.toUpperCase()} ${url}':`, error);

var service = {};

['get', 'post', 'patch', 'delete'].forEach(verb => {
	service[verb] = (url, body, callback) => {
		switch (typeof body) {
		case 'function':
			callback = body;
			body = {};
			break;

		case 'undefined':
			body = {};
		}

		var action = SuperAgent[verb](url)[verb === 'get' ? 'query' : 'send'](body);

		return callback ?
			action.end((error, response) => {
				error && logError(error, url, verb);

				callback(error, response ? response.body || response.text : null);
			}) : action;
	};
});

export default service;
