import React from 'react';
import PropTypes from 'prop-types';

import NavBarNotifications from '../nav/NavBar_Notifications.jsx';
import Footer from '../footer/Footer.jsx';

export default class LogOutPage extends React.Component {
	static contextTypes = {
		router: PropTypes.object
	}

	render = () => {
		return (
			<div className="viewport_width">
				<NavBarNotifications
					showLoginButton={true}
					showSignupButton={false}
				/>
				<div className="signup_hero">
					<img src="images/signup_finish.svg" />
				</div>

				<div className="clear"></div>

				<div>
					<div className="signup_container">
						<div className="finish_container">
							<h4>You have successfully logged out!</h4>
							<h5>Come back soon!</h5>
						</div>
					</div>
				</div>
				<Footer />
			</div>
		);
	}
};
