// A bank profile summary is a brief summary of a users profile used in the kreatorbank.

import React from 'react';
import { QueryLink } from 'utils/Utils';
import PropTypes from 'prop-types';

export default class BankProfileSummary extends React.Component {
	static propTypes = {
		kreator: PropTypes.object,
		handleDeleteKreatorBank: PropTypes.func,
		deleteButton: PropTypes.string
	}

	generateSchool = () => {
		// console.log(this.props.kreator);
		// console.log(this.props.kreator.schools[0].school.hasOwnProperty("names"));
		if(this.props.kreator.schools[0].school.hasOwnProperty("names")){
			
			var schoolName = this.props.kreator.schools[0].school.names[0];
			var display = this.props.kreator.schools[0].attending ? "visible_block" : "hidden";
				
			return (
				<div key={this.props.kreator.schools[0].school._id}>
					<p><span className={"middle_dot " + display}>&middot;</span>{schoolName}</p>
				</div>
			);
		}
		
	}

	generateBaseSkills = (baseSkill, index) => {
		return <img key={index} src={`/images/${(CONSTANTS.baseSkills.find(bs => bs.id === baseSkill) || {}).text.toLowerCase()}.svg`} />;
	}

	generateExpertises = () => {
		var listOfExpertiseStrings = this.props.kreator.expertises
			.concat(this.props.kreator.expertisesOther.map(expertise => expertise.text))
			.join(', ');

		if (listOfExpertiseStrings.length > 40) {
			var expListSubstr = listOfExpertiseStrings.substr(0, 40);
			return expListSubstr.substr(0, expListSubstr.lastIndexOf(',')) + '...';
		}

		return listOfExpertiseStrings;
	}

	generateSkills = () => {
			var arrayOfSkills = this.props.kreator.skills;
			if (arrayOfSkills !== null){
				var listOfSkills = arrayOfSkills.join(', ');
				if (listOfSkills.length > 40) {
					var skillListSubstr = listOfSkills.substr(0, 40);
					return skillListSubstr.substr(0, skillListSubstr.lastIndexOf(',')) + '...';
				}

				return listOfSkills;
			}
	}

	generateInterests = () => {
		var listOfInterestStrings = this.props.kreator.interests
			.map(interestNumber => (CONSTANTS.projectCategories[interestNumber] || {}).text)
			.join(', ');

		if (listOfInterestStrings.length > 40) {
			var interestListSubstr = listOfInterestStrings.substr(0, 40);
			return interestListSubstr.substr(0, interestListSubstr.lastIndexOf(',')) + '...';
		}

		return listOfInterestStrings;
	}

	handleDeleteKreatorBank = () => {
		return this.props.handleDeleteKreatorBank(this.props.kreator._id);
	}

	render = () => {
		return (
			<div>
				<div className="profile_summarybox">
					<div className="profile_summary">
						<QueryLink to={{ pathname:"/profilepage", query: { profileId: this.props.kreator._id} }}>
							<div className="profile_summary_pic">
								<img src={"/uploads/" + this.props.kreator.photo} />
								<div className="clear"></div>
								<div className="profile_summary_name">{this.props.kreator.firstName} {this.props.kreator.lastName}</div>
							</div>

							<div className="profile_summary_info">
								{this.generateSchool()}
								<div className="clear"></div>
								<div className="text_style">{this.props.kreator.location}</div>
								<div className="clear"></div><br/>
								<div className="text_style" style={{marginBottom: '0.5em'}}>Skills:</div>
								<div className="profile_summary_skills">
									{this.props.kreator.baseSkills.map(this.generateBaseSkills)}
								</div>
								<div className="clear"></div>
								<div style={{height: '4em', overflow: 'hidden'}}>
									<p>{this.generateExpertises()}</p>
								</div>
								<div style={{height: '4em', overflow: 'hidden'}}>
									<p>{this.generateSkills()}</p>
								</div>
								<div style={{height: '4em', overflow: 'hidden'}}>
									<p>{this.generateInterests()}</p>
								</div>
							</div>
						</QueryLink>
					</div>
					<div className="clear"></div>
					<div className={"bank_delete_buttonbox " + this.props.deleteButton}>
						<button className="bank_delete_button" onClick={this.handleDeleteKreatorBank}>delete</button>
					</div>
				</div>
			</div>
		);
	}
};