// A profile link is for the portfolio links that a user may have to other websites.
// This is used by the ProfileDetail module.

import React from 'react';
import PropTypes from 'prop-types';

export default class ProfileLink extends React.Component {
	static propTypes = {
		link: PropTypes.string
	}
	render = () => {
		var stringLink = this.props.link;
		var finalLink = stringLink.length > 27 ? stringLink.substr(0, 27) + "..." : stringLink;

		return (
			<div>
				<li><a href={this.props.link}>{finalLink}</a></li>
			</div>
		);
	}
};
