// This is the search page to search for team members.

import React from 'react';
import HTTP from '../../utils/Http';

import ProfileSummary from './ProfileSummary.jsx';
import NavBarNotifications from '../nav/NavBar_Notifications.jsx';
import Footer from '../footer/Footer.jsx';

export default class ProfileSearch extends React.Component {
	constructor(props) {
    	super(props);
		this.state = {
			mainSearchBar: '',
			searchResults: [],
			searchOptions: false,
			noMoreResults: false,
			shouldShowResultsBox: true,

			expertises: [],
			skills: [],
			interests: [],

			skillsText: '',
			school: '',
			course: '',

			baseSkill: -1,
			travelRadius: -1,
			hoursPerWeek: -1,
			teamOrProject: -1,
			country: '',

			schoolText: '',
			autoCompleteSchools: [],
			displayAutoCompleteSchools: false,
			courseInputDisabled: true,
			courseInputPlaceholder: "Input School First",
			page: 1,
			scrolling: false,
			noResultsVisibility: 'hidden'
		}
	}


	UNSAFE_componentWillMount = () => {
		this.setLocalStorage();
		this._schoolInputTimer = -1;
		this.loadSearchParameters();
	}

	componentDidMount = () => {
		this.onSearch()
		var profileResults = document.getElementById("profile_results");
		this.scrollListener = profileResults.addEventListener('scroll', (e) => {
			this.handleScroll(e)
		});
	}

	componentWillUnmount = () => {
		var profileResults = document.getElementById("profile_results");
		profileResults.removeEventListener('scroll', this.handleScroll);
	}

	setLocalStorage = () => {
		if(localStorage.getItem('mainSearchBarProfile') === null){
			localStorage.setItem('mainSearchBarProfile', this.state.mainSearchBar);
		}
		if(localStorage.getItem('expertisesProfile') === null){
			localStorage.setItem('expertisesProfile', this.state.expertises);
		}
		if(localStorage.getItem('skillsTextProfile') === null){
			localStorage.setItem('skillsTextProfile', this.state.skillsText);
		}
		if(localStorage.getItem('skillsProfile') === null){
			localStorage.setItem('skillsProfile', this.state.skills);
		}
		if(localStorage.getItem('interestsProfile') === null){
			localStorage.setItem('interestsProfile', this.state.interests);
		}
		if(localStorage.getItem('schoolTextProfile') === null){
			localStorage.setItem('schoolTextProfile', this.state.schoolText);
		}
		if(localStorage.getItem('schoolProfile') === null){
			localStorage.setItem('schoolProfile', this.state.school);
		}
		if(localStorage.getItem('courseProfile') === null){
			localStorage.setItem('courseProfile', this.state.course);
		}
		if(localStorage.getItem('baseSkillProfile') === null){
			localStorage.setItem('baseSkillProfile', this.state.baseSkill);
		}
		if(localStorage.getItem('travelRadiusProfile') === null){
			localStorage.setItem('travelRadiusProfile', this.state.travelRadius);
		}
		if(localStorage.getItem('hoursPerWeekProfile') === null){
			localStorage.setItem('hoursPerWeekProfile', this.state.hoursPerWeek);
		}
		if(localStorage.getItem('teamOrProjectProfile') === null){
			localStorage.setItem('teamOrProjectProfile', this.state.teamOrProject);
		}
		if(localStorage.getItem('countryProfile') === null){
			localStorage.setItem('countryProfile', this.state.country);
		}
	}

	saveSearchParameters = () => {
		localStorage.setItem('mainSearchBarProfile', this.state.mainSearchBar);
		localStorage.setItem('expertisesProfile', this.state.expertises);
		localStorage.setItem('skillsTextProfile', this.state.skillsText);
		localStorage.setItem('skillsProfile', this.state.skills);
		localStorage.setItem('interestsProfile', this.state.interests);

		localStorage.setItem('schoolTextProfile', this.state.schoolText);
		localStorage.setItem('schoolProfile', this.state.school);
		localStorage.setItem('courseProfile', this.state.course);

		localStorage.setItem('baseSkillProfile', this.state.baseSkill);
		localStorage.setItem('travelRadiusProfile', this.state.travelRadius);
		localStorage.setItem('hoursPerWeekProfile', this.state.hoursPerWeek);
		localStorage.setItem('teamOrProjectProfile', this.state.teamOrProject);
		localStorage.setItem('countryProfile', this.state.country);
	}

	loadSearchParameters = () => {

		if (localStorage.getItem('mainSearchBarProfile') !== ''){
			this.setState({mainSearchBar: localStorage.getItem('mainSearchBarProfile')});
		}
		if (localStorage.getItem('expertisesProfile') !== ''){
			this.setState({
				expertises: [localStorage.getItem('expertisesProfile')],
				searchOptions: true
			});
		}
		if (localStorage.getItem('skillsProfile') !== ''){
			const skillsText = localStorage.getItem('skillsTextProfile');
			const skills = localStorage.getItem('skillsProfile');
			const savedSkillsArray = skills.split(/[, ]/g).filter(skill => skill);
			this.setState({
				skillsText: skillsText,
				skills: savedSkillsArray,
				searchOptions: true
			});
		}
		if (localStorage.getItem('interestsProfile') !== ''){
			const interests = localStorage.getItem('interestsProfile');
			const savedInterestsArray = interests.split(/[, ]/g).filter(interest => interest);
			this.setState({
				interests: savedInterestsArray,
				searchOptions: true
			});
		}
		if (localStorage.getItem('schoolTextProfile') !== ''){
			this.setState({
				schoolText: localStorage.getItem('schoolTextProfile'),
				searchOptions: true
			});
		}
		if (localStorage.getItem('schoolProfile') !== ''){
			this.setState({
				school: localStorage.getItem('schoolProfile'),
				courseInputDisabled: false,
				courseInputPlaceholder: "Ex: KB101",
				searchOptions: true
			});
		}
		if (localStorage.getItem('courseProfile') !== ''){
			this.setState({
				course: localStorage.getItem('courseProfile'),
				searchOptions: true
			});
		}
		if (localStorage.getItem('baseSkillProfile') !== '-1'){
			this.setState({
				baseSkill: localStorage.getItem('baseSkillProfile'),
				searchOptions: true
			});
		}
		if (localStorage.getItem('travelRadiusProfile') !== '-1'){
			this.setState({
				travelRadius: localStorage.getItem('travelRadiusProfile'),
				searchOptions: true
			});
		}
		if (localStorage.getItem('hoursPerWeekProfile') !== '-1'){
			this.setState({
				hoursPerWeek: localStorage.getItem('hoursPerWeekProfile'),
				searchOptions: true
			});
		}
		if (localStorage.getItem('teamOrProjectProfile') !== '-1'){
			this.setState({
				teamOrProject: localStorage.getItem('teamOrProjectProfile'),
				searchOptions: true
			});
		}
		if (localStorage.getItem('countryProfile') !== ''){
			this.setState({
				country: localStorage.getItem('countryProfile'),
				searchOptions: true
			});
		}
	}

	handleScroll = (e) => {
		const { scrolling, page } = this.state
		if (scrolling) return

		const profileResults = document.getElementById("profile_results");
		const lastComponent = document.querySelector('#profile_results > div:last-child');
		var lastComponentOffset = 0;
		if (lastComponent !== null) {
			lastComponentOffset = lastComponent.offsetTop + lastComponent.clientHeight;
		}

		const pageOffset = profileResults.scrollTop;
		var bottomOffset = 0;

		//Prevents this.state.page from advancing when new search is done and lastComponentOffset initially sets to 0.
		if (lastComponentOffset > 0){
			bottomOffset = 1000;
		}
		//console.log("pageOffset:" + pageOffset, "lastComponentOffset:" + lastComponentOffset, "bottomOffset:" + bottomOffset);
		if(this.state.noMoreResults === false){
			if (pageOffset > lastComponentOffset - bottomOffset && pageOffset > 0) this.loadMore();
		}

	}

	loadMore = () => {
		this.setState(prevState => ({
			page: prevState.page + 1,
			scrolling: true,
		}), this.onSearch);

	}

	onSearch = () => {
		HTTP.post('/kreator', { ...this.state, searchResults: null }, (error, body) => {
			if (error) return alert('Could not fetch kreators.');

			let noResultsVisibility;
			if (!body.length && !this.state.searchResults.length) {
				window.scrollTo(0, 0);
				noResultsVisibility = 'visible';
			} else {
				noResultsVisibility = 'hidden';
			}

			this.setState({
				noResultsVisibility,
				searchResults: [...this.state.searchResults, ...body ],
				scrolling: false
			});

			if (body.length == 0){
				this.setState({noMoreResults: true});
			}

			this.saveSearchParameters();
		});
	}

	newSearch = (hideMoreSearch) => {
		const newState = {};

		// check if school input is "dirty"/invalid
		if (!this.state.school && this.state.autoCompleteSchools.length) {
			Object.assign(newState, {
				autoCompleteSchools: [],
				schoolText: '',
				displayAutoCompleteSchools: false
			});
		}

		if(hideMoreSearch){
			this.setState({
				shouldShowResultsBox: true,
				searchOptions:false
			});
		}

		this.setState(prevState => ({
			...newState,
			page: 1,
			searchResults: [],
			noMoreResults: false,
		}), this.onSearch);
	}

	toggleSearchOptions = () => {
		this.setState({ searchOptions: !this.state.searchOptions });
	}

	toggleSearchOptionsMobile = e => {
		this.setState({
			searchOptions: !this.state.searchOptions,
			shouldShowResultsBox: !this.state.shouldShowResultsBox
		});
	}

	onMainSearchBarUpdate = e => {
		this.setState({ mainSearchBar: e.target.value });
	}

	getSchoolAutoCompleteList = () => {
		if (!this.state.schoolText) {
			return;
		}

		HTTP.get(`/schools?query=${this.state.schoolText}`, (error, object) => {
			if (error) {
				return;
			}

			this.setState({ autoCompleteSchools: object.schools || [] });
		});
	}

	generateAutoCompleteSchools = school => {
		return(
			<div key={school._id}>
				<li className="auto_complete_item" data-id={school._id} data-school={school.names[0]} onClick={this.handleAutoComplete} >{school.names[0]}</li>
			</div>
		);
	}

	handleAutoComplete = e => {
		this.setState({
			school: e.target.dataset.id,
			schoolText: e.target.dataset.school,
			courseInputDisabled:false,
			courseInputPlaceholder: "Ex: KB101",
			displayAutoCompleteSchools: false
		});
	}

	onSchoolUpdate = e => {
		this.setState({
			displayAutoCompleteSchools: !!e.target.value,
			schoolText: e.target.value,
			school: '',
			courseInputDisabled: true,
			courseInputPlaceholder:'Input School First',
			course: ''
		}, () => {
			if (this.state.schoolText && this._schoolInputTimer === -1) {
				this._schoolInputTimer = setTimeout(function() {
					this.getSchoolAutoCompleteList();

					this._schoolInputTimer = -1;
				}.bind(this), 100);
			}
		});
	}

	// when user blurs school input, check if "dirty"/invalid
	onSchoolBlur = e => {
		const schoolText = this.state.schoolText.trim();
		if(schoolText === ''){
			this.setState({
				autoCompleteSchools: [],
				courseInputDisabled:true,
				courseInputPlaceholder: 'Input School First',
				school:'',
				course:'',
				displayAutoCompleteSchools: false
			});
		}
		if (this.state.autoCompleteSchools.length || this.state.school !== '') {
			return
		}


		this.setState({
			schoolText: '',
			courseInputDisabled:true,
			courseInputPlaceholder: 'Input School First',
			school:'',
			course:'',
			displayAutoCompleteSchools: false
		});


	}

	onResetFilters = () => {
		this.setState ({
			expertises: [],
			skills: [],
			interests: [],

			school: '',
			course: '',

			baseSkill: -1,
			travelRadius: -1,
			hoursPerWeek: -1,
			teamOrProject: -1,
			schoolText: '',
			skillsText: '',
			country:''
		});
	}

	onCourseUpdate = e => {
		this.setState({ course: e.target.value });
	}

	onBaseSkillUpdate = e => {
		this.setState({ baseSkill: +e.target.value });
	}

	onExpertisesUpdate = e => {
		this.setState({ expertises: +e.target.value === -1 ? [] : [e.target.value] });
	}

	onSkillsUpdate = e => {
		this.setState({
			skillsText: e.target.value,
			skills: e.target.value.split(/[, ]/g).filter(skill => skill)
		});
	}

	onTravelRadiusUpdate = e => {
		this.setState({ travelRadius: +e.target.value });
	}

	onHoursPerWeekUpdate = e => {
		this.setState({ hoursPerWeek: +e.target.value });
	}

	onTeamOrProjectUpdate = e => {
		this.setState({ teamOrProject: +e.target.value });
	}

	onInterestsUpdate = e => {
		var interests = [...this.state.interests];
		var value = e.target.value;

		var potentialValueIndex = interests.indexOf(value);
		if (potentialValueIndex === -1) {
			interests.push(value);
		} else {
			interests.splice(potentialValueIndex, 1);
		}

		interests.forEach(function(interest){
			if(interest === "-1"){
				interests = [];
			}
		})

		this.setState({ interests }); // +e.target.value === -1 ? [] : [+e.target.value]
	}

	onCountryChangeUpdate = e => {
		this.setState({ country: e.target.value });
	}

	onEnterKeyPressed = (e) => {
		if (e.key === 'Enter') {
			this.newSearch();
		}
	}
	generateBaseSkillOption = baseSkill => {
		return <option key={baseSkill.id} value={baseSkill.id}>{baseSkill.text}</option>;
	}

	generateExpertiseOption = expertise => {
		return <option key={expertise.id} value={expertise.id}>{expertise.id}</option>;
	}

	generateTravelRadiusOption = travelRadius => {
		return <option key={travelRadius.id} value={travelRadius.id}>{travelRadius.text}</option>;
	}

	generateHoursPerWeekOption = hoursPerWeek => {
		return <option key={hoursPerWeek.id} value={hoursPerWeek.id}>{hoursPerWeek.text}</option>;
	}

	generateTeamOrProjectOption = teamOrProject => {
		return teamOrProject.id === 2 ? false : <option key={teamOrProject.id} value={teamOrProject.id}>{teamOrProject.text}</option>;
	}

	generateProjectCategoryOption = projectCategory => {
		return <option key={projectCategory.id} value={projectCategory.id}>{projectCategory.text}</option>;
	}

	generateCountryOptions = option => {
		return <option key={option.code} value={option.code}>{option.name}</option>
	}

	generateSummary = kreator => {
		return <ProfileSummary
			key={kreator._id}
			kreator={kreator}
			mainSearchBar={this.state.mainSearchBar}
			expertises={this.state.expertises}
			skills={this.state.skills}
			interests={this.state.interests}
			school={this.state.school}
			schoolText={this.state.schoolText}
			course={this.state.course}
			searching={true}
		/>;
	}

	render = () => {

		var filtersVisibilityTitle;

		if (this.state.searchOptions) {
			filtersVisibilityTitle = "hide filters";
		} else {
			filtersVisibilityTitle = "use filters";
		}

		var expertiseValue = "-1";
		if (this.state.expertises.length > 0){
			expertiseValue = this.state.expertises[0];
		}

		return (
			<div className="viewport_width" style={{overflowY:'hidden'}}>
				<NavBarNotifications
					showLoginButton={true}
					showSignupButton={true}
				/>

				<div className="master_search_box">
					<div className="search_barbox">
						<input
							type="text"
							placeholder="Search Profiles"
							value={this.state.mainSearchBar}
							onChange={this.onMainSearchBarUpdate}
							onKeyPress={this.onEnterKeyPressed}
						/>
						<div className="show_for_desktop">
							<button onClick={() => this.newSearch(false)}> </button>
						</div>
						<div className="show_for_mobile">
							<button onClick={() => this.newSearch(true)}> </button>
						</div>
					</div>

					<div className="show_for_desktop">
						<button className="more_search_button" onClick={this.toggleSearchOptions}>&nbsp;&nbsp;&nbsp;{filtersVisibilityTitle}</button>
					</div>

					<div className="show_for_mobile">
						<button className="more_search_button" onClick={this.toggleSearchOptionsMobile}>&nbsp;&nbsp;&nbsp;{filtersVisibilityTitle}</button>
					</div>

					<div className="clear"></div>
					<div className={`more_search_options_box ${this.state.searchOptions ? 'visible_block' : 'hidden'}`}>
						<button className="reset_filters_button" onClick={this.onResetFilters}>&nbsp;&nbsp;&nbsp;reset filters</button>
						<div className="main_search_options">
						{/*
						<p>B.A.S.E. Skill:</p>
						<select value={this.state.baseSkill} onChange={this.onBaseSkillUpdate}>
							<option value="-1">B.A.S.E. skill</option>
							{ CONSTANTS.baseSkills.map(this.generateBaseSkillOption) }
						</select>
						*/}
						<p>Expertise:</p>
						<select value={expertiseValue} onChange={this.onExpertisesUpdate}>
							<option value="-1">Expertise</option>
							{ CONSTANTS.expertises.map(this.generateExpertiseOption) }
						</select>

						<p>Special Skills:</p>
						<input type="text" value={this.state.skillsText} onChange={this.onSkillsUpdate} />
						<div className="clear"></div>
						</div>

						<div className="more_search_options">
							<p style={{marginTop:'1em'}}>School:</p>
							<input
								type="text"
								placeholder="Ex: Santa Clara University"
								value={this.state.schoolText}
								onChange={this.onSchoolUpdate}
								onBlur={this.onSchoolBlur}
							/>
							<div className="search_schools_dropdown">
								<div className={`search_auto_complete_schools ${this.state.displayAutoCompleteSchools ? 'visible_block' : 'hidden'}`}>
									<ul className="auto_complete_item">
										{ this.state.autoCompleteSchools.map(this.generateAutoCompleteSchools) }
									</ul>
								</div>
							</div>
								<span>
									<p>Course #</p>
									<input
										disabled={this.state.courseInputDisabled}
										type="text"
										placeholder={this.state.courseInputPlaceholder}
										className="course_input"
										value={this.state.course}
										onChange={this.onCourseUpdate}
									/>
								</span>
							<p>Within:</p>
							<select value={this.state.travelRadius} onChange={this.onTravelRadiusUpdate}>
								{ CONSTANTS.additionalInfo.travelRadius.map(this.generateTravelRadiusOption) }
							</select>
							<p>Availability:</p>
							<select value={this.state.hoursPerWeek} onChange={this.onHoursPerWeekUpdate}>
								{ CONSTANTS.additionalInfo.hoursPerWeek.map(this.generateHoursPerWeekOption) }
							</select>
							<p>Looking for:</p>
							<select value={this.state.teamOrProject} onChange={this.onTeamOrProjectUpdate}>
								{ CONSTANTS.additionalInfo.teamOrProject.map(this.generateTeamOrProjectOption) }
							</select>

							<p>Interested in:</p>
							<select value={this.state.interests} onChange={this.onInterestsUpdate} multiple={true}>
								<option value={-1}>Select category</option>
								{ CONSTANTS.projectCategories.map(this.generateProjectCategoryOption) }
							</select>

							<p>Location:</p>
							<select className="country_select" value={this.state.country} onChange={this.onCountryChangeUpdate}>
								<option value="">Select Country</option>
								{CONSTANTS.countryData.map(this.generateCountryOptions)}
							</select>
						</div>
						<div className="clear"></div>
						<div className="show_for_desktop">
							<button className="search_button" onClick={() => this.newSearch(false)}>Search</button>
						</div>
						<div className="show_for_mobile">
							<button className="search_button" onClick={() => this.newSearch(true)}>Search</button>
						</div>
						<div className="bottom_padding"></div>
					</div>


				</div>

				<div className="show_for_mobile">
					<div className="clear"></div>
				</div>

				<div className={`master_resultsbox ${this.state.shouldShowResultsBox ? 'visible_block' : 'hidden'}`}>
					<div id="profile_results">
						<div id="no_results_notification" className={"no_results_found " + this.state.noResultsVisibility} >
							Sorry, there were no results that matched your search.
						</div>
						{ this.state.searchResults.map(this.generateSummary) }
						<div style={{height:'10em', width:'100%', float:'left'}}></div>
					</div>
				</div>

				<div className="clear"></div>
				<div className="bottom_padding"></div>
				<Footer />
			</div>
		);
	}
};
