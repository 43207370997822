// This module will let project leaders edit their team.

import React from 'react';
import PropTypes from 'prop-types';
import HTTP from '../../utils/Http';
import { getLocationSearchParam } from 'utils/Utils';

import NavBarNotifications from '../nav/NavBar_Notifications.jsx';
import Footer from '../footer/Footer.jsx';
import TeammateSummary from  './TeammateSummary.jsx';
export default class TeamManagement extends React.Component {

	state = {
		teammates:[],
		teammateSelectedId:"",
		opacity: "1",
		pointerEvents: "all",
		projectKreator: {},
		projectId: '',
		projectName: '',

		followProjectMessage: 'hidden',
		followProjectError: 'hidden',
		updateButtonVisibility: 'hidden',
		isProjectReviewPage: false,

		deleteTeammateWarning: 'hidden'
	}

	UNSAFE_componentWillMount = () => {
		const projectId = getLocationSearchParam('projectId');
		this.setState({projectId: projectId});

		HTTP.get(`/project/${projectId}/members`, (error, body) => {
			if (error) {
				alert("Could not load team members");
			} else {
				this.setState({ teammates: body });
			}
		});
	}

	generateTeammates = () => {
		return this.state.teammates.map(kreator => (
			<TeammateSummary
				key={kreator.member._id}
				kreator={kreator}
				projectId={this.state.projectId}
				roleSummary={kreator.roleSummary}
				handleShowDeleteTeammateWarning={this.handleShowDeleteTeammateWarning}
				handleUpdateTeammates={this.handleUpdateTeammates}
				teammates={this.state.teammates}
			/>
		));
	}

	handleShowDeleteTeammateWarning = (teammateId) => {
		this.setState({
			deleteTeammateWarning: "visible",
			teammateSelectedId: teammateId,
			opacity: "0.5",
			pointerEvents: "none"
		});
	}

	handleDeleteTeammateClick = () => {
		const teammateId = this.state.teammateSelectedId;
		const projectId = this.state.projectId;

		HTTP.delete(`/project/${projectId}/members`, {member: teammateId}, (error, body) => {
			if (error) {
				alert("Could not delete team member.");
				return false;
			} else {

				var newTeammates = this.state.teammates;

				for(var i = 0; i < newTeammates.length; i++){
					if ( newTeammates[i].member._id === teammateId){
						newTeammates.splice(i, 1);
					}
				}

				this.setState({
					teammates: newTeammates,
					deleteTeammateWarning: "hidden",
					opacity: "1",
					pointerEvents: "all"
				});

				return true;
			}
		});
	}

	handleUpdateTeammates = (updatedTeammates) => {
		this.setState({teammates: updatedTeammates});
	}

	handleHideDeleteTeammateWarning = () => {
		this.setState({
			deleteTeammateWarning: "hidden",
			teammateSelectedId: "",
			opacity: "1",
			pointerEvents: "all"
		});
	}

	stripHTMLFromString = (htmlString) => {
		var stripedHtml = htmlString.replace(/<[^>]+>/g, '');
		return stripedHtml;
	}

	render = () => {
		const photo = USER.photo ? `/uploads/${USER.photo}` : '/images/add_photo_account.svg';

		return (
			<div className="viewport_width">
				<div style={{background: "white", marginBottom: '-1.5em'}}>
					<NavBarNotifications
						showLoginButton={false}
						showSignupButton={false}
					 />
					<div style={{marginBottom: "2em"}}>
						<div className="clear"></div>
						<h1 style={{fontSize: '1.5em', textAlign: 'center'}}>{this.state.projectName}</h1>
						<p style={{fontSize: '1.5em', textAlign: 'center', color: '#00A4A4'}}>Teammates</p>
						<div className="clear"></div>
						<div className={"teammate_invitation_warning " + this.state.deleteTeammateWarning}>
							<p>
								Are you sure you want to delete this teammate?
								This will remove this team member from your project and is not reversible.
							</p>
							<div style={{width:"14em", margin: "1em auto"}}>
								<button className="delete_teammate_warning_cancel" onClick={this.handleHideDeleteTeammateWarning}>Cancel</button>
								<button className="delete_teammate_warning_continue" style={{float: "right"}} onClick={this.handleDeleteTeammateClick}>I&#39;m Sure</button>
							</div>
						</div>
						<div style={{margin: '1em auto', width: '100%', opacity: this.state.opacity, pointerEvents: this.state.pointerEvents}}>
							{this.generateTeammates()}
						</div>
						<div className="clear"></div>
					</div>
					<div className="bottom_padding"></div>
					<Footer />
				</div>
			</div>
		);
	}
};
