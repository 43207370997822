// This is the box for the featured profiles on the homepage.

import React from 'react';
import { Link } from 'react-router-dom';
import HTTP from '../../utils/Http';
import ProfileSummary from './ProfileSummary.jsx';
import PropTypes from 'prop-types';

export default class FeaturedProfiles extends React.Component {
	static propTypes = {
		backgroundColor: PropTypes.string
	}
	state = {
		profiles: []
	}

	UNSAFE_componentWillMount = () => {
		HTTP.get('/kreator', (error, profiles) => {
			if (error) {
				return;
			}

			if (!Array.isArray(profiles)) {
				return console.error('No featured profiles to display.');
			}

			this.setState({ profiles: profiles });
		});
	}

	generateProfileSummary = profile => {
		return <ProfileSummary key={profile._id} kreator={profile} />;
	}

	render = () => {
		return (
			<div>
				<div style={{background: this.props.backgroundColor}}>
					<div>
						<h2>Kreator Spotlight</h2>
						{ this.state.profiles.map(this.generateProfileSummary) }
					</div>
				</div>
			</div>
		);
	}
};
