import React from 'react';
import Slides from './Slides.jsx';
import Controls from './Controls.jsx';
import Pagination from './Pagination.jsx';

export default class Slideshow extends React.Component {
	state = {
		currentSlide: 0,
		data: []
	}

	UNSAFE_componentWillMount = () => {
		var data = [];
		var numberOfSlides = this.props.numberOfSlides;

		var i;
		for (i = 0; i < numberOfSlides; i++){
			var slide = "slide" + i.toString();
			var slideObject = { id: slide}
			data.push(slideObject);
		}

		this.setState({ data: data });
	}

	componentDidMount = () => {
		//this.timer = setInterval(this.toggleNext, 15000);
	}

	componentWillUnmount = () => {
		clearInterval(this.timer);
	}

	toggleNext = () => {
		var next = this.state.currentSlide + 1;
		if (next > this.state.data.length - 1) {
			next = 0;
		}
		this.setState({ currentSlide: next });
 	}

	togglePrev = () => {
		var prev = this.state.currentSlide - 1;
		if (prev < 0) {
			prev = this.state.data.length - 1;
		}
		this.setState({ currentSlide: prev });
	}

	toggleSlide = id => {
		const currentIndex = this.state.data.map(el => el.id).indexOf(id);

		this.setState({ currentSlide: currentIndex });
	}

	render = () => {
		return (
			<div>
				<div className="slideshow_box">
					<div className="slideshow">
						<Slides collectionNumber={this.props.collectionNumber} data={this.state.data} currentSlide={this.state.currentSlide}/>
					</div>
					<div className="paginationbox">
						<Pagination data={this.state.data} toggleSlide={this.toggleSlide} currentSlide={this.state.currentSlide}/>
					</div>
				</div>
			</div>
		);
	}
};
