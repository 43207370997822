import React from 'react';
import NavBarNotifications from './nav/NavBar_Notifications.jsx';
import Footer from './footer/Footer.jsx';
import PropTypes from 'prop-types';

export default class PrivacyPolicy extends React.Component {
	static contextTypes = {
		router: PropTypes.object
	}

	render = () => {
		return(
			<div className="viewport_width">
				<NavBarNotifications
					showLoginButton={true}
					showSignupButton={true}
				/>
					<div className="clear"></div>

					<div>
						<div className="signup_container" style={{margin: '0 auto', maxWidth: '50em', padding: '0em 1em', fontSize: '1.1em'}}>
							<h4 style={{textAlign: 'center', color: '#00A4A4', marginTop: '1.5em', marginBottom: '1em', fontSize: '2em'}}>Privacy Policy</h4>
							Welcome to www.KreatorBus.com (“Website”, “Platform”, “Site”) owned and operated by KreatorBus (“Company”, “We”, “Us”, or “Our”) a business operating in accordance with the Laws of the State of California, USA. We are the data controller for the purposes of this policy and can be contacted at: contact@kreatorbus.com.
							<br/><br/>
							We respect your privacy and are committed to providing full transparency about how we collect and use the personal information you entrust us with. Please read this Policy carefully to inform yourself about our business practices with regards to the collection and use of your personal data. We urge you not to use our website and provide us with any personal information if you do not expressly agree with our policy as described hereunder. By using our website, you hereby give Us your express consent to the collection and use of your Personal Information as stated herein.
							<br/><br/>

							<b className="termsofservice_title">Modification</b><br/>
							We reserve the right to amend this Privacy Policy at any time in our sole discretion without giving any prior notice to you. In the event we decide to make any changes to our practices and policies, we will inform you of such amendments by changing the last updated date on the top of this page. Please take a few minutes of your time to review this Policy periodically. A change will be effective when posted on this web page and the notice will be given by amendment to the last updated date on the top.
							<br/><br/>

							<b className="termsofservice_title">How we collect your information</b><br/>
							There is certain information that you expressly provide us when you register an account on our website. This information includes your full name, email address, your portfolio, zip code, skills and interests (“Personally identifiable information”)
							<br/><br/>
							<span style={{textDecoration: 'underline'}}>Information we collect about you from your use of our Service</span><br/>
							There is certain information that we are able to collect about you through the use of log data that we collect. It enables us to collect information about you such as your IP address, your browser type or version, the pages you visit on our Website and data on the time, date of your visit as well as your transaction history (“Non-Personally Identifiable Information”).
							<br/><br/>

							<b className="termsofservice_title">How we use the information we collect</b><br/>
							We use your personally identifiable informations to help other users find you as a potential teammate to join their projects, to deliver our services to you and to contact you.  We use the information we obtain through log data to deliver and improve our services to you. Please note that we may occasionally use your personally identifiable information to send marketing material if you subscribe to our newsletter. All personally identifiable User information is visible to other Platform Users except a User’s email address.
							<br/><br/>
							We do not and never will sell or rent your personally identifiable information to any person or entity with the exception of the event where we undergo a merger or acquisition.
							<br/><br/>

							<b className="termsofservice_title">Sharing your information</b><br/>
							Please note that we may be required to disclose your personal information to local or international law enforcement authorities or governmental bodies upon reasonable requests or to third-party service providers in the event of security breach of system, networks or website. We may also share your information with our partners and any service provider that we may engage to help us in facilitating our Platform and Services to you and to assist us with intelligent analysis of how our users interact with our Website.
							<br/><br/>

							In the event where any Service Providers we engage comes in contact with your personally identifiable information, please be assured that we have entered into confidentiality agreements with such Service Provider and their access to your information is only in the course of performing these tasks on our behalf.
							<br/><br/>

							<b className="termsofservice_title">Marketing Communications</b><br/>
							From time to time we may use your personally identifiable information to send you content  that we think you will find interesting. You can easily opt out of receiving communication from us, at any time, by simply changing the contact settings in your user account or by simply contacting us at contact@kreatorbus.com.
							<br/><br/>

							<b className="termsofservice_title">Processing of your personally identifiable information</b><br/>
							We process all personally identifiable information on computers and servers that are located in United States of America and in accordance with data protection laws of USA. By creating an account on our Website and providing us with your personally identifiable information, you consent to such processing of your personally identifiable information.
							<br/><br/>

							<b className="termsofservice_title">Security of your data</b><br/>
							We strive to keep all your personally identifiable information secure. We use all commercially viable means and industry best practices to protect your Personal Information but you understand that our website is built upon third-party software and hardware which may possess vulnerabilities and therefore we are unable to offer any guarantees for security of your personal information.
							<br/><br/>

							<b className="termsofservice_title">Retention of data</b><br/>
							We assure you that we will not retain any of your personally identifiable information for a period longer than necessary for fulfilment of our business and legal requirements. We reserve the right to retain your personal information for a period of five years after your account is terminated but we do not have any obligation towards you to retain such information and we may in our sole discretion discard your personal information from our records sooner.
							<br/><br/>

							<b className="termsofservice_title">Use of Cookies on our Website</b><br/>
							Like most other website nowadays, we make use of cookies to enhance our User experience. Cookies are small files with data that helps Us to uniquely identify You as a User without linking any personally identifiable information with these unique identifier files. You have the choice to either instruct Your browser to decline all cookies or to indicate to You when a cookie is being sent. In the event that You choose to decline cookies from Our Website, You understand that You may not be able to use some parts of Our Website and Services We offer.
							<br/><br/>

							<b className="termsofservice_title">Third-Party Analytics </b><br/>
							Like a lot of other websites nowadays, we use third-party analytics tools such as Google Analytics to evaluate the usage of our Website. We use Analytics to improve our offering, performance and user experience. We use this tool to only collect non-personal information about our users. Google analytics uses Cookies to perform its own services, and may combine the information that it collects about you on our website with other information that they have collected.
							<br/><br/>

							<b className="termsofservice_title">Disclaimer for third-party website links</b><br/>
							Our Website may contain links to third party websites that are neither owned nor operated by us. Should you decide to click on any such third party links, you will be directed to such third party website. These third-party sites have their own Terms of Service and Privacy Policy and we encourage you to review their Terms and Privacy Policy prior to using their site or any services offered by them.
							<br/><br/>
							We disclaim all liability with regards to your use and access of such third-party sites. We cannot be held responsible for any content made available by these third-party sites or their practices. You access and use these sites solely at your own risk.
							<br/><br/>

							<b className="termsofservice_title">Protection of Children’s Privacy</b><br/>
							Our website and services are not aimed at people who are under the age of thirteen (13) years ("Children"). If you are the parent / legal guardian of a Child who you believe has provided us with his/her personally identifiable information, please contact us immediately and we will take steps to remove such personally identifiable information from our database and servers immediately.
							<br/><br/>

							<b className="termsofservice_title">Right to Access Your Personally Identifiable Information</b><br/>
							You have the right to request to access your personally identifiable information that we hold and to update or edit this information to ensure it is current and accurate. To exercise your right you can log into your account on www.KreatorBus.com to edit your information or alternatively contact us at contact@kreatorbus.com.
							<div className="clear" style={{marginBottom: '3em'}}></div>
						</div>
					</div>
				<div className="bottom_padding"></div>
				<Footer />
			</div>
		);
	}
};
