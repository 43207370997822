import React from 'react';
import { Link } from 'react-router-dom';
import HTTP from './Http';

////////////////////////////////////////////////////////////////////////////////
// QueryLink helpers ///////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////

const stringifyQuery = (obj, prefix) => {
	var str = [], p;

	for (p in obj) {
		if (obj.hasOwnProperty(p)) {
			var k = prefix ? `${prefix}[${p}]` : p, v = obj[p];
			str.push((v !== null && typeof v === 'object') ?
				stringifyQuery(v, k) :
				encodeURIComponent(k) + '=' + encodeURIComponent(v));
		}
	}

	return str.join('&');
};

////////////////////////////////////////////////////////////////////////////////
// draggable helpers ///////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////

const getElementByIdString = el => {
	return typeof el == 'string' ? document.getElementById(el) : el;
};

const mouseX = e => {
	if (e.pageX) {
		return e.pageX;
	}

	if (e.clientX) {
		return e.clientX + (document.documentElement.scrollLeft ?
			document.documentElement.scrollLeft :
			document.body.scrollLeft);
	}

	return null;
};

const mouseY = e => {
	if (e.pageY) {
		return e.pageY;
	}

	if (e.clientY) {
		return e.clientY + (document.documentElement.scrollTop ?
			document.documentElement.scrollTop :
			document.body.scrollTop);
	}

	return null;
};

////////////////////////////////////////////////////////////////////////////////
// exports /////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////

export function draggable(clickEl,dragEl) {
	var p = getElementByIdString(clickEl);
	var t = getElementByIdString(dragEl);
	var drag = false;
	var offsetX = 0;
	var offsetY = 0;
	var mousemoveTemp = null;

	if (t) {
		var move = function (x,y) {
			t.style.left = (parseInt(t.style.left)+x) + 'px';
			t.style.top	= (parseInt(t.style.top) +y) + 'px';
		};
		var mouseMoveHandler = function (e) {
			e = e || window.event;

			if(!drag){return true;};

			var x = mouseX(e);
			var y = mouseY(e);
			if (x != offsetX || y != offsetY) {
				move(x-offsetX,y-offsetY);
				offsetX = x;
				offsetY = y;
			}
			return false;
		};
		var start_drag = function (e) {
			e = e || window.event;

			offsetX=mouseX(e);
			offsetY=mouseY(e);
			drag=true; // basically we're using this to detect dragging

			// save any previous mousemove event handler:
			if (document.body.onmousemove) {
				mousemoveTemp = document.body.onmousemove;
			}
			document.body.onmousemove = mouseMoveHandler;
			return false;
		};
		var stop_drag = function () {
			drag=false;

			// restore previous mousemove event handler if necessary:
			if (mousemoveTemp) {
				document.body.onmousemove = mousemoveTemp;
				mousemoveTemp = null;
			}
			return false;
		};
		p.onmousedown = start_drag;
		p.onmouseup = stop_drag;
	}
};

export function getLocationSearchParam(name, url) {
	if (!url) url = window.location.href;
	name = name.replace(/[\[\]]/g, '\\$&');
	var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
		results = regex.exec(url);
	if (!results || !results[2]) return '';
	return decodeURIComponent(results[2].replace(/\+/g, ' '));
};

export function getLocationSearchParams(url) {
	var match,
		pl = /\+/g, // Regex for replacing addition symbol with a space
		search = /([^&=]+)=?([^&]*)/g,
		decode = function (s) { return decodeURIComponent(s.replace(pl, ' ')); },
		query  = (url || window.location.href).split('?')[1] || '',
		urlParams = {};

	while (match = search.exec(query)) {
		urlParams[decode(match[1])] = decode(match[2]);
	}

	return urlParams;
};

export function QueryLink(props) {
	return <Link {...props} to={{ ...props.to, search: stringifyQuery(props.to.query || {}) }} />;
};

export function generateLocation(zip, cb) {
	return HTTP.get('/zipInfo', { zip }, (err, body) => {
		return cb(err, err ? '' : `${body.city}, ${body.state}`);
	});
};
