import React from 'react';
import { Link } from 'react-router-dom';
import HTTP from '../../utils/Http';
import NavBarNotifications from '../nav/NavBar_Notifications.jsx';
import ProgressBar from './ProgressBar.jsx';
import Footer from '../footer/Footer.jsx';
import AddLink from './AddLink.jsx';
import PropTypes from 'prop-types';
import history from 'app/history';

export default class SignupPortfolio extends React.Component {
	static propTypes = {
		edit: PropTypes.bool
	};

	state = {
		profileCompleteness: USER ? USER.profileCompleteness : 0,
		successSave: 'hidden',
		failedSave: 'hidden',
		errLink: 'hidden',
		errDuplicate: 'hidden',
		linkList: [],
		userLinks: USER ? USER.portfolio.links : [],
		linkText: ''
	};

	UNSAFE_componentWillMount = () => {
		// Checks to see if there are user links already inputted. If there are,
		// then a newLinkList array will be created that has link objects in it
		// instead of just strings. This array is used to build the AddLink
		// React modules that need link objects not just link strings.

		if (this.state.userLinks) {
			this.setState({
				linkList: this.state.userLinks.map(linkText => ({
					name: linkText,
					id: linkText,
					keyName: linkText
				}))
			});
		}
	}

	onLinkChange = e => {
		this.setState({ linkText: e.target.value });
	}

	onLinkClick = e => {
		e.preventDefault();

		var reg = /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+|([-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)/;

		if (!reg.test(this.state.linkText)) {
			this.setState({ errLink: 'visible_block' });
		} else {
			this.setState({ errLink: 'hidden' });

			//checks to see if user has inputted text and if a link has been repeated.
			if (this.state.linkText && this.state.linkList.map(link => link.name).indexOf(this.state.linkText) == -1) {
				this.setState({
					errDuplicate: 'hidden',
					linkText: '',
					linkList: this.state.linkList.concat({
						name: this.state.linkText,
						id: this.state.linkText,
						keyName: this.state.linkText
					})
				});
			} else {
				this.setState({ errDuplicate: 'visible_block' });
			}
		}

		this.setState({ successSave: 'hidden' });
	}

	deleteLink = link => {
		this.setState({
			successSave: 'hidden',
			linkList: this.state.linkList.filter(el => link.keyName !== el.keyName)
		});
	}

	handleBack = e => {
		e.preventDefault();
		this.save({ shouldContinueSignUp: true, backToEditProfilePage: true });
	}

	handleSave = e => {
		e.preventDefault();
		this.save();
	}

	handleSubmit = e => {
		e.preventDefault();
		this.save({ shouldContinueSignUp: true });
	}

	save = ({ shouldContinueSignUp = false, backToEditProfilePage = false } = {}) => {
		// turns linkList from array of objects to an array of strings
		const trimmedLinkList = this.state.linkList.map(link => link.name);

		// posts the trimmedLinkList to the server.
		HTTP.post('/profile/portfolio/links', trimmedLinkList, (error, body) => {
			if (error) {
				return this.setState({ failedSave: 'visible', successSave: 'hidden' });
			}

			this.setState({
				failedSave: 'hidden',
				successSave: 'visible',
				profileCompleteness: body.profileCompleteness
			});

			USER.portfolio.links = trimmedLinkList;
			USER.profileCompleteness = body.profileCompleteness;

			if (shouldContinueSignUp) {
				const path = backToEditProfilePage ? '/editprofilepage' : '/signupfictional';
				history.push(path);
			}
		});
	}

	renderLinkList = link => {
		return (
			<AddLink
				deletefunc={this.deleteLink.bind(null, link)}
				key={link.id}
				name={link.name}
				keyName={link.keyName}
			/>
		);
	}

	render = () => {
		const editNavBarDisplay = this.props.edit ? 'visible_block' : 'hidden';
		const signupNavBarDisplay = this.props.edit ? 'hidden' : 'visible_block';

		return (
			<div className="viewport_width">
				<NavBarNotifications
					showLoginButton={false}
					showSignupButton={false}
				/>
				<div className="signup_hero">
					<img src="images/signup_portfolio.png" />
				</div>

				<div className="clear"></div>

				<div>
					<div className="show_for_desktop">
						<div className="signup_progressbar" style={{width:'56em'}}>
							<ProgressBar progress={this.state.profileCompleteness} width="56em"/>
						</div>
					</div>

					<div className="show_for_mobile">
						<div className="signup_progressbar" style={{width:'19em'}}>
							<ProgressBar progress={this.state.profileCompleteness} width="18em"/>
						</div>
					</div>

					<div className="signup_container">
						<div className="portfolio_container" style={{overflow:"visible"}}>
							<div className="portfolio_box">
								<h4>Have a link to some cool stuff that you did?</h4>
								<div className="portfolio_centering_box">
									<span className={this.state.errLink + " error"}>&nbsp;{CONSTANTS.errors.urlError}</span>
									<span className={this.state.errDuplicate + " error"}>&nbsp;{CONSTANTS.errors.repeatUrlError}</span>
								</div>
								<div className="clear"></div>
								<form>
									<div className="portfolio_inputbox">
										<div className="show_for_desktop">
											<p style={{marginLeft: '2em'}}>Link:</p>
										</div>
										<div className="show_for_mobile">
											<p style={{marginLeft: '0.5em'}}>Link:</p>
											<div className="clear"></div>
										</div>
										<input
											className="input_link"
											type="text"
											value={this.state.linkText}
											onChange={this.onLinkChange}
										/>
										<button className="input_addlink" onClick={this.onLinkClick}>add link</button>
										<br /><br />
									</div>
									<div className="clear"></div>
									<ul className="new_link">
										{this.state.linkList.map(this.renderLinkList)}
									</ul>
									<div className="clear" style={{marginBottom: "10em"}}></div>
									{/*<br /><br />
									<h5>Have some illustrations, designs, or other images to share?</h5>
									<br />
									<div className="clear"></div>
									<div className="portfolio_addimage">
										<div className="addimagebox">
											<img src="images/portfolio_addimage.png" />
										</div>
										<div className="addimagebox">
											<img src="images/portfolio_addimage.png" />
										</div>
										<div className="addimagebox">
											<img src="images/portfolio_addimage.png" />
										</div>
										<div className="addimagebox">
											<img src="images/portfolio_addimage.png" />
										</div>
									</div>*/}
									<div className={"signup_navigation " + signupNavBarDisplay}>
										<span style={{float: "right", marginRight: '.5em'}} className={this.state.successSave + " success"}>&nbsp;{CONSTANTS.errors.successSave}</span>
										<span style={{float: "right", marginRight: '.5em'}} className={this.state.failedSave + " error"}>&nbsp;{CONSTANTS.errors.failedSave}</span>
										<div className="clear"></div>
										<input name="submit" className="nextnav" type="submit" value="Next >" onClick={this.handleSubmit}/>
										<Link to="/signupfictional" className="skipnav">Skip</Link>
										<input name="submit" className="savenav" type="submit" value="Save" onClick={this.handleSave}/>
										<Link to="/signupphoto" className="backnav">&lt; Back</Link>
									</div>
									<div className={"edit_signup_navigationb " + editNavBarDisplay}>
										<span style={{float: "right", marginRight: '1.5em'}} className={this.state.successSave + " success"}>&nbsp;{CONSTANTS.errors.successSave}</span>
										<span style={{float: "right", marginRight: '1.5em'}} className={this.state.failedSave + " error"}>&nbsp;{CONSTANTS.errors.failedSave}</span>
										<div className="clear"></div>
										<input className="savenavb"  type="submit" value="Save" onClick={this.handleSave}/>
										<input className="backnavb" type="submit" style={{marginLeft:'1em'}} value="Back to Profile" onClick={this.handleBack}/>
									</div>
								</form>
							</div>
						</div>
					</div>
				</div>
				<div className="bottom_padding" style={{paddingBottom:'4em'}}></div>
				<Footer />
			</div>
		);
	}
};