// A profile portfolio image is the image a user can upload to show off his or her work.
// This is used by ProfileDetail module.
import React from 'react';
import PropTypes from 'prop-types';

export default class ProfilePortfolioImage extends React.Component {
	static propTypes = {
		image: PropTypes.string
	}
	render = () => {
		return (
			<div>
				<img src={this.props.image} className="user_image" />
			</div>
		);
	}
};