import React from 'react';
import PropTypes from 'prop-types';

export default class Pager extends React.Component {
	toggleSlide = () => {
		this.props.toggleSlide(this.props.id);
	}

	render = () => {
		const pagerDisplay = this.props.isActive ? 'pager_active' : 'pager_inactive';

		return (
			<span onClick={this.toggleSlide}><div className={pagerDisplay}></div></span>
		);
	}
};

Pager.propTypes = {
  isActive: PropTypes.bool,
  id: PropTypes.string,
  toggleSlide: PropTypes.func
};