import React from 'react';
import PropTypes from 'prop-types';

export default class ProgressBar extends React.Component {
	static propTypes = {
		progress: PropTypes.number,
		width: PropTypes.string
	}
	
	render = () => {
		const progress = this.props.progress + '%';
		const width = this.props.width;
		var backgroundColor = '#00DEDE';
		var fontColor = '#00DEDE';

		if (this.props.progress < 30) {
			backgroundColor = 'red';
			fontColor = 'red';
		} else if (this.props.progress >= 30 && this.props.progress <= 60) {
			backgroundColor = '#FFA904';
			fontColor = '#FFA904';
		}

		const progressBarStyle = {
			background: backgroundColor,
			width: progress,
			height: '0.8em',
			float: 'left',
			maxWidth: width
		};

		const progressBarContainerStyle = {
			background: 'transparent',
			width: width,
			border: '1px #D8D8D8 solid',
			overflow: 'hidden',
			marginTop: '0.2em',
			float: 'left'
		};

		return (
			<div>
				<p style={{float: 'left'}}>Profile Progress:</p><span style={{color: fontColor, marginTop: '0em'}}> {progress + " Completed!"}</span><br/>
				<div style={progressBarContainerStyle}>
					<div style={progressBarStyle}></div>
				</div>
			</div>
		);
	}
};