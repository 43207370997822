// This is the page users will see when they finish creating a project.

import React from 'react';
import { Link } from 'react-router-dom';
import NavBarNotifications from '../nav/NavBar_Notifications.jsx';
import Footer from '../footer/Footer.jsx';

export default class ProjectFinish extends React.Component {
	render = () => {
		return (
			<div className="viewport_width">
				<NavBarNotifications
					showLoginButton={false}
					showSignupButton={false}
				/>
				<div className="clear"></div>
				<div>
					<div className="signup_container">
						<div className="finish_container">
							<h4>Thank you for your submission!</h4><br />
							<p>
								Once your project is approved, it will be posted on
								our website for all our kreators to see. However…
							</p>
							<br /><br />
							<h5>
								Don’t just wait for someone to contact you!
								<br />
								Be proactive!
							</h5>
							
							<p>
								Start looking for kreators you think will be a good fit for your project and
								save them to your bank. When your project is posted, contact them with a
								friendly message and a link to your project.<br/><br/>

								Also, don’t feel discouraged if not everyone responds or is ready to jump
								aboard. Finding the right teammates is a process, but when you do meet them
								it is very rewarding.
							</p>
							<div className="four_buttons">
								<li><Link to="kreator/search">Kreators</Link></li>
								<li><Link to="project/search">Projects</Link></li>
								<li style={{marginRight:'0em'}}><Link style={{marginRight:'0em'}} to="useraccount">My Account</Link></li>
							</div>
							<br /><br />
						</div>
					</div>
				</div>
				<div className="bottom_padding" style={{paddingBottom: '4em'}}></div>
				<Footer />
			</div>
		);
	}
};
