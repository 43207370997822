// Used by ProjectMainPage.jsx or ProjectMasterReview.jsx. This is the top box of the project master
// page that holds information such as the tagline, team leader, and roles to be filled.

import React from 'react';
import { QueryLink } from 'utils/Utils';
import PropTypes from 'prop-types';
import YoutubePlayer from './YoutubePlayer.jsx';

export default class ProjectTopBox extends React.Component {
	static propTypes = {
		projectKreator: PropTypes.object,
		teammateEquity: PropTypes.bool,
		editProjectButtonDisplay: PropTypes.bool,
		roleList: PropTypes.array,
		projectCourse: PropTypes.string,
		logo: PropTypes.string,
		projectName: PropTypes.string,
		tagline: PropTypes.string,
		leaderRoleSummary: PropTypes.string,
		projectZipcode: PropTypes.string,
		projectCourse: PropTypes.string,
		projectId: PropTypes.string,
		handleFollowProject: PropTypes.func,
		videoLink: PropTypes.string
	}

	state = {
		firstName: '',
		lastName: '',
		zipCode: '',
		location: '',
		baseSkills: [],
		expertises: [],
		expertisesOther: [],
		skills: [],
		schools: [],
		teamLeaderTopSchool: '',
		videoLink: '',
		courses: [],
		interests: [],
		photo: '',
		kreatorID:'',
		displayVideo: 'hidden',
		displayLogo: 'visible',
		timeTitle: '',
		numberOfDays: '',
		teamLeaderIsAttendingSchool: "hidden"
	}

	UNSAFE_componentWillMount = () => {

		if(this.isEmpty(this.props.projectKreator)){
			console.log("no project kreator");
			return;
		}

		var kreator;

		if (!USER){
			kreator = this.props.projectKreator;
		} else {
			kreator = this.isKreator(this.props.projectKreator._id) ? this.props.projectKreator : USER;
		}

		this.setState({
			firstName: kreator.firstName,
			lastName: kreator.lastName,
			zipCode: kreator.zipCode,
			location: kreator.location,
			baseSkills: kreator.baseSkills,
			expertises: kreator.expertises,
			expertisesOther: kreator.expertisesOther,
			skills: kreator.skills,
			schools: kreator.schools,
			courses: kreator.courses,
			interests: kreator.interests,
			photo: `/uploads/${kreator.photo}`,
			kreatorID:kreator._id
		});
		this.generateStartDate(this.props.startDate);

		if (this.isKreator(this.props.projectKreator._id) && USER){
			this.moveSameAttendingSchoolsToTop(USER, kreator);
		} else if(USER){
				if(USER.schools.length > 0){
					this.setState({teamLeaderTopSchool: USER.schools[0].school.names[0]});
				}
		} else {
			this.setState({
				teamLeaderTopSchool: kreator.schools[0].school.names[0],
				teamLeaderIsAttendingSchool: kreator.schools[0].attending ? "visible_block" : "hidden"
			});
		}


	}

	UNSAFE_componentWillReceiveProps = nextProp => {
		this.generateStartDate(nextProp.startDate);
	}

	isKreator = object => {
		for(var key in object) {
			if (object.hasOwnProperty(key)) {
				return true;
			}
		}
		return false;
	}

	isEmpty = object => {
	    for(var key in object) {
	        if(object.hasOwnProperty(key))
	            return false;
	    }
	    return true;
	}

	moveSameAttendingSchoolsToTop = (user, teamLeader) => {
		var userAttendingSchools = [];
		var teamLeaderAttendingSchools = [];
		var teamLeaderTopSchool = teamLeader.schools[0];
		var teamLeaderTopSchoolName = teamLeader.schools[0].school.names[0];

		var i;
		for(i = 0; i < user.schools.length; i++){
			if(user.schools[i].attending == true){
				userAttendingSchools.push(user.schools[i]);
			}
		}
		var j;
		for(j = 0; j < teamLeader.schools.length; j++){
			if(teamLeader.schools[j].attending == true){
				teamLeaderAttendingSchools.push(teamLeader.schools[j]);
			}
		}

		if (teamLeaderAttendingSchools.length > 0){
			teamLeaderTopSchool = teamLeaderAttendingSchools[0];
		}

		userAttendingSchools.forEach(function(userSchool) {
  			teamLeaderAttendingSchools.forEach(function(teamLeaderSchool){
  				if(userSchool.school._id == teamLeaderSchool.school._id){
  					teamLeaderTopSchool = teamLeaderSchool;
  				}
  			});
		});

		teamLeaderTopSchoolName = teamLeaderTopSchool.school.names[0];

		this.setState({
			teamLeaderTopSchool: teamLeaderTopSchoolName,
			teamLeaderIsAttendingSchool: teamLeaderTopSchool.attending ? "visible_block" : "hidden"
		});

	}

	generateStartDate = startDate => {
		var timeTitle;

		if (!startDate) {
			this.setState({numberOfDays: 0});
		} else {
			var startDateObject = new Date(this.props.startDate);
			var currentDate = new Date();

			if (startDateObject < currentDate && this.props.isProjectReviewPage === true) {
				startDateObject = currentDate;
			}

			var oneDay = 24 * 60 * 60 * 1000; // hours * minutes * seconds * milliseconds

			var daysToStart = Math.round((startDateObject.getTime() - currentDate.getTime()) / oneDay);

			if (daysToStart >= 0) {
				timeTitle = "Starts in: ";
			} else if (daysToStart < 0) {
				timeTitle = "Going for: "
			}

			this.setState({ numberOfDays: Math.abs(daysToStart) });
		}

		this.setState({ timeTitle: timeTitle });
	}

	generateBaseSkills = (baseSkill, index) => {
		return <img key={index} src={`/images/${(CONSTANTS.baseSkills.find(bs => bs.id === baseSkill) || {}).text.toLowerCase()}.svg`} />;
	}

	generateExpertises = () => {
		var listOfExpertiseStrings = this.state.expertises
			.concat(this.state.expertisesOther.map(expertise => expertise.text))
			.join(', ');

		if (listOfExpertiseStrings.length > 55) {
			var expListSubstr = listOfExpertiseStrings.substr(0, 55);
			return expListSubstr.substr(0, expListSubstr.lastIndexOf(',')) + '...';
		}

		return listOfExpertiseStrings;
	}

	generateInterests = () => {
		var listOfInterestStrings = this.state.interests
			.map(interestNumber => (CONSTANTS.projectCategories[interestNumber] || {}).text)
			.join(', ');

		if (listOfInterestStrings.length > 55) {
			var interestListSubstr = listOfInterestStrings.substr(0, 55);
			return interestListSubstr.substr(0, interestListSubstr.lastIndexOf(',')) + '...';
		}

		return listOfInterestStrings;
	}

	generateSkills = () => {
		var listOfSkills = this.state.skills.join(', ');

		if (listOfSkills.length > 40) {
			var skillListSubstr = listOfSkills.substr(0, 40);
			var skillListSubstrThreeDots = skillListSubstr.substr(0, skillListSubstr.lastIndexOf(',')) + '...';
			return skillListSubstrThreeDots;
		}

		return listOfSkills;
	}

	generateTeammateEquity = () => {
		return this.props.teammateEquity ? 'Equity in project.' : 'Experience only.';
	}

	generateRoles = role => {
		return (
			<div key={role.id} className="added_kreator">
				<div className="addkreator_symbol">
					<img src={role.image}/><br/>
					<p>
					{role.baseSkillTitle}<br/>
					<span>{role.expertise}</span>
					</p>
				</div>
				<p className="added_kreator_description">
					{role.text}
				</p>
			</div>
		);
	}

	handleDisplayVideoOrLogo = () => {
		this.setState({
			displayVideo: "visible",
			displayLogo: "hidden"
		});
	}
	render = () => {
		var editProjectButtonDisplay;
		var projectId = this.props.projectId;
		var contactFollowButtonDisplay;
		if (this.props.editProjectButtonDisplay === true) {
			editProjectButtonDisplay = "visible_block";
			contactFollowButtonDisplay = "hidden";
		} else {
			editProjectButtonDisplay = "hidden";
			contactFollowButtonDisplay = "visible_block";
		}
		var projectRoles = this.props.roleList;
		var teamLeaderSchoolName = this.state.teamLeaderTopSchool;

		var displayCourse = "";
		if(this.props.projectCourse == "" || this.props.projectCourse == null) {
			displayCourse = "hidden";
		} else {
			displayCourse = "visible_block";
		}

		var projectLocation = this.props.projectLocation;
		if (projectLocation == "") {
			projectLocation = this.state.location;
		}

		var schoolName = '';
		if (this.props.projectSchool){
			schoolName = this.props.projectSchool.names[0];
		}

		var pointerEvents = "";
		if (this.props.isProjectReviewPage === true){
			pointerEvents = "no_pointer_events";
		}

		var followButtonVisibility = this.props.showFollowProjectButton ? " inline_block" : " hidden";
		var unfollowButtonVisibility = this.props.showUnfollowProjectButton ? " inline_block" : " hidden";

		if (this.isEmpty(this.props.projectKreator)){
			return (<div style={{textAlign: 'center', fontSize: '2em'}}>You need to be a member and logged in to view projects!</div>);
		} else {
			return (
				<div>
					<div className="project_topbox">
						<div className="project_logotitlebox">
							<div className="project_image_video_box">
								<div className={this.state.displayVideo}>
									<YoutubePlayer
										videoLink={this.props.videoLink}
										handleDisplayVideoOrLogo={this.handleDisplayVideoOrLogo}
									/>
								</div>
								<img className={this.state.displayLogo} src={this.props.logo} />
							</div>
							<div className="project_titlebox">
								<h2>{this.props.projectName}</h2>
								<p>{this.props.tagline}</p>
							</div>
						</div>

						<div className="show_for_desktop">
							<div className={"team_leaderbox " + pointerEvents}>
								<QueryLink to={{ pathname:"/profilepage", query: { profileId: this.state.kreatorID} }}>
									<h3>Team Leader</h3>
									<img src={this.state.photo} />
									<h4>{this.state.firstName} {this.state.lastName}</h4>
									<p><span className={"middle_dot " + this.state.teamLeaderIsAttendingSchool}>&middot;</span>{teamLeaderSchoolName}</p><br/><br/>
									<p>{this.state.location}</p><br/><br/>
									<p>Skills:</p>
										<div className="teamleader_skillsbox">
											{this.state.baseSkills.map(this.generateBaseSkills)}
										</div>
									<p>
									{this.generateExpertises()}<br/><br/>
									{this.generateSkills()}<br/><br/>
									</p>
								</QueryLink>
							</div>
						</div>

						<div className="project_timecompbox">
							<p>{this.state.timeTitle}&nbsp;<span>{this.state.numberOfDays} days &nbsp;&nbsp;&nbsp;&nbsp;</span></p>
							<div className="show_for_mobile"><div className="clear" style={{marginBottom:'0.4em'}}></div></div>
							{/*<p style={{marginTop: '0.5em'}}>Compensation:<span>&nbsp;{this.generateTeammateEquity()}</span></p>
							<div className="clear"></div>*/}
							<p style={{marginTop: "0.5em", float: 'left'}}>Location:<span>&nbsp;{projectLocation}</span></p>
							<div className="clear"></div>
							<div className={displayCourse + " project_school_text_box"}>
								<div style={{float: 'left'}}>School: <span>{schoolName}</span></div>
								<div className="show_for_desktop">
									<div style={{float: 'left', marginLeft: '1em'}}>Course: <span>{this.props.projectCourse}</span></div>
								</div>
								<div className="show_for_mobile">
									<br/>
									<div style={{float: 'left', marginLeft: '0em', marginTop: '0.5em'}}>Course: <span>{this.props.projectCourse}</span></div>
								</div>
							</div>
						</div>

						<div className="show_for_mobile">
							<div className={"team_leaderbox " + pointerEvents}>
								<QueryLink to={{ pathname:"/profilepage", query: { profileId: this.state.kreatorID} }}>
									<h3>Team Leader</h3>
									<img src={this.state.photo} />
									<h4>{this.state.firstName} {this.state.lastName}</h4>
									<p><span className={"middle_dot " + this.state.teamLeaderIsAttendingSchool}>&middot;</span>{teamLeaderSchoolName}</p><br/><br/>
									<p>{this.state.location}</p><br/><br/>
									<p>Skills:</p>
										<div className="teamleader_skillsbox">
											{this.state.baseSkills.map(this.generateBaseSkills)}
										</div>
									<p>
									{this.generateExpertises()}<br/><br/>
									{this.generateSkills()}<br/><br/>
									</p>
								</QueryLink>
							</div>
						</div>

						<div className={editProjectButtonDisplay + " edit_project_buttons"}>
							<div className="project_buttonbox" style={{marginTop: '0'}}>
								<QueryLink to={{ pathname:"/createprojectcontroller", query: {projectId: this.props.projectId}}}><button>Edit Project</button></QueryLink>
								<QueryLink to={{ pathname:"/teammatemanagement", query: {projectId: this.props.projectId}}}><button>Manage Team</button></QueryLink>
							<div className={this.props.unpublishButtonVisibiity} style={{marginTop: '0'}}>
								<button onClick={this.props.showUnpublishProjectWarning}>Unpublish Project</button>
							</div>
								{/*<button onClick={this.props.handleDeleteProject} className="project_delete_button" >Delete Project Forever</button>*/}
							</div>
						</div>
						<div className= {contactFollowButtonDisplay}>
							<div className = {this.props.contactFollowReportButtonVisibility}>
								<div className="project_buttonbox">
									<QueryLink className={pointerEvents} to={{ pathname:"/contactkreator", query: { profileId: this.state.kreatorID, projectId: this.props.projectId, sourcePage: 'projectMainPage' } }}><button>Contact Team Leader</button></QueryLink>
									<button className={pointerEvents + followButtonVisibility} onClick={this.props.handleFollowProject}>Follow Project</button>
									<button className={pointerEvents + unfollowButtonVisibility} onClick={this.props.handleUnfollowProject}>Unfollow Project</button>
									<br/>
									<div className="project_report_button" onClick={() => { this.props.handleShowReportBox(projectId, "Project") }}> Report Project</div>
								</div>
							</div>
						</div>
						<div className="lookingfor_kreatorbox">
							<div className="show_for_desktop">
								<h3 style={{marginLeft: '2px'}}>Team Leader Role</h3>
							</div>
							<div className="show_for_mobile">
								<h3 style={{marginLeft: '0.5em'}}>Team Leader Role</h3>
							</div>
							<div className="added_kreator">
								<p className="team_leader_description">
									{this.props.leaderRoleSummary}
								</p>
							</div>
						</div>
						<div className="lookingfor_kreatorbox">
							<div className="show_for_desktop">
								<h3>Looking for:</h3>
							</div>
							<div className="show_for_mobile">
								<h3>Looking for:</h3>
							</div>
							{projectRoles.map(this.generateRoles)}
						</div>
					</div>
					<div className="clear"></div>
				</div>
			);
		}

	}
};