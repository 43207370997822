// This element is for the CreateProject01 page for creating expertise inputs for the baseskill columns.

import React from 'react';
import PropTypes from 'prop-types';

export default class ExpertiseInput extends React.Component {
	static propTypes = {
		checked: PropTypes.bool,
		id: PropTypes.string,
		handleSelection: PropTypes.func,
		text: PropTypes.string
	}

	render = () => {
		return (
			<div>
				<label>
					<input
						type="checkbox"
						checked={this.props.checked}
						id={this.props.id}
						value={this.props.id}
						onChange={this.props.handleSelection}
					/>
					{this.props.text}
				</label>
			</div>
		);
	}
};