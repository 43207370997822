// These are the featured projects for the homepage.

import React from 'react';
import HTTP from '../../utils/Http';
import ProjectSummary from './ProjectSummary.jsx';

export default class FeaturedProjects extends React.Component {
	state = {
		projects: []
	}

	UNSAFE_componentWillMount = () => {
		HTTP.get('/project', (error, projects) => {
			if (error) {
				return;
			}

			if (!Array.isArray(projects)) {
				return console.error('No featured projects to display.');
			}

			this.setState({ projects: projects });
		});
	}

	generateProjectSummary = project => {
		return (
			<ProjectSummary
				key={project._id}
				project={project}
			/>
		);
	}

	render = () => {
		return (
			<div>
				<div>
					<h2>Featured Projects</h2>
					{ this.state.projects.map(this.generateProjectSummary) }
				</div>
			</div>
		);
	}
};
