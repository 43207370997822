import React from 'react';
import ReactRouter from 'react-router';
import { Link } from 'react-router-dom';
import HTTP from '../../utils/Http';
import NavBarNotifications from '../nav/NavBar_Notifications.jsx';
import ProgressBar from './ProgressBar.jsx';
import Footer from '../footer/Footer.jsx';
import ReactImageCropper from '../misc/ReactImageCropper.jsx';
import PropTypes from 'prop-types';
import history from 'app/history';

export default class SignupPhoto extends React.Component {
	static propTypes = {
		edit: PropTypes.bool
	};

	state = {
		chooseFileErr: 'hidden',
		failedSave: 'hidden',
		successSave: 'hidden',
		displayPhoto: 'visible',
		displayCrop: 'hidden',
		fileTooBigError: 'hidden',
		fileData: null,
		file: USER.photo ? '/uploads/' + USER.photo : '/images/signup_addphoto.svg'
	};

	dataURItoBlob = dataURI => {
		const splitDataURI = dataURI.split(',');

		// convert base64/URLEncoded data component to raw binary data held in a string
		const byteString = (splitDataURI[0].indexOf('base64') >= 0 ? atob : unescape)(splitDataURI[1]);

		// separate out the mime component
		const mimeString = splitDataURI[0].split(':')[1].split(';')[0];

		// write the bytes of the string to a typed array
		const ia = new Uint8Array(byteString.split('').map(c => c.charCodeAt(0)));

		return new Blob([ia], { type: mimeString });
	}

	loadCroppedFile = (file, fileData) =>{
		if (!file) {
			return this.setState(this.getInitialState());
		}

		this.setState({
			displayPhoto: 'visible',
			fileTooBigError: 'hidden',
			file: fileData,
			fileData: fileData,
			successSave: 'hidden'
		});
	}

	showCropBox = e => {
		this.setState({displayCrop: 'visible_block'});
	}

	handleBack = e => {
		e.preventDefault();
		this.save({ shouldContinueSignUp: true, backToEditProfilePage: true });
	}

	handleSave = e => {
		e.preventDefault();
		this.save();
	}

	handleSubmit = e => {
		e.preventDefault();
		this.save({ shouldContinueSignUp: true });
	}

	showFileTooBigWarning = () => {
		this.setState({fileTooBigError:'visible_block'});
	}

	save = ({ shouldContinueSignUp = false, backToEditProfilePage = false } = {}) => {
		const path = backToEditProfilePage ? '/editprofilepage' : '/signupportfolio';
		if (!this.state.fileData && this.state.file === "/images/signup_addphoto.svg") {
			this.setState({chooseFileErr: "visible_block"});
			return;
		}
		if (this.state.file && this.state.fileData) {
			var formData = new FormData();
			const blob = this.dataURItoBlob(this.state.fileData);
			formData.append('avatar', blob);

			HTTP.post('/profile/photo', formData, (error, body) => {
				if (error) {
					return this.setState({
						successSave: 'hidden',
						failedSave: 'visible',
						chooseFileErr: 'hidden'
					});
				}

				USER.photo = body.photo;
				USER.profileCompleteness = body.profileCompleteness;

				this.setState({
					successSave: 'visible',
					failedSave: 'hidden',
					chooseFileErr: 'hidden',
					file: '/uploads/' + USER.photo,
					fileData: null
				});

				if (shouldContinueSignUp) {
					history.push(path);
				}
			});
		} else if (shouldContinueSignUp) {
			history.push(path);
		}
	}

	render = () => {
		const editNavBarDisplay = this.props.edit ? 'visible_block' : 'hidden';
		const signupNavBarDisplay = this.props.edit ? 'hidden' : 'visible_block';
		return (
			<div className="viewport_width">
				<NavBarNotifications
					showLoginButton={false}
					showSignupButton={false}
				/>

				<div className="signup_hero">
					<img src="images/signup_photo.svg" />
				</div>

				<div className="clear"></div>

				<div>
					<div className="show_for_desktop">
						<div className="signup_progressbar" style={{width:'56em'}}>
							<ProgressBar progress={USER.profileCompleteness} width="56em"/>
						</div>
					</div>

					<div className="show_for_mobile">
						<div className="signup_progressbar" style={{width:'19em'}}>
							<ProgressBar progress={USER.profileCompleteness} width="18em"/>
						</div>
					</div>
					<div className={"signup_container " + this.state.displayPhoto}>
						<div id="photo_container">
							<h4 className="photo_header">Show people who you are!</h4>
							<h4 className="photo_header" style={{color: "#FF3030"}}>(Please no avatars!)</h4>
							<div className="clear"></div>
							<p className={"success " + this.state.successSave}>
								{CONSTANTS.errors.successSave}
							</p>
							<p className={"error " + this.state.failedSave}>
								{CONSTANTS.errors.failedSave}
							</p>
							<p className={"error " + this.state.chooseFileErr}>
								{CONSTANTS.errors.emptyPhotoError}
							</p>
							<p className={"error " + this.state.fileTooBigError}>
								{CONSTANTS.errors.fileSizeUploadError}
							</p>
							<div className="clear"></div>
							<form className="fileUploadForm">
								<div className="photo_box">
									
									<ReactImageCropper
										file={this.state.file}
										loadCroppedFile={this.loadCroppedFile}
										displayCropBox={null}
										hideCropBox={null}
										showFileTooBigWarning={this.showFileTooBigWarning}
										aspect={1/1}
										width={172}
										minWidth={125}
										isSignupPhotoPage={true}
									/>

								</div>
								<div className={"signup_navigation " + signupNavBarDisplay}>
									<input name="submit" className="nextnav" type="submit" value="Next >" onClick={this.handleSubmit} />
									<input name="submit" className="savenav" type="button" value="Save" onClick={this.handleSave} />
									<Link to="/signupfuture" className="backnav">&lt; Back</Link>
								</div>
								<div className={"edit_signup_navigationb " + editNavBarDisplay}>
									<input className="savenavb" type="submit" value="Save" onClick={this.handleSave}/>
									<input className="backnavb" type="submit" value="Back to Profile" onClick={this.handleBack}/>
								</div>
							</form>
						</div>
					</div>
				</div>
				<div className="bottom_padding"></div>
				<Footer />
			</div>
		);
	}
};

//import ImageCropper from '../misc/ImageCropper.jsx';
/*<div className="show_for_desktop">
	<div className={"cropbox " + this.state.displayCrop}>
		<ImageCropper
			file={this.state.fileData}
			handlePhotoChange={this.handlePhotoChange}
			displayCrop={this.state.displayCrop}
			cutoutWidth={30}
			windowWidth={300}
			windowHeight={300}
			panelWidth={360}
			panelHeight={360}
		/>
	</div>
</div>
<div className="show_for_mobile">
	<div className={"cropbox " + this.state.displayCrop}>
		<ImageCropper
			file={this.state.fileData}
			handlePhotoChange={this.handlePhotoChange}
			displayCrop={this.state.displayCrop}
			cutoutWidth={30}
			windowWidth={200}
			windowHeight={200}
			panelWidth={260}
			panelHeight={260}
		/>
	</div>
</div>
<img height="172" width="172" src={this.state.file} onClick={this.shouldAddFile} style={{ cursor: 'pointer' }} />
	<div className="clear"></div>
	<button onClick={this.shouldAddFile}>choose photo</button>
	<input id="fileUpload" className="hidden" type="file" onChange={this.addFile} accept="image/gif,image/jpeg,image/jpg,image/png,"/>
*/
// addFile = (e) => {
	// 	var file = e.target.files[0];
	// 	var form = document.getElementById('fileUploadForm');

	// 	if (!file) {
	// 		return this.setState(this.getInitialState());
	// 	}

	// 	var fileSize = document.getElementById('fileUpload').files[0].size;

	// 	if (fileSize > CONSTANTS.maxFileSizeUpload) {
	// 		this.setState({ fileTooBigError: 'visible' });
	// 	} else {
	// 		this.setState({ displayPhoto: 'hidden', fileTooBigError: 'hidden' });


	// 		var reader = new FileReader();
	// 		reader.onloadend = function() {
	// 			this.setState({ fileData: reader.result, file: file  });
	// 		}.bind(this);
	// 		reader.readAsDataURL(file);

	// 		this.setState({ successSave: 'hidden', displayCrop: 'visible_block' });
	// 	}

	// 	form.reset();
	// }

// shouldAddFile = e => {
// 	e.preventDefault();
// 	document.getElementById('fileUpload').click();
// }

	// handlePhotoChange = newPhoto => {
// 	this.setState({
// 		file: newPhoto,
// 		displayPhoto: 'visible',
// 		displayCrop: 'hidden'
// 	});
// }