// A profile summary is a brief summary of a users profile used in search pages.

import React from 'react';
import { QueryLink } from 'utils/Utils';
import PropTypes from 'prop-types';

export default class ProfileSummary extends React.Component {
	static propTypes = {
	  mainSearchBar: PropTypes.string,
	  expertises: PropTypes.array,
	  interests: PropTypes.array,
	  skills: PropTypes.array,
	  searching: PropTypes.bool,
	  kreator: PropTypes.object,
	  school: PropTypes.string,
	  schoolText: PropTypes.string,
	  course: PropTypes.string
	}

	state = {
		mainSearchBarText: this.props.mainSearchBar,
		displayAutoCompleteSchools: false
	}

	generateBaseSkills = (baseSkill, index) => {
		return <img key={index} src={`/images/${(CONSTANTS.baseSkills.find(bs => bs.id === baseSkill) || {}).text.toLowerCase()}.svg`} />;
	}

	generateExpertises = () => {
		if (this.props.searching === true) {
			var mainSearchBarText = this.props.mainSearchBar;
			var expertises = this.props.expertises;
			var expertisesText = "";
			var searchText = "";

			//Takes out whitespaces between main search bar text.
			mainSearchBarText = mainSearchBarText.trim();

			//Splits the main search text into an array of strings.
			var mainSearchBarArray = mainSearchBarText.split(/\ +/);

			var searchTermsArray = [];

			if (mainSearchBarText.length > 0){
				searchTermsArray = mainSearchBarArray.concat(expertises);
			} else {
				searchTermsArray = expertises;
			}

			if (searchTermsArray.length > 0) {

				var kreatorExpertises = this.props.kreator.expertises;
				var lowerCaseKreatorExpertisesArray = kreatorExpertises.map(expertise => expertise.toLowerCase());

				// Gets the input text of the search bar, turns it to lower case, and creates an
				// array for checking match.
				var lowerCaseSearchTermsArray = searchTermsArray.map(searchTerm => searchTerm.toLowerCase());

				// Creates two arrays to hold the expertises to be bolded and the expertises to remain normal.
				var textArrayBold = [];
				var textArrayNormal = lowerCaseKreatorExpertisesArray;


				lowerCaseSearchTermsArray.forEach(function (searchTerm){
					lowerCaseKreatorExpertisesArray.forEach(function (expertise) {
						// If a searchTerm matches a expertise then it will be pushed into the textArrayBold.
						var match = expertise.indexOf(searchTerm);
						if (match !== -1) {
							textArrayBold.push(expertise);
						}
					});
				});

				var textArrayBoldUnique = this.removeDuplicateResults(textArrayBold, textArrayNormal);

				// Turns the arrays into a list of strings.
				var listOfExpertisesBold = textArrayBoldUnique.join(', ')
				var listOfExpertisesNormal = textArrayNormal.join(', ');
				var completeListOfExpertises = listOfExpertisesBold + (listOfExpertisesBold.length ? ', ' : '') + listOfExpertisesNormal;

				// Capitalizes every word in the string.
				var completeListOfExpertisesCapitalized = completeListOfExpertises.replace(/\b\w/g, function(letter){ return letter.toUpperCase() });

				// Creates strings that will be returned with JSX.
				var expertisesListSubstrBold = "";
				var expertisesListSubstrNormal = "";
				var expertisesListSubstr = completeListOfExpertisesCapitalized.substr(0, 40);
				var expertisesListSubstrThreeDots = expertisesListSubstr.substr(0, expertisesListSubstr.lastIndexOf(','));

				if (completeListOfExpertisesCapitalized.length > 40) {
					if (listOfExpertisesBold.length > expertisesListSubstr.length) {
						expertisesListSubstrBold = expertisesListSubstrThreeDots + "...";
					} else {
						expertisesListSubstrBold = expertisesListSubstrThreeDots.substr(0, listOfExpertisesBold.length);
						expertisesListSubstrNormal = expertisesListSubstrThreeDots.substr(listOfExpertisesBold.length, 40) + "...";
					}
				} else {
					expertisesListSubstrBold = completeListOfExpertisesCapitalized.substr(0, listOfExpertisesBold.length);
					expertisesListSubstrNormal = completeListOfExpertisesCapitalized.substr(listOfExpertisesBold.length, 40);
				}

				return (<div>
							<p>
								<b>{expertisesListSubstrBold}</b>
								{expertisesListSubstrNormal}
							</p>
						</div>
				);

			} else {
				var listOfExpertiseStrings = this.props.kreator.expertises
				.concat(this.props.kreator.expertisesOther.map(expertise => expertise.text))
				.join(', ');

				if (listOfExpertiseStrings.length > 40) {
					var expListSubstr = listOfExpertiseStrings.substr(0, 40);
					var expListSubstrThreeDots = expListSubstr.substr(0, expListSubstr.lastIndexOf(',')) + '...';
					return <div><p>{expListSubstrThreeDots}</p></div>
				}

				return <div><p>{listOfExpertiseStrings}</p></div>;
			}
		} else {
			var listOfExpertiseStrings = this.props.kreator.expertises
			.concat(this.props.kreator.expertisesOther.map(expertise => expertise.text))
			.join(', ');

			if (listOfExpertiseStrings.length > 40) {
				var expListSubstr = listOfExpertiseStrings.substr(0, 40);
				var expListSubstrThreeDots = expListSubstr.substr(0, expListSubstr.lastIndexOf(',')) + '...';
				return <div><p>{expListSubstrThreeDots}</p></div>
			}

			return <div><p>{listOfExpertiseStrings}</p></div>;
		}

	}

	generateSkills = () => {

		if (this.props.searching === true) {
			var mainSearchBarText = this.props.mainSearchBar;
			var searchText = "";

			//Takes out whitespaces between text.
			mainSearchBarText = mainSearchBarText.trim();
			var mainSearchBarArray = mainSearchBarText.split(/\ +/);

			var skillsArray = this.props.skills;
			var skillsSearchBarText = "";

			// Turns arrays into strings.
			mainSearchBarText = mainSearchBarArray.join(" ");
			if (skillsArray.length > 0) {
				skillsSearchBarText = skillsArray.join(" ");
			}

			// Adds searchText and mainSearchBarText together and assigns them to searchText.
			if (mainSearchBarText !== "" && skillsSearchBarText !== "") {
				searchText += mainSearchBarText + " " + skillsSearchBarText;
			} else if (mainSearchBarText !== "" && skillsSearchBarText === ""){
				searchText += mainSearchBarText;
			} else if (mainSearchBarText === "" && skillsSearchBarText !== ""){
				searchText += skillsSearchBarText;
			}

			var searchTextArrayUnique = this.removeDuplicateSearchTerms (searchText);

			searchText = searchTextArrayUnique.join(" ");

			if (searchText.length > 0) {
				// Gets the kreator skills array and changes them to lower case for checking match.
				var kreatorSkillsArray = this.props.kreator.skills;
				var lowerCaseSkillsArray = kreatorSkillsArray.map(skill => skill.toLowerCase());

				// Gets the input text of the search bar, turns it to lower case, and creates an
				// array for checking match.
				var lowerCaseSearchText = searchText.toLowerCase();
				var lowerCaseSearchArray = lowerCaseSearchText.split(" ");

				// Creates two arrays to hold the skills to be bolded and the skills to remain normal.
				var textArrayBold = [];
				var textArrayNormal = lowerCaseSkillsArray;

				lowerCaseSearchArray.forEach(function (textElement){
					lowerCaseSkillsArray.forEach(function (skill) {
						// If a search text matches a skill then it will be pushed into the textArrayBold.
						var match = skill.indexOf(textElement);
						if (match !== -1) {
							textArrayBold.push(skill);
						}
					});
				});

				var textArrayBoldUnique = this.removeDuplicateResults(textArrayBold, textArrayNormal);

				// Turns the arrays into a list of strings.
				var listOfSkillsBold = textArrayBoldUnique.join(', ')
				var listOfSkillsNormal = textArrayNormal.join(', ');
				var completeListOfSkills = listOfSkillsBold + (listOfSkillsBold.length ? ', ' : '') + listOfSkillsNormal;

				// Capitalizes every word in the string.
				var completeListOfSkillsCapitalized = completeListOfSkills.replace(/\b\w/g, function(letter){ return letter.toUpperCase() });

				// Creates strings that will be returned with JSX.
				var skillListSubstrBold = "";
				var skillListSubstrNormal = "";
				var skillListSubstr = completeListOfSkillsCapitalized.substr(0, 40);
				var skillListSubstrThreeDots = skillListSubstr.substr(0, skillListSubstr.lastIndexOf(','));

				if (completeListOfSkillsCapitalized.length > 40) {
					if (listOfSkillsBold.length > skillListSubstr.length) {
						skillListSubstrBold = skillListSubstrThreeDots + "...";
					} else {
						skillListSubstrBold = skillListSubstrThreeDots.substr(0, listOfSkillsBold.length);
						skillListSubstrNormal = skillListSubstrThreeDots.substr(listOfSkillsBold.length, 40) + "...";
					}
				} else {
					skillListSubstrBold = completeListOfSkillsCapitalized.substr(0, listOfSkillsBold.length);
					skillListSubstrNormal = completeListOfSkillsCapitalized.substr(listOfSkillsBold.length, 40);
				}

				return (<div>
							<p>
								<b>{skillListSubstrBold}</b>
								{skillListSubstrNormal}
							</p>
						</div>
				);
			} else {
				var listOfSkills = this.props.kreator.skills.join(', ');

				if (listOfSkills.length > 40) {
					var skillListSubstr = listOfSkills.substr(0, 40);
					var skillListSubstrThreeDots = skillListSubstr.substr(0, skillListSubstr.lastIndexOf(',')) + '...';
					return <div><p>{skillListSubstrThreeDots}</p></div>
				}

				return <div><p>{listOfSkills}</p></div>;
				}
		} else {
			var listOfSkills = this.props.kreator.skills.join(', ');

			if (listOfSkills.length > 40) {
				var skillListSubstr = listOfSkills.substr(0, 40);
				var skillListSubstrThreeDots = skillListSubstr.substr(0, skillListSubstr.lastIndexOf(',')) + '...';
				return <div><p>{skillListSubstrThreeDots}</p></div>
			}

			return <div><p>{listOfSkills}</p></div>;
		}
	}

	generateInterests = () => {
		if (this.props.searching === true) {
			var mainSearchBarText = this.props.mainSearchBar;
			var kreatorInterestsIds = this.props.kreator.interests;

			var kreatorInterestsStrings = this.turnIdsIntoText(kreatorInterestsIds);
			kreatorInterestsStrings = kreatorInterestsStrings.map(interest => interest.toLowerCase());

			var searchedInterestsIds = this.props.interests;
			searchedInterestsIds = searchedInterestsIds.map(interestId => parseInt(interestId));
			var searchedInterestsStrings = this.turnIdsIntoText(searchedInterestsIds);
			searchedInterestsStrings = searchedInterestsStrings.map(interest => interest.toLowerCase());

			//Takes out whitespaces between text.
			mainSearchBarText = mainSearchBarText.trim();
			var mainSearchBarArray = mainSearchBarText.split(/\ +/);
			mainSearchBarArray = mainSearchBarArray.map(searchTerm => searchTerm.toLowerCase());


			// Turns arrays into strings.
			mainSearchBarText = mainSearchBarArray.join(" ");
			var searchArrayTerms;
			if (mainSearchBarText !== ""){
				searchArrayTerms = searchedInterestsStrings.concat(mainSearchBarArray);
			} else {
				searchArrayTerms = searchedInterestsStrings;
			}

			var searchArrayTermsStrings = searchArrayTerms.join(" ");

			var interestsToBeBolded = [];
				if (searchArrayTermsStrings.length > 0){
					kreatorInterestsStrings.forEach (function (interest) {
						searchArrayTerms.forEach(function (searchedInterest){
							var match = interest.indexOf(searchedInterest);

								if (match !== -1) {
									interestsToBeBolded.push(interest);

								}
						});
					});

					var textArrayBoldUnique = this.removeDuplicateResults(interestsToBeBolded, kreatorInterestsStrings);
					var listOfBoldStrings = textArrayBoldUnique.join(', ');
					var listOfNormalStrings = kreatorInterestsStrings.join(', ');

					var completeListOfInterests = listOfBoldStrings + (listOfBoldStrings.length ? ', ' : '') + listOfNormalStrings;
					// Capitalizes every word in the string.
					var completeListOfInterestsCapitalized = completeListOfInterests.replace(/\b\w/g, function(letter){ return letter.toUpperCase() });

					// Creates strings that will be returned with JSX.
					var interestListSubstrBold = "";
					var interestListSubstrNormal = "";
					var interestListSubstr = completeListOfInterestsCapitalized.substr(0, 40);
					var interestListSubstrThreeDots = interestListSubstr.substr(0, interestListSubstr.lastIndexOf(','));

					if (completeListOfInterestsCapitalized.length > 40) {
						if (listOfBoldStrings.length > interestListSubstr.length) {
							interestListSubstrBold = interestListSubstrThreeDots + "...";
						} else {
							interestListSubstrBold = interestListSubstrThreeDots.substr(0, listOfBoldStrings.length);
							interestListSubstrNormal = interestListSubstrThreeDots.substr(listOfBoldStrings.length, 40) + "...";
						}
					} else {
						interestListSubstrBold = completeListOfInterestsCapitalized.substr(0, listOfBoldStrings.length);
						interestListSubstrNormal = completeListOfInterestsCapitalized.substr(listOfBoldStrings.length, 40);
					}

					return (<div>
								<p>
									<b>{interestListSubstrBold}</b>
									{interestListSubstrNormal}
								</p>
							</div>
					);
				} else {
			var listOfInterests = this.turnIdsIntoText(this.props.kreator.interests);
			listOfInterests = listOfInterests.join(', ');

			if (listOfInterests.length > 40) {
				var interestListSubstr = listOfInterests.substr(0, 40);
				var interestListSubstrThreeDots = interestListSubstr.substr(0, interestListSubstr.lastIndexOf(',')) + '...';
				return <div><p>{interestListSubstrThreeDots}</p></div>
			}
			return <div><p>{listOfInterests}</p></div>;
			}
		} else {
				var listOfInterests = this.turnIdsIntoText(this.props.kreator.interests);
				listOfInterests = listOfInterests.join(', ');

				if (listOfInterests.length > 40) {
					var interestListSubstr = listOfInterests.substr(0, 40);
					var interestListSubstrThreeDots = interestListSubstr.substr(0, interestListSubstr.lastIndexOf(',')) + '...';
					return <div><p>{interestListSubstrThreeDots}</p></div>
				}
				return <div><p>{listOfInterests}</p></div>;
		}
	}

	generateSchool = () => {

		var schools = this.props.kreator.schools;

		// Reorders schools so that schools kreator is attending shows up first.
		var i
		for(i = 0; i < schools.length; i++){
			if(schools[i].attending == true){
				var schoolObject = schools[i];
				schools.splice(i, 1);
				schools.splice(0, 0, schoolObject);
			}
		}

		var schoolName = schools[0].school.names[0];
		var schoolMain = {name: schools[0].school.names[0], attending: schools[0].attending};

		var schoolSearchText = this.props.schoolText;
		var kreator = this.props.kreator;

		if (this.props.searching === true) {

			var mainSearchBarText = this.props.mainSearchBar;
			var searchText = "";

			//Takes out whitespaces between text.
			mainSearchBarText = mainSearchBarText.trim();
			var mainSearchBarArray = mainSearchBarText.split(/\ +/);

			schoolSearchText = schoolSearchText.trim();
			var schoolNamesAndAttendance = [];

			schools.forEach(function (schoolObject){
				var name = schoolObject.school.names[0];
				var attending = schoolObject.attending;
				schoolNamesAndAttendance.push({name: name, attending: attending});
			});

			mainSearchBarArray.forEach(function(searchTermText){
				var searchTerm = searchTermText.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&');
				var regex = new RegExp(`(\\s|^)${searchTerm}(\\s|$)`, 'ig'); // keep in outer "mainSearchBarArray" loop
				schoolNamesAndAttendance.forEach (function (school){
					if(regex.test(school.name)){
						schoolName = school.name;
						schoolMain = school;
					}
				});
			});

			schoolNamesAndAttendance.forEach (function (school){
				var lowerCaseSchoolName = school.name.toLowerCase();
				var lowerCaseSchoolSearchText = schoolSearchText.toLowerCase();
				if (lowerCaseSchoolSearchText === lowerCaseSchoolName) {
					schoolName = school.name;
					schoolMain = school;
				}
			});

			if (schoolMain.name.length > 30) {
				var schoolNameSubstr = schoolMain.name.substr(0, 30);
				schoolMain.name = schoolNameSubstr + "...";
			}
			//return schoolName;
			var display = "hidden";
			if (schoolMain.attending){
				display = "visible_block";
			}

			return (
				<div>
					<div className={"middle_dot " + display}>&middot;</div>{schoolMain.name}
				</div>
			);

		} else {
			try {
				if (schoolName.length > 30) {
					var schoolNameSubstr = schoolName.substr(0, 30);
					schoolName = schoolNameSubstr + "...";
				}
				return schoolName;
			} catch (e) {
				return '';
			}
		}

	}

	removeDuplicateSearchTerms = (searchTerms) => {
			var searchTextArray = searchTerms.split(" ");
			var lowerCaseSearchTextArray = searchTextArray.map(term => term.toLowerCase());
			var searchTextArrayUnique = lowerCaseSearchTextArray.filter(function(item, pos) {
			    return lowerCaseSearchTextArray.indexOf(item) == pos;
			});
			return searchTextArrayUnique;
	}

	removeDuplicateResults = (resultsArrayBold, resultsArrayNormal) => {
		var textArrayUnique = resultsArrayBold.filter(function(item, pos) {
			return resultsArrayBold.indexOf(item) == pos;
		});

		textArrayUnique.forEach(function (result) {
			var resultIndex = resultsArrayNormal.indexOf(result);
			resultsArrayNormal.splice(resultIndex, 1);
		});

		return textArrayUnique;
	}

	turnIdsIntoText = (idArray) => {
		var stringsArray = idArray.map(function (interestId){
			var interestText = "";
			CONSTANTS.interests.forEach(function(interest){
				if (interest.id === interestId) {
					interestText = interest.text;
				}
			})
			if (interestText !== "") {
				return interestText;
			}
		});
		return stringsArray;
	}
	render = () => {
		return (
				<div className="profile_summarybox">
					<div className="profile_summary">
						<QueryLink to={{ pathname:"/profilepage", query: { profileId: this.props.kreator._id }}}>
							<div className="profile_summary_pic">
								<img src={"/uploads/" + this.props.kreator.photo} />
								<div className="clear"></div>
								<div className="profile_summary_name">{this.props.kreator.firstName} {this.props.kreator.lastName}</div>
							</div>

							<div className="profile_summary_info">
								<div className="school_summary_name">{this.generateSchool()}</div>
								<div className="clear"></div>
								<div className="text_style">{this.props.kreator.location}</div>
								<div className="clear"></div><br/>
								<div className="text_style" style={{ marginBottom: '0.5em' }}>Skills:</div>
								<div className="profile_summary_skills">
									{this.props.kreator.baseSkills.map(this.generateBaseSkills)}
								</div>
								<div className="clear"></div>
								<div style={{height: '4em', overflow: 'hidden'}}>
									<div>{this.generateExpertises()}</div>
								</div>
								<div style={{height: '4em', overflow: 'hidden'}}>
									<div>{this.generateSkills()}</div>
								</div>
								<div style={{height: '4em', overflow: 'hidden'}}>
									<div>{this.generateInterests()}</div>
								</div>

							</div>
						</QueryLink>
					</div>
				</div>
		);
	}
};