// This is the start project explanation page that users will see when they first start a project.

import React from 'react';
import PropTypes from 'prop-types';
import history from 'app/history';

import NavBarNotifications from '../nav/NavBar_Notifications.jsx';
import Footer from '../footer/Footer.jsx';
import Slideshow from '../misc/Slideshow.jsx';

export default class CreateProjectStart extends React.Component {
	handleStartProject = () => {
		const path = USER ? '/createprojectcontroller' : '/loginpage';

		history.push(path);
	}

	render = () => {
		return (
			<div className="viewport_width">
				<NavBarNotifications
					isProjectStartPage = {true}
					showLoginButton = {false}
					showSignupButton = {false}
				/>

				<div className="clear"></div>
				<div style={{ marginBottom: '2em' }} >
					
					<div className="show_for_desktop">
						<div className="create_project_image_box">
							<img className="hero_image" src="images/desert_road_01.png"/>
						</div>
						<div className="create_project_image_text">
							<p>The journey of a thousand miles begins with a simple step. &nbsp;&nbsp;&nbsp;- Lao Tzu<br/>
								But it's way easier to use the bus.
							</p>
						</div>
					</div>

					<div className="show_for_mobile">
						<div className="create_project_image_box">
							<img className="hero_image" style={{marginTop: '-3em'}} src="images/desert_road_01.png"/>
						</div>
						<div className="create_project_image_text">
							<p>The journey of a thousand miles begins with a simple step. &nbsp;&nbsp;- Lao Tzu<br/><br/>
								But it's way easier to use the bus.
							</p>
						</div>
					</div>
					
					<div className="clear"></div>
					<div id="create_project_button">
						<button onClick={this.handleStartProject}>Start Project</button>
					</div>
				</div>

				
				<div>
					<div className="why_kb">
						<h1>Why use Kreator<span>Bus</span>?</h1>
						<p>Have you ever had a great project idea but couldn’t find the
						right people to help you make it? We’ve run into that exact same
						problem which is why we started KreatorBus. A project can be as
						small as a class assignment to something as large as the next great
						startup. What’s important is that you are passionate about your idea
						and dedicated to seeing it through.
						</p>
					</div>
				</div>
				<div className="clear"></div>
				<Footer />
			</div>
		);
	}
};
