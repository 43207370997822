// This is the box that shows the updates of a project. It is used in ProjectMainPage.

import React from 'react';
import PropTypes from 'prop-types';
import { QueryLink } from 'utils/Utils';
import HTTP from '../../utils/Http';
import history from 'app/history';
import ProjectUpdate from './ProjectUpdate.jsx';

export default class ProjectUpdatesBox extends React.Component {
	static propTypes = {
		projectId: PropTypes.string
	}

	static contextTypes = {
		router: PropTypes.object
	}

	state = {
		skipUpdates: 0,
		updates: [],
		numberOfUpdates: 5,
		deleteUpdateError: 'hidden',
		displayEditCommentTextArea: false
	}

	UNSAFE_componentWillMount = () => {
		this.getUpdates();
	}

	handleEditUpdate = updateId => {
		var path = "/postupdate?projectId=" + this.props.projectId + "&updateId=" + updateId + "&editUpdate=" + true;
		history.push(path);
	}

	getUpdates = ({ shouldAppend = false } = {}) => {
		HTTP.get(`/project/${this.props.projectId}/updates`, { skip: this.state.skipUpdates }, (error, body) => {
			if (error) {
				return;
			}

			this.setState({
				updates: shouldAppend ? this.state.updates.concat(body) : body
			});
		});
	}

	generateUpdates = (update = {}) => {
		return [...this.state.updates]
			.sort((a, b) =>
				+new Date(b.date) - +new Date(a.date)
			)
			.map(update => {
				const date = new Date(update.date);

				return (
					<ProjectUpdate
						key={update._id}
						updateId={update._id}
						text={update.text}
						dateString={date.toDateString()}
						timeString={date.toLocaleTimeString()}
						canBeEditedByUSER={this.props.canBeEditedByUSER}
						handleEditUpdate={this.handleEditUpdate}
						handleShowReportBox={this.props.handleShowReportBox}
					/>
				);
			});
	}

	nextUpdatePage = () => {
		this.setState({
			skipUpdates: this.state.skipUpdates + 5
		}, () => this.getUpdates({ shouldAppend: true }));
	}

	render = () => {
		var displayPostUpdateButton = "none";
		if(USER){
			if (USER._id === this.props.projectKreator._id) {
				displayPostUpdateButton = "block";
			}
		}

		return (
			<div className="project_updatebox">
				<h3>Project Updates</h3>
				<div style={{display: displayPostUpdateButton}}>
				<QueryLink to={{ pathname: '/postupdate', query: { projectId: this.props.projectId } }}><button className="post_update_button">Post Update</button></QueryLink>
				</div>
				<a id="update_error_text"></a>
				<p className={"update_error " + this.state.deleteUpdateError}>There was a problem with your request</p>
				{this.generateUpdates()}
				<button className="show_more" onClick={this.nextUpdatePage}>+show more</button>
			</div>
		);
	}
};