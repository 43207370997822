// This is the page a user is directed to when signup is finished.

import React from 'react';
import { Link } from 'react-router-dom';
import NavBarNotifications from '../nav/NavBar_Notifications.jsx';
import Footer from '../footer/Footer.jsx';
import PropTypes from 'prop-types';
import ProgressBar from './ProgressBar.jsx';

export default class SignupFinish extends React.Component {
	static contextTypes = {
		router: PropTypes.object
	}

	render = () => {
		const profileCompleteness = USER ? USER.profileCompleteness : 0
		return (
			<div className="viewport_width">
				<NavBarNotifications
					showLoginButton={false}
					showSignupButton={false}
				 />
				<div className="signup_hero">
					<img src="images/signup_finish.svg" />
				</div>

				<div className="clear"></div>

				<div>
					<div className="show_for_desktop">
						<div className="signup_progressbar" style={{width:'56em'}}>
							<ProgressBar progress={profileCompleteness} width="56em"/>
						</div>
					</div>

					<div className="show_for_mobile">
						<div className="signup_progressbar" style={{width:'19em'}}>
							<ProgressBar progress={profileCompleteness} width="18em"/>
						</div>
					</div>
					<div className="clear"></div>
					<div className="signup_container">
						<div className="finish_container">
							<h4>Hooray! You made it!</h4>
							<h5>Your profile has been submitted!</h5>
							<p>
							Thank you for taking the time to sign up. You can always go to your
							account to edit or add information about yourself. Click below to start
							searching for projects or kreators to work with. <br/><br/>
							As soon as your profile is approved, you will be able to start messaging
							other kreators, creating projects, and posting comments.
							Our mission is to help kreators meet and make wonderful things.
							If you have any suggestions or problems with the site, please let us
							know. We would love to hear from you.
							</p>
							<div className="finish_container_button_box">
								<Link to="/kreator/search">Kreators</Link>
								<Link to="/project/search">Projects</Link>
								<Link to="/createprojectstart">Start Project</Link>
								<Link to="useraccount">My Account</Link>
							</div>
							<br/><br/>
						</div>
					</div>
				</div>
				<div className="bottom_padding"></div>
				<Footer />
			</div>
		);
	}
};
