import * as types from './actionTypes';

export function login(user) {
	return {
		type: types.LOGIN,
		user,
	};
};

export function logout() {
	return {
		type: types.LOGOUT,
	};
};

export function refreshUnread() {
	return {
		type: types.SHOULD_REFRESH_UNREAD,
	};
};

export function refreshedUnread() {
	return {
		type: types.REFRESH_UNREAD_DONE,
	};
};
