// @todo Finish

// This is the main profile page for users that holds all their details.
// It is used by the ProfilePage module.

import React from 'react';
import { Link } from 'react-router-dom';
import HTTP from '../../utils/Http';
import { QueryLink } from 'utils/Utils';
import history from 'app/history';

import ProfileLink from './ProfileLink.jsx';
import ProfilePortfolioImage from './ProfilePortfolioImage.jsx';
import ProfileElement from './ProfileElement.jsx';
import PropTypes from 'prop-types';

export default class ProfileDetail extends React.Component {
	static contextTypes = {
		router: PropTypes.object,
	}

	static propTypes = {
		profileId: PropTypes.string,
		displayReportIssue: PropTypes.func,
		buttonVisibility: PropTypes.string
	}

	state = {
		profileId: '',
		firstName: '',
		lastName: '',
		email: '',
		zipCode: '',
		location: '',
		baseSkills: [],
		expertises: [],
		expertisesOther: [],
		skills: [],
		achievement: '',
		schools: [],
		courses: [],
		bio: '',
		interests: [],
		photo: '',
		additionalInfo: {
			hoursPerWeek: 0,
			whatToGain: 0,
			travelRadius: 0,
			teamOrProject: 0
		},
		futureGoals: '',
		portfolio: null,
		fictional: '',
		weekend: '',
		projectsCreatedArray: [],
		saveSuccess: "hidden",
		saveError: "hidden",
		duplicateError: "hidden",
		displayCourses: "hidden"
	}

	UNSAFE_componentWillMount = () => {
		this.loadProfile();
	}

	UNSAFE_componentWillUpdate = () => {
		if(this.state.profileId !== this.props.profileId){
			this.loadProfile();
		}
	}

	loadProfile = () => {
		var profileId = this.props.profileId;
		if (!profileId) {
			return;
		}

		HTTP.get(`/kreator/${profileId}`, (error, body) => {
			if (error) {
				return history.replace('/pagenotfound');
			}
			this.setState({
				profileId: profileId,
				firstName: body.firstName,
				lastName: body.lastName,
				email: body.email,
				zipCode: body.zipCode,
				location: body.location,
				baseSkills: body.baseSkills,
				expertises: body.expertises,
				expertisesOther: body.expertisesOther,
				skills: body.skills,
				achievement: body.achievement,
				schools: body.schools,
				courses: body.courses,
				bio: body.bio,
				interests: body.interests,
				photo: 'uploads/' + body.photo,
				additionalInfo: body.additionalInfo,
				futureGoals: body.futureGoals,
				portfolio: body.portfolio,
				fictional: body.fictional,
				weekend: body.weekend,
				displayCourses: body.courses.length > 0 ? "visible" : "hidden",
				projectsCreatedArray: body.projectsCreated
			});
		});
	}
	generateSchools = () => {
		var userSchools = this.state.schools;
		var i
		for(i = 0; i < userSchools.length; i++){
			if(userSchools[i].attending == true){
				var schoolObject = userSchools[i];
				userSchools.splice(i, 1);
				userSchools.splice(0, 0, schoolObject);
			}
		}
		var userSchoolList = userSchools.map(function(schoolObject){
			var display = "hidden";
			if(schoolObject.attending){
				display = "visible_block";
			}
			return (
				<div key={schoolObject.school._id}>
					<div className={"middle_dot " + display}>&middot;</div>{schoolObject.school.names[0]}
				</div>
			);
		});
		return userSchoolList
	}

	generateCourses = () => {
		var courses = this.state.courses;
		var coursesNameArray = [];

		courses.forEach(function(course){
			coursesNameArray.push(course.name);
		});
		var listOfCourses = coursesNameArray.join(', ');
		return listOfCourses;
	}

	generateBaseSkills = (baseSkill, index) => {
		return <img key={index} src={`/images/${(CONSTANTS.baseSkills.find(bs => bs.id === baseSkill) || {}).text.toLowerCase()}.svg`} />;
	}

	generateExpertises = () => {
		var listOfExpertiseStrings = this.state.expertises
			.concat(this.state.expertisesOther.map(expertise => expertise.text))
			.join(', ');

		return listOfExpertiseStrings;
	}

	generateSkills = () => {
		var listOfSkills = this.state.skills.join(', ');

		return listOfSkills;
	}

	generateInterests = () => {
		var listOfInterestStrings = this.state.interests
			.map(interestNumber => (CONSTANTS.projectCategories[interestNumber] || {}).text)
			.join(', ');

		return listOfInterestStrings;
	}

	generateProjectList = () => {
		var projectsCreated = this.state.projectsCreatedArray;
		var projectsCreatedNames = projectsCreated.map( function(project) {
			return (
				<div key={project._id}>
					<li><QueryLink to={{ pathname:"/projectmainpage", query: { projectId: project._id } }}>{project.name}</QueryLink></li>
				</div>
			);
		});
		return projectsCreatedNames
	}

	handleGoToMessagePage = e => {
		history.push(`/contactkreator${e.target.attributes['data-key'].value}`);
	}


	handleAddToBank = () => {
		if(!USER){
			history.push('/loginpage');
			return;
		}
		var kreatorBank = USER.kreatorBank;
		var duplicateKreator = false;
		var profileId = this.props.profileId;

		// Checks to see if there is a duplicate profile in the kreatorBank.
		kreatorBank.forEach(function (kreatorId) {
			if (kreatorId._id === profileId) {
				duplicateKreator = true;
			}
		});

		if (duplicateKreator === true) {
			this.setState({
				duplicateError: "visible_block",
				saveError: "hidden",
				saveSuccess: "hidden"
			});
		} else {
			var kreatorBankStringList = [];
			kreatorBank.forEach(function (kreator) {
				kreatorBankStringList.push(kreator._id);
			});
			kreatorBankStringList.push(profileId);

			HTTP.post('/profile/kreatorBank', kreatorBankStringList, (error, body) => {
				if (error) {
					this.setState({ saveError: 'visible_block', saveSuccess: 'hidden' });
				} else {
					this.setState({ saveSuccess: 'visible_block', saveError: 'hidden' });
					USER.kreatorBank = body.kreatorBank;
				}
			});
		}

		document.getElementById('kreatorBank_results').scrollIntoView();
	}

	render = () => {
		// var profileImageList = this.state.profile.portfolioPics.map(function(portfolioimage) {
		// 	return (
		// 		<ProfilePortfolioImage
		// 			key={portfolioimage.id}
		// 			image={portfolioimage.portfolioPic}
		// 		/>
		// 	);
		// });

		var portfolio = this.state.portfolio;
		var profileLinkList = portfolio ? portfolio.links.map((portfoliolink, index) => (
			<ProfileLink
				key={index}
				link={portfoliolink}
			/>
		)) : <div />;

		var profileFilledOutFieldsList = [];
		if (this.state.achievement) {
			profileFilledOutFieldsList.push(<ProfileElement key="0" icon="images/achievement_icon.svg" title="Proudest Achievement" text={this.state.achievement} />);
		}
		if (this.state.bio) {
			profileFilledOutFieldsList.push(<ProfileElement key="1" icon="images/bio_icon.svg" title="Personal Bio" text={this.state.bio} />);
		}
		if (this.state.futureGoals) {
			profileFilledOutFieldsList.push(<ProfileElement key="2" icon="images/future_icon.svg" title="Future Goals" text={this.state.futureGoals} />);
		}
		if (this.state.fictional) {
			profileFilledOutFieldsList.push(<ProfileElement key="3" icon="images/fictional_icon.svg" title="Fictional Character" text={this.state.fictional} />);
		}
		if (this.state.weekend) {
			profileFilledOutFieldsList.push(<ProfileElement key="4" icon="images/weekend_icon.svg" title="Typical Weekend" text={this.state.weekend} />);
		}

		return (
			<div>
				<div>
					<a id="kreatorBank_results"></a>
					<div className="clear"></div>
					<div className={"kreatorbank_add " + this.state.saveSuccess}>
						This kreator was successfully added to your bank.
					</div>
					<div className="clear"></div>
					<div className={"kreatorbank_fail " + this.state.saveError}>
						There was a problem adding this kreator to your bank.
					</div>
					<div className="clear"></div>
					<div className={"kreatorbank_fail " + this.state.duplicateError}>
						This kreator is already in your bank.
					</div>
					<div className="clear"></div>
					<div className="user_profile_topbox">
						<div className="user_profile_picbox">
							<img src={this.state.photo} /><br/>
							<p>{this.state.firstName} {this.state.lastName}</p>
						</div>
						<div className="user_profile_info">
							<span className="blacktext">Schools:&nbsp;</span>
							<div className="clear"></div>
							{this.generateSchools()}
							<div className="clear" style={{marginBottom: '1em'}}></div>
							<span className={"blacktext " + this.state.displayCourses}>Courses:&nbsp;</span>
							{this.generateCourses()}
							<div className="clear" style={{marginBottom: '1em'}}></div>
							<span className="blacktext">Location:&nbsp;</span>
							{this.state.location}
							<div className="clear" style={{marginBottom: '1em'}}></div>
							<span className="blacktext">B.A.S.E. Skills:</span>
							<div className="user_profile_skills">
								{this.state.baseSkills.map(this.generateBaseSkills)}
							</div>
							<div className="clear"></div>
							<span className="blacktext">Expertises:&nbsp;</span>
							{this.generateExpertises()}
							<div className="clear" style={{marginBottom: '1em'}}></div>
							<span className="blacktext">Interests:&nbsp;</span>
							{this.generateInterests()}
							<div className="clear"></div>
						</div>
						<div className="user_profile_detailbox">
							{/*<div className="text_style">
								<span className="blacktext">Last Active:&nbsp;</span> days ago
							</div>*/}
							<div className="text_style">
								<span className="blacktext">Hours can work per week:&nbsp;</span>
								{CONSTANTS.additionalInfo.hoursPerWeek[this.state.additionalInfo.hoursPerWeek + 1].text}
							</div>
							{/*<div className="text_style">
								<span className="blacktext">Looking for:&nbsp;</span>
								{CONSTANTS.additionalInfo.whatToGain[this.state.additionalInfo.whatToGain + 1].text}
							</div>*/}
							<div className="text_style">
								<span className="blacktext">Willing to travel:&nbsp;</span>
								{CONSTANTS.additionalInfo.travelRadius[this.state.additionalInfo.travelRadius + 1].text}<br/>
							</div>
							<div className="text_style">
								<span className="blacktext">Wants to:&nbsp;</span>
								{CONSTANTS.additionalInfo.teamOrProject[this.state.additionalInfo.teamOrProject + 1].text}
							</div>
							<div className="text_style">
								<span className="blacktext" style={{float: 'left'}}>Skills:&nbsp;</span>
								{this.generateSkills()}
							</div>

						</div>
					</div>
				</div>

				<div className="clear"></div>

				<div className="user_profile_bottombox_holder">
					<div className="user_profile_bottombox">
						<div className={this.props.buttonVisibility + " user_profile_buttons"} >
							<div className="show_for_desktop">
								<p onClick={() => this.props.handleShowReportBox(this.props.profileId, "Kreator")}><span>Report user</span></p>
								<QueryLink to={{ pathname:"/contactkreator", query: { profileId: this.props.profileId, buttonType: "invite" } }}><div className="text_style">invite to team</div></QueryLink>
								<button onClick={this.handleAddToBank}>save to bank</button>
								<QueryLink to={{ pathname:"/contactkreator", query: { profileId: this.props.profileId, buttonType: "contact" } }}><div className="text_style">contact member</div></QueryLink>
							</div>
							<div className="show_for_mobile">
								<QueryLink to={{ pathname:"/contactkreator", query: { profileId: this.props.profileId, buttonType: "contact" } }}><div className="text_style">contact member</div></QueryLink>
								<button onClick={this.handleAddToBank}>save to bank</button>
								<QueryLink to={{ pathname:"/contactkreator", query: { profileId: this.props.profileId, buttonType: "invite" } }}><div className="text_style">invite to team</div></QueryLink>
								<p onClick={() => this.props.handleShowReportBox(this.props.profileId, "Kreator")}><span>Report user</span></p>
							</div>
						</div>
						<br/>
						<div className="clear"></div>
						<div className="left_bottombox">
							<img src="images/portfolio.svg" className="clear" style={{float: 'left'}} />
							<div className="text_style">Portfolio:</div>
							<div className="clear"></div>
							<ul>
							{profileLinkList}
							</ul>
							<div className="clear"></div>
							<br/>
							<img src="/images/bus_icon_small.svg" className="clear" style={{float: 'left'}} />
							<div className="text_style">KreatorBus Projects:</div><br/>
							{/*Created:*/}
							<div className="clear"></div>
							<ul>
								{this.generateProjectList()}
							</ul>
							<div className="clear"></div>
							{/*Joined:*/}
							<div className="clear"></div>
						</div>
						<div className="right_bottombox">
							{profileFilledOutFieldsList}
						</div>
					</div>
				</div>
			</div>
		);
	}
};