// This module will is only for mobile so users can navigate to their notifications.

import React from 'react';
import PropTypes from 'prop-types';
import HTTP from '../../utils/Http';
import { getLocationSearchParam } from 'utils/Utils';
import history from 'app/history';
import { QueryLink } from 'utils/Utils';

import NavBarNotifications from '../nav/NavBar_Notifications.jsx';
import Footer from '../footer/Footer.jsx';
import Notifications from './Notifications.jsx';

export default class NotificationsPageForMobile extends React.Component {

	state = {
		notifications: [],
		page: 1
	}

	UNSAFE_componentWillMount = () => {
		this.getNotifications();
		this.markNotificationsAsRead();
	}

	getNotifications = () => {
		if(USER) {
			const { page, notifications } = this.state;
			HTTP.get('/notifications', {page}, (error, body) => {
				if (error) {
					console.log ("error fetching notifications.");
				} else {
					const allNotifications = [...body];
					this.setState({
						hasUnread: allNotifications.some(notification => new Date(notification.date) >= new Date(USER.lastReadNotification)),
						notifications: allNotifications,
						scrolling: false,
					});
				}
			});
		}
	}

	markNotificationsAsRead = () => {
		HTTP.post('/profile/readNotifications', {}, error => {
			if (error) return console.error('Could not post /readNotifications:', error);
			USER.lastReadNotification = new Date().toString();
		});
	}

	createNotificationsComponent = (notification) => {
		if(notification !== null){
			if (notification.type === "ProjectUpdate") {
				const projectLogo = "/uploads/" + notification.ref0.project.logo;
				return (
					<div key={notification._id} className="notification_component">
						<div>
							<img src={projectLogo}/>
							<div className="notification_textbox">
								<QueryLink to={{ pathname:"/projectmainpage", query: { projectId: notification.ref0.project._id, projectPageTarget: "projectUpdates" }}}>
								<span>{notification.ref0.project.name}</span>
								</QueryLink>
								<div className="clear"></div>
								<span style={{color: '#303030', cursor: 'default'}}>Just posted a new update.</span>
							</div>
						</div>
					</div>
				);
			}

			if (notification.type === "ProjectComment") {
				const kreatorPhoto = "/uploads/" + notification.ref0.kreator.photo;
				return (
					<div key={notification._id} className="notification_component">
						<div>
							<img src={kreatorPhoto} style={{width: '35px', height: '35px', borderRadius: '35px', padding: '0', marginTop: '.35em', marginRight: '.97em', marginLeft: '1em'}}/>
							<div className="notification_textbox">
								<QueryLink to={{ pathname:"/profilepage", query: { profileId: notification.ref0.kreator._id}}}>
								<span>{notification.ref0.kreator.firstName} {notification.ref0.kreator.lastName}</span>
								</QueryLink>
								<div style={{float:'left', marginTop:'0.1em'}}>commented on</div>
								<div className="clear"></div>
								<QueryLink to={{ pathname:"/editprojectpage", query: { projectId: notification.ref0.project._id, projectPageTarget: "projectComments" }}}>
									<span style={{marginTop: '0', marginLeft: '0.2em'}}>{notification.ref0.project.name}</span>
								</QueryLink>
							</div>
						</div>
					</div>
				);
			}

			if (notification.type === "ProjectInvite") {
				const kreatorPhoto = "/uploads/" + notification.ref0.kreator.photo;
				return (
					<div key={notification._id} className="notification_component">
						<div>
							<img src={kreatorPhoto} style={{width: '35px', height: '35px', borderRadius: '35px', padding: '0', marginTop: '.35em', marginRight: '.97em', marginLeft: '.8em'}}/>
							<div className="notification_textbox">
								<QueryLink to={{ pathname:"/profilepage", query: { profileId: notification.ref0.kreator._id}}}>
								<span>{notification.ref0.kreator.firstName} {notification.ref0.kreator.lastName}</span>
								</QueryLink>
								<div className="clear"></div>
								<div style={{float:'left'}}>invited you to </div>
								<QueryLink to={{ pathname:"/projectmainpage", query: { projectId: notification.ref0._id, projectPageTarget: "projectDetails" }}}>
									<span style={{marginTop: '0', marginLeft: '0.2em'}}>{notification.ref0.name}</span>
								</QueryLink>
							</div>
						</div>
					</div>
				);
			}

			if (notification.type === "TeammateJoin") {
				const kreatorPhoto = "/uploads/" + notification.ref0.photo;
				return (
					<div key={notification._id} className="notification_component">
						<div>
							<img src={kreatorPhoto} style={{width: '35px', height: '35px', borderRadius: '35px', padding: '0', marginTop: '.35em', marginRight: '.97em', marginLeft: '.8em'}}/>
							<div className="notification_textbox">
								<QueryLink to={{ pathname:"/profilepage", query: { profileId: notification.ref0._id}}}>
								<span>{notification.ref0.firstName} {notification.ref0.lastName}</span>
								</QueryLink>
								<div className="clear"></div>
								<div style={{float:'left'}}>just joined</div>
								<QueryLink to={{ pathname:"/editprojectpage", query: { projectId: notification.ref1._id, projectPageTarget: "projectTeamMembers" }}}>
									<span style={{marginTop: '0', marginLeft: '0.2em'}}>{notification.ref1.name}</span>
								</QueryLink>
							</div>
						</div>
					</div>
				);
			}

			if (notification.type === "ProjectPublished") {
				const projectLogo = "/uploads/" + notification.ref0.logo;
				return (
					<div key={notification._id} className="notification_component">
						<div>
							<img src={projectLogo}/>
							<div className="notification_textbox">
								<QueryLink to={{ pathname:"/projectmainpage", query: { projectId: notification.ref0._id}}}>
								<span>{notification.ref0.name}</span>
								</QueryLink>
								<div className="clear"></div>
								<span style={{color: '#303030', cursor: 'default'}}>Has just been published!</span>
							</div>
						</div>
					</div>
				);
			}

			if (notification.type === "ProfilePublished") {
				return (
					<div key={notification._id} className="notification_component">
						<div>
							<img src={`/uploads/${USER.photo}`} style={{width: '35px', height: '35px', borderRadius: '35px', padding: '0', marginTop: '.35em', marginRight: '.97em', marginLeft: '.8em'}}/>
							<span style={{color: '#303030', cursor: 'default'}}> Congratulations {USER.firstName}</span>
							<div className="notification_textbox">
								<span style={{color: '#303030', cursor: 'default'}}>Your profile has been published!</span>
							</div>
						</div>
					</div>
				);
			}
		}
	}
	render = () => {
		if(!USER){
			return null;
		}

		return (
			<div className="viewport_width">
				<div style={{background: "white", marginBottom: '-1.5em'}}>
					<NavBarNotifications
						showLoginButton={false}
						showSignupButton={false}
					/>

				<div className="clear"></div>
				<h4 style={{textAlign:'center', fontSize:'1.5em', marginTop: '1em', fontWeight: '500'}}>Notifications</h4>
				<div className="notification_page_notification_box">	
					{ this.state.notifications.map(this.createNotificationsComponent) }
				</div>	
				</div>
				<div className="bottom_padding" ></div>
				<Footer />
			</div>
		);
	}
};
