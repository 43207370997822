import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { login } from '_redux/actions';
import HTTP from '../../utils/Http';

class ProjectComment extends React.Component {
	static propTypes = {
		comment: PropTypes.object,
		commentId: PropTypes.string,
		commentText: PropTypes.string.isRequired,
		handleReportComment: PropTypes.func,
		handleDeleteComment: PropTypes.func,
		kreator: PropTypes.shape({
			_id: PropTypes.string.isRequired,
			firstName: PropTypes.string.isRequired,
			lastName: PropTypes.string.isRequired,
			photo: PropTypes.string.isRequired,
		}).isRequired,
		dateString: PropTypes.string.isRequired,
		timeString: PropTypes.string.isRequired,
	}
	state = {
		displayEditCommentTextArea: false,
		commentText: '',
		commentTextArea: 'visible_block',
	}

	UNSAFE_componentWillMount = () => {
		this.setState({commentText: this.props.commentText});
	}

	showEditCommentTextArea = (shouldDisplay) => {
		this.setState({displayEditCommentTextArea: shouldDisplay});
	}

	handleCommentChange = e => {
		this.setState({commentText: e.target.value});
	}

	handleEditComment = (commentId, commentText) => {
		this.props.handleEditComment(commentId, commentText);
		this.setState({displayEditCommentTextArea: false});
	}
	render = () => {

		var editComment = "";
		var deleteComment = "";
		var reportComment = "";

		if(this.props.loggedIn){
			editComment = USER._id === this.props.kreator._id ? 'visible' : 'hidden';
			deleteComment = USER._id === this.props.kreator._id ? 'visible' : 'hidden';
			reportComment = USER._id !== this.props.kreator._id ? 'visible' : 'hidden';
		}
		
		if (!USER){
			editComment = 'hidden';
			deleteComment = 'hidden';
			reportComment = 'hidden';
		}

		const kreator = this.props.kreator;

		return(
			<div className="project_commentbox">
				<img src={'/uploads/' + kreator.photo} /><div className="text_style">{kreator.firstName} {kreator.lastName}</div>
				<div className="clear"></div>
				<div className={"post_commentbox " + (this.state.displayEditCommentTextArea ? 'visible' : 'hidden')}>
					<textarea cols="50" rows="5" value={this.state.commentText} onChange={this.handleCommentChange}></textarea>
					<div className="edit_commentbox">
						<button  onClick={() => this.showEditCommentTextArea(false)}>cancel</button>
						<button  onClick={() => this.handleEditComment(this.props.commentId, this.state.commentText)}>save</button>
					</div>
				</div>
				<div className={this.state.displayEditCommentTextArea ? 'hidden' : 'visible'}>
					<p>{this.props.commentText}<br/></p>
					<div className="clear"></div>
					<span>{this.props.dateString} {this.props.timeString}</span>

					<button className={'delete_comment_button ' + reportComment} onClick={() => this.props.handleShowReportBox(this.props.commentId, "ProjectComment")}>report</button>
					<button className={'delete_comment_button ' + deleteComment} onClick={() => this.props.handleDeleteComment(this.props.commentId)}>delete</button>
					<button className={'delete_comment_button ' + editComment} onClick={() => this.showEditCommentTextArea(true)}> edit </button>
				</div>
			</div>
		);
	}
};

export default connect(({ loggedIn }) => ({ loggedIn }))(ProjectComment);