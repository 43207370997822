import React from 'react';
import SlidesProjectStart from './SlidesProjectStart.jsx';
import SlidesHomePage from './SlidesHomePage.jsx';
import PropTypes from 'prop-types';

export default class Slides extends React.Component {
	render = () => {
		var slidesNodes = [];

		if (this.props.collectionNumber === "0") {
			 slidesNodes = this.props.data.map((node, index) => (
				<SlidesHomePage
					key={node.id}
					id={node.id}
					active={this.props.currentSlide === index}
					imagePath={node.imagePath}
				/>
			));
		} else if (this.props.collectionNumber === "1") {
			slidesNodes = this.props.data.map((node, index) => (
				<SlidesProjectStart
					key={node.id}
					id={node.id}
					active={this.props.currentSlide === index}
					imagePath={node.imagePath}
				/>
			));

		}
		return (
			<div className="slides">
				{slidesNodes}
			</div>
		);
	}
};

Slides.propTypes = {
	data: PropTypes.array,
	currentSlide: PropTypes.number
};
