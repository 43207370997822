// This is the page users can use to change their account seetings such a name and email address.

import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import { login } from '_redux/actions';
import history from 'app/history';
import HTTP from '../../utils/Http';
import { QueryLink } from 'utils/Utils';

import NavBarNotifications from '../nav/NavBar_Notifications.jsx';
import Footer from '../footer/Footer.jsx';


class AccountSettings extends React.Component {
	state = {
		errFirstName: 'hidden',
		errLastName: 'hidden',
		errEmail: 'hidden',
		errEmailText: '',
		errPassword: 'hidden',
		errPasswordConfirm: 'hidden',
		errCountryCode:'hidden',
		errZipCode: 'hidden',
		errSubmit: 'hidden',
		validZip: false,
		firstName: '',
		lastName: '',
		email: '',
		countryCode: '',
		location:'',
		zipCode: '',

		firstNameText: 'visible_block',
		firstNameInput: 'hidden',
		firstNameEditButton: 'visible_block',
		firstNameSaveButton: 'hidden',

		lastNameText: 'visible_block',
		lastNameInput: 'hidden',
		lastNameEditButton: 'visible_block',
		lastNameSaveButton: 'hidden',

		changeEmailVisibility: 'hidden',
		changeEmailConfirmationVisibility: 'hidden',

		zipcodeTitle: 'hidden',
		countryInput: 'hidden',
		zipCodeText: 'visible_block',
		zipCodeInput: 'hidden',
		locationEditButton: 'visible_block',
		locationSaveButton: 'hidden',

		error: '',
		opacity: '1',
		pointerEvents: 'all'
	};

	UNSAFE_componentWillMount = () => {

		if(!this.props.loggedIn){
			history.push('/loginpage');
			return;
		}

		this.setState({
			firstName: USER.firstName,
			lastName: USER.lastName,
			email: USER.email,
			countryCode:USER.country,
			location: USER.location,
			zipCode: USER.zipCode
		});

		if(USER.country === "US"){
			this.setState({zipcodeTitle: "visible_block"});
		}
	}

	generateCountryOptions = option => {
		return <option key={option.code} value={option.code}>{option.name}</option>
	}

	handleFirstNameChange = e => {
		this.setState({ firstName: e.target.value });
	}

	handleLastNameChange = e => {
		this.setState({ lastName: e.target.value });
	}

	handleEmailChange = e => {
		this.setState({ email: e.target.value });
	}

	handleCountryCodeChange = e => {
		this.setState({countryCode: e.target.value});
	}

	handleZipCodeChange = e => {
		this.setState({ zipCode: e.target.value });
	}

	handleShowFirstNameInput = () => {
		this.setState({
			firstNameText: "hidden",
			firstNameInput: "visible_block",
			firstNameEditButton: "hidden",
			firstNameSaveButton: "visible_block"
		});
	}

	handleSaveFirstName = () => {
		if (!this.checkFirstName()) return this.setState({ errFirstName: 'visible'});
		this.setState({ errFirstName: 'hidden'});

		const { firstName } = this.state;
		HTTP.post('/profile/firstName', { firstName }, error => {
			if (error) return this.setState({ errSubmit: 'visible_block' });

			USER.firstName = firstName;
			this.setState({
				firstName,
				firstNameText: 'visible_block',
				firstNameInput: 'hidden',
				firstNameEditButton: 'visible_block',
				firstNameSaveButton: 'hidden',
			});
		});
	}

	handleShowLastNameInput = () => {
		this.setState({
			lastNameText: 'hidden',
			lastNameInput: 'visible_block',
			lastNameEditButton: 'hidden',
			lastNameSaveButton: 'visible_block',
		});
	}

	handleSaveLastName = () => {
		if (!this.checkLastName()) return this.setState({ errLastName: 'visible' });
		this.setState({ errLastName: 'hidden' });

		const { lastName } = this.state;
		HTTP.post('/profile/lastName', { lastName }, error => {
			if (error) return this.setState({ errSubmit: 'visible_block' });

			USER.lastName = lastName;
			this.setState({
				lastName,
				lastNameText: 'visible_block',
				lastNameInput: 'hidden',
				lastNameEditButton: 'visible_block',
				lastNameSaveButton: 'hidden',
			});
		});
	}

	handleShowEmailChangeBox = () => {
		this.setState({
			changeEmailVisibility: 'visible_block',
			changeEmailConfirmationVisibility: 'hidden',
			opacity: '0.5',
			pointerEvents: 'none',
		});
	}

	handleShowEmailConfirmationBox = () => {
		this.setState({
			changeEmailVisibility: "hidden",
			changeEmailConfirmationVisibility: "visible_block",
		});
	}

	handleHideEmailConfirmationBox = () => {
		this.setState({
			changeEmailVisibility: "hidden",
			changeEmailConfirmationVisibility: "hidden",
			email: USER.email,
			opacity: "1",
			pointerEvents: "all"
		});
	}

	handleCancelEmailChange = () => {
		this.setState({
			changeEmailVisibility: "hidden",
			changeEmailConfirmationVisibility: "hidden",
			opacity: "1",
			pointerEvents: "all",
			email: USER.email,
			errEmail: 'hidden'
		});
	}

	handleSendEmailChange = () => {

		if(!this.checkEmail()){
			this.setState({
				errEmail: 'visible',
				errEmailText: CONSTANTS.errors.emailErrorShort
			});

			return;
		} else {
			this.setState({ errEmail: 'hidden'});
		}

		if (this.state.email.trim() === USER.email){
			this.setState({
				errEmail:'visible',
				errEmailText: CONSTANTS.errors.emailErrorSame
			});
			return;
		} else {
			this.setState({ errEmail: 'hidden'});
		}

		HTTP.post('/profile/email', {email: this.state.email}, (error, body) => {
			if (error) {
				const errorText = body.split(":");
				return this.setState({
					errEmail: 'visible_block',
					errEmailText: errorText[1]
				});
			}

			this.handleShowEmailConfirmationBox();
		});
	}

	handleShowCountryCodeInput = () => {
		this.setState({
			countryInput:"visible_block",
			zipCodeText: "hidden",
			locationEditButton: "hidden",
			locationSaveButton: "visible_block"
		});

		
		if (this.state.countryCode === "US"){
			this.setState({
				zipCodeInput: "visible_block",
				zipcodeTitle: "visible_block"
			});
		} else {
			this.setState({
				zipCodeInput: "hidden",
				zipcodeTitle: "hidden"
			});
		}
	}

	handleCountryChange = e => {

		this.setState({ countryCode: e.target.value, errCountryCode: 'hidden' });
		if(e.target.value === "US"){
			this.setState({
				zipCodeInput: "visible_block",
				zipcodeTitle: "visible_block"
			});
		} else {
			this.setState({
				zipCodeInput: "hidden",
				zipcodeTitle: "hidden",
				errZipCode: "hidden",
				zipCode:""
			});
		}
	}

	handleSaveLocation = () => {

		if(this.state.countryCode === ''){
			this.setState({errCountryCode: 'visible'});
			return;	
		}

		if(this.state.countryCode === "US"){
			if(!this.checkZipCode()){
				this.setState({ errZipCode: 'visible'});
				return;
			}
		}
		
		HTTP.post('/profile/zipCode', {country: this.state.countryCode, zipCode: this.state.zipCode}, (error, body) => {
			if (error) {
				return this.setState({ errSubmit: 'visible_block' });
			}

			USER.zipCode = body.zipCode;
			USER.location = body.location;
			USER.country = body.country;
			this.setState({
				zipCode: body.zipCode,
				zipCodeText: "visible_block",
				zipCodeInput: "hidden",
				errZipCode: "hidden",
				locationEditButton: "visible_block",
				locationSaveButton: "hidden",
				countryInput: "hidden"
			});
		});
	}

	handlePasswordClick = () => {
		history.push('/passwordchange');
	}

	checkFirstName = () => {
		var reg = /([A-Za-z0-9]){1,}/;
		const text = this.state.firstName;

		return reg.test(text);
	}

	checkLastName = () => {
		var reg = /([A-Za-z0-9]){1,}/;
		const text = this.state.lastName;

		return reg.test(text);
	}

	checkEmail = () => {
		var reg = /^[A-Za-z0-9_\-\.]{1,}\@[A-Za-z0-9_\-\.]{1,}\.[A-Za-z]{2,}$/;
		/*/.+\@.+\..+/.test(emailString);*/

		return reg.test(this.state.email);
	}

	checkPassword = e => {
		var reg = /^.*(?=.{8,})(?=.*[a-zA-Z])(?=.*\d).*$/;

		this.setState({ errPassword: reg.test(this.state.password) ? 'hidden' : 'visible' });
	}

	checkPasswordConfirm = e => {
		this.setState({ errPasswordConfirm: this.state.password === this.state.passwordConfirmation ? 'hidden' : 'visible' });
	}

	checkZipCode = () => {
		var reg = /^([0-9]){5,}$/;
		var zipCode = this.state.zipCode;

		return reg.test(zipCode);
	}

	render = () => {

		var firstName = "";
		var lastName = "";
		var email = "";
		var location = "";
		var zipCode = "";

		if(this.props.loggedIn){
			var firstName = USER.firstName;
			var lastName = USER.lastName;
			var email = USER.email;
			var location = USER.location;
			var zipCode = USER.zipCode;
		} else {
			history.push('/loginpage');
		}

		return(
			<div className="viewport_width">
				<NavBarNotifications
					showLoginButton={true}
					showSignupButton={true}
				/>
				<div className="clear"></div>
				<div className="signup_hero" style={{textAlign: "center", fontSize: "1.6em", marginTop: "1em", marginBottom: "0.5em"}}>
					Account Settings
				</div>

				<div className={"account_settings_warning_box " + this.state.changeEmailVisibility}>
					<div className="show_for_desktop">
						<p style={{margin: "1em 0 0.5em 7.5em", float: "left"}}>
							Please enter your new email address.
						</p>
					</div>

					<div className="show_for_mobile">
						<p style={{margin: "1em 0 0.5em 0em", float: "left"}}>
							Please enter your new email address.
						</p>
					</div>

					<br/>

					<div className="show_for_desktop">
						<span className={this.state.errEmail + " error account_settings_error"} style={{float: "left", margin: '0.5em 0em 0.5em 7.5em'}}>{this.state.errEmailText}</span>
					</div>

					<div className="show_for_mobile">
						<span className={this.state.errEmail + " error account_settings_error"} style={{float: "left", margin: '0.5em 0em 0.5em 0em'}}>{this.state.errEmailText}</span>
					</div>

					<input className="new_email_inputbox" type="text" placeholder="New Email Address" name="email" value={this.state.email} onChange={this.handleEmailChange}/>
					<div className="clear"></div>
					<div className="account_settings_warning_button_box">
						<button className="delete_teammate_warning_cancel" onClick={this.handleCancelEmailChange}>Cancel</button>
						<button className="delete_teammate_warning_cancel" style={{float: "right"}} onClick={this.handleSendEmailChange}>Continue</button>
					</div>
				</div>

				<div className={"account_settings_warning_box " + this.state.changeEmailConfirmationVisibility}>
					<div className="show_for_desktop">
						<p style={{width: "23em"}}>
							An email has been sent to <span style={{color: "#00A4A4"}}>{this.state.email}</span>.<br/>
							For this change to take place, please verify your new email address.
						</p>
						<div style={{width:"20em", margin: "2.5em auto 2em auto", textAlign: "center"}}>
							<button className="delete_teammate_warning_cancel" onClick={this.handleHideEmailConfirmationBox}>Got It</button>
						</div>
					</div>
					<div className="show_for_mobile">
						<p style={{width: "14em"}}>
							An email has been sent to <span style={{color: "#00A4A4"}}>{this.state.email}</span>.<br/>
							For this change to take place, please verify your new email address.
						</p>
						<div style={{width:"14em", margin: "2.5em auto 2em auto", textAlign: "center"}}>
							<button className="delete_teammate_warning_cancel" onClick={this.handleHideEmailConfirmationBox}>Got It</button>
						</div>
					</div>
				</div>

				<div className="clear"></div>

				<div style={{opacity: this.state.opacity, pointerEvents: this.state.pointerEvents}}>
					<p className={"failed_submit " + this.state.errSubmit} style={{width: "32em", padding: "1em"}}>{CONSTANTS.errors.failedSaveTryAgain}</p>
					<div className="account_settings_container">
						<div className="show_for_desktop">
							<div className="account_settings_titleBox">
								<div className={"account_settings_titles"}>First Name:</div>
								<div className="clear"></div>
								<div className={"account_settings_titles"}>Last Name:</div>
								<div className="clear"></div>
								<div className={"account_settings_titles"}>Email Address:</div>
								<div className="clear"></div>
								<div className="account_settings_titles">Password:</div>
								<div className="clear"></div>
								<div className="account_settings_titles">Location:</div>
								<div className="clear"></div>
								<div className={"account_settings_titles " + this.state.zipcodeTitle}>Zipcode:</div>
							</div>
						</div>
						<div className="account_settings_inputbox">
							<div className="show_for_mobile"><div className="account_settings_titles" style={{marginBottom:'0.2em'}}>First Name:</div><div className="clear"></div></div>
							<div className={"account_settings_titles " + this.state.firstNameText}>{firstName}</div>
							<input className={"account_settings_inputbox " + this.state.firstNameInput} type="text" placeholder={this.state.firstName} name="firstname" value={this.state.firstName} onChange={this.handleFirstNameChange}/>
							<button className={this.state.firstNameEditButton} onClick={this.handleShowFirstNameInput}>Edit</button>
							<button className={this.state.firstNameSaveButton} onClick={this.handleSaveFirstName}>Save</button>
							<span className={this.state.errFirstName + " error account_settings_error"}>{CONSTANTS.errors.firstNameError}</span>
							<div className="clear"></div>

							<div className="show_for_mobile"><div className="account_settings_titles" style={{marginBottom:'0.2em'}}>Last Name:</div><div className="clear"></div></div>
							<div className={"account_settings_titles " + this.state.lastNameText}>{lastName}</div>
							<input className={"account_settings_inputbox " + this.state.lastNameInput} type="text" placeholder={this.state.lastName} name="lastname" value={this.state.lastName} onChange={this.handleLastNameChange}/>
							<button className={this.state.lastNameEditButton} onClick={this.handleShowLastNameInput}>Edit</button>
							<button className={this.state.lastNameSaveButton} onClick={this.handleSaveLastName}>Save</button>
							<span className={this.state.errLastName + " error account_settings_error"}>{CONSTANTS.errors.lastNameErrorShort}</span>
							<div className="clear"></div>

							<div className="show_for_mobile"><div className="account_settings_titles" style={{marginBottom:'0.2em'}}>Email Address:</div><div className="clear"></div></div>
							<div className={"account_settings_titles " + this.state.emailText}>{email}</div>
							<button onClick={this.handleShowEmailChangeBox}>Edit</button>
							<div className="clear"></div>

							<div className="show_for_mobile"><div className="account_settings_titles" style={{marginBottom:'0.2em'}}>Password:</div><div className="clear"></div></div>
							<div className="account_settings_titles">&#8226;&#8226;&#8226;&#8226;&#8226;&#8226;&#8226;&#8226;</div>
							<button onClick={this.handlePasswordClick}>Edit</button>
							<div className="clear"></div>

							<div className="show_for_mobile"><div className="account_settings_titles" style={{marginBottom:'0.2em'}}>Location:</div><div className="clear"></div></div>
							<div className={"account_settings_titles " + this.state.zipCodeText}>{location}</div>

							<select className={"country_select " + this.state.countryInput} value={this.state.countryCode} onChange={this.handleCountryChange}>
								<option value="">Select Country</option>
								{CONSTANTS.countryData.map(this.generateCountryOptions)}
							</select>

							<button className={this.state.locationEditButton} onClick={this.handleShowCountryCodeInput}>Edit</button>
							<button className={this.state.locationSaveButton} onClick={this.handleSaveLocation}>Save</button>
							<div className="clear"></div>

							<span className={this.state.errCountryCode + " error"} style={{marginBottom: '1em', width: '15em', float: 'left'}}>{CONSTANTS.errors.countryCodeError}</span>
							<div className="clear"></div>
							<div className="show_for_mobile"><div className={"account_settings_titles " + this.state.zipcodeTitle} style={{marginBottom:'0.2em'}}>Zipcode:</div><div className="clear"></div></div>
							<div className="clear"></div>
							<div className={"account_settings_titles " + this.state.zipCodeText}>{zipCode}</div>
							<input className={"account_settings_inputbox " + this.state.zipCodeInput} style={{width: '3em', clear: 'both', marginTop: '0.1em'}}type="text" placeholder={this.state.zipCode} name="zipcode" maxLength="5" value={this.state.zipCode} onChange={this.handleZipCodeChange} />
							
							
							<span className={this.state.errZipCode + " error account_settings_error"}>{CONSTANTS.errors.zipCodeError}</span>



						</div>
						<div className="clear"></div>
						<br/><br/><br/>
					</div>

					<div className="clear"></div>
				</div>
				<div className="bottom_padding" style={{paddingBottom: '4.5em'}}></div>
				<Footer />
			</div>
		);
	}
};

export default connect(({ loggedIn }) => ({ loggedIn }))(AccountSettings);