// This module will hold the main project page for when users click on a project.

import React from 'react';
import PropTypes from 'prop-types';
import HTTP from '../../utils/Http';

import { getLocationSearchParam } from 'utils/Utils';
import history from 'app/history';

import NavBarNotifications from '../nav/NavBar_Notifications.jsx';
import Footer from '../footer/Footer.jsx';
import ProjectTopBox from './ProjectTopBox.jsx';
import ProjectDetailBox from './ProjectDetailBox.jsx';
import ProjectUpdatesBox from './ProjectUpdatesBox.jsx';
import ProjectTeammatesBox from './ProjectTeammatesBox.jsx';
import ProjectCommentsBox from './ProjectCommentsBox.jsx';

export default class ProjectMainPage extends React.Component {
	static contextTypes = {
		router: PropTypes.object
	}

	static propTypes = {
		edit: PropTypes.bool
	}

	state = {
		projectKreator: {},
		projectId: '',
		projectName: '',
		tagline: '',
		category: -1,
		leaderRoleSummary: '',
		expertiseList: [],
		roleList: [],

		fileData: null,
		file: '/images/add_logo.svg',

		videoLink: '',
		projectDescription: '',
		projectCourse: '',
		projectSchool:null,
		projectMission: '',
		projectLocation: '',
		startDateID: 0,
		startDate: '',
		projectZipcode: '',
		projectStatus: '',
		underReview: false,
		published: false,

		profitPlan: '',
		competition: '',
		targetMarket: '',
		teammateEquity: false,
		futureBusiness: false,

		navButton: 0,

		showFollowProjectButton: true,
		showUnfollowProjectButton: false,
		followProjectMessage: 'hidden',
		unfollowProjectMessage: 'hidden',
		alreadyFollowingProjectError: 'hidden',
		updateButtonVisibility: 'hidden',
		isProjectReviewPage: false,

		contactFollowReportButtonVisibility: 'visible_block',
		allowUserInput: 'true',
		reportBoxVisibility: 'hidden',
		objectToBeReportedType: '',
		objectToBeReportedId: '',
		objectToBeReportedText: '',
		reportText: '',
		reportTextVisibility: 'visible',
		reportSuccessVisibility: 'hidden'
	}

	UNSAFE_componentWillMount = () => {
		this.loadProject();
		this.handleShowFollowProjectButton();
	}

	UNSAFE_componentWillUpdate = () => {
		if(getLocationSearchParam('projectId') !== this.state.projectId){
			this.loadProject();
			this.handleShowFollowProjectButton();
		}
	}

	loadProject = () => {
		const projectId = getLocationSearchParam('projectId');
		const projectPageTarget = getLocationSearchParam('projectPageTarget');

		HTTP.get(`/project/${projectId}`, (error, body) => {
			if (error) {
				return;
			}
			this.setState({
				projectKreator: body.kreator,
				projectId: body._id,
				projectName: body.name,
				tagline: body.tagline,
				category: body.category,
				leaderRoleSummary: body.leaderRoleSummary,
				videoLink: body.video,
				projectDescription: body.description,
				projectCourse: body.course,
				projectSchool:body.school,
				projectMission: body.mission,
				startDate: body.startDate,
				projectStatus: body.status,
				projectZipcode: body.zipCode,
				projectLocation: body.location,
				teammateEquity: body.teammateEquity,
				futureBusiness: body.futureBusiness,
				profitPlan: body.profitPlan,
				competition: body.competition,
				targetMarket: body.targetMarket,
				published: body.published,
				allowUserInput: true,
				file: body.logo ? '/uploads/' + body.logo : '/images/add_logo.svg',
				roleList: body.lookingFor.map(this.generateRoleList),
				...(body.published && { saveButton: 'hidden' })
			});
			if (projectPageTarget === 'projectTeamMembers') {
				this.setState({navButton: 2});
			} else if (projectPageTarget === 'projectUpdates'){
				this.setState({navButton: 1});
			} else {
				this.setState({navButton: 0});
			}

			if (body.published === true) {
				this.setState({updateButtonVisibility: 'visible'});
			}

			if(USER){
				if (body.kreator._id === USER._id){
					this.setState({contactFollowReportButtonVisibility: 'hidden'});
				}
			}

			if(!USER){
				this.setState({contactFollowReportButtonVisibility: 'hidden'});
			}

		});
	}
	// This will turn the roleDescription information saved in the project into a roleList needed
	// to generate the added roles correctly.
	generateRoleList = (role, index) => {
		var modifiedRole = {
			id: `${role.expertise}${index}`,
			baseSkill: role.baseSkill,
			expertise: role.expertise,
			text: role.description
		};

		switch(role.baseSkill) {
			case 0:
				modifiedRole.baseSkillTitle = 'Business';
				modifiedRole.image = 'images/lookingfor_business.svg';
				break;

			case 1:
				modifiedRole.image = 'images/lookingfor_artist.svg';
				modifiedRole.baseSkillTitle = 'Artist';
				break;

			case 2:
				modifiedRole.image = 'images/lookingfor_scribe.svg';
				modifiedRole.baseSkillTitle = 'Scribe';
				break;

			case 3:
				modifiedRole.image = 'images/lookingfor_engineer.svg';
				modifiedRole.baseSkillTitle = 'Engineer';
				break;

			default:
				modifiedRole.image = '';
				modifiedRole.baseSkill = '';
				modifiedRole.baseSkillTitle = '';
		}

		return modifiedRole;
	}

	handleNavButtonClick = e => {
		// This keeps track of the states of the details, updates, and teammembers buttons.
		this.setState({ navButton: ['details', 'updates', 'comments', 'teammembers'].indexOf(e.target.name) });
	}

	handleShowFollowProjectButton = () => {
		var alreadyFollowing = false;

		if (!USER){
			this.setState({showFollowProjectButton: false});
			this.setState({showUnfollowProjectButton: false});
			return;
		}

		if(USER){
			var projectId = getLocationSearchParam('projectId');
			var projectsFollowing = USER.projectsFollowing;
			
			projectsFollowing.forEach( function(project) {
				if (projectId === project._id) {
					alreadyFollowing = true;
				}
			});
		}
		
		if(alreadyFollowing){
			this.setState({
				showFollowProjectButton: false,
				showUnfollowProjectButton: true
			});
		} else {
			this.setState({
				showFollowProjectButton: true,
				showUnfollowProjectButton: false
			});
		}

	}

	handleFollowProject = () => {
		if(!USER){
			history.push('/loginpage');
			return;
		}

		var projectId = getLocationSearchParam('projectId');
		var projectsFollowing = USER.projectsFollowing;
		var error = false;

		projectsFollowing.forEach( function(project) {
			if (projectId === project._id) {
				error = true;
			}
		});

		var projectIdArray = [projectId];

		if (error === true) {
			this.setState({
				followProjectMessage: "hidden",
				unfollowProjectMessage: "hidden",
				alreadyFollowingProjectError: "visible_block"
			});
		} else if (error === false) {
			HTTP.post(`/project/${projectId}/following`, null, function(error, body) {
				if (error) {
					return;
				}
				USER.projectsFollowing = body;
				this.setState({
					followProjectMessage: "visible_block",
					unfollowProjectMessage: "hidden",
					alreadyFollowingProjectError: "hidden"
				}, () => {
    			this.handleShowFollowProjectButton();
				});

				
			}.bind(this));
		}

		var element = document.getElementById("follow_project_result");
		element.scrollIntoView();
	}

	handleUnfollowProject = () => {
		if(!USER){
			history.push('/loginpage');
			return;
		}
		var projectId = getLocationSearchParam('projectId');
		var projectsFollowing = USER.projectsFollowing;
		var error = false;

		if (error === true) {
			this.setState({
				followProjectMessage: "hidden",
				unfollowProjectMessage: "hidden",
				alreadyFollowingProjectError: "visible_block"
			});
		} else if (error === false) {
			HTTP.delete(`/project/${projectId}/following`, null, function(error, body) {
				if (error) {
					return;
				}

				USER.projectsFollowing = body;

				this.setState({
					unfollowProjectMessage: "visible_block",
					followProjectMessage: "hidden",
					alreadyFollowingProjectError: "hidden"
				}, () => {
    			this.handleShowFollowProjectButton();
				});

				
			}.bind(this));
		}
		var element = document.getElementById("follow_project_result");
		element.scrollIntoView();
	}

	handleReportTextChange = (e) => {
		this.setState({reportText: e.target.value});
	}

	handleShowReportBox = (objectId, objectType) => {
		console.log(objectType + " " + objectId);
		this.setState({
			objectToBeReportedType: objectType,
			objectToBeReportedId: objectId,
			reportBoxVisibility: "visible",
			allowUserInput: false
		});
	}

	handleHideReportBox = () => {
		this.setState({
			objectToBeReportedType: '',
			objectToBeReportedId: '',
			reportBoxVisibility: "hidden",
			reportTextVisibility: "visible",
			reportSuccessVisibility: "hidden",
			reportText: "",
			allowUserInput: true
		});
	}

	handleSuccessfulReport = () => {
		this.setState({
			reportTextVisibility: 'hidden',
			reportSuccessVisibility: 'visible'
		});
	}

	handleReporting = () => {
		var objectType = this.state.objectToBeReportedType;
		var objectId = this.state.objectToBeReportedId;
		var objectText = this.state.reportText;

		HTTP.post(`/report`, { type: objectType, id: objectId, reason: objectText}, (error, body) => {
			if (error) {
				alert(error);
				return;
			} else {
				console.log("report successful");
				this.handleSuccessfulReport();
			}
		});
	}

	render = () => {
		//This code changes which button is highlighted between details, updated, and teammates and shows the appropriate module underneath.
		var bottombox_element = null;
		var userInputState = true;
		if (!this.state.allowUserInput){
			userInputState = "prevent_user_input";
		} else {
			userInputState = "allow_user_input";
		}
		switch (this.state.navButton) {
			case 0:
				bottombox_element = (
					<ProjectDetailBox
						projectDescription={this.state.projectDescription}
						projectMission={this.state.projectMission}
						profitPlan={this.state.profitPlan}
						competition={this.state.competition}
						targetMarket={this.state.targetMarket}
						futureBusiness={this.state.futureBusiness}
					/>
				);
				break;

			case 1:
				bottombox_element = (
					<ProjectUpdatesBox
						projectId={this.state.projectId}
						projectKreator={this.state.projectKreator}
						handleShowReportBox={this.handleShowReportBox}
						canBeEditedByUSER={false}
					/>
				);
				break;

			case 2:
				bottombox_element = (
					<ProjectCommentsBox
						projectId={this.state.projectId}
						projectKreator={this.state.projectKreator}
						published = {this.state.published}
						handleShowReportBox={this.handleShowReportBox}
						canDeleteComments = {false}
					/>
				);
				break;

			case 3:
				bottombox_element = (
					<ProjectTeammatesBox
						projectId={this.state.projectId}
					/>
				);
		}

		// var showBottomBoxElements = "";
		// if(!USER){
		// 	showBottomBoxElements = "hidden";
		// }

		return (
			<div className="viewport_width">
				<NavBarNotifications
					showLoginButton={true}
					showSignupButton={true}
				/>
				<br/><br/>
				<div className="clear"></div>
				<div className={"report_box_container " + this.state.reportBoxVisibility}>
					<div className={this.state.reportTextVisibility}>
						<p>
						If you believe this violates our rules of conduct, please make a report.
						</p>
						<textarea cols="50" rows="5" value={this.state.reportText} onChange={this.handleReportTextChange} placeholder="Let us know what is the problem."></textarea>
						<div className="report_box_button_holder">
							<button className="report_box_button_cancel" onClick={this.handleHideReportBox}>Cancel</button>
							<button className="report_box_button_report" style={{float: "right"}} onClick={this.handleReporting}>Report</button>
						</div>
					</div>
					<div className={this.state.reportSuccessVisibility} style={{textAlign: "center"}}>
						<p>
						Your report was succesfully submitted.
						</p>
						<button className="report_box_button_cancel" style={{display: "block", margin: "1.5em auto"}}onClick={this.handleHideReportBox}>Close</button>
					</div>
				</div>
				<div className={userInputState}>
					<div id="follow_project_result"></div>
					<p className={"follow_project_message " + this.state.followProjectMessage}><br/>You are now following this project!</p>
					<p className={"follow_project_message " + this.state.unfollowProjectMessage}><br/>You are no longer following this project!</p>
					<p className={"follow_project_error " + this.state.alreadyFollowingProjectError}><br/>You are already following this project.</p>
					<div className="clear"></div>
					<ProjectTopBox
						key={this.state.projectId}
						projectKreator={this.state.projectKreator}
						projectId={this.state.projectId}
						projectName={this.state.projectName}
						tagline={this.state.tagline}
						category={this.state.category}
						leaderRoleSummary= {this.state.leaderRoleSummary}
						roleList={this.state.roleList}
						logo={this.state.file}
						videoLink={this.state.videoLink}
						teammateEquity={this.state.teammateEquity}
						startDateID={this.state.startDateID}
						startDate={this.state.startDate}
						projectStatus={this.state.projectStatus}
						showFollowProjectButton={this.state.showFollowProjectButton}
						showUnfollowProjectButton={this.state.showUnfollowProjectButton}
						handleFollowProject={this.handleFollowProject}
						handleUnfollowProject={this.handleUnfollowProject}
						handleShowReportBox={this.handleShowReportBox}
						projectCourse={this.state.projectCourse}
						projectSchool={this.state.projectSchool}
						projectZipcode={this.state.projectZipcode}
						projectLocation={this.state.projectLocation}
						editProjectButtonDisplay={!!this.props.edit}
						isProjectReviewPage={this.state.isProjectReviewPage}
						underReview={this.state.underReview}
						published={this.state.published}
						contactFollowReportButtonVisibility={this.state.contactFollowReportButtonVisibility}
					/>
					<div className={"projectnav_buttonbox "}>
						<button name="details" className={this.state.navButton === 0 ? 'active' : 'inactive'} onClick={this.handleNavButtonClick}>Details</button>
						<button name="updates" className={this.state.navButton === 1 ? 'active' : 'inactive'} onClick={this.handleNavButtonClick}>Updates</button>
						<button name="comments" className={this.state.navButton === 2 ? 'active' : 'inactive'} onClick={this.handleNavButtonClick}>Comments</button>
						<button name="teammembers" className={this.state.navButton === 3 ? 'active' : 'inactive'} onClick={this.handleNavButtonClick}>Team</button>
					</div>
					<div className={"project_bottombox "}>
						{bottombox_element}
					</div>
				</div>
				<div className="bottom_padding"></div>
				<Footer />
			</div>
		);
	}
};