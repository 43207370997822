// @todo Finish

// This is the master controller for all the create project pages.

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { login } from '_redux/actions';
import HTTP from '../../utils/Http';
import { getLocationSearchParam } from 'utils/Utils';
import history from 'app/history';

import NavBar_Notifications from '../nav/NavBar_Notifications.jsx';
import Footer from '../footer/Footer.jsx';
import CreateProject01 from './CreateProject01.jsx';
import CreateProject02 from './CreateProject02.jsx';
import CreateProject03 from './CreateProject03.jsx';
import CreateProjectReview from './CreateProjectReview.jsx';
import ExpertiseInput from './ExpertiseInput.jsx';

class CreateProjectController extends React.Component {
	static propTypes = {
		edit: PropTypes.bool
	};

	state = {
		currentPage: 1,
		displayCreate01: "visible",
		displayCreate02: "hidden",
		displayCreate03: "hidden",
		displayProjectReview: "hidden",
		saveButton: "visible",
		startDateVisibility: "visible_block",

		projectKreator: {},
		projectId: "",
		projectName: "",
		tagline: "",
		leaderRoleSummary: "",
		category: -1,
		expertiseList: [],
		roleList: [],

		otherBusiness: '',
		otherArtist: '',
		otherScribe: '',
		otherEngineer: '',
		fileData: null,
		file: '/images/add_logo.svg',
		displayImageBox: "visible_block",
		displayPhoto: 'visible',
		displayCrop: false,
		fileTooBigError: 'hidden',
		projectNameError: "hidden",
		taglineError: "hidden",
		leadershipRoleError: "hidden",
		categoryError: "hidden",
		projectLogoError: "hidden",
		roleListError: "hidden",
		emptyRoleError: "hidden",

		videoLink: "",
		projectDescription: "",
		projectCourse:"",
		projectCourseSchool:"",
		projectSchool: null,
		projectMission: "",
		projectLocation: "",
		projectCountryCode: USER.country,
		projectZipcode: USER.zipCode,
		zipCodeInput: "visible_block",
		zipCodeTitle: "visible_block",
		startDate: new Date(),
		projectStatus: "",
		published: false,
		underReview: false,

		projectMissionError: "hidden",
		projectCourseError: "hidden",
		projectSchoolError:"hidden",
		projectDescriptionError: "hidden",
		projectCountryCodeError: "hidden",
		projectZipcodeError: "hidden",
		projectReviewError: "hidden",
		projectSubmitError: "hidden",


		profitPlan: "",
		competition: "",
		targetMarket: "",
		teammateEquity: false,
		futureBusiness: false,

		successSave: "hidden",
		failedSave: "hidden",
		errorText: ""
	};

	UNSAFE_componentWillMount = () => {
		if(!this.props.loggedIn) {
			history.push("/loginpage");
			return;
		}

		if (!USER.emailVerified) {
			history.push("/emailverify");
			return;
		}

		if(USER.country !== "US"){
			this.setState({zipCodeInput: "hidden", zipCodeTitle: "hidden"});
		}

		var projectId = getLocationSearchParam('projectId');

		//Set the start day to one day after current day.

		var earliestPossibleStartDate = this.state.startDate;
		earliestPossibleStartDate.setDate(earliestPossibleStartDate.getDate() + 1);

		this.setState({startDate: earliestPossibleStartDate});

		if (projectId !== "") {
			HTTP.get('/project/' + projectId, function(error, body) {
				if (error) {
					return;
				}
				if (USER._id !== body.kreator._id) {
					return history.push('/useraccount');
				}
				var schoolId = '';
				if (body.school !== null){
					schoolId = body.school._id;
				}

				var startDate = new Date(body.startDate);
				this.setState({
					projectKreator: body.kreator, // USER
					projectId: body._id,
					projectName: body.name,
					tagline: body.tagline,
					category: body.category,
					leaderRoleSummary: body.leaderRoleSummary,
					videoLink: body.video,
					projectDescription: body.description,
					projectCourse: body.course,
					projectCourseSchool: schoolId,
					projectSchool: body.school,
					projectCountryCode:body.country,
					projectZipcode: body.zipCode,
					projectLocation: body.location,
					startDate: startDate,
					projectMission: body.mission,
					projectStatus: body.status,
					teammateEquity: body.teammateEquity,
					futureBusiness: body.futureBusiness,
					profitPlan: body.profitPlan,
					competition: body.competition,
					targetMarket: body.targetMarket,
					published: body.published,
					underReview: body.underReview
				});

				if (body.logo) {
					this.setState({file: '/uploads/' + body.logo});
				} else {
					this.setState({file: '/images/add_logo.svg'});
				}

				var projectRoleList = body.lookingFor;

				var modifiedRoleList = projectRoleList.map(this.createRoleList);
				this.setState({roleList: modifiedRoleList});

				if (body.published || body.underReview) {
					this.setState({
						saveButton: "hidden",
						startDateVisibility: "hidden"
					});
				}

				if(body.country !== "US"){
					this.setState({zipCodeInput: "hidden", zipCodeTitle: "hidden"});
				} else {
					this.setState({zipCodeInput: "visible_block", zipCodeTitle: "visible_block"});
				}

			}.bind(this));
		}

	}

	checkProfileCompleteness = () => {

		if (!this.props.loggedIn){
			return;
		}

		var profileComplete = true;
		if (USER.baseSkills.length === 0) {
			profileComplete = false;
		} else if (USER.schools.length === 0) {
			profileComplete = false;
		} else if (USER.bio === "") {
			profileComplete = false;
		} else if (USER.interests.length === 0) {
			profileComplete = false;
		} else if (USER.additionalInfo.hoursPerWeek === -1) {
			profileComplete = false;
		} else if (USER.additionalInfo.teamOrProject === -1) {
			profileComplete = false;
		} else if (USER.additionalInfo.travelRadius === -1) {
			profileComplete = false;
		// } else if(USER.additionalInfo.whatToGain === -1) {
		// 	profileComplete = false;
		} else if (USER.photo === "") {
			profileComplete = false;
		}

		return profileComplete;
	}

	showProfileIncompleteWarning = () => {
		this.setState({
				displayCreate01: "hidden",
				displayCreate02: "hidden",
				displayCreate03: "hidden",
				displayProjectReview: "hidden",
				profileIncompleteWarning: "visible_block",
				currentPage: 1
			});
	}

	//creates a clickable checkbox with label for the expertise columns.
	generateExpertiseInput = expertise => {
		var checked = this.state.expertiseList.indexOf(expertise.id) !== -1;
		return <ExpertiseInput
					key={expertise.id}
					id={expertise.id}
					text={expertise.id}
					checked={checked}
					handleSelection={this.handleSelection}
					/>;
	}

	// This will turn the roleDescription information saved in the project into a roleList needed
	// to generate the added roles correctly.
	createRoleList = role => {
		var modifiedRole = {
				id: role._id,
				image: "",
				baseSkill: role.baseSkill,
				expertise: role.expertise,
				baseSkillTitle: "",
				text: role.description
			};
		switch(role.baseSkill) {
			case 0:
				modifiedRole.image = "images/lookingfor_business.svg";
				modifiedRole.baseSkillTitle = "Business";
				break;

			case 1:
				modifiedRole.image = "images/lookingfor_artist.svg";
				modifiedRole.baseSkillTitle = "Artist";
				break;

			case 2:
				modifiedRole.image = "images/lookingfor_scribe.svg";
				modifiedRole.baseSkillTitle = "Scribe";
				break;

			case 3:
				modifiedRole.image = "images/lookingfor_engineer.svg";
				modifiedRole.baseSkillTitle = "Engineer";
				break;

			default:
				modifiedRole.image = "";
				modifiedRole.baseSkill = "";
				modifiedRole.baseSkillTitle = "";
				break;
		}

		return modifiedRole;
	}

	removeWhiteSpaces = content => {
		return content.replace(/\s+/g,'');
	}

	//sets the state values for otherExpertises when a user types in an other expertise.
	handleOtherExpertise = e => {
		this.setState({
    		[e.target.id]: e.target.value
    	});
    	this.setState({ successSave: 'hidden' });
	}

	handleProjectNameChange = e => {
		this.setState({
			projectName: e.target.value,
			successSave: "hidden",
			projectNameError: "hidden"
		});
	}

	dataURItoBlob = dataURI => {
		// convert base64/URLEncoded data component to raw binary data held in a string.
		var byteString;
		if (dataURI.split(',')[0].indexOf('base64') >= 0)
			byteString = atob(dataURI.split(',')[1]);
		else
			byteString = unescape(dataURI.split(',')[1]);

		// separate out the mime component
		var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

		// write the bytes of the string to a typed array
		var ia = new Uint8Array(byteString.length);
		for (var i = 0; i < byteString.length; i++) {
			ia[i] = byteString.charCodeAt(i);
		}

		return new Blob([ia], {type:mimeString});
	}

	loadCroppedFile = (file, fileData) =>{
		if (!file) {
			return this.setState(this.getInitialState());
		}

		this.setState({
			displayPhoto: 'visible',
			fileTooBigError: 'hidden',
			file: fileData,
			fileData: fileData,
			successSave: 'hidden'
		});
	}

	showFileTooBigWarning = () => {
		this.setState({fileTooBigError:'visible_block'});
	}

	displayCropBox = () => {
		this.setState({displayCrop: true});
	}

	hideCropBox = () => {
		this.setState({displayCrop: false});
	}

	handlePhotoChange = newPhoto => {
		this.setState({
			file: newPhoto,
			displayPhoto: "visible",
			displayCrop: false,
			projectLogoError: "hidden",
			displayImageBox: "visible_block"
		});
	}

	handleTaglineChange = e => {
		this.setState({
			tagline: e.target.value,
			successSave: 'hidden',
			taglineError: 'hidden'
		});
	}

	handleLeadershipRoleChange = e => {
		this.setState({
			leaderRoleSummary:e.target.value,
			successSave: 'hidden',
			leadershipRoleError: 'hidden'
		})
	}

	handleCategoryChange = e => {
		this.setState({
			category: +e.target.value,
			successSave: 'hidden',
			categoryError: 'hidden'
		});
	}

	// This adds expertises to the expertise list or removes them based off of which checkboxes are checked.
	// The expertise list is then used by the handleAddRoles function to determine what roles should be added.
	handleSelection = e => {
		var expertiseList = this.state.expertiseList;
		if (e.target.checked){
			this.state.expertiseList.push(e.target.value);
			var stateObject = function() {
	      		var returnObj = {};
	      		returnObj[this.target.id] = true;
	         	return returnObj;
    		}.bind(e)();
    	this.setState( stateObject );
		} else if (!e.target.checked) {
			var index = expertiseList.indexOf(e.target.value);
			if (index > -1) {
    			expertiseList.splice(index, 1);
			}
			this.state.expertiseList = expertiseList;
			var stateObject = function() {
	      		var returnObj = {};
	      		returnObj[this.target.id] = false;
	         	return returnObj;
    		}.bind(e)();
    		this.setState( stateObject );
		}
	}

	// This function uses the expertise list to generate a role list. The role list will then be used
	// to generate the roles a user wants to add.
	handleAddRoles = () => {
		var detailRoleList = this.state.expertiseList.map(expertise => {
			var role = {
				id: `${expertise}${this.state.roleList.filter(({ expertiseTitle }) => expertiseTitle === expertise).length}`,
				image: "",
				baseSkill: "",
				expertise: "",
				baseSkillTitle: "",
				text: ""
			};

			const baseSkillId = CONSTANTS.expertises.find(({ id }) => id === expertise).baseSkill
			const baseSkill = CONSTANTS.baseSkills.find(({ id }) => id === baseSkillId).text;

			role.image = `images/lookingfor_${baseSkill.toLowerCase()}.svg`;
			role.baseSkill = baseSkillId;
			role.baseSkillTitle = baseSkill;
			role.expertise = expertise;

			return role;
		});
		if (this.state.otherBusiness !== "") {
			var businessRole = {
				id: `otherBusiness${detailRoleList.length + 1}`,
				image: "images/lookingfor_business.svg",
				baseSkill: 0,
				baseSkillTitle: "Business",
				expertise: this.state.otherBusiness,
				text: ""
			};
			detailRoleList.push(businessRole);
			this.setState({otherBusiness: ""});
		}
		if (this.state.otherArtist !== "") {
			var artistRole = {
				id: `otherArtist${detailRoleList.length + 1}`,
				image: "images/lookingfor_artist.svg",
				baseSkill: 1,
				baseSkillTitle: "Artist",
				expertise: this.state.otherArtist,
				text: ""
			};
			detailRoleList.push(artistRole);
			this.setState({otherArtist: ""});
		}
		if (this.state.otherScribe !== "") {
			var scribeRole = {
				id: `otherScribe${detailRoleList.length + 1}`,
				image: "images/lookingfor_scribe.svg",
				baseSkill: 2,
				baseSkillTitle: "Scribe",
				expertise: this.state.otherScribe,
				text: ""
			};
			detailRoleList.push(scribeRole);
			this.setState({otherScribe: ""});
		}
		if (this.state.otherEngineer !== "") {
			var engineerRole = {
				id: `otherEngineer${detailRoleList.length + 1}`,
				image: "images/lookingfor_engineer.svg",
				baseSkill: 3,
				baseSkillTitle: "Engineer",
				expertise: this.state.otherEngineer,
				text: ""
			};
			detailRoleList.push(engineerRole);
			this.setState({otherEngineer: ""});
		}

		// This adds the new roles to the old role list then creates a new role list.
		var oldRoleList = this.state.roleList;
		var newRoleList = detailRoleList.concat(oldRoleList);

		//This resets the states to default values after user adds roles and updates the role list state.
		this.setState({
					roleList: newRoleList,
					expertiseList: [],
					sales: false,
					marketing: false,
					productManagement: false,
					graphicDesigner: false,
					webDesigner: false,
					illustrator: false,
					animator: false,
					filmmaker: false,
					photographer: false,
					composer: false,
					soundDesigner:false,
					screenWriter: false,
					copyWriter: false,
					editor: false,
					softwareEngineer: false,
					electricalEngineer: false,
					mechanicalEngineer: false,
					audioEngineer: false,
					successSave: 'hidden',
					roleListError: 'hidden',
					emptyRoleError: 'hidden'
		});
		var element = document.getElementById("rolesAdded");
		element.scrollIntoView();
	}

	handleRoleDelete = roleId => {
		var roleList = this.state.roleList;
		var editedRoleList = roleList.filter(function(role) {
			return role.id !== roleId;
		});
		this.setState({
			roleList: editedRoleList,
			successSave: "hidden"
		});
	}

	handleRoleDescriptionChange = (text, roleId) => {
		var currentRoleList = this.state.roleList;
		currentRoleList.forEach( function(role) {
			if (role.id === roleId) {
				role.text = text;
			}
		});
		this.setState({
			roleList: currentRoleList,
			successSave: 'hidden',
			emptyRoleError: 'hidden'
		});
	}

	handleVideoChange = e => {
		this.setState({
			videoLink: e.target.value,
			successSave: 'hidden'
		});
	}

	handleTinyMCE = projectDescription => {
		this.setState({projectDescription: projectDescription});
	}

	handleProjectCourseInput = e => {
		this.setState({
			projectCourse: e.target.value,
			projectCourseError: "hidden"
		});
	}

	handleCourseSchoolSelect = e => {
		this.setState({
			projectCourseSchool: e.target.value,
			projectSchoolError: "hidden"
		});
	}

	handleMissionChange = e => {
		this.setState({
			projectMission: e.target.value,
			successSave: 'hidden',
			projectMissionError: 'hidden'
		});
	}

	handleDateChange = date => {
		this.setState({startDate: date});
	}
	handleProjectStatusChange = e => {
		this.setState({
			projectStatus: e.target.value,
			successSave: 'hidden'
		});
	}

	handleCountryChange = e => {

		this.setState({ projectCountryCode: e.target.value, projectCountryCodeError: 'hidden' });
		if(e.target.value === "US"){
			this.setState({
				zipCodeInput: "visible_block",
				zipCodeTitle: "visible_block"
			});
		} else {
			this.setState({
				zipCodeInput: "hidden",
				zipCodeTitle: "hidden",
				projectZipcodeError: "hidden",
				projectZipcode:""
			});
		}
	}

	handleZipCodeChange = e => {
		this.setState({
			projectZipcode: e.target.value,
			projectZipcodeError: "hidden"
		});
	}

	handleFutureBusinessChange = futureBusiness => {
		if (futureBusiness === "Yes") {
			this.setState({futureBusiness: true});
		} else if (futureBusiness === "No") {
			this.setState({futureBusiness: false});
		}
		this.setState({successSave: "hidden"});
	}

	handleEquityChange = e => {
		if (e.target.value == "yes") {
			this.setState({teammateEquity: true});
		} else if (e.target.value == "no") {
			this.setState({teammateEquity: false});
		}
		this.setState({successSave: "hidden"});
	}

	handleTargetMarketChange = e => {
		this.setState({
			targetMarket: e.target.value,
			successSave: "hidden"
		});
	}

	handleCompetitionChange = e => {
		this.setState({
			competition: e.target.value,
			successSave: "hidden"
		});
	}

	handleProfitPlanChange = e => {
		this.setState({
			profitPlan: e.target.value,
			successSave: "hidden"
		});
	}

	checkProjectPageForErrors = (projectPageToBeChecked) => {
		var error = false;
		var projectCourseSchool = this.state.projectCourseSchool;
		var currentRoleList = this.state.roleList;

		// This code removes the white spaces for checking strings.
		var trimmedProjectName = this.removeWhiteSpaces(this.state.projectName);
		var trimmedTagline = this.removeWhiteSpaces(this.state.tagline);
		var trimmedLeadershipRole = this.removeWhiteSpaces(this.state.leaderRoleSummary);
		var trimmedProjectMission = this.removeWhiteSpaces(this.state.projectMission);
		var trimmedProjectCourse = this.removeWhiteSpaces(this.state.projectCourse);

		if (projectPageToBeChecked === 1){
			if (trimmedProjectName === "") {
				this.setState({projectNameError: "visible"});
				error = true;
			} else {
				this.setState({projectNameError: "hidden"});
			}
			if (this.state.file === "/images/add_logo.svg") {
				this.setState({projectLogoError: "visible"});
				error = true;
			} else {
				this.setState({projectLogoError: "hidden"});
			}
			if (trimmedTagline === "") {
				this.setState({taglineError: "visible"});
				error = true;
			} else {
				this.setState({taglineError: "hidden"});
			}

			if (trimmedLeadershipRole === "") {
				this.setState({leadershipRoleError: "visible"});
				error = true;
			} else {
				this.setState ({leadershipRoleError: "hidden"});
			}

			if (this.state.category === -1) {
				this.setState({categoryError: "visible"});
				error = true;
			} else {
				this.setState({categoryError: "hidden"});
			}
			if (currentRoleList.length === 0) {
				this.setState({roleListError: "visible"});
				error = true;
			} else {
				this.setState({roleListError: "hidden"});
			}


			// This code checks to see if the role descriptions are empty or not.
			var emptyRoleError = false;
			currentRoleList.forEach(function(role) {
				if (role.text === "") {
					emptyRoleError = true;
				}
			});

			if (emptyRoleError === true) {
				this.setState({emptyRoleError: "visible"});
				error = true;
			} else {
				this.setState({emptyRoleError: "hidden"});
			}
		}

		if (projectPageToBeChecked === 2){
			if (trimmedProjectMission === "") {
				this.setState({projectMissionError: "visible"});
				error = true;
			} else {
				this.setState({projectMissionError: "hidden"});
			}
			var editorContent = tinyMCE.activeEditor.getContent({ format: 'text' });
			var trimmedEditorContent = this.removeWhiteSpaces(editorContent);

			if (trimmedEditorContent.length <= 1) {
				this.setState({projectDescriptionError: "visible"});
				error = true;
			} else {
				this.setState({projectDescriptionError: "hidden"});
			}

			if (this.state.projectDescription === "") {
				this.setState({projectDescriptionError: "visible"});
				error = true;
			} else {
				this.setState({projectDescriptionError: "hidden"});
			}

			if (trimmedProjectCourse !== "") {
				if (projectCourseSchool == "" || projectCourseSchool == "select_school" || projectCourseSchool == null) {
					this.setState({projectSchoolError: "visible"});
					error = true;
				} else {
					this.setState({projectSchoolError: "hidden"});
				}
			}

			if (trimmedProjectCourse == "") {
				if (projectCourseSchool !== ""){
					if (projectCourseSchool == "select_school" || projectCourseSchool == null){
						this.setState({projectCourseError: "hidden"});
					} else {
						this.setState({projectCourseError: "visible"});
						error = true;
					}
				} else {
					this.setState({projectCourseError: "hidden"});
				}
			}

			if (this.state.projectCountryCode === "-1"){
				this.setState({projectCountryCodeError: 'visible_block'});
				error = true;
			}

			if (this.state.projectCountryCode === "US"){
				// This code checks to see if the zipcode is valid.
				const zipCodeError = this.checkZipCodeForError();
				if (zipCodeError){
					error = true;
				}
			}

		}

		return error;
	}

	checkCountryCodeForError = () => {
		var error = false;
		if(this.state.countryCode === ''){
			this.setState({
				successSave:'hidden',
				failedSave: 'visible',
				projectCountryCodeError: 'visible'
			});
			error = true;
		}
		return error;
	}

	checkZipCodeForError = () => {
		var error = false;
		const reg = /^([0-9]){5,}$/;
			const zipCode = this.state.projectZipcode;
			if (reg.test(zipCode) == false) {
				this.setState({
					successSave:'hidden',
					failedSave: 'visible',
					projectZipcodeError: 'visible'
				});
				error = true;
			}

		return error;
	}
	handleBackButtonClick = (goToPageNumber, e) => {
		if(this.state.currentPage === 2){

		}
		if (goToPageNumber === 1) {
			this.setState({
				displayCreate01: "visible",
				displayCreate02: "hidden",
				displayCreate03: "hidden",
				displayProjectReview: "hidden",
				currentPage: 1
			});
		} else if (goToPageNumber === 2) {
			this.setState({
				displayCreate01: "hidden",
				displayCreate02: "visible",
				displayCreate03: "hidden",
				displayProjectReview: "hidden",
				currentPage: 2
			});
		} else if (goToPageNumber === 3) {
			this.setState({
				displayCreate01: "hidden",
				displayCreate02: "hidden",
				displayCreate03: "visible",
				displayProjectReview: "hidden",
				currentPage: 3
			});
		}
		this.setState({successSave: "hidden", failedSave: "hidden"});
	}

	handleSkip = (goToPageNumber, e) => {
		if (this.state.videoLink !== "") {
			var modifiedVideoLink = this.state.videoLink;
			modifiedVideoLink = modifiedVideoLink.replace('watch?v=', 'embed/');
			modifiedVideoLink = modifiedVideoLink.replace('&feature=youtu.be', '');
			modifiedVideoLink = modifiedVideoLink.replace('youtu.be/', 'www.youtube.com/embed/');
			this.setState({videoLink: modifiedVideoLink});
		}
		if (goToPageNumber === 1) {
			this.setState({
				displayCreate01: "visible",
				displayCreate02: "hidden",
				displayCreate03: "hidden",
				displayProjectReview: "hidden",
				currentPage: 1
			});
		} else if (goToPageNumber === 2) {
			this.setState({
				displayCreate01: "hidden",
				displayCreate02: "visible",
				displayCreate03: "hidden",
				displayProjectReview: "hidden",
				currentPage: 2
			});
		} else if (goToPageNumber === 3) {
			this.setState({
				displayCreate01: "hidden",
				displayCreate02: "hidden",
				displayCreate03: "visible",
				displayProjectReview: "hidden",
				currentPage: 3
			});
		}

		this.setState({successSave: "hidden", failedSave: "hidden"});
	}

	handleSave = e => {
		e.preventDefault();

		// This creates a roleList to be saved to the lookingFor roleDescriptions path.
		var currentRoleList = this.state.roleList;

		var roleList = this.createLookingForRoleList(currentRoleList);

		// This code goes through the roleList and creates an array that holds all the baseSkills
		// a project is looking for to be save to the lookingFor baseSkills path.
		var lookingForBaseSkills = this.createLookingForBaseSkillsArray(roleList);

		// This code goes through the roleList and creates an array that holds all the expertises
		// a project is looking for to be save to the lookingFor expertises path.
		var trimmedExpertises = this.createTrimmedExpertisesArray(roleList);

		// This grabs the HTML contents of the tinyMCE and assigns the value to a variable projectDescription.
		var projectDescription = this.state.projectDescription;

		// This code changes share youtube links into embed youtube links that can be used to embed youtube videos into the html.
		var modifiedVideoLink = this.state.videoLink;
		modifiedVideoLink = this.changeSharedYTLinkToEmbedded(modifiedVideoLink);
		this.setState({videoLink: modifiedVideoLink});

		var projectId = this.state.projectId;
		var projectName = this.state.projectName;
		var trimmedProjectName = this.removeWhiteSpaces(projectName);

		// This code changes the startDate to a string.
		var startDate = this.state.startDate
		var startDateString = startDate.toString();

		var error = false;

		error = this.checkCountryCodeForError();

		if(this.state.countryCode === 'US'){
			error = this.checkZipCodeForError();
		}

		if (error) {
			this.setState({
				successSave: 'hidden',
				failedSave: 'visible'
			});
			return;
		}

		if (trimmedProjectName === "") {

		} else {
			if (projectId === "") {
				HTTP.post('/project', {name: this.state.projectName}, function(error, body) {
					if (error) {
						this.setState({successSave: 'hidden', failedSave: 'visible'});
					} else {
						this.setState({
							successSave: 'visible',
							failedSave: 'hidden',
							projectId: body._id,
							projectKreator: USER
						});
						USER.projectsCreated.push(body);
						projectId = body._id;
						HTTP.post('/project/' + projectId,
							{
								name: this.state.projectName,
								tagline: this.state.tagline,
								category: this.state.category,
								leaderRoleSummary: this.state.leaderRoleSummary,
								mission: this.state.projectMission,
								course: this.state.projectCourse,
								school: this.state.projectCourseSchool,
								status: this.state.projectStatus,
								video: modifiedVideoLink,
								description: projectDescription,
								startDate: startDateString,
								country: this.state.projectCountryCode,
								zipCode: this.state.projectZipcode,
								futureBusiness: this.state.futureBusiness,
								teammateEquity: this.state.teammateEquity,
								targetMarket: this.state.targetMarket,
								competition: this.state.competition,
								profitPlan: this.state.profitPlan,
								lookingFor:roleList

							}, function(error, body) {
							if (error) {
								this.setState({successSave: 'hidden', failedSave: 'visible'});
							} else {
								this.setState({successSave: 'visible', failedSave: 'hidden', projectZipcodeError: 'hidden'});
								if (this.state.file && this.state.fileData) {
									var formData = new FormData();
									var blob = this.dataURItoBlob(this.state.file);
									formData.append('logo', blob);
									HTTP.post('/project/' + projectId + '/logo', formData, function(error, body) {
										if (error) {
											this.setState({successSave: 'hidden', failedSave: 'visible'});
										} else {
											this.setState({
												successSave: 'visible',
												failedSave: 'hidden',
												file: '/uploads/' + body,
												fileData: null
											});
										}
									}.bind(this));
								}
							}
						}.bind(this));
					}
				}.bind(this));
			} else {
				HTTP.post('/project/' + projectId,
					{
						name: this.state.projectName,
						tagline: this.state.tagline,
						category: this.state.category,
						leaderRoleSummary: this.state.leaderRoleSummary,
						mission: this.state.projectMission,
						course: this.state.projectCourse,
						school: this.state.projectCourseSchool,
						status: this.state.projectStatus,
						video: modifiedVideoLink,
						description: projectDescription,
						startDate: startDateString,
						country: this.state.projectCountryCode,
						zipCode: this.state.projectZipcode,
						futureBusiness: this.state.futureBusiness,
						teammateEquity: this.state.teammateEquity,
						targetMarket: this.state.targetMarket,
						competition: this.state.competition,
						profitPlan: this.state.profitPlan,
						lookingFor:roleList

					}, function(error, body) {
					if (error) {
						this.setState({successSave: 'hidden', failedSave: 'visible'});
					} else {
						this.setState({successSave: 'visible', failedSave: 'hidden', projectZipcodeError: 'hidden'});
						if (this.state.file && this.state.fileData) {
							var formData = new FormData();
							var blob = this.dataURItoBlob(this.state.file);
							formData.append('logo', blob);
							HTTP.post('/project/' + projectId + '/logo', formData, function(error, body) {
								if (error) {
									this.setState({successSave: 'hidden', failedSave: 'visible'});
								} else {
									this.setState({
										successSave: 'hidden',
										failedSave: 'hidden',
										file: '/uploads/' + body,
										fileData: null
									});
								}
							}.bind(this));
						}
					}
				}.bind(this));
			}
		}

		if (trimmedProjectName !== "") {
			this.setState({projectNameError: "hidden"});
		}

		if (this.state.tagline !== "") {
			this.setState({taglineError: "hidden"});
		}

		if (this.state.leaderRoleSummary !== ""){
			this.setState({leadershipRoleError: "hidden"});
		}

		if (this.state.category !== -1) {
			this.setState({categoryError: "hidden"});
		}
		if (this.state.projectMission !== "") {
			this.setState({projectMissionError: "hidden"});
		}

		var allRoles = this.state.roleList;
		if (allRoles.length !== 0) {
			this.setState({roleListError: "hidden"});
		}

		var editorContent = this.state.projectDescription;
		if (editorContent !== "") {
			this.setState({projectDescriptionError: "hidden"});
		}

		// This code checks to see if the role descriptions are empty or not.
		var emptyRoleError = false;
		currentRoleList.forEach(function(role) {
			if (role.text === "") {
				emptyRoleError = true;
			}
		});

		if (emptyRoleError === true) {
				this.setState({emptyRoleError: "visible"});
			} else {
				this.setState({emptyRoleError: "hidden"});
			}
	}

	handleSubmit = (goToPageNumber, e) => {
		e.preventDefault();
		var error = false;

		var currentRoleList = this.state.roleList;

		var roleList = this.createLookingForRoleList(currentRoleList);
		var lookingForBaseSkills = this.createLookingForBaseSkillsArray(roleList);
		var trimmedExpertises = this.createTrimmedExpertisesArray(roleList);

		// This grabs the HTML contents of the tinyMCE and assigns the value to a variable projectDescription.
		var projectDescription = this.state.projectDescription;

		// This code changes share youtube links into embed youtube links that can be used to embed youtube videos into the html.
		var modifiedVideoLink = this.state.videoLink;
		modifiedVideoLink = this.changeSharedYTLinkToEmbedded(modifiedVideoLink);
		this.setState({videoLink: modifiedVideoLink});

		var projectName = this.state.projectName;
		var tagline = this.state.tagline;
		var leaderRoleSummary = this.state.leaderRoleSummary;
		var projectMission = this.state.projectMission;
		var projectCourse = this.state.projectCourse;
		var projectCourseSchool = this.state.projectCourseSchool;

		// This code removes the white spaces for checking strings.
		var trimmedProjectName = this.removeWhiteSpaces(projectName);
		var trimmedTagline = this.removeWhiteSpaces(tagline);
		var trimmedLeadershipRole = this.removeWhiteSpaces(leaderRoleSummary);
		var trimmedProjectMission = this.removeWhiteSpaces(projectMission);
		var trimmedProjectCourse = this.removeWhiteSpaces(projectCourse);


		if (this.state.currentPage === 1) {
			error = this.checkProjectPageForErrors(1);
		}

		if (this.state.currentPage === 2) {
			error = this.checkProjectPageForErrors(2);
		}

		if (this.state.currentPage === 3) {
			var projectReviewError = false;

			if(this.checkProjectPageForErrors(1) === true){
				error = true;
				projectReviewError = true;
			}

			if (this.checkProjectPageForErrors(2) === true) {
				error = true;
				projectReviewError = true;
			}


			if (projectReviewError === true) {
				this.setState({projectReviewError: "visible"});
			}
		}


		// This code changes the startDate to a string.
		var startDate = this.state.startDate
		var startDateString = startDate.toString();

		var projectId = this.state.projectId;
		if (error === true) {

		} else {
			if (trimmedProjectName === "") {
				this.setState({
					projectNameError: "visible",
					successSave: 'hidden',
					failedSave: 'visible'
				});
			} else {
				if (projectId === "") {
					HTTP.post('/project', {name: this.state.projectName}, function(error, body) {
						if (error) {
							error = true;
						} else {
							this.setState({
								projectId: body._id,
								projectKreator: USER
							});
							projectId = body._id;
							USER.projectsCreated.push(body);
							HTTP.post('/project/' + projectId,
								{
									name: this.state.projectName,
									tagline: this.state.tagline,
									category: this.state.category,
									leaderRoleSummary: this.state.leaderRoleSummary,
									mission: this.state.projectMission,
									course: this.state.projectCourse,
									school: this.state.projectCourseSchool,
									status: this.state.projectStatus,
									video: modifiedVideoLink,
									description: projectDescription,
									startDate: startDateString,
									country: this.state.projectCountryCode,
									zipCode: this.state.projectZipcode,
									futureBusiness: this.state.futureBusiness,
									teammateEquity: this.state.teammateEquity,
									targetMarket: this.state.targetMarket,
									competition: this.state.competition,
									profitPlan: this.state.profitPlan,
									lookingFor:roleList

								}, function(error, body) {
								if (error) {
									error = true;
								} else {
									if (this.state.file && this.state.fileData) {
										var formData = new FormData();
										var blob = this.dataURItoBlob(this.state.file);
										formData.append('logo', blob);
										HTTP.post('/project/' + projectId + '/logo', formData, function(error, body) {
											if (error) {
												error = true;
												this.setState({failedSave: 'visible', successSave: 'hidden'});
											} else {
												console.log("success");
												this.setState({
													successSave: 'hidden',
													failedSave: 'hidden',
													file: '/uploads/' + body,
													fileData: null
												});
											}
										}.bind(this));
									}
								}
							}.bind(this));
						}
					}.bind(this));
				} else {

					HTTP.post('/project/' + projectId,
						{
							name: this.state.projectName,
							tagline: this.state.tagline,
							category: this.state.category,
							leaderRoleSummary: this.state.leaderRoleSummary,
							mission: this.state.projectMission,
							course: this.state.projectCourse,
							school: this.state.projectCourseSchool,
							status: this.state.projectStatus,
							video: modifiedVideoLink,
							description: projectDescription,
							startDate: startDateString,
							country: this.state.projectCountryCode,
							zipCode: this.state.projectZipcode,
							futureBusiness: this.state.futureBusiness,
							teammateEquity: this.state.teammateEquity,
							targetMarket: this.state.targetMarket,
							competition: this.state.competition,
							profitPlan: this.state.profitPlan,
							lookingFor:roleList

						}, function(error, body) {
						if (error) {
							this.setState({successSave: 'hidden', failedSave: 'visible'});
						} else {
							this.updateUserProjectsCreated(body);
							this.setState({failedSave: 'hidden'});
							if (this.state.file && this.state.fileData) {
								var formData = new FormData();
								var blob = this.dataURItoBlob(this.state.file);
								formData.append('logo', blob);
								HTTP.post('/project/' + projectId + '/logo', formData, function(error, body) {
									if (error) {
										this.setState({successSave: 'hidden', failedSave: 'visible'});
									} else {
										this.setState({
											successSave: 'hidden',
											failedSave: 'hidden',
											file: '/uploads/' + body,
											fileData: null
										});
									}
								}.bind(this));
							}
						}
					}.bind(this));
				}
			}
		}

		if (error === true) {
			this.setState({successSave: 'hidden', failedSave: 'visible'});
		} else if (error === false) {
			if (goToPageNumber === 2) {
				this.setState({
					displayCreate01: "hidden",
					displayCreate02: "visible",
					displayCreate03: "hidden",
					displayProjectReview: "hidden",
					currentPage: 2
				});
			} else if (goToPageNumber === 3) {
				this.setState({
					displayCreate01: "hidden",
					displayCreate02: "hidden",
					displayCreate03: "visible",
					displayProjectReview: "hidden",
					currentPage: 3
				});
			} else if (goToPageNumber === 4) {
				this.setState({
					displayCreate01: "hidden",
					displayCreate02: "hidden",
					displayCreate03: "hidden",
					displayProjectReview: "visible",
					currentPage: 4
				});
			}
			if (this.state.currentPage === 1) {
				this.setState({
					projectNameError: "hidden",
					taglineError: "hidden",
					leadershipRoleError: "hidden",
					categoryError: "hidden",
					roleListError: "hidden",
					emptyRoleError: "hidden",
					projectReviewError: "hidden"
				});
			}
			if (this.state.currentPage === 2) {
				this.setState({
					projectMissionError: "hidden",
					projectDescriptionError: "hidden",
					emptyRoleError: "hidden",
					projectReviewError: "hidden"
				});
			}
			this.setState({successSave: "hidden", failedSave: "hidden"});
		}
	}

	handleProjectSubmit = () => {
		var pathFinished = "/projectfinish";
		var pathUserAccount = "/useraccount";
		var projectId = this.state.projectId;

		if (this.state.published === true || this.state.underReview === true) {
			history.push(pathUserAccount);
		} else {
			HTTP.post(`/project/${projectId}/published`, { shouldPublish: true }, (error, body) => {
				if (error) {
					const errorText = body.split(":");
					this.setState({
						projectSubmitError: "visible",
						errorText: errorText[1],
					});
				} else {
					HTTP.get('/profile', (error, body) => {
						if (!error) USER.projectsCreated = body.projectsCreated;

						history.push(pathFinished);
					});
				}
			});
		}
	}

	updateUserProjectsCreated = (project) => {
		var i;
		for (i = 0; i < USER.projectsCreated.length; i++ ){
			if(project._id === USER.projectsCreated[i]._id){
				USER.projectsCreated[i].school = project.school;
				USER.projectsCreated[i].name = project.name;
			}
		}
	}

	checkCountryCode = () => {
		const projectCountryCode = this.state.projectCountryCode;
		var location = "";

		if (projectCountryCode === ''){
			this.setState({projectCountryCodeError: "visible"});
		} else {

			if(projectCountryCode === "US"){
				return;
			}

			var i;
			for (i = 0; i < CONSTANTS.countryData.length; i++) {
				if(projectCountryCode === CONSTANTS.countryData[i].code){
					this.setState({projectLocation: CONSTANTS.countryData[i].name});
				}
			}
		}
	}

	checkZipCode = () => {
		var reg = /^([0-9]){5,}$/;
		var zipCode = this.state.projectZipcode;
		var location = "";
		if (reg.test(zipCode) == false) {
			this.setState({projectZipcodeError: "visible"});
		} else {
			this.getNewLocationFromZipCode(zipCode);
		}
	}

	getNewLocationFromZipCode = (zipCode) =>{
		HTTP.get(`/zipinfo?zip=${zipCode}`, (error, body) => {
			if (error) {
				const errorText = body.split(":");
				this.setState({
					projectSubmitError: "visible",
					errorText: errorText[1],
				});
			} else {
				const locationString = body.city + ", " + body.state;
				this.setState({projectLocation: locationString});
			}
		});
	}

	createLookingForRoleList = (roleList) => {
		var roleListToReturn = roleList.map(function(role) {
			return (
				{
					baseSkill: role.baseSkill,
					expertise: role.expertise,
					description: role.text
				}
			);
		});
		return roleListToReturn;
	}

	createLookingForBaseSkillsArray = (roleList) => {
		var baseSkills = [];
		var lookingForBaseSkills = [];

		roleList.forEach(function(role) {
			if (role.baseSkill === 0) {
				baseSkills.push(0);
			} else if (role.baseSkill === 1) {
				baseSkills.push(1);
			} else if (role.baseSkill === 2) {
				baseSkills.push(2);
			} else if (role.baseSkill === 3) {
				baseSkills.push(3);
			}
		});

		if (baseSkills.indexOf(0) !== -1) {
			lookingForBaseSkills.push(0);
		}
		if (baseSkills.indexOf(1) !== -1) {
			lookingForBaseSkills.push(1);
		}
		if (baseSkills.indexOf(2) !== -1) {
			lookingForBaseSkills.push(2);
		}
		if (baseSkills.indexOf(3) !== -1) {
			lookingForBaseSkills.push(3);
		}

		return lookingForBaseSkills;
	}

	createTrimmedExpertisesArray = (roleList) => {
		var lookingForExpertises = [];
		var trimmedExpertises = [];
		roleList.forEach(function(role) {
			lookingForExpertises.push(role.expertise);
		});

		lookingForExpertises.forEach(function(expertise) {
			trimmedExpertises.push(expertise);
		});

		return trimmedExpertises;
	}

	changeSharedYTLinkToEmbedded = (link) => {
		link = link.replace('watch?v=', 'embed/');
		link = link.replace('&feature=youtu.be', '');
		link = link.replace('youtu.be/', 'www.youtube.com/embed/');
		return link;
	}

	render = () => {
		var editProjectButtonDisplay = this.props.edit ? true : false;
		var profilePublished = true;
		if (USER != null){
			profilePublished = USER.published;
		}
		if (!profilePublished) {
			return (
				<div>
					<NavBar_Notifications />
					<div className="clear"></div>
					<div>
						<h1 style={{color: "#00A4A4", textAlign: "center", margin: "auto", width: "19em", paddingTop: "2em"}}> Your profile must be approved before you can create a project.</h1>
					</div>
					<Footer />
				</div>
				);
		} else {
		return (
			<div className="viewport_width">
				<NavBar_Notifications
					isProjectStartPage = {true}
					showLoginButton = {false}
					showSignupButton = {false}
				/>
				<div className="clear"></div>
				<div className={this.state.displayCreate01}>
					<CreateProject01
						projectName={this.state.projectName}
						tagline={this.state.tagline}
						leaderRoleSummary={this.state.leaderRoleSummary}
						category={this.state.category}
						roleList={this.state.roleList}
						otherBusiness={this.state.otherBusiness}
						otherArtist={this.state.otherArtist}
						otherScribe={this.state.otherScribe}
						otherEngineer={this.state.otherEngineer}
						file={this.state.file}
						fileData={this.state.fileData}
						displayCrop={this.state.displayCrop}
						displayCropBox={this.displayCropBox}
						hideCropBox={this.hideCropBox}
						displayImageBox={this.state.displayImageBox}
						handlePhotoChange={this.handlePhotoChange}
						generateExpertiseInput={this.generateExpertiseInput}

						handleOtherExpertise={this.handleOtherExpertise}
						handleProjectNameChange={this.handleProjectNameChange}
						handleLeadershipRoleChange={this.handleLeadershipRoleChange}
						handleRoleDescriptionChange={this.handleRoleDescriptionChange}
						loadCroppedFile={this.loadCroppedFile}
						handleTaglineChange={this.handleTaglineChange}
						handleCategoryChange={this.handleCategoryChange}
						handleAddRoles={this.handleAddRoles}
						handleRoleDelete={this.handleRoleDelete}
						handleSave={this.handleSave}
						handleSubmit={this.handleSubmit}
						handleBackButtonClick={this.handleBackButtonClick}
						handleSkip={this.handleSkip}

						roleListError={this.state.roleListError}
						projectNameError={this.state.projectNameError}
						projectLogoError={this.state.projectLogoError}
						taglineError={this.state.taglineError}
						leadershipRoleError={this.state.leadershipRoleError}
						categoryError={this.state.categoryError}
						emptyRoleError={this.state.emptyRoleError}
						successSave={this.state.successSave}
						failedSave={this.state.failedSave}
						fileTooBigError={this.state.fileTooBigError}
						showFileTooBigWarning={this.showFileTooBigWarning}

						saveButton = {this.state.saveButton}
					/>
				</div>
				<div className={this.state.displayCreate02}>
					<CreateProject02
						projectId={this.state.projectId}
						videoLink={this.state.videoLink}
						projectDescription={this.state.projectDescription}
						projectCourse={this.state.projectCourse}
						projectCourseSchool={this.state.projectCourseSchool}
						projectMission={this.state.projectMission}
						projectStatus={this.state.projectStatus}
						projectCountryCode={this.state.projectCountryCode}
						projectZipcode={this.state.projectZipcode}
						startDate={this.state.startDate}

						handleVideoChange={this.handleVideoChange}
						handleTinyMCE={this.handleTinyMCE}
						handleProjectCourseInput={this.handleProjectCourseInput}
						handleMissionChange={this.handleMissionChange}
						handleDateChange={this.handleDateChange}
						handleProjectStatusChange={this.handleProjectStatusChange}
						handleSave={this.handleSave}
						handleSubmit={this.handleSubmit}
						handleBackButtonClick={this.handleBackButtonClick}
						handleSkip={this.handleSkip}
						handleCourseSchoolSelect={this.handleCourseSchoolSelect}
						handleCountryChange={this.handleCountryChange}
						handleZipCodeChange={this.handleZipCodeChange}
						checkCountryCode={this.checkCountryCode}
						checkZipCode={this.checkZipCode}

						zipCodeInput={this.state.zipCodeInput}
						zipCodeTitle={this.state.zipCodeTitle}

						successSave={this.state.successSave}
						failedSave={this.state.failedSave}
						projectNameError={this.state.projectNameError}
						projectMissionError={this.state.projectMissionError}
						projectCountryCodeError={this.state.projectCountryCodeError}
						projectZipcodeError={this.state.projectZipcodeError}
						projectDescriptionError={this.state.projectDescriptionError}
						projectCourseError={this.state.projectCourseError}
						projectSchoolError={this.state.projectSchoolError}

						saveButton={this.state.saveButton}
						startDateVisibility={this.state.startDateVisibility}
					/>
				</div>
				<div className={this.state.displayCreate03}>
					<CreateProject03
						profitPlan={this.state.profitPlan}
						competition={this.state.competition}
						targetMarket={this.state.targetMarket}
						teammateEquity={this.state.teammateEquity}
						futureBusiness={this.state.futureBusiness}

						handleFutureBusinessChange={this.handleFutureBusinessChange}
						handleEquityChange={this.handleEquityChange}
						handleTargetMarketChange={this.handleTargetMarketChange}
						handleCompetitionChange={this.handleCompetitionChange}
						handleProfitPlanChange={this.handleProfitPlanChange}
						handleSave={this.handleSave}
						handleSubmit={this.handleSubmit}
						handleBackButtonClick={this.handleBackButtonClick}
						handleSkip={this.handleSkip}

						successSave={this.state.successSave}
						failedSave={this.state.failedSave}
						projectNameError={this.state.projectNameError}
						projectReviewError={this.state.projectReviewError}

						saveButton={this.state.saveButton}
					/>
				</div>
				<div className={this.state.displayProjectReview}>
					<CreateProjectReview
						projectKreator={this.state.projectKreator}
						projectId={this.state.projectId}
						projectName={this.state.projectName}
						tagline={this.state.tagline}
						leaderRoleSummary={this.state.leaderRoleSummary}
						category={this.state.category}
						roleList={this.state.roleList}
						logo={this.state.file}
						videoLink={this.state.videoLink}
						projectDescription={this.state.projectDescription}
						projectMission={this.state.projectMission}
						startDate= {this.state.startDate}
						projectStatus={this.state.projectStatus}
						profitPlan={this.state.profitPlan}
						competition={this.state.competition}
						targetMarket={this.state.targetMarket}
						teammateEquity={this.state.teammateEquity}
						futureBusiness={this.state.futureBusiness}
						published={this.state.published}
						underReview={this.state.underReview}
						projectCourse={this.state.projectCourse}
						projectSchool={this.state.projectSchool}
						projectCountryCode={this.state.projectCountryCode}
						projectZipcode={this.state.projectZipcode}
						projectLocation={this.state.projectLocation}
						editProjectButtonDisplay={this.editProjectButtonDisplay}

						handleProjectSubmit={this.handleProjectSubmit}
						handleBackButtonClick={this.handleBackButtonClick}

						projectSubmitError={this.state.projectSubmitError}
						errorText={this.state.errorText}

						underReview={this.state.underReview}
						published={this.state.published}
					/>

				</div>
				<div className="bottom_padding" style={{paddingBottom: '4em'}}></div>
				<Footer />
			</div>
		);
		}
	}
};

export default connect(({ loggedIn }) => ({ loggedIn }))(CreateProjectController);
