import React from 'react';
import { Link } from 'react-router-dom';
import HTTP from '../../utils/Http.js';
import NavBarNotifications from '../nav/NavBar_Notifications.jsx';
import ProgressBar from './ProgressBar.jsx';
import Footer from '../footer/Footer.jsx';
import PropTypes from 'prop-types';
import history from 'app/history';

export default class SignupFuture extends React.Component {
	state = {
		profileCompleteness: USER ? USER.profileCompleteness : 0,
		futureBody: USER ? USER.futureGoals : '',
		successSave: 'hidden',
		failedSave: 'hidden'
	};

	handleFutureBodyChange = e => {
		this.setState({ futureBody: e.target.value, successSave: 'hidden'});
	}

	handleBack = () => {
		this.save({
			shouldContinueSignUp: true,
			backToEditProfilePage: true
		});
	}

	handleSave = () => {
		this.save();
	}

	handleSubmit = () => {
		this.save({ shouldContinueSignUp: true });
	}

	save = ({ shouldContinueSignUp = false, backToEditProfilePage = false } = {}) => {
		const futureBody = this.state.futureBody.trim();

		HTTP.post('/profile/futureGoals', { futureGoals: futureBody }, (error, body) => {
			if (error) {
				return this.setState({ failedSave: 'visible', successSave: 'hidden' });
			}

			USER.futureGoals = futureBody;
			USER.profileCompleteness = body.profileCompleteness;

			this.setState({
				profileCompleteness: body.profileCompleteness,
				failedSave: 'hidden',
				successSave: 'visible'
			});

			if (shouldContinueSignUp) {
				const path = backToEditProfilePage ? '/editprofilepage' : '/signupphoto';
				history.push(path);
			}
		});
	}

	render = () => {
		return (
			<div className="viewport_width">
				<NavBarNotifications
					showLoginButton={true}
					showSignupButton={true}
				/>

				<div className="signup_hero">
					<img src="images/signup_future.svg" />
				</div>

				<div className="clear"></div>

				<div>
					<div className="show_for_desktop">
						<div className="signup_progressbar" style={{width:'56em'}}>
							<ProgressBar progress={this.state.profileCompleteness} width="56em"/>
						</div>
					</div>

					<div className="show_for_mobile">
						<div className="signup_progressbar" style={{width:'19em'}}>
							<ProgressBar progress={this.state.profileCompleteness} width="18em"/>
						</div>
					</div>

					<div className="signup_container">
						<div id="future_container">
							<div id="future_header">Future Goals:</div>
							<span className={this.state.successSave + " signup_success"}>{CONSTANTS.errors.successSave}</span>
							<span className={this.state.failedSave + " signup_error"}>{CONSTANTS.errors.failedSave}</span>

							<textarea id="future_textarea" name="response_text" rows="10" cols="84" value={this.state.futureBody} onChange={this.handleFutureBodyChange} placeholder="What do you hope to accomplish in the future?  This could be the next few months or the next few years." />
							<div className="signup_navigation ">
								<input name="submit" className="nextnav" type="submit" value="Next >" onClick={this.handleSubmit}/>
								<Link to="/signupphoto" className="skipnav">Skip</Link>
								<input name="submit" className="savenav" type="submit" value="Save" onClick={this.handleSave}/>
								<Link to="/signupaddinfo" className="backnav">&lt; Back</Link>
							</div>
						</div>
					</div>
				</div>
				<div className="bottom_padding"></div>
				<Footer />
			</div>
		);
	}
};
