// This is the third page that discusses business elements of a project. It is optional.

import React from 'react';
import PropTypes from 'prop-types';

export default class CreateProject03 extends React.Component {
	static propTypes = {
		handleFutureBusinessChange: PropTypes.func,
		futureBusiness: PropTypes.bool,
		projectNameError: PropTypes.string,
		failedSave: PropTypes.string,
		projectReviewError: PropTypes.string,
		handleSubmit: PropTypes.func,
		handleBackButtonClick: PropTypes.func,
		teammateEquity: PropTypes.bool,
		handleEquityChange: PropTypes.func,
		targetMarket: PropTypes.string,
		handleTargetMarketChange: PropTypes.func,
		competition: PropTypes.string,
		handleCompetitionChange: PropTypes.func,
		profitPlan: PropTypes.string,
		handleProfitPlanChange: PropTypes.func,
		projectNameError: PropTypes.string,
		successSave: PropTypes.string,
		failedSave: PropTypes.string,
		projectReviewError: PropTypes.string,
		handleSubmit: PropTypes.func,
		handleSave: PropTypes.func,
		handleBackButtonClick: PropTypes.func,
		saveButton: PropTypes.string
	}
	handleClick = e => {
		this.props.handleFutureBusinessChange(e.target.value);
	}

	render = () => {
		var yesBackgroundColor = "#00A4A4";
		var noBackgroundColor = "#00A4A4";
		if (!this.props.futureBusiness){
			yesBackgroundColor = "#00A4A4";
			noBackgroundColor = "#00DEDE";
		} else {
			yesBackgroundColor = "#00DEDE";
			noBackgroundColor = "#00A4A4";
		}
		
		return (
			<div>
				<div>
					<div className="project_container">
						<div className="back_button_warning">
						{CONSTANTS.warnings.backButtonWarning}
						</div>
						<div className="create_project">
							<h2>Do you plan to turn this project into a business?</h2>
							<p>
							If you hope to eventually turn your project into a business, then please
							click “Yes” and answer the following questions, otherwise click “No” and
							review your project for submission.
							</p>
						</div>
						<div className="clear"></div>
						<div id="yesno_buttons">
							<input style={{backgroundColor: yesBackgroundColor}}className="yes" type="button" value="Yes" onClick={this.handleClick}/>
							<input style={{backgroundColor: noBackgroundColor}}type="button" value="No" onClick={this.handleClick}/>
						</div>
						<div className="clear"></div>
						<div className={!this.props.futureBusiness ? 'visible' : 'hidden'}>
							<div className="show_for_desktop">
								<div className="lower_navigation" style={{margin: '5em auto', width: '35em'}}>
									<p className={this.props.projectNameError + " error"} style={{float: 'right'}}>{CONSTANTS.errors.projectNameError}</p>
									<div className="clear"></div>
									<p className={this.props.failedSave + " error"} style={{float: 'right'}}>{CONSTANTS.errors.failedSaveCheckFields}</p>
									<div className="clear"></div>
									<p className={this.props.projectReviewError + " error"} style={{float: 'right'}}>{CONSTANTS.errors.projectReviewError} </p>
									<br/><br/>
									<div className="clear"></div>
									<input className="lower_projectreviewnav" name="submit" type="submit" value="Review >" onClick={this.props.handleSubmit.bind(null, 4)}/>
									<input className="backnav" name="submit" type="submit" value="< Back" onClick={this.props.handleBackButtonClick.bind(null, 2)}/>
								</div>
							</div>
							<div className="show_for_mobile">
								<div className="lower_navigation" style={{margin: '5em auto', width: '19em'}}>
									<p className={this.props.projectNameError + " error"} style={{float: 'right'}}>{CONSTANTS.errors.projectNameError}</p>
									<div className="clear"></div>
									<p className={this.props.failedSave + " error"} style={{float: 'right'}}>{CONSTANTS.errors.failedSaveCheckFields}</p>
									<div className="clear"></div>
									<p className={this.props.projectReviewError + " error"} style={{float: 'right'}}>{CONSTANTS.errors.projectReviewError} </p>
									<br/><br/>
									<div className="clear"></div>
									<input className="lower_projectreviewnav" name="submit" type="submit" value="Review >" onClick={this.props.handleSubmit.bind(null, 4)}/>
									<input className="backnav" name="submit" type="submit" value="< Back" onClick={this.props.handleBackButtonClick.bind(null, 2)}/>
								</div>
							</div>

						</div>
						<div className={this.props.futureBusiness ? 'visible' : 'hidden'}>
							<div className="project_box_holder">
								<div id="project_equitybox">
									<p className="project_business_titles">Are you willing to give teammates equity in your business?</p><br/><br/>
									<div id="project_equityintro">
										If you plan to make money from your project, it will be more attractive
										to kreators if you are willing to share the potential rewards. They may
										also work harder and be more involved knowing they have a financial
										stake in the business if it succeeds.<br/>
										<div id="equity_radioboxes">
											<input type="radio" name="yesno" value="yes" checked={this.props.teammateEquity} onChange={this.props.handleEquityChange} />
											<b>Yes</b>
											<input type="radio" name="yesno" value="no" checked={!this.props.teammateEquity} onChange={this.props.handleEquityChange} />
											<b>No</b>
										</div>
									</div>
								</div>
							</div>
							<div className="project_box_holder">
								<div id="project_marketbox">
									<p className="project_business_titles">Who is your target market and how will you market to them?</p><br/><br/>
									<div id="project_marketintro">
										<span>
										Successful businesses know how to identify and cater to their customers.
										Tell people who you believe is your target audience and how you will reach
										them.
										</span>
										<textarea id="market_textarea" maxLength="1000" rows="10" cols="40" value={this.props.targetMarket} onChange={this.props.handleTargetMarketChange} placeholder="(optional)"></textarea>
									</div>
								</div>
							</div>
							<div className="project_box_holder">
								<div id="project_competitionbox">
									<p className="project_business_titles">Who is your competition and what will be your competitive advantage?</p><br/><br/>
									<div id="project_competitionintro">
										<span>
										Every business has competition. For your business to succeed, you need
										to have some sort of advantage over them. Let kreators know what yours
										will be.
										</span>
										<textarea id="competition_textarea" maxLength="1000" rows="10" cols="40" value={this.props.competition} onChange={this.props.handleCompetitionChange} placeholder="(optional)"></textarea>
									</div>
								</div>
							</div>
							<div className="project_box_holder">
								<div id="project_moneybox">
									<p className="project_business_titles">How will your business make money?</p><br/><br/>
									<div id="project_moneyintro">
										<span>
										Businesses need to earn money eventually. Let people know how you intend to do this in the future.
										</span>
										<textarea id="money_textarea" maxLength="1000" rows="10" cols="40" value={this.props.profitPlan} onChange={this.props.handleProfitPlanChange} placeholder="(optional)"></textarea>
									</div>
								</div>
							</div>
							<div className="clear"></div>
							<div className="lower_navigation">
								<p className={this.props.projectNameError + " error"} style={{float: 'right'}}>{CONSTANTS.errors.projectNameError}</p>
								<div className="clear"></div>
								<p className={this.props.successSave + " success"} style={{float: 'right', marginRight: '0.8em'}}>{CONSTANTS.errors.successSave}</p>
								<div className="clear"></div>
								<p className={this.props.failedSave + " error"} style={{float: 'right', marginRight: '0.8em'}}>{CONSTANTS.errors.failedSave}</p>
								<div className="clear"></div>
								<p className={this.props.projectReviewError + " error"} style={{float: 'right'}}>{CONSTANTS.errors.projectReviewError} </p>
								
								<div className="clear"></div>
								<input className="lower_projectreviewnav" name="submit" type="submit" value="Review >" onClick={this.props.handleSubmit.bind(null, 4)}/>
								<input className={this.props.saveButton + " savenav"} name="submit" type="submit" value="Save" onClick={this.props.handleSave}/>
								<input className="backnav" name="submit" type="submit" value="< Back" onClick={this.props.handleBackButtonClick.bind(null, 2)}/>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
};