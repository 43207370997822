// A component used by the TeamManageInvites to show invitations sent by the USER.

import React from 'react';
import { QueryLink } from 'utils/Utils';
import PropTypes from 'prop-types';

export default class TeamSentInvitation extends React.Component {
	static propTypes = {
	  kreatorId: PropTypes.string,
	  showDeleteInviteAlert: PropTypes.func,
	  deleteButtonsVisibility: PropTypes.string
	}

	state = {
		text: "",
		editDescriptionVisibility: "hidden"
	}

	UNSAFE_componentWillMount = () => {
		const textDescription = "Allie is a pompous actress in a show that really stinks but people like it anyway. A much better show is Cheers or Fraiser. Man I miss those old shows. Golden Girls was really good as well. However, most of the cast members are dead now. Even if they were alive, a reboot would have to be called Ancient Women. Betty White is the lone survivor. Who would have thunk that? Allie is a pompous actress in a show that really stinks but people like it anyway. A much better show is Cheers or Fraiser. Man I miss those old shows. Golden Girls was really good as well. However, most of the cast members are dead now. Even if they were alive, a reboot would have to be called Ancient Women. Betty White is the lone survivor. Who would have thunk that?"
		this.setState({ text: textDescription});
	}


	render = () => {
		const deleteButtonsVisibility = this.props.deleteButtonsVisibility;
		var inviteStatus = "";
		var isPending = true;
		if (this.props.invitePending){
			inviteStatus = "open";
		} else {
			isPending = false;
			if (this.props.inviteAccepted){
				inviteStatus = "accepted";
			} else {
				inviteStatus = "closed";
			}
		}

		return (
				<div >
					<div className="invitation_box_holder">
						<div className="invitationbox">
							<QueryLink to={{ pathname:"/profilepage", query: { profileId: this.props.inviteeId }}}>
								<div className="invitation_picbox">
									<img src={"/uploads/" + this.props.inviteePhoto} />
								</div>
							</QueryLink>
							<h4>{this.props.inviteeFirstName} {this.props.inviteeLastName}</h4>
							<p>was invited to join</p>
							<QueryLink to={{ pathname:"/editprojectpage", query: { projectId: this.props.projectId }}}>
								<h4>{this.props.projectName}</h4>
							</QueryLink>
							<div style={{marginTop: "1em"}}>
								<p>status: <span style={{color: "#00A4A4"}}>{inviteStatus}</span></p>
							</div>
						</div>
						<div className="clear"></div>
						<div className={deleteButtonsVisibility} style={{marginBottom: '1em'}}>
							<button style={{fontSize: '1em'}} className="invitation_decline_button" onClick={() => this.props.handleShowDeleteInviteAlert(this.props.inviteId, isPending, this.props.projectName)}> Delete</button>
						</div>
					</div>
				</div>
		);
	}
};