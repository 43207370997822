import React from 'react';
import HTTP from '../../utils/Http';
import PropTypes from 'prop-types';

export default class ReportIssue extends React.Component {
	static contextTypes = {
		router: PropTypes.object
	}

	state = {
		selectedInput: NaN
	}

	handleRadioChange = e => {
		this.setState({ selectedInput: +e.target.value })
	}

	handleSendReport = () => {
		this.props.handleSendReport();
		this.setState({ selectedInput: NaN });
	}

	render = () => {
		return (
			<div>
				<div className={"report_container " + this.props.report_container_visibility}></div>
				<div className={"report_radioboxes " + this.props.radiobox_visibility}>
					<input type="radio" name="obscene" value="0" checked={this.state.selectedInput === 0} onChange={this.handleRadioChange} />
					This person posted obscene or inappropriate material.<br/>
					<div className="clear"></div>
					<input type="radio" name="selling" value="1" checked={this.state.selectedInput === 1} onChange={this.handleRadioChange} />
					This person is using the site to sell goods or services.<br/>
					<div className="clear"></div>
					<input type="radio" name="spamming" value="2" checked={this.state.selectedInput === 2} onChange={this.handleRadioChange} />
					This person is spamming users.
					<div className="clear"></div>
					<button onClick={this.handleSendReport} style={{ float: "right" }}>Report</button>
					<button onClick={this.props.handleCancelReport} style={{ float: "right", marginRight: '1em' }}> Cancel</button>
				</div>
				<div className={"thanks_report " + this.props.thankyou_visibility}>
					Thank you for your input. We will review the content in question and take appropriate action.
					<div className="clear"></div>
					<button onClick={this.props.handleCancelReport} style={{ float: "right", marginRight: '1em' }}> Close</button>
				</div>
			</div>
		);
	}
};

ReportIssue.propTypes = {
	handleSendReport: PropTypes.func,
	handleCancelReport: PropTypes.func,
	handleRadioChange: PropTypes.func,
	report_container_visibility: PropTypes.string,
	radiobox_visibility: PropTypes.string,
	thankyou_visibility: PropTypes.string
};
